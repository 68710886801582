define('social-web/pods/components/twitter-button/component', ['exports', 'social-web/mixins/share-button-component'], function (exports, _shareButtonComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_shareButtonComponent.default, {
    i18n: Ember.inject.service(),

    svgFill: '#fff',
    shareUrl: 'https://twitter.com/intent/tweet',
    windowTitle: Ember.computed(function () {
      return this.get('i18n').t('common.social.twitter');
    }),
    queryParams: Ember.computed('text', 'fullUrl', function () {
      return {
        text: Ember.String.isHTMLSafe(this.get('text')) ? this.get('text.string') : this.get('text'),
        url: this.get('fullUrl')
      };
    })
  });
});