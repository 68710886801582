define('social-web/mirage/factories/travel', ['exports', 'ember-cli-mirage', 'social-web/mirage/factories/application'], function (exports, _emberCliMirage, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    name: function name() {
      return _emberCliMirage.faker.company.companyName();
    },
    description: function description() {
      return _emberCliMirage.faker.lorem.paragraph();
    },
    sortPriority: function sortPriority(n) {
      return n;
    },
    url: function url() {
      return _emberCliMirage.faker.internet.url();
    },
    afterCreate: function afterCreate(travel, server) {
      if (!travel.travelCategory) {
        travel.travelCategory = server.create('travel-category');
      }
      travel.save();
    }
  });
});