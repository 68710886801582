define('social-web/pods/invite/model', ['exports', 'ember-data', 'ember-data/attr'], function (exports, _emberData, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    entityToFollowName: (0, _attr.default)('string'),
    entityToFollowId: (0, _attr.default)('string'),
    forwardingUrl: (0, _attr.default)('string')
  });
});