define('social-web/tests/mirage/mirage/factories/invite', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    id: _emberCliMirage.faker.random.number(),
    entityToFollowName: 'event',
    entityToFollowId: 1,
    forwardingUrl: function forwardingUrl() {
      return 'http://localhost:3000/events/' + this.entityToFollowId + '?invite_id=' + this.id;
    }
  });
});