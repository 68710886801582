define('social-web/pods/components/media-gallery/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    mediaService: Ember.inject.service('media'),
    i18n: Ember.inject.service(),

    classNameBindings: ['tiled', 'rect'],
    tiled: false,
    isVisible: Ember.computed.bool('media.length'),
    maxMedia: null,
    stretchLast: false,
    rect: false,

    largeColumns: 6,
    mediumColumns: 4,
    smallColumns: 2,
    columnClass: Ember.computed('smallColumns', 'mediumColumns', 'largeColumns', function () {
      var columnCounts = this.getProperties('smallColumns', 'mediumColumns', 'largeColumns');

      var columns = {
        small: 12 / columnCounts.smallColumns,
        medium: 12 / columnCounts.mediumColumns,
        large: 12 / columnCounts.largeColumns
      };
      return 'col s' + columns.small + ' m' + columns.medium + ' l' + columns.large;
    }),
    shouldStretchLast: Ember.computed('stretchLast', 'limitedMedia.length', 'maxMedia', 'smallColumns', 'mediumColumns', 'largeColumns', function () {

      return this.get('stretchLast') && this.get('limitedMedia.length') % 2;
    }),
    limitedMedia: Ember.computed('maxMedia', 'media', function () {
      var maxMedia = this.get('maxMedia'),
          media = this.get('media');
      return maxMedia ? media.slice(0, maxMedia) : media;
    }),
    hasExtra: Ember.computed('media.length', 'maxMedia', function () {
      var maxMedia = this.get('maxMedia');
      return maxMedia && this.get('media.length') > maxMedia;
    }),

    didInsertElement: function didInsertElement() {
      this.$().magnificPopup({
        delegate: 'a',
        type: 'image',
        tLoading: this.get('i18n').t('common.loading').string,
        gallery: {
          enabled: true,
          preload: [0, 1],
          tCounter: '<button class="transparent no-border light-grey-text lightens comment"><i class="material-icons">comment</i></button>'
        },
        zoom: {
          enabled: true,
          duration: 300,
          opener: function opener(element) {
            return element;
          }
        },
        image: {
          titleSrc: function titleSrc(_ref) {
            var el = _ref.el;

            return el.data('owner');
          }
        }
      });
    }
  });
});