define("social-web/pods/components/comment-card/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 2
              },
              "end": {
                "line": 8,
                "column": 2
              }
            },
            "moduleName": "social-web/pods/components/comment-card/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "lazy-image", [], ["url", ["subexpr", "@mut", [["get", "comment.publisher.customPictureThumb", ["loc", [null, [4, 21], [4, 57]]], 0, 0, 0, 0]], [], [], 0, 0], "fallbackUrl", ["subexpr", "@mut", [["get", "media.defaults.profilePicture", ["loc", [null, [5, 29], [5, 58]]], 0, 0, 0, 0]], [], [], 0, 0], "alt", ["subexpr", "@mut", [["get", "comment.publisher.fullName", ["loc", [null, [6, 21], [6, 47]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "circle responsive-img"], ["loc", [null, [4, 4], [7, 48]]], 0, 0]],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 14,
                "column": 2
              },
              "end": {
                "line": 14,
                "column": 94
              }
            },
            "moduleName": "social-web/pods/components/comment-card/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["content", "comment.publisher.fullName", ["loc", [null, [14, 2], [14, 94]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      }();
      var child2 = function () {
        var child0 = function () {
          var child0 = function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 22,
                    "column": 8
                  },
                  "end": {
                    "line": 27,
                    "column": 8
                  }
                },
                "moduleName": "social-web/pods/components/comment-card/template.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1, "class", "item");
                dom.setAttribute(el1, "href", "");
                var el2 = dom.createTextNode("\n            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("i");
                dom.setAttribute(el2, "class", "large icon");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element3 = dom.childAt(fragment, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createElementMorph(element3);
                morphs[1] = dom.createMorphAt(dom.childAt(element3, [1]), 0, 0);
                morphs[2] = dom.createMorphAt(element3, 3, 3);
                return morphs;
              },
              statements: [["element", "action", [["get", "actionHash.action", ["loc", [null, [23, 40], [23, 57]]], 0, 0, 0, 0], ["get", "statusUpdate", ["loc", [null, [23, 58], [23, 70]]], 0, 0, 0, 0]], [], ["loc", [null, [23, 31], [23, 72]]], 0, 0], ["content", "actionHash.icon", ["loc", [null, [24, 34], [24, 53]]], 0, 0, 0, 0], ["content", "actionHash.title", ["loc", [null, [25, 12], [25, 32]]], 0, 0, 0, 0]],
              locals: ["actionHash"],
              templates: []
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 19,
                  "column": 4
                },
                "end": {
                  "line": 29,
                  "column": 4
                }
              },
              "moduleName": "social-web/pods/components/comment-card/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("i");
              dom.setAttribute(el1, "class", "ui large icon");
              var el2 = dom.createTextNode("more_horiz");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "menu");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
              return morphs;
            },
            statements: [["block", "each", [["get", "contextActions", ["loc", [null, [22, 16], [22, 30]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [22, 8], [27, 17]]]]],
            locals: [],
            templates: [child0]
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 16,
                "column": 2
              },
              "end": {
                "line": 36,
                "column": 2
              }
            },
            "moduleName": "social-web/pods/components/comment-card/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1, "class", "text-content");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("section");
            dom.setAttribute(el1, "class", "post-actions right-align");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.setAttribute(el2, "href", "");
            dom.setAttribute(el2, "class", "hide-on-large-only");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("i");
            dom.setAttribute(el3, "class", "material-icons");
            var el4 = dom.createTextNode("more_horiz");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [5, 1]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            morphs[2] = dom.createElementMorph(element4);
            return morphs;
          },
          statements: [["content", "comment.body", ["loc", [null, [17, 28], [17, 44]]], 0, 0, 0, 0], ["block", "ui-dropdown", [], ["class", "right floated hide-on-med-and-down", "on", "hover"], 0, null, ["loc", [null, [19, 4], [29, 20]]]], ["element", "action", [["subexpr", "toggle", ["actionModal", ["get", "this", ["loc", [null, [32, 40], [32, 44]]], 0, 0, 0, 0]], [], ["loc", [null, [32, 18], [32, 45]]], 0, 0]], [], ["loc", [null, [32, 9], [32, 47]]], 0, 0]],
          locals: [],
          templates: [child0]
        };
      }();
      var child3 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 41,
                  "column": 4
                },
                "end": {
                  "line": 50,
                  "column": 4
                }
              },
              "moduleName": "social-web/pods/components/comment-card/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "edit-actions right-align");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("button");
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("button");
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var element1 = dom.childAt(element0, [1]);
              var element2 = dom.childAt(element0, [3]);
              var morphs = new Array(6);
              morphs[0] = dom.createAttrMorph(element1, 'class');
              morphs[1] = dom.createElementMorph(element1);
              morphs[2] = dom.createMorphAt(element1, 1, 1);
              morphs[3] = dom.createAttrMorph(element2, 'class');
              morphs[4] = dom.createAttrMorph(element2, 'onclick');
              morphs[5] = dom.createMorphAt(element2, 1, 1);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["grey btn ", ["subexpr", "unless", [["get", "saving", ["loc", [null, [43, 41], [43, 47]]], 0, 0, 0, 0], "waves-effect waves-light", "disabled"], [], ["loc", [null, [43, 32], [43, 87]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["cancel", ["get", "comment", ["loc", [null, [43, 107], [43, 114]]], 0, 0, 0, 0]], [], ["loc", [null, [43, 89], [43, 116]]], 0, 0], ["inline", "t", ["feed.comment.actions.cancelEdit"], [], ["loc", [null, [44, 10], [44, 49]]], 0, 0], ["attribute", "class", ["concat", ["red btn ", ["subexpr", "unless", [["get", "saving", ["loc", [null, [46, 40], [46, 46]]], 0, 0, 0, 0], "waves-effect waves-light", "disabled"], [], ["loc", [null, [46, 31], [46, 86]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "onclick", ["subexpr", "perform", [["get", "save", ["loc", [null, [46, 106], [46, 110]]], 0, 0, 0, 0], ["get", "comment", ["loc", [null, [46, 111], [46, 118]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [46, 120]]], 0, 0], 0, 0, 0, 0], ["inline", "t", ["feed.comment.actions.finishEdit"], [], ["loc", [null, [47, 10], [47, 49]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 36,
                "column": 2
              },
              "end": {
                "line": 51,
                "column": 2
              }
            },
            "moduleName": "social-web/pods/components/comment-card/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "focus-textarea", [], ["placeholder", ["subexpr", "t", ["feed.comment.placeholder"], [], ["loc", [null, [37, 33], [37, 63]]], 0, 0], "value", ["subexpr", "@mut", [["get", "comment.body", ["loc", [null, [38, 27], [38, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "focus-in", ["subexpr", "action", ["setFocus", true], [], ["loc", [null, [39, 30], [39, 54]]], 0, 0], "focus-out", ["subexpr", "action", ["setFocus", false], [], ["loc", [null, [40, 31], [40, 56]]], 0, 0]], ["loc", [null, [37, 4], [40, 58]]], 0, 0], ["block", "if", [["subexpr", "or", [["get", "comment.body", ["loc", [null, [41, 14], [41, 26]]], 0, 0, 0, 0], ["get", "focused", ["loc", [null, [41, 27], [41, 34]]], 0, 0, 0, 0]], [], ["loc", [null, [41, 10], [41, 35]]], 0, 0]], [], 0, null, ["loc", [null, [41, 4], [50, 11]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 52,
              "column": 0
            }
          },
          "moduleName": "social-web/pods/components/comment-card/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("time");
          dom.setAttribute(el1, "class", "right grey-text time-stamp");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [2]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createAttrMorph(element5, 'datetime');
          morphs[2] = dom.createMorphAt(element5, 1, 1);
          morphs[3] = dom.createMorphAt(fragment, 4, 4, contextualElement);
          morphs[4] = dom.createMorphAt(fragment, 6, 6, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "link-to", ["user.show", ["get", "comment.publisher.slug", ["loc", [null, [3, 25], [3, 47]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [3, 2], [8, 14]]]], ["attribute", "datetime", ["get", "comment.publishedAt", ["loc", [null, [10, 19], [10, 38]]], 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "moment-from-now", [["get", "comment.publishedAt", ["loc", [null, [11, 22], [11, 41]]], 0, 0, 0, 0]], ["interval", 60000], ["loc", [null, [11, 4], [11, 58]]], 0, 0], ["block", "link-to", ["user.show", ["get", "comment.publisher.slug", ["loc", [null, [14, 51], [14, 73]]], 0, 0, 0, 0]], ["class", "card-title"], 1, null, ["loc", [null, [14, 2], [14, 94]]]], ["block", "unless", [["get", "editing", ["loc", [null, [16, 12], [16, 19]]], 0, 0, 0, 0]], [], 2, 3, ["loc", [null, [16, 2], [51, 13]]]]],
        locals: ["comment"],
        templates: [child0, child1, child2, child3]
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 66,
            "column": 0
          }
        },
        "moduleName": "social-web/pods/components/comment-card/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
        morphs[3] = dom.createMorphAt(fragment, 7, 7, contextualElement);
        return morphs;
      },
      statements: [["block", "with", [["subexpr", "or", [["get", "comment", ["loc", [null, [2, 12], [2, 19]]], 0, 0, 0, 0], ["subexpr", "hash", [], ["publisher", ["get", "session.currentUser", ["loc", [null, [2, 36], [2, 55]]], 0, 0, 0, 0]], ["loc", [null, [2, 20], [2, 56]]], 0, 0]], [], ["loc", [null, [2, 8], [2, 57]]], 0, 0]], [], 0, null, ["loc", [null, [2, 0], [52, 9]]]], ["inline", "action-modal", [], ["title", ["subexpr", "t", ["feed.comment.actions.title"], [], ["loc", [null, [54, 21], [54, 53]]], 0, 0], "contextActions", ["subexpr", "@mut", [["get", "contextActions", ["loc", [null, [55, 30], [55, 44]]], 0, 0, 0, 0]], [], [], 0, 0], "visible", ["subexpr", "mut", [["get", "actionModal", ["loc", [null, [56, 28], [56, 39]]], 0, 0, 0, 0]], [], ["loc", [null, [56, 23], [56, 40]]], 0, 0]], ["loc", [null, [54, 0], [56, 42]]], 0, 0], ["inline", "prompt-modal", [], ["title", ["subexpr", "t", ["feed.comment.actions.deletePrompt.title"], [], ["loc", [null, [58, 21], [58, 66]]], 0, 0], "message", ["subexpr", "t", ["feed.comment.actions.deletePrompt.message"], [], ["loc", [null, [59, 23], [59, 70]]], 0, 0], "contextActions", ["subexpr", "@mut", [["get", "deleteActions", ["loc", [null, [60, 30], [60, 43]]], 0, 0, 0, 0]], [], [], 0, 0], "visible", ["subexpr", "mut", [["get", "deleteModal", ["loc", [null, [61, 28], [61, 39]]], 0, 0, 0, 0]], [], ["loc", [null, [61, 23], [61, 40]]], 0, 0]], ["loc", [null, [58, 0], [61, 42]]], 0, 0], ["inline", "report-modal", [], ["message", ["subexpr", "t", ["feed.comment.report.message"], [], ["loc", [null, [63, 23], [63, 56]]], 0, 0], "model", ["subexpr", "@mut", [["get", "comment", ["loc", [null, [64, 21], [64, 28]]], 0, 0, 0, 0]], [], [], 0, 0], "visible", ["subexpr", "mut", [["get", "reportModal", ["loc", [null, [65, 28], [65, 39]]], 0, 0, 0, 0]], [], ["loc", [null, [65, 23], [65, 40]]], 0, 0]], ["loc", [null, [63, 0], [65, 42]]], 0, 0]],
      locals: [],
      templates: [child0]
    };
  }());
});