define("social-web/pods/components/filter-modal/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 4,
                  "column": 4
                },
                "end": {
                  "line": 11,
                  "column": 4
                }
              },
              "moduleName": "social-web/pods/components/filter-modal/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "modal-header");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2, "href", "");
              dom.setAttribute(el2, "class", "grey-text right");
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("i");
              dom.setAttribute(el3, "class", "material-icons");
              var el4 = dom.createTextNode("close");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("h4");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element3 = dom.childAt(fragment, [1]);
              var element4 = dom.childAt(element3, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element4);
              morphs[1] = dom.createMorphAt(dom.childAt(element3, [3]), 0, 0);
              return morphs;
            },
            statements: [["element", "action", [["subexpr", "toggle", ["visible", ["get", "this", ["loc", [null, [6, 67], [6, 71]]], 0, 0, 0, 0]], [], ["loc", [null, [6, 49], [6, 72]]], 0, 0]], [], ["loc", [null, [6, 40], [6, 74]]], 0, 0], ["content", "title", ["loc", [null, [9, 12], [9, 21]]], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          var child0 = function () {
            var child0 = function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 19,
                      "column": 10
                    },
                    "end": {
                      "line": 21,
                      "column": 10
                    }
                  },
                  "moduleName": "social-web/pods/components/filter-modal/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "spinner-widget", [], ["class", "abs-right-1"], ["loc", [null, [20, 12], [20, 50]]], 0, 0]],
                locals: [],
                templates: []
              };
            }();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 13,
                    "column": 6
                  },
                  "end": {
                    "line": 23,
                    "column": 6
                  }
                },
                "moduleName": "social-web/pods/components/filter-modal/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "modal-content search-wrapper primary-grey");
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element2 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(element2, 1, 1);
                morphs[1] = dom.createMorphAt(element2, 3, 3);
                return morphs;
              },
              statements: [["inline", "input", [], ["placeholder", ["subexpr", "or", [["get", "searchPlaceholder", ["loc", [null, [15, 34], [15, 51]]], 0, 0, 0, 0], ["subexpr", "t", ["common.search.placeholder"], [], ["loc", [null, [15, 52], [15, 83]]], 0, 0]], [], ["loc", [null, [15, 30], [15, 84]]], 0, 0], "value", ["subexpr", "@mut", [["get", "searchTerm", ["loc", [null, [16, 24], [16, 34]]], 0, 0, 0, 0]], [], [], 0, 0], "key-up", ["subexpr", "perform", [["get", "search", ["loc", [null, [17, 34], [17, 40]]], 0, 0, 0, 0], ["get", "searchTerm", ["loc", [null, [17, 41], [17, 51]]], 0, 0, 0, 0]], [], ["loc", [null, [17, 25], [17, 52]]], 0, 0], "class", "primary-text no-bottom-margin"], ["loc", [null, [15, 10], [18, 57]]], 0, 0], ["block", "if", [["subexpr", "and", [["get", "search.isRunning", ["loc", [null, [19, 21], [19, 37]]], 0, 0, 0, 0], ["get", "items.length", ["loc", [null, [19, 38], [19, 50]]], 0, 0, 0, 0]], [], ["loc", [null, [19, 16], [19, 51]]], 0, 0]], [], 0, null, ["loc", [null, [19, 10], [21, 17]]]]],
              locals: [],
              templates: [child0]
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 12,
                  "column": 4
                },
                "end": {
                  "line": 24,
                  "column": 4
                }
              },
              "moduleName": "social-web/pods/components/filter-modal/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["subexpr", "gt", [["get", "initialCount", ["loc", [null, [13, 16], [13, 28]]], 0, 0, 0, 0], ["get", "resultCountForSearch", ["loc", [null, [13, 29], [13, 49]]], 0, 0, 0, 0]], [], ["loc", [null, [13, 12], [13, 50]]], 0, 0]], [], 0, null, ["loc", [null, [13, 6], [23, 13]]]]],
            locals: [],
            templates: [child0]
          };
        }();
        var child2 = function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 26,
                  "column": 6
                },
                "end": {
                  "line": 28,
                  "column": 6
                }
              },
              "moduleName": "social-web/pods/components/filter-modal/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "spinner-widget", [], ["class", "spinner-wrapper"], ["loc", [null, [27, 8], [27, 50]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        var child3 = function () {
          var child0 = function () {
            var child0 = function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 30,
                      "column": 10
                    },
                    "end": {
                      "line": 32,
                      "column": 10
                    }
                  },
                  "moduleName": "social-web/pods/components/filter-modal/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "yield", [["get", "item", ["loc", [null, [31, 20], [31, 24]]], 0, 0, 0, 0]], [], ["loc", [null, [31, 12], [31, 26]]], 0, 0]],
                locals: [],
                templates: []
              };
            }();
            var child1 = function () {
              var child0 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 34,
                        "column": 14
                      },
                      "end": {
                        "line": 36,
                        "column": 14
                      }
                    },
                    "moduleName": "social-web/pods/components/filter-modal/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("i");
                    dom.setAttribute(el1, "class", "primary-text material-icons");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                    return morphs;
                  },
                  statements: [["content", "item.icon", ["loc", [null, [35, 55], [35, 68]]], 0, 0, 0, 0]],
                  locals: [],
                  templates: []
                };
              }();
              var child1 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 38,
                        "column": 14
                      },
                      "end": {
                        "line": 40,
                        "column": 14
                      }
                    },
                    "moduleName": "social-web/pods/components/filter-modal/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("p");
                    dom.setAttribute(el1, "class", "secondary-text");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                    return morphs;
                  },
                  statements: [["content", "item.description", ["loc", [null, [39, 42], [39, 62]]], 0, 0, 0, 0]],
                  locals: [],
                  templates: []
                };
              }();
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 32,
                      "column": 10
                    },
                    "end": {
                      "line": 42,
                      "column": 10
                    }
                  },
                  "moduleName": "social-web/pods/components/filter-modal/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1, "class", "collection-item waves-effect waves-grey");
                  dom.setAttribute(el1, "href", "");
                  var el2 = dom.createTextNode("\n");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("              ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("strong");
                  dom.setAttribute(el2, "class", "primary-text");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("            ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element1 = dom.childAt(fragment, [1]);
                  var morphs = new Array(4);
                  morphs[0] = dom.createElementMorph(element1);
                  morphs[1] = dom.createMorphAt(element1, 1, 1);
                  morphs[2] = dom.createMorphAt(dom.childAt(element1, [3]), 0, 0);
                  morphs[3] = dom.createMorphAt(element1, 5, 5);
                  return morphs;
                },
                statements: [["element", "action", ["select", ["get", "item", ["loc", [null, [33, 86], [33, 90]]], 0, 0, 0, 0]], [], ["loc", [null, [33, 68], [33, 92]]], 0, 0], ["block", "if", [["get", "item.icon", ["loc", [null, [34, 20], [34, 29]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [34, 14], [36, 21]]]], ["content", "item.title", ["loc", [null, [37, 43], [37, 57]]], 0, 0, 0, 0], ["block", "if", [["get", "item.description", ["loc", [null, [38, 20], [38, 36]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [38, 14], [40, 21]]]]],
                locals: [],
                templates: [child0, child1]
              };
            }();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 29,
                    "column": 8
                  },
                  "end": {
                    "line": 43,
                    "column": 8
                  }
                },
                "moduleName": "social-web/pods/components/filter-modal/template.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["get", "hasBlock", ["loc", [null, [30, 16], [30, 24]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [30, 10], [42, 17]]]]],
              locals: ["item"],
              templates: [child0, child1]
            };
          }();
          var child1 = function () {
            var child0 = function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 44,
                      "column": 10
                    },
                    "end": {
                      "line": 46,
                      "column": 10
                    }
                  },
                  "moduleName": "social-web/pods/components/filter-modal/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "yield", [], ["to", "inerse"], ["loc", [null, [45, 12], [45, 33]]], 0, 0]],
                locals: [],
                templates: []
              };
            }();
            var child1 = function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 46,
                      "column": 10
                    },
                    "end": {
                      "line": 50,
                      "column": 10
                    }
                  },
                  "moduleName": "social-web/pods/components/filter-modal/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1, "class", "grey-text collection-item waves-effect waves-grey");
                  dom.setAttribute(el1, "href", "");
                  var el2 = dom.createTextNode("\n              ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("strong");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n            ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element0 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createElementMorph(element0);
                  morphs[1] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
                  return morphs;
                },
                statements: [["element", "action", ["clearSearch"], [], ["loc", [null, [47, 78], [47, 102]]], 0, 0], ["inline", "t", ["common.search.noResults"], [], ["loc", [null, [48, 22], [48, 53]]], 0, 0]],
                locals: [],
                templates: []
              };
            }();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 43,
                    "column": 8
                  },
                  "end": {
                    "line": 51,
                    "column": 8
                  }
                },
                "moduleName": "social-web/pods/components/filter-modal/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["subexpr", "hasBlock", ["inverse"], [], ["loc", [null, [44, 16], [44, 36]]], 0, 0]], [], 0, 1, ["loc", [null, [44, 10], [50, 17]]]]],
              locals: [],
              templates: [child0, child1]
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 28,
                  "column": 6
                },
                "end": {
                  "line": 52,
                  "column": 6
                }
              },
              "moduleName": "social-web/pods/components/filter-modal/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "each", [["get", "items", ["loc", [null, [29, 16], [29, 21]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [29, 8], [51, 17]]]]],
            locals: [],
            templates: [child0, child1]
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 2
              },
              "end": {
                "line": 55,
                "column": 2
              }
            },
            "moduleName": "social-web/pods/components/filter-modal/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "modal-content collection no-top-border");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "modal-footer");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[2] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [["block", "if", [["get", "title", ["loc", [null, [4, 10], [4, 15]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [4, 4], [11, 11]]]], ["block", "if", [["subexpr", "and", [["get", "handleSearch", ["loc", [null, [12, 15], [12, 27]]], 0, 0, 0, 0], ["get", "modelName", ["loc", [null, [12, 28], [12, 37]]], 0, 0, 0, 0]], [], ["loc", [null, [12, 10], [12, 38]]], 0, 0]], [], 1, null, ["loc", [null, [12, 4], [24, 11]]]], ["block", "if", [["subexpr", "and", [["get", "search.isRunning", ["loc", [null, [26, 17], [26, 33]]], 0, 0, 0, 0], ["subexpr", "not", [["get", "items.length", ["loc", [null, [26, 39], [26, 51]]], 0, 0, 0, 0]], [], ["loc", [null, [26, 34], [26, 52]]], 0, 0]], [], ["loc", [null, [26, 12], [26, 53]]], 0, 0]], [], 2, 3, ["loc", [null, [26, 6], [52, 13]]]]],
          locals: [],
          templates: [child0, child1, child2, child3]
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 56,
              "column": 0
            }
          },
          "moduleName": "social-web/pods/components/filter-modal/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "md-modal", [], ["close", ["subexpr", "toggle", ["visible", ["get", "this", ["loc", [null, [3, 38], [3, 42]]], 0, 0, 0, 0]], [], ["loc", [null, [3, 20], [3, 43]]], 0, 0], "modalClassNames", ["subexpr", "@mut", [["get", "modalClassNames", ["loc", [null, [3, 60], [3, 75]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [3, 2], [55, 15]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 57,
            "column": 0
          }
        },
        "moduleName": "social-web/pods/components/filter-modal/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "visible", ["loc", [null, [2, 6], [2, 13]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [2, 0], [56, 7]]]]],
      locals: [],
      templates: [child0]
    };
  }());
});