define('social-web/pods/notification/adapter', ['exports', 'social-web/pods/application/adapter'], function (exports, _adapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _adapter.default.extend({
    acknowledgeUnseen: function acknowledgeUnseen(user) {
      var _this = this;

      var buildURL = this.buildURL('user', user.id, null, null, {
        scope: {
          model: user,
          action: 'notifications/acknowledge_unseen'
        }
      });
      return this.ajax(buildURL, 'POST').then(function (response) {
        _this.get('store').peekAll('notification').forEach(function (notification) {
          notification.set('seen', true);
        });
        return response;
      });
    },
    markAsRead: function markAsRead(notification) {
      var buildURL = this.buildURL('notification', notification.id, null, null, {
        scope: {
          model: notification.get('user'),
          scope: {
            model: notification,
            action: 'mark_as_read'
          }
        }
      });
      return this.ajax(buildURL, 'POST').then(function (response) {
        notification.set('read', true);
        return response;
      });
    }
  });
});