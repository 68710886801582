define('social-web/pods/notification/model', ['exports', 'ember-data/attr', 'ember-data/relationships', 'social-web/pods/application/model'], function (exports, _attr, _relationships, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    mention: Ember.inject.service(),

    message: (0, _attr.default)('string'),
    read: (0, _attr.default)('boolean'),
    seen: (0, _attr.default)('boolean'),
    editionId: (0, _attr.default)('number'),
    eventId: (0, _attr.default)('number'),
    type: (0, _attr.default)('string'),

    user: (0, _relationships.belongsTo)('user', { inverse: 'notifications' }),
    statusUpdate: (0, _relationships.belongsTo)(),
    notificator: (0, _relationships.belongsTo)('user', { inverse: null }),

    formattedMessage: Ember.computed('message', function () {
      return this.get('mention').stripMentions(this.get('message'));
    }),

    statusUpdateId: Ember.computed('statusUpdate', function () {
      return this.belongsTo('statusUpdate').belongsToRelationship.canonicalState.id;
    }),

    linkTo: Ember.computed('type', 'editionId', 'eventId', function () {
      var type = this.get('type'),
          types = this.get('types');
      var linkToType = types[type];
      if (linkToType) {
        var map = {};
        map[linkToType] = true;
        return map;
      }
    }),
    types: {
      UserYouFollowIsMentionedNotification: 'post',
      NewCommentNotification: 'post',
      LikeNotification: 'post',
      PinnedPostNotification: 'post',
      NewPostMentionNotification: 'post',
      UserPostsResultsNotification: 'post',
      UserYouFollowPostsNotification: 'post',
      UserJoinsCompetitionNotification: 'edition',
      InterimResultsNotification: 'edition',
      FollowedByNotification: 'user',
      InviteeAcceptedNotification: 'user',
      // 
      GroupNewPostNotification: 'post',
      GroupAdminNewPostNotification: 'post'
    },

    markAsRead: function markAsRead() {
      return this.get('adapter').markAsRead(this);
    }
  });
});