define('social-web/pods/user-photos/model', ['exports', 'ember-data', 'ember-data/attr'], function (exports, _emberData, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    coverPhoto: (0, _attr.default)('file'),
    customPicture: (0, _attr.default)('file'),
    deleteCoverPhoto: (0, _attr.default)('boolean')
  });
});