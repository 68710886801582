define('social-web/pods/password/model', ['exports', 'ember-data', 'ember-data/attr', 'social-web/pods/password/validations'], function (exports, _emberData, _attr, _validations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    currentPassword: (0, _attr.default)('string'),
    password: (0, _attr.default)('string'),
    passwordConfirmation: (0, _attr.default)('string'),
    resetPasswordToken: (0, _attr.default)('string'),

    validations: _validations.validations,
    changePasswordValidations: _validations.changePasswordValidations
  });
});