define('social-web/pods/edition-activity/model', ['exports', 'ember-data/attr', 'ember-data/relationships', 'social-web/pods/application/model'], function (exports, _attr, _relationships, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    display: Ember.computed.alias('name'),

    name: (0, _attr.default)('string'),
    url: (0, _attr.default)('string'),
    file: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),

    start: (0, _attr.default)('date'),
    end: (0, _attr.default)('date'),
    startTimezone: (0, _attr.default)('string'),
    endTimezone: (0, _attr.default)('string'),

    edition: (0, _relationships.belongsTo)(),
    location: (0, _relationships.belongsTo)(),

    createdAt: (0, _attr.default)('date'),
    updatedAt: (0, _attr.default)('date')
  });
});