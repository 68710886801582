define("social-web/pods/scrapbook/show/route", ["exports", "social-web/mixins/infinity-route"], function (exports, _infinityRoute) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_infinityRoute.default, {
    model: function model(params) {
      var _this = this;

      return this.store.findRecord("scrapbook", params.id).then(function (scrapbook) {
        var user = scrapbook.get("user.content");
        var edition = scrapbook.get("edition.content");
        var event = edition.get("event.content");

        return Ember.RSVP.hash({
          scrapbook: scrapbook,
          user: user,
          edition: edition,
          event: event,
          media: _this.infinityModel("media", {
            perPage: 12,
            modelPath: "controller.model.media",
            scope: {
              model: scrapbook,
              action: "media"
            }
          })
        });
      }).catch(function (error) {
        if (error.status === 404) {
          _this.transitionTo("install-app");
        } else {
          throw error;
        }
      });
    }
  });
});