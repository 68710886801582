define('social-web/pods/head-tags/service', ['exports', 'ember-cli-meta-tags/services/head-tags'], function (exports, _headTags) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _headTags.default.extend({
    getBasicHeadTags: function getBasicHeadTags(_ref) {
      var title = _ref.title,
          description = _ref.description,
          image = _ref.image,
          type = _ref.type,
          url = _ref.url;

      var tags = [];
      if (title) {
        tags.push({
          type: 'meta',
          tagId: 'meta-title',
          attrs: {
            name: 'title',
            property: 'og:title',
            content: title
          }
        });
        tags.push({
          type: 'meta',
          tagId: 'twitter:title',
          attrs: {
            name: 'twitter:title',
            content: title
          }
        });
      }
      if (description) {
        tags.push({
          type: 'meta',
          tagId: 'description',
          attrs: {
            name: 'description',
            property: 'og:description',
            content: description
          }
        });
      }
      if (image) {
        tags.push({
          type: 'meta',
          tagId: 'image',
          attrs: {
            property: 'og:image',
            content: image
          }
        });
        tags.push({
          type: 'meta',
          tagId: 'twitter:image',
          attrs: {
            name: 'twitter:image',
            content: image
          }
        });
      }
      if (type) {
        tags.push({
          type: 'meta',
          tagId: 'type',
          attrs: {
            property: 'og:type',
            content: type
          }
        });
      }
      if (url) {
        tags.push({
          type: 'meta',
          tagId: 'og:url',
          attrs: {
            property: 'og:url',
            content: url
          }
        });
        tags.push({
          type: 'meta',
          tagId: 'al:ios:url',
          attrs: {
            property: 'al:ios:url',
            content: url
          }
        });
        tags.push({
          type: 'meta',
          tagId: 'al:android:url',
          attrs: {
            property: 'al:android:url',
            content: url
          }
        });
      }
      // Default tags
      tags.push({
        type: 'meta',
        tagId: 'og:site_name',
        attrs: {
          name: 'og:site_name',
          content: 'Splife'
        }
      });

      // Twitter
      tags.push({
        type: 'meta',
        tagId: 'twitter:card',
        attrs: {
          name: 'twitter:card',
          content: 'summary'
        }
      });
      tags.push({
        type: 'meta',
        tagId: 'twitter:site',
        attrs: {
          name: 'twitter:site',
          content: '@splife_go'
        }
      });
      tags.push({
        type: 'meta',
        tagId: 'twitter:maxage',
        attrs: {
          name: 'twitter:maxage',
          content: '86400'
        }
      });

      // iOS
      tags.push({
        type: 'meta',
        tagId: 'al:ios:app_name',
        attrs: {
          name: 'al:ios:app_name',
          content: 'Splife'
        }
      });
      tags.push({
        type: 'meta',
        tagId: 'al:ios:app_store_id',
        attrs: {
          name: 'al:ios:app_store_id',
          content: '1640992025'
        }
      });

      // Android
      tags.push({
        type: 'meta',
        tagId: 'al:android:app_name',
        attrs: {
          name: 'al:android:app_name',
          content: 'Splife'
        }
      });
      tags.push({
        type: 'meta',
        tagId: 'al:android:package',
        attrs: {
          name: 'al:android:package',
          content: 'com.splife.v3'
        }
      });

      // Facebook
      tags.push({
        type: 'meta',
        tagId: 'fb:app_id',
        attrs: {
          name: 'fb_app_id',
          content: '1373292186488860',
          property: 'fb:app_id'
        }
      });

      return tags;
    }
  });
});