define('social-web/pods/transmission/model', ['exports', 'ember-data/attr', 'ember-data/relationships', 'social-web/pods/application/model'], function (exports, _attr, _relationships, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    hlsAddressEncoded: (0, _attr.default)('string'),
    hlsAddress: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    status: (0, _attr.default)('string'),
    edition: (0, _relationships.belongsTo)('edition', { async: true, inverse: 'transmissions' }),
    live: Ember.computed('status', function () {
      return this.get('status') == 'Live';
    })
  });
});