define('social-web/pods/components/lazy-image/component', ['exports', 'ember-lazy-image/components/lazy-image', 'social-web/mixins/lazy-image'], function (exports, _lazyImage, _lazyImage2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _lazyImage.default.extend(_lazyImage2.default, {
    classNames: ['lazy-image-wrapper'],
    attributeBindings: ['href'],
    init: function init() {
      this.classNames.removeObject('lazy-image-container');
      this._super.apply(this, arguments);
    }
  });
});