define('social-web/mixins/followable-adapter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    isFollowing: function isFollowing(model) {
      return this.makeFollowRequest(model, 'GET');
    },
    follow: function follow(model) {
      return this.makeFollowRequest(model, 'POST');
    },
    unfollow: function unfollow(model) {
      return this.makeFollowRequest(model, 'DELETE');
    },
    makeFollowRequest: function makeFollowRequest(model, requestType) {
      var _this = this;

      var extraOptions = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      var buildURL = this.buildURL(model.constructor.modelName, model.id, null, null, {
        scope: { model: model, action: 'follow' }
      });
      return this.ajax(buildURL, requestType, extraOptions).then(function (response) {
        if (response && response.data) {
          var data = _this.store.normalize('follow-data', response.data);
          return _this.store.push(data);
        }
      });
    }
  });
});