define('social-web/pods/components/live-bambuser/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.componentId = 'transmission-bambuser-' + this.id;
      this.streamingName = this.description;
    },


    id: null,
    description: null,
    componentId: null,
    hlsAddress: null,
    hlsAddressEncoded: null,
    streamingName: null
  });
});