define('social-web/mirage/factories/travel-category', ['exports', 'ember-cli-mirage', 'social-web/mirage/factories/application'], function (exports, _emberCliMirage, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    name: function name() {
      return _emberCliMirage.faker.company.companyName();
    },
    sortPriority: function sortPriority(n) {
      return n;
    },
    afterCreate: function afterCreate(travelCategory, server) {
      if (!travelCategory.edition) {
        travelCategory.edition = server.create('edition');
      }
      if (!travelCategory.travels.models.length) {
        server.createList('travel', travelCategory.travelsCount === undefined ? _emberCliMirage.faker.random.number({ min: 1, max: 5 }) : travelCategory.travelsCount, { travelCategory: travelCategory });
      }
      travelCategory.save();
    }
  });
});