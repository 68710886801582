define('social-web/pods/terms/route', ['exports', 'social-web/mixins/reset-scroll'], function (exports, _resetScroll) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_resetScroll.default, {
    i18n: Ember.inject.service(),
    media: Ember.inject.service(),

    titleToken: function titleToken() {
      return this.get('i18n').t('terms.title');
    },
    afterModel: function afterModel() {
      this.get('media.nav').setProperties({
        fixed: false,
        secondary: true
      });
    },

    actions: {
      willTransition: function willTransition() {
        this.get('media.nav').setProperties({
          fixed: true,
          secondary: false
        });
      }
    }
  });
});