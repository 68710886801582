define('social-web/tests/mirage/mirage/models/comment', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Model.extend({
    publisher: (0, _emberCliMirage.belongsTo)('user'),
    statusUpdate: (0, _emberCliMirage.belongsTo)('status-update')
  });
});