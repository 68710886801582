define('social-web/pods/index/route', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    media: Ember.inject.service(),

    extraClassNames: ['splife-red'],

    afterModel: function afterModel() {
      this.get('media.nav').setProperties({
        fixed: false,
        secondary: true
      });
    },
    setupController: function setupController() {
      this.controllerFor('application').set('innerAppLayout', false);
    },


    actions: {
      willTransition: function willTransition() {
        this.get('media.nav').setProperties({
          fixed: true,
          secondary: false
        });
      }
    }
  });
});