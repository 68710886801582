define('social-web/pods/event/show/edition/show/about/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    i18n: Ember.inject.service(),

    titleToken: function titleToken() {
      return this.get('i18n').t('events.show.about.title');
    }
  });
});