define('social-web/tests/mirage/mirage/serializers/application', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.JSONAPISerializer.extend({
    serialize: function serialize(object, request) {
      var json = _emberCliMirage.JSONAPISerializer.prototype.serialize.apply(this, arguments);

      if (Ember.isArray(json.data) && object.meta) {
        json.meta = object.meta;
        json.meta.pages = Math.ceil(json.meta.count / request.queryParams.per_page || 25);
      }
      return json;
    },
    normalize: function normalize(payload) {
      payload.data.attributes['created-at'] = new Date();
      payload.data.attributes['updated-at'] = new Date();
      var json = _emberCliMirage.JSONAPISerializer.prototype.normalize.apply(this, arguments);
      return json;
    }
  });
});