define("social-web/pods/components/md-chips/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 14,
                "column": 2
              },
              "end": {
                "line": 16,
                "column": 2
              }
            },
            "moduleName": "social-web/pods/components/md-chips/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "yield", [["get", "item", ["loc", [null, [15, 12], [15, 16]]], 0, 0, 0, 0]], [], ["loc", [null, [15, 4], [15, 18]]], 0, 0]],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 16,
                "column": 2
              },
              "end": {
                "line": 18,
                "column": 2
              }
            },
            "moduleName": "social-web/pods/components/md-chips/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["content", "item", ["loc", [null, [17, 4], [17, 12]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 19,
              "column": 0
            }
          },
          "moduleName": "social-web/pods/components/md-chips/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "hasBlock", ["loc", [null, [14, 8], [14, 16]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [14, 2], [18, 9]]]]],
        locals: ["item"],
        templates: [child0, child1]
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 20,
            "column": 0
          }
        },
        "moduleName": "social-web/pods/components/md-chips/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "power-select-multiple", [], ["selected", ["subexpr", "@mut", [["get", "selected", ["loc", [null, [2, 34], [2, 42]]], 0, 0, 0, 0]], [], [], 0, 0], "options", ["subexpr", "@mut", [["get", "options", ["loc", [null, [3, 33], [3, 40]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", ["subexpr", "@mut", [["get", "placeholder", ["loc", [null, [4, 37], [4, 48]]], 0, 0, 0, 0]], [], [], 0, 0], "renderInPlace", true, "optionsComponent", ["subexpr", "@mut", [["get", "optionsComponent", ["loc", [null, [6, 42], [6, 58]]], 0, 0, 0, 0]], [], [], 0, 0], "loadingMessage", ["subexpr", "@mut", [["get", "loadingMessage", ["loc", [null, [7, 40], [7, 54]]], 0, 0, 0, 0]], [], [], 0, 0], "noMatchesMessage", ["subexpr", "@mut", [["get", "noMatchesMessage", ["loc", [null, [8, 42], [8, 58]]], 0, 0, 0, 0]], [], [], 0, 0], "searchMessage", ["subexpr", "@mut", [["get", "searchMessage", ["loc", [null, [9, 39], [9, 52]]], 0, 0, 0, 0]], [], [], 0, 0], "onchange", ["subexpr", "action", [["subexpr", "mut", [["get", "selected", ["loc", [null, [10, 47], [10, 55]]], 0, 0, 0, 0]], [], ["loc", [null, [10, 42], [10, 56]]], 0, 0]], [], ["loc", [null, [10, 34], [10, 57]]], 0, 0], "search", ["subexpr", "@mut", [["get", "search", ["loc", [null, [11, 32], [11, 38]]], 0, 0, 0, 0]], [], [], 0, 0], "searchField", ["subexpr", "@mut", [["get", "searchField", ["loc", [null, [12, 37], [12, 48]]], 0, 0, 0, 0]], [], [], 0, 0], "onkeydown", ["subexpr", "action", ["onkeydown"], [], ["loc", [null, [13, 35], [13, 55]]], 0, 0]], 0, null, ["loc", [null, [2, 0], [19, 26]]]]],
      locals: [],
      templates: [child0]
    };
  }());
});