define('social-web/pods/mention/service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    mentionMatcher: /@\[([^\(\)]*)\]\(([^:]+):(\d+)\)/g, //$1 = display, $2 = model, $3 = id
    mentionEditTemplate: '<span class=\'mention\' contenteditable=\'false\'><span class=\'text\' data-value=\'$1\'>' + '</span><span class=\'value\'>$&</span></span>',
    mentionDisplayTemplate: '<a href=\'/$2/$3\' class=\'mention\'>$1</a>',

    toHTML: function toHTML(mention) {
      var _this = this;

      var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : { htmlSafe: false, editable: false },
          htmlSafe = _ref.htmlSafe,
          editable = _ref.editable;

      var templateName = editable ? 'mentionEditTemplate' : 'mentionDisplayTemplate';

      var replacer = function replacer(matchString, display, model, id) {
        return _this.get(templateName).replace('$&', matchString).replace('$1', display).replace('$2', model.pluralize()).replace('$3', id);
      };
      var htmlString = typeof mention === 'string' ? mention.replace(this.get('mentionMatcher'), replacer) : replacer(this.toMention(mention), mention.display, mention.model, mention.id);
      return htmlSafe ? Ember.String.htmlSafe(htmlString) : htmlString;
    },
    toMention: function toMention(_ref2) {
      var display = _ref2.display,
          model = _ref2.model,
          id = _ref2.id;

      return '@[' + display + '](' + model + ':' + id + ')';
    },
    stripMentions: function stripMentions(content) {
      return content.replace(this.mentionMatcher, '$1');
    }
  });
});