define('social-web/pods/i18n/service', ['exports', 'ember-i18n/utils/locale', 'ember-i18n/services/i18n'], function (exports, _locale, _i18n) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _i18n.default.extend({
    _locale: Ember.computed('locale', function () {
      var locale = this.get('locale') || 'en';
      return new _locale.default(locale, Ember.getOwner(this));
    })
  });
});