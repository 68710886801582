define('social-web/mixins/mobile-leaf-route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    media: Ember.inject.service(),

    extraClassNames: ['dark-grey'],
    model: function model() {
      return this.modelFor(this.get('parentRoute'));
    },
    redirect: function redirect() {
      if (!this.get('media.isSmall')) {
        this.transitionTo(this.get('parentRoute'));
      }
    },
    sizeChanged: function sizeChanged() {
      if (!this.get('media.isSmall')) {
        this.transitionTo(this.get('parentRoute'));
      }
    },

    parentRoute: Ember.computed('routeName', function () {
      return this.routeName.substring(0, this.routeName.lastIndexOf('.'));
    }),
    actions: {
      didTransition: function didTransition() {
        this.addObserver('media.isSmall', this, this.sizeChanged);
      },
      willTransition: function willTransition() {
        this.removeObserver('media.isSmall', this, this.sizeChanged);
      }
    }
  });
});