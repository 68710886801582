define('social-web/pods/application/model', ['exports', 'ember-data', 'ember-data/attr'], function (exports, _emberData, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    createdAt: (0, _attr.default)('date'),
    updatedAt: (0, _attr.default)('date'),
    group: {},
    edition: {},

    adapter: Ember.computed(function () {
      return this.store.adapterFor(this.constructor.modelName);
    })
  });
});