define('social-web/pods/onboarding/signup/route', ['exports', 'social-web/mixins/reset-scroll'], function (exports, _resetScroll) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_resetScroll.default, {
    afterModel: function afterModel() {
      this._super.apply(this, arguments);
      var controller = this.get('controller');
      if (controller) {
        controller.set('expanded', false);
      }
      Ember.run.later(function () {
        Ember.$('.slider').slider();
      });
    },
    setupController: function setupController() {
      this.controllerFor('application').set('innerAppLayout', false);
    },


    actions: {
      onSuccess: function onSuccess() {
        if (sessionStorage.getItem('redirectPath')) {
          this.transitionTo(sessionStorage.getItem('redirectPath'));
        } else {
          this.transitionTo('feed');
        }
      }
    }
  });
});