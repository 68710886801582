define('social-web/pods/components/svg-twitter/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'i',
    svgFill: '#fff'
  });
});