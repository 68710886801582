define('social-web/instance-initializers/inflections', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() /* appInstance */{
    Ember.Inflector.inflector.uncountable('authentication');
    Ember.Inflector.inflector.uncountable('media');
    Ember.Inflector.inflector.uncountable('vote-data');
    Ember.Inflector.inflector.uncountable('follow-data');
    Ember.Inflector.inflector.uncountable('member-data');
    Ember.Inflector.inflector.uncountable('user-stats');
    Ember.Inflector.inflector.uncountable('series');
  }

  exports.default = {
    name: 'inflections',
    initialize: initialize
  };
});