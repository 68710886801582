define('social-web/pods/components/chart-splits/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    distance: Ember.inject.service(),

    chartType: 'time',
    distanceType: 'mi',

    results: Ember.computed('splits.[]', 'distanceType', function () {
      var _this = this;

      var distanceType = this.get('distanceType');
      return this.get('splits').map(function (_ref) {
        var timeInSeconds = _ref.timeInSeconds,
            distanceInMeters = _ref.distanceInMeters;

        var distance = _this.get('distance').distance(distanceInMeters, distanceType);
        var rate = null;
        if (timeInSeconds && distance) {
          rate = Math.round(timeInSeconds * 1000 / distance);
        }
        return {
          timeInSeconds: timeInSeconds,
          distance: distance,
          rate: rate
        };
      });
    }),

    chart: Ember.computed('splits.[]', 'chartType', function () {
      var splits = this.get('splits') || [];
      var type = this.get('chartType');
      var data = splits.map(function (_ref2) {
        var distanceInMeters = _ref2.distanceInMeters,
            timeInSeconds = _ref2.timeInSeconds;
        return type === 'time' ? timeInSeconds : distanceInMeters;
      });

      return {
        type: type,
        options: {
          chart: {
            type: 'areaspline',
            backgroundColor: 'transparent'
          },
          plotOptions: {
            areaspline: {
              color: '#f2453d'
            }
          }
        },

        data: [{
          name: this.get('i18n').t('scrapbook.show.results.splits.' + type + 'Splits'),
          data: data
        }]
      };
    })
  });
});