define('social-web/mixins/member-adapter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    isMember: function isMember(model) {
      return this.makeMemberRequest(model, 'GET');
    },
    include: function include(model) {
      return this.makeMemberRequest(model, 'POST');
    },
    remove: function remove(model) {
      return this.makeMemberRequest(model, 'DELETE');
    },
    makeMemberRequest: function makeMemberRequest(model, requestType) {
      var _this = this;

      var extraOptions = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      var buildURL = this.buildURL(model.constructor.modelName, model.id, null, null, {
        scope: { model: model, action: 'member' }
      });
      return this.ajax(buildURL, requestType, extraOptions).then(function (response) {
        if (response && response.data) {
          var data = _this.store.normalize('member-data', response.data);
          return _this.store.push(data);
        }
      });
    }
  });
});