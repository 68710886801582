define('social-web/tests/mirage/mirage/factories/notification-setting', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    slug: null,
    kind: function kind(n) {
      return _emberCliMirage.faker.list.random('user_joins_competition', 'user_starts_competition', 'user_finishes_competition', 'user_joins_team', 'user_you_follow_event_progress_has_been_posted', 'user_you_follow_race_results_have_been_posted', 'interim_results', 'user_posts_results', 'user_follows_you', 'user_likes_your_post', 'user_comments_on_your_post', 'new_post_mention', 'user_you_follow_posts_or_is_mentioned_during_live_event')(n);
    },

    accepts_email: false,
    afterCreate: function afterCreate(setting, server) {
      if (!setting.user) {
        setting.user = server.schema.users.where({ current: true }).models[0];
      }
      setting.slug = 'user-' + setting.user.id + '-notification_setting-' + setting.kind;
      setting.save();
    }
  });
});