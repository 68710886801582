define('social-web/tests/mirage/mirage/factories/user-stats', ['exports', 'social-web/mirage/factories/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    completedMeters: 0,
    completedMiles: 0,
    completedEvents: 0,
    upcomingEvents: 0
  });
});