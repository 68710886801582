define('social-web/pods/group/show/detail/index/route', ['exports', 'social-web/mixins/refreshable-infinity-route', 'social-web/mixins/feed-route'], function (exports, _refreshableInfinityRoute, _feedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_refreshableInfinityRoute.default, _feedRoute.default, {
    i18n: Ember.inject.service(),

    feedScope: function feedScope() {
      return {
        model: this.modelFor('group.show.detail'),
        action: 'feed'
      };
    },
    titleToken: function titleToken() {
      return this.get('i18n').t('user.show.activity.title');
    },
    model: function model() {

      Ember.Logger.log('group.show.detail.index route.js .model');

      return Ember.RSVP.hash({
        group: this.modelFor('group.show.detail'),
        feed: this.getFeed()
      });
    }
  });
});