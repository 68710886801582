define('social-web/pods/edition/model', ['exports', 'ember-data/attr', 'ember-data/relationships', 'social-web/pods/application/model'], function (exports, _attr, _relationships, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    slug: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    liveUTL: (0, _attr.default)('string'),
    composedName: Ember.computed('event.name', function () {
      return this.get('event.name') ? this.get('event.name') + ' - ' + this.get('name') : this.get('name');
    }),
    startDate: (0, _attr.default)('utc'),
    endDate: (0, _attr.default)('utc'),

    description: (0, _attr.default)('string'),
    faq: (0, _attr.default)('string'),
    rules: (0, _attr.default)('string'),

    participatableCompetitionsCount: (0, _attr.default)('number'),
    competitionsCount: (0, _attr.default)('number'),

    primaryPhotoOriginal: (0, _attr.default)('string'),
    secondaryPhotoOriginal: (0, _attr.default)('string'),
    tertiaryPhotoOriginal: (0, _attr.default)('string'),
    quaternaryPhotoOriginal: (0, _attr.default)('string'),
    primaryPhotoLarge: (0, _attr.default)('string'),
    secondaryPhotoLarge: (0, _attr.default)('string'),
    tertiaryPhotoLarge: (0, _attr.default)('string'),
    quaternaryPhotoLarge: (0, _attr.default)('string'),
    primaryPhotoMedium: (0, _attr.default)('string'),
    secondaryPhotoMedium: (0, _attr.default)('string'),
    tertiaryPhotoMedium: (0, _attr.default)('string'),
    quaternaryPhotoMedium: (0, _attr.default)('string'),
    primaryPhotoSmall: (0, _attr.default)('string'),
    secondaryPhotoSmall: (0, _attr.default)('string'),
    tertiaryPhotoSmall: (0, _attr.default)('string'),
    quaternaryPhotoSmall: (0, _attr.default)('string'),

    event: (0, _relationships.belongsTo)('event', { async: true, inverse: 'editions' }),
    location: (0, _relationships.belongsTo)('location', { async: true }),
    seriesEdition: (0, _relationships.belongsTo)('series-edition', { async: true }),

    travelCategories: (0, _relationships.hasMany)('travel-category', { async: true }),
    accommodationCategories: (0, _relationships.hasMany)('accommodation-category', { async: true }),
    causeCategories: (0, _relationships.hasMany)('cause-category', { async: true }),
    partnerCategories: (0, _relationships.hasMany)('partner-category', { async: true }),
    sponsorCategories: (0, _relationships.hasMany)('sponsor-category', { async: true }),
    editionActivities: (0, _relationships.hasMany)('edition-activity', { async: true }),
    mapFiles: (0, _relationships.hasMany)('map-file', { async: true }),

    competitions: (0, _relationships.hasMany)('competition', { async: true }),

    _participantsCount: null,
    participantsCount: Ember.computed('_participantsCount', function () {
      var _this = this;

      return !Ember.isNone(this.get('_participantsCount')) ? this.get('_participantsCount') : this.get('store').query('user-id', {
        scope: { model: this, action: 'participations' },
        per_page: 1
      }).then(function (model) {
        _this.set('_participantsCount', model.get('meta.count'));
      });
    }),
    transmissions: (0, _relationships.hasMany)('transmission', { async: true }),

    display: Ember.computed.alias('composedName')
  });
});