define('social-web/pods/email/model', ['exports', 'ember-data/attr', 'ember-data/relationships', 'social-web/pods/email/validations', 'social-web/pods/application/model'], function (exports, _attr, _relationships, _validations, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    email: (0, _attr.default)('string'),
    defaultEmail: (0, _attr.default)('boolean'),

    user: (0, _relationships.belongsTo)(),

    setDefault: function setDefault() {
      return this.get('adapter').setDefault(this);
    },


    validations: _validations.default
  });
});