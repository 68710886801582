define('social-web/pods/components/comment-card-list/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),

    isVisible: Ember.computed('disabled', 'displayComments.length', 'statusUpdate.commentsCount', function () {
      return !this.get('disabled') || this.get('displayComments.length') > 0 || this.get('statusUpdate.commentsCount') > 0;
    }),

    disabled: false,
    collapsed: true,

    init: function init() {
      var _this = this;

      if (this.attrs.component && this.attrs.component.update) {
        this.attrs.component.update(this);
      }
      this._super.apply(this, arguments);
      this.set('displayComments', this.get('statusUpdate.latestComments').toArray());

      Ember.RSVP.all(this.get('displayComments').getEach('publisher')).then(function () {
        if (!_this.get('isDestroyed')) {
          _this.set('loaded', true);
        }
      });
    },


    actions: {
      showComments: function showComments() {
        var _this2 = this;

        this.set('loading', true);
        this.get('store').query('comment', {
          scope: { model: this.get('statusUpdate'), action: 'comments' }
        }).then(function (comments) {
          Ember.RSVP.all(comments.getEach('publisher')).then(function () {
            _this2.set('loading');
            _this2.set('collapsed');
          });
        });
      },
      save: function save(comment) {
        if (this.get('collapsed')) {
          this.get('displayComments').pushObject(comment);
        }
      },
      delete: function _delete(comment) {
        if (this.get('collapsed')) {
          this.get('displayComments').removeObject(comment);
        }
      }
    }
  });
});