define('social-web/tests/mirage/mirage/factories/event', ['exports', 'ember-cli-mirage', 'social-web/mirage/factories/application'], function (exports, _emberCliMirage, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    slug: null,
    name: function name() {
      return _emberCliMirage.faker.company.companyName();
    },
    description: function description() {
      return _emberCliMirage.faker.lorem.paragraph();
    },

    logoThumb: _emberCliMirage.faker.image.avatar,
    coverPhotoTile: _emberCliMirage.faker.image.imageUrl,
    coverPhotoDefault: _emberCliMirage.faker.image.imageUrl,
    currentUserFollowing: function currentUserFollowing() {
      return _emberCliMirage.faker.random.boolean();
    },
    followersCount: function followersCount() {
      return _emberCliMirage.faker.random.number(1000);
    },
    editionsCount: function editionsCount() {
      return 0;
    },
    afterCreate: function afterCreate(event, server) {
      if (event.propagate) {
        event.editions = server.createList('edition', event.editionsCount || _emberCliMirage.faker.random.number({ min: 1, max: 5 }), { event: event, propagate: true });
        event.latestEdition = event.editions.models[0];
      }

      event.editionsCount = event.editions.models.length;
      event.slug = event.name.dasherize().replace(/([^a-zA-Z0-9\-_]|^-)/g, '').replace(/-{2,}/g, '-');
      event.coverPhotoDefault = event.coverPhotoTile;
      event.constructor.modelName = event.modelName;
      event.save();
    },


    withFollowers: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(event, server) {
        event.followers = server.createList('user', _emberCliMirage.faker.random.number(1000));
        event.followersCount = event.followers.models.length;
        event.save();
      }
    })
  });
});