define('social-web/pods/account/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    media: Ember.inject.service(),

    redirect: function redirect() /*model, transition*/{
      if (!this.get('media.isSmall')) {
        this.transitionTo('account.general');
      }
    },
    sizeChanged: function sizeChanged() {
      if (!this.get('media.isSmall')) {
        this.transitionTo('account.general');
      }
    },

    actions: {
      didTransition: function didTransition() {
        this.addObserver('isSmall', this.get('media'), this.sizeChanged);
      },
      willTransition: function willTransition() {
        this.addObserver('isSmall', this.get('media'), this.sizeChanged);
      }
    }
  });
});