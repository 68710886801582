define('social-web/pods/event/show/edition/show/athletes/route', ['exports', 'social-web/mixins/refreshable-infinity-route'], function (exports, _refreshableInfinityRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_refreshableInfinityRoute.default, {
    i18n: Ember.inject.service(),
    follow: Ember.inject.service(),

    titleToken: function titleToken() {
      return this.get('i18n').t('events.show.athletes.title');
    },

    modelName: 'participation',
    modelParams: function modelParams(params) {
      var scopeModel = this.get('controller.competition') || this.get('competition') || this.modelFor('event.show.edition.show');
      return {
        perPage: 24,
        modelPath: 'controller.model.participations',
        search: Ember.get(params, 'search'),
        sort: {
          user_id: 'not_null'
        },
        scope: {
          model: scopeModel,
          action: 'participations'
        }
      };
    },
    model: function model(params) {
      params.search = params.currentSearch;
      var edition = this.modelFor('event.show.edition.show');
      if (edition.get('competitionsCount')) {
        return new Ember.RSVP.hash({
          edition: edition,
          competitions: this.store.query('competition', {
            scope: {
              model: edition, action: 'competitions'
            }
          }),
          participations: this.getInfinityModel(params)
        });
      } else {
        return new Ember.RSVP.hash({
          edition: edition
        });
      }
    },
    afterInfinityModel: function afterInfinityModel(participations) {
      var _this = this;

      var controller = this.get('controller');
      if (!controller) {
        this.set('competition');
      } else {
        var _controller = this.get('controller');
        _controller.set('currentSearch', _controller.get('search'));
      }
      return new Ember.RSVP.Promise(function (resolve) {
        new Ember.RSVP.all(participations.getEach('user')).then(function (users) {
          _this.get('follow').checkCurrentUserFollowedUsers(users.compact()).then(function () {
            resolve(participations);
          });
        });
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      if (model.competitions && model.competitions.get('length')) {
        var competition = model.competitions.findBy('id', controller.get('competitionID'));
        controller.set('search', this.get('_extraParams.search'));
        controller.set('currentSearch', this.get('_extraParams.search'));
        controller.set('competition', competition);
        controller.set('competitions', model.competitions);
      }
    },

    actions: {
      clearAndRefresh: function clearAndRefresh() {
        this.set('controller.search', '');
        return this.send('refresh');
      }
    }
  });
});