define('social-web/pods/participation/model', ['exports', 'ember-data/attr', 'ember-data/relationships', 'social-web/pods/application/model', 'moment'], function (exports, _attr, _relationships, _model, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    i18n: Ember.inject.service(),
    display: Ember.computed.alias('fullName'),
    fullName: Ember.computed('firstName', 'lastName', function () {
      var firstName = this.get('firstName'),
          lastName = this.get('lastName');
      return firstName && lastName ? this.get('firstName') + ' ' + this.get('lastName') : null;
    }),

    email: (0, _attr.default)('string'),
    firstName: (0, _attr.default)('string'),
    lastName: (0, _attr.default)('string'),
    bib: (0, _attr.default)('string'),
    gender: (0, _attr.default)('string'),
    genderDisplay: Ember.computed('gender', function () {
      var i18n = this.get('i18n');
      var genderMap = {
        M: i18n.t('user.gender.m'),
        F: i18n.t('user.gender.f')
      };
      return genderMap[this.get('gender').toUpperCase()] || '';
    }),

    birthday: (0, _attr.default)('date'),
    age: Ember.computed('birthday', function () {
      var birthday = this.get('birthday');
      if (birthday) {
        return (0, _moment.default)().diff(birthday, 'years');
      }
    }),

    competition: (0, _relationships.belongsTo)('competition', { async: true }),
    user: (0, _relationships.belongsTo)('user', { async: true }),
    splits: (0, _relationships.hasMany)()
  });
});