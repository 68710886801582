define('social-web/helpers/possessive', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.possessive = possessive;
  function possessive(params /*, hash*/) {
    var text = params[0];
    var possessive = !text ? '' : text[text.length - 1] === 's' ? text + '\'' : text + '\'s';
    return new Ember.String.htmlSafe(possessive);
  }

  exports.default = Ember.Helper.helper(possessive);
});