define('social-web/pods/media/model', ['exports', 'ember-data/attr', 'ember-data/relationships', 'social-web/pods/application/model'], function (exports, _attr, _relationships, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    fileMed: (0, _attr.default)('string'),
    fileOrig: (0, _attr.default)('string'),

    owner: (0, _relationships.belongsTo)('application', { polymorphic: true }),
    ownerKey: Ember.computed('owner', function () {
      var owner = this.belongsTo('owner').belongsToRelationship.canonicalState;
      return owner.modelName + ':' + owner.id;
    }),

    report: function report() {
      return this.get('adapter').report(this);
    }
  });
});