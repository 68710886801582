define('social-web/pods/event/adapter', ['exports', 'social-web/pods/application/adapter', 'social-web/mixins/followable-adapter'], function (exports, _adapter, _followableAdapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _adapter.default.extend(_followableAdapter.default, {
    checkUserFollowing: function checkUserFollowing(user, events) {
      var queryParams = {
        scope: { model: user, action: 'followed_events' },
        event_ids: events.mapBy('id')
      };
      var buildURL = this.buildURL('event', user.id, null, null, queryParams);
      return this.ajax(buildURL, 'GET', { data: queryParams });
    }
  });
});