define('social-web/tests/mirage/mirage/factories/mention', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    participantNumber: function participantNumber() {
      return _emberCliMirage.faker.random.number({ min: 1000, max: 10000 });
    }
  });
});