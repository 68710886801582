define('social-web/pods/components/tile-widget/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['bg-image', 'hoverable'],
    classNameBindings: ['stretched'],
    attributeBindings: ['style'],
    style: Ember.computed('imageUrl', function () {
      var imageUrl = this.get('imageUrl');
      return Ember.String.htmlSafe(imageUrl ? 'background-image: url(' + this.get('imageUrl') + ');' : '');
    })
  });
});