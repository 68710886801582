define('social-web/mirage/scenarios/default', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (server) {

    /*
      Seed your development database using your factories.
      This data will not be loaded in your tests.
       Make sure to define a factory for each model you want to create.
    */

    // server.createList('post', 10);

    var currentUser = server.create('user', 'currentUser');
    server.createList('user', 10);
    server.createList('event', 10, { propagate: true });
    server.createList('series', 2, { propagate: true });
    server.createList('notification', 35);
    server.createList('status-update', 25, 'withEdition', 'withMedia', 'withComments', 'withMentions', { user: currentUser });
  };

  exports.testConfig = testConfig;
  function testConfig() {}
});