define("social-web/pods/components/signin-form/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 18,
              "column": 8
            },
            "end": {
              "line": 20,
              "column": 8
            }
          },
          "moduleName": "social-web/pods/components/signin-form/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1, "href", "");
          dom.setAttribute(el1, "class", "forgot");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element1);
          morphs[1] = dom.createMorphAt(element1, 0, 0);
          return morphs;
        },
        statements: [["element", "action", [["get", "onForgot", ["loc", [null, [19, 42], [19, 50]]], 0, 0, 0, 0]], [], ["loc", [null, [19, 33], [19, 52]]], 0, 0], ["inline", "t", ["onboarding.signin.actions.forgot"], [], ["loc", [null, [19, 53], [19, 93]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 21,
                "column": 10
              },
              "end": {
                "line": 21,
                "column": 95
              }
            },
            "moduleName": "social-web/pods/components/signin-form/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "t", ["onboarding.signin.actions.forgot"], [], ["loc", [null, [21, 10], [21, 95]]], 0, 0]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 20,
              "column": 8
            },
            "end": {
              "line": 22,
              "column": 8
            }
          },
          "moduleName": "social-web/pods/components/signin-form/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["block", "link-to", ["onboarding.forgot"], ["class", "forgot"], 0, null, ["loc", [null, [21, 10], [21, 95]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child2 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 27,
              "column": 6
            },
            "end": {
              "line": 31,
              "column": 6
            }
          },
          "moduleName": "social-web/pods/components/signin-form/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "t", ["onboarding.signin.actions.signin"], [], ["loc", [null, [30, 8], [30, 48]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    var child3 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 41,
              "column": 6
            },
            "end": {
              "line": 43,
              "column": 6
            }
          },
          "moduleName": "social-web/pods/components/signin-form/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1, "href", "");
          dom.setAttribute(el1, "class", "sign-up");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element0);
          morphs[1] = dom.createMorphAt(element0, 0, 0);
          return morphs;
        },
        statements: [["element", "action", [["get", "onSignup", ["loc", [null, [42, 41], [42, 49]]], 0, 0, 0, 0]], [], ["loc", [null, [42, 32], [42, 51]]], 0, 0], ["inline", "t", ["onboarding.signin.actions.signup"], [], ["loc", [null, [42, 52], [42, 92]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    var child4 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 44,
                "column": 8
              },
              "end": {
                "line": 44,
                "column": 94
              }
            },
            "moduleName": "social-web/pods/components/signin-form/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "t", ["onboarding.signin.actions.signup"], [], ["loc", [null, [44, 8], [44, 94]]], 0, 0]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 43,
              "column": 6
            },
            "end": {
              "line": 45,
              "column": 6
            }
          },
          "moduleName": "social-web/pods/components/signin-form/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["block", "link-to", ["onboarding.signup"], ["class", "sign-up"], 0, null, ["loc", [null, [44, 8], [44, 94]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 49,
            "column": 0
          }
        },
        "moduleName": "social-web/pods/components/signin-form/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "ui segment transparent padded");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("form");
        dom.setAttribute(el2, "novalidate", "");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "input-actions");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "left floated");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5, "for", "filled-in-box");
        dom.setAttribute(el5, "class", "remember");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "right floated");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "submit-action");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "ui padded");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "form-footer center");
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "sign-up-container");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0, 1]);
        var element3 = dom.childAt(element2, [5]);
        var element4 = dom.childAt(element3, [1]);
        var element5 = dom.childAt(fragment, [2, 1, 1]);
        var morphs = new Array(9);
        morphs[0] = dom.createElementMorph(element2);
        morphs[1] = dom.createMorphAt(element2, 1, 1);
        morphs[2] = dom.createMorphAt(element2, 3, 3);
        morphs[3] = dom.createMorphAt(element4, 1, 1);
        morphs[4] = dom.createMorphAt(dom.childAt(element4, [3]), 0, 0);
        morphs[5] = dom.createMorphAt(dom.childAt(element3, [3]), 1, 1);
        morphs[6] = dom.createMorphAt(dom.childAt(element2, [7]), 1, 1);
        morphs[7] = dom.createMorphAt(dom.childAt(element5, [1]), 0, 0);
        morphs[8] = dom.createMorphAt(element5, 3, 3);
        return morphs;
      },
      statements: [["element", "action", ["submit", ["get", "model", ["loc", [null, [2, 26], [2, 31]]], 0, 0, 0, 0]], ["on", "submit"], ["loc", [null, [2, 8], [2, 45]]], 0, 0], ["inline", "md-input", [], ["value", ["subexpr", "@mut", [["get", "model.email", ["loc", [null, [3, 21], [3, 32]]], 0, 0, 0, 0]], [], [], 0, 0], "label", ["subexpr", "t", ["onboarding.signin.labels.email"], [], ["loc", [null, [4, 22], [4, 58]]], 0, 0], "type", "email", "class", "email no-margin"], ["loc", [null, [3, 4], [6, 41]]], 0, 0], ["inline", "md-input", [], ["value", ["subexpr", "@mut", [["get", "model.password", ["loc", [null, [7, 21], [7, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "label", ["subexpr", "t", ["onboarding.signin.labels.password"], [], ["loc", [null, [8, 22], [8, 61]]], 0, 0], "type", "password", "class", "password no-margin"], ["loc", [null, [7, 4], [10, 44]]], 0, 0], ["inline", "input", [], ["type", "checkbox", "class", "filled-in", "id", "filled-in-box", "checked", ["subexpr", "@mut", [["get", "model.remember", ["loc", [null, [14, 77], [14, 91]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [14, 8], [14, 93]]], 0, 0], ["inline", "t", ["onboarding.signin.labels.remember"], [], ["loc", [null, [15, 52], [15, 93]]], 0, 0], ["block", "if", [["get", "onForgot", ["loc", [null, [18, 14], [18, 22]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [18, 8], [22, 15]]]], ["block", "ui-button", [], ["type", "submit", "loading", ["subexpr", "@mut", [["get", "loading", ["loc", [null, [28, 28], [28, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "fluid circular splife-red waves-effect submit"], 2, null, ["loc", [null, [27, 6], [31, 20]]]], ["inline", "t", ["onboarding.signin.labels.signup"], [], ["loc", [null, [40, 12], [40, 51]]], 0, 0], ["block", "if", [["get", "onSignup", ["loc", [null, [41, 12], [41, 20]]], 0, 0, 0, 0]], [], 3, 4, ["loc", [null, [41, 6], [45, 13]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  }());
});