define('social-web/tests/mirage/mirage/factories/map-file', ['exports', 'ember-cli-mirage', 'social-web/mirage/factories/application'], function (exports, _emberCliMirage, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    name: function name() {
      return _emberCliMirage.faker.company.companyName();
    },
    sortPriority: function sortPriority(n) {
      return n;
    },
    file: function file() {
      return _emberCliMirage.faker.image.image();
    },
    afterCreate: function afterCreate(mapFile, server) {
      if (!mapFile.edition) {
        mapFile.edition = server.create('map-file');
      }
      mapFile.save();
    }
  });
});