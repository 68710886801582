define('social-web/pods/group-member/service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),

    checkCurrentUserMemberUsers: function checkCurrentUserMemberUsers(users) {
      return this.checkCurrentUserIsMember('user', 'member-user-id', users);
    },
    checkCurrentUserMemberGroups: function checkCurrentUserMemberGroups(groups) {
      return this.checkCurrentUserIsMember('group', 'group-id', groups);
    },
    checkCurrentUserIsMember: function checkCurrentUserIsMember(modelName, prop, modelArray) {
      var currentUser = this.get('session.currentUser');
      var store = this.get('store');
      var adapter = store.adapterFor(modelName);
      if (currentUser && Ember.get(modelArray, 'length')) {
        return adapter.checkUserIsMember(currentUser, modelArray).then(function (response) {
          response.data.forEach(function (memberData) {
            var id = memberData.attributes[prop],
                member = memberData.attributes.followed;
            var model = store.peekRecord(modelName, id);
            if (model) {
              model.set('_currentUserIsMember', member);
            }
          });
        });
      } else {
        return new Ember.RSVP.Promise(function (resolve) {
          resolve(modelArray);
        });
      }
    }
  });
});