define('social-web/pods/components/sidebar-nav/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    media: Ember.inject.service(),
    session: Ember.inject.service(),
    notification: Ember.inject.service(),

    actions: {
      showNotifications: function showNotifications() {
        Ember.$('.ui.sidebar').sidebar('toggle');
        this.get('notification').showNotificationDropdown();
      }
    }
  });
});