define('social-web/tests/mirage/mirage.lint-test', [], function () {
  'use strict';

  QUnit.module('ESLint | mirage');

  QUnit.test('mirage/config.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/config.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/accommodation-category.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/accommodation-category.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/accommodation.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/accommodation.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/application.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/application.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/cause-category.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/cause-category.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/cause.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/cause.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/comment.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/comment.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/competition.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/competition.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/edition-activity.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/edition-activity.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/edition.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/edition.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/email.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/email.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/event-suggestion.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/event-suggestion.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/event.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/event.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/follow-data.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/follow-data.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/invite.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/invite.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/location.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/location.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/map-file.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/map-file.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/media.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/media.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/mention.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/mention.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/notification-setting.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/notification-setting.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/notification.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/notification.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/participation.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/participation.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/partner-category.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/partner-category.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/partner.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/partner.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/result.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/result.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/scrapbook.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/scrapbook.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/series-edition.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/series-edition.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/series.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/series.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/split.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/split.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/sponsor-category.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/sponsor-category.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/sponsor.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/sponsor.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/sport.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/sport.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/status-update.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/status-update.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/transmission.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/transmission.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/travel-category.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/travel-category.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/travel.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/travel.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/user-id.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/user-id.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/user-stats.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/user-stats.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/user.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/user.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/accommodation-category.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/accommodation-category.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/accommodation.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/accommodation.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/camera-configuration.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/camera-configuration.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/camera.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/camera.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/cause-category.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/cause-category.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/cause.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/cause.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/comment.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/comment.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/competition.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/competition.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/edition-activity.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/edition-activity.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/edition.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/edition.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/email.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/email.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/event-suggestion.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/event-suggestion.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/event.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/event.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/feed.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/feed.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/follow-data.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/follow-data.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/group.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/group.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/invite.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/invite.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/location.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/location.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/map-file.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/map-file.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/media.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/media.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/mention.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/mention.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/notification-setting.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/notification-setting.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/notification.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/notification.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/participation.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/participation.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/partner-category.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/partner-category.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/partner.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/partner.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/result.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/result.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/scrapbook.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/scrapbook.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/series-edition.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/series-edition.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/series.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/series.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/split.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/split.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/sponsor-category.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/sponsor-category.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/sponsor.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/sponsor.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/sport.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/sport.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/status-update.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/status-update.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/transmission.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/transmission.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/travel-category.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/travel-category.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/travel.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/travel.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/user-id.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/user-id.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/user-stats.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/user-stats.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/user.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/user.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/vote-data.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/vote-data.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/scenarios/default.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/scenarios/default.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/application.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/application.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/notification-settings.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/notification-settings.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/series-edition.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/series-edition.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/status-update.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/status-update.js should pass ESLint\n\n');
  });
});