define('social-web/pods/status-update/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord('status-update', params.id, {
        include: 'publisher,media,latest-comment,mentions'
      });
    }
  });
});