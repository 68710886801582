define('social-web/pods/components/result-row/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    media: Ember.inject.service(),
    tagName: 'tr',
    classNames: ['title'],
    attributeBindings: ['role'],
    role: 'button',
    radius: 17,

    click: function click() {
      this.getSplits();
    },


    progressColor: Ember.computed('model.completePercentage', function () {
      var percent = this.get('model.completePercentage');
      return percent > 0.66 ? 'green' : percent > 0.33 ? 'yellow' : 'red';
    }),

    circumference: Ember.computed('radiuus', function () {
      return this.get('radius') * 2 * Math.PI;
    }),

    percentageAsCircumference: Ember.computed('model.completePercentage', 'circumference', function () {
      return (1 - this.get('model.completePercentage')) * this.get('circumference');
    })
  });
});