define("social-web/pods/account/general/email/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 16,
              "column": 0
            },
            "end": {
              "line": 19,
              "column": 0
            }
          },
          "moduleName": "social-web/pods/account/general/email/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "t", ["common.save"], [], ["loc", [null, [18, 2], [18, 21]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 24,
            "column": 0
          }
        },
        "moduleName": "social-web/pods/account/general/email/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "ui basic segment no-left-padding");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h3");
        dom.setAttribute(el2, "class", "ui inverted upcase header");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row center-align");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("img");
        dom.setAttribute(el2, "class", "responsive-img");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h4");
        dom.setAttribute(el2, "class", "ui inverted header");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        dom.setAttribute(el2, "class", "white-text");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("button");
        dom.setAttribute(el1, "class", "btn-round secondary waves-effect no-border white-text full-width-on-small-only cancel");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [3, 1]);
        var element1 = dom.childAt(fragment, [5]);
        var element2 = dom.childAt(fragment, [8]);
        var morphs = new Array(9);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 0, 0);
        morphs[1] = dom.createAttrMorph(element0, 'src');
        morphs[2] = dom.createAttrMorph(element0, 'alt');
        morphs[3] = dom.createMorphAt(dom.childAt(element1, [1]), 0, 0);
        morphs[4] = dom.createMorphAt(dom.childAt(element1, [3]), 0, 0);
        morphs[5] = dom.createMorphAt(element1, 5, 5);
        morphs[6] = dom.createMorphAt(fragment, 7, 7, contextualElement);
        morphs[7] = dom.createElementMorph(element2);
        morphs[8] = dom.createMorphAt(element2, 1, 1);
        return morphs;
      },
      statements: [["inline", "t", ["account.general.email.label"], [], ["loc", [null, [3, 40], [3, 75]]], 0, 0], ["attribute", "src", ["get", "media.account.email", ["loc", [null, [7, 36], [7, 55]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "alt", ["subexpr", "t", ["account.general.email.label"], [], ["loc", [null, [null, null], [7, 97]]], 0, 0], 0, 0, 0, 0], ["inline", "t", ["account.general.email.primaryContact"], [], ["loc", [null, [11, 33], [11, 77]]], 0, 0], ["inline", "t", ["account.general.email.description"], [], ["loc", [null, [12, 24], [12, 65]]], 0, 0], ["inline", "email-selector", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [13, 25], [13, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "handlers", ["subexpr", "@mut", [["get", "handlers", ["loc", [null, [13, 40], [13, 48]]], 0, 0, 0, 0]], [], [], 0, 0], "handlerProp", "email"], ["loc", [null, [13, 2], [13, 70]]], 0, 0], ["block", "ui-button", [["subexpr", "route-action", ["save", ["get", "handlers.email", ["loc", [null, [16, 34], [16, 48]]], 0, 0, 0, 0]], [], ["loc", [null, [16, 13], [16, 49]]], 0, 0]], ["class", "circular red fluid waves-effect confirm"], 0, null, ["loc", [null, [16, 0], [19, 14]]]], ["element", "action", [["subexpr", "route-action", ["transitionToParent"], [], ["loc", [null, [21, 17], [21, 52]]], 0, 0]], [], ["loc", [null, [21, 8], [21, 54]]], 0, 0], ["inline", "t", ["common.cancel"], [], ["loc", [null, [22, 2], [22, 23]]], 0, 0]],
      locals: [],
      templates: [child0]
    };
  }());
});