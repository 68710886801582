define('social-web/instance-initializers/popup-shim', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function initialize(appInstance) {
    Ember.$(document).on('click', '.mfp-bottom-bar button.comment', function () {
      var _Ember$$$parents$find = Ember.$(this).parents('.mfp-bottom-bar').find('.mfp-title').text().split(':'),
          _Ember$$$parents$find2 = _slicedToArray(_Ember$$$parents$find, 2),
          routeName = _Ember$$$parents$find2[0],
          id = _Ember$$$parents$find2[1];

      appInstance.lookup('router:main').transitionTo(routeName, id).then(function () {
        Ember.$('.mfp-close').click();
      });
    });
  }

  exports.default = {
    name: 'popup-shim',
    initialize: initialize
  };
});