define('social-web/pods/mention/model', ['exports', 'ember-data/attr', 'ember-data/relationships', 'social-web/pods/application/model'], function (exports, _attr, _relationships, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    participantNumber: (0, _attr.default)('string'),
    kind: (0, _attr.default)('string'),

    user: (0, _relationships.belongsTo)('user'),
    participation: (0, _relationships.belongsTo)('participation'),
    statusUpdate: (0, _relationships.belongsTo)('status-update'),

    displayParticipantNumber: Ember.computed('participantNumber', function () {
      var participantNumber = this.get('participantNumber');
      return participantNumber ? '#' + participantNumber : '';
    }),

    display: Ember.computed('participation.user.display', 'participation.display', 'displayParticipantNumber', function () {
      return this.get('kind') == 'BibMention' ? this.get('participation.user.display') || this.get('participation.display') || this.get('displayParticipantNumber') || this.get('user.display') : '';
    })
  });
});