define('social-web/mirage/factories/partner-category', ['exports', 'ember-cli-mirage', 'social-web/mirage/factories/application'], function (exports, _emberCliMirage, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    name: function name() {
      return _emberCliMirage.faker.company.companyName();
    },
    sortPriority: function sortPriority(n) {
      return n;
    },
    afterCreate: function afterCreate(partnerCategory, server) {
      if (!partnerCategory.edition) {
        partnerCategory.edition = server.create('edition');
      }
      if (!partnerCategory.partners.models.length) {
        server.createList('partner', partnerCategory.partnersCount === undefined ? _emberCliMirage.faker.random.number({ min: 1, max: 5 }) : partnerCategory.partnersCount, { partnerCategory: partnerCategory });
      }
      partnerCategory.save();
    }
  });
});