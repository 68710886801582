define('social-web/pods/user/model', ['exports', 'ember-data/attr', 'ember-data/relationships', 'social-web/pods/application/model', 'social-web/mixins/followable-model', 'social-web/pods/user/validations', 'moment'], function (exports, _attr, _relationships, _model, _followableModel, _validations, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend(_followableModel.default, {
    i18n: Ember.inject.service(),
    session: Ember.inject.service(),
    locale: (0, _attr.default)('string'),
    slug: (0, _attr.default)('string'),
    firstName: (0, _attr.default)('string'),
    lastName: (0, _attr.default)('string'),
    admin: (0, _attr.default)('boolean'),
    phoneNumber: (0, _attr.default)('string'),
    birthday: (0, _attr.default)('utc'),
    age: Ember.computed('birthday', function () {
      var birthday = this.get('birthday');
      if (birthday) {
        return (0, _moment.default)().diff(birthday, 'years');
      }
    }),
    shirtSize: (0, _attr.default)('string'),
    shirtSizeDisplay: Ember.computed('shirtSize', function () {
      var shirtSize = this.get('shirtSize');
      if (shirtSize) {
        return this.get('i18n').t('user.shirtSize.' + shirtSize);
      }
    }),
    distance: (0, _attr.default)(),
    distanceDisplay: Ember.computed('distance', function () {
      var distance = this.get('distance');
      if (distance) {
        return this.get('i18n').t('distanceMeasureUnit.' + distance + '.long');
      }
    }),
    gender: (0, _attr.default)('string'),
    genderDisplay: Ember.computed('gender', function () {
      var i18n = this.get('i18n');
      var genderMap = {
        M: i18n.t('user.gender.m'),
        F: i18n.t('user.gender.f')
      };
      return genderMap[this.get('gender')] || '';
    }),

    customPictureThumb: (0, _attr.default)('string'),
    customPictureMed: (0, _attr.default)('string'),
    coverPhoto: (0, _attr.default)('string'),

    statusUpdatesCount: (0, _attr.default)('number'),
    followersCount: (0, _attr.default)('number'),

    quote: (0, _attr.default)('string'),
    bio: (0, _attr.default)('string'),

    notifications: (0, _relationships.hasMany)('notification', { inverse: 'user', async: true }),

    location: (0, _relationships.belongsTo)('location', { async: true }),
    fullName: Ember.computed('firstName', 'lastName', function () {
      var firstName = this.get('firstName'),
          lastName = this.get('lastName');
      return firstName && lastName ? this.get('firstName') + ' ' + this.get('lastName') : null;
    }),
    display: Ember.computed.alias('fullName'),

    ogDescription: Ember.computed('statusUpdatesCount', 'followersCount', 'bio', function () {
      var statusUpdatesCount = this.get('statusUpdatesCount'),
          followersCount = this.get('followersCount'),
          bio = this.get('bio');
      return bio && followersCount && statusUpdatesCount ? this.get('followersCount') + ' followers, ' + this.get('statusUpdatesCount') + ' posts, ' + this.get('bio') : null;
    }),
    ogUrl: Ember.computed('slug', function () {
      var userSlug = this.get('slug'),
          userId = this.get('id');
      return userSlug ? 'https://splife.com/users/' + userSlug : 'https://splife.com/users/' + userId;
    }),

    stats: Ember.computed('_stats', function () {
      var _this = this;

      var value = this.get('_stats');
      return !Ember.isNone(value) ? value : this.get('store').query('edition', {
        perPage: 1,
        scope: {
          model: this,
          action: 'editions'
        }
      }).then(function (editions) {
        _this.set('_stats', {
          eventsCount: editions.get('meta.count')
        });
      });
    }),
    isSelf: Ember.computed('session.currentUser', function () {
      return this.get('session.currentUser.id') === this.id;
    }),

    validations: _validations.default
  });
});