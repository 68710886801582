define('social-web/pods/feed/route', ['exports', 'social-web/mixins/infinity-route', 'social-web/mixins/feed-route', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _infinityRoute, _feedRoute, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_infinityRoute.default, _feedRoute.default, _authenticatedRouteMixin.default, {
    i18n: Ember.inject.service(),
    ht: Ember.inject.service('head-tags'),
    session: Ember.inject.service(),
    media: Ember.inject.service(),

    titleToken: function titleToken() {
      return this.get('i18n').t('feed.title');
    },
    headTags: function headTags() {
      var i18n = this.get('i18n');
      return this.get('ht').getBasicHeadTags({
        title: i18n.t('meta.title'),
        description: i18n.t('meta.description'),
        image: this.get('media.logo.small')
      });
    },
    feedScope: function feedScope() {
      return {
        model: this.get('session.currentUser'),
        action: 'news_feed'
      };
    },
    model: function model() {
      var currentUser = this.get('session.currentUser');

      return Ember.RSVP.hash({
        user: currentUser,

        featuredEditions: this.get('store').query('edition', {
          per_page: 3,
          include: 'event',
          featured: true
        }),

        featuredUsers: this.get('store').query('user', {
          per_page: 3,
          featured: true
        }),

        feed: this.getFeed(),

        currentEdition: {},
        currentGroup: {}
      });
    }
  });
});