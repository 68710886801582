define('social-web/pods/status-update/serializer', ['exports', 'social-web/pods/application/serializer'], function (exports, _serializer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _serializer.default.extend({
    attrs: {
      votesCount: { serialize: false },
      commentsCount: { serialize: false },
      latestComment: { serialize: false },
      mentions: { serialize: false }
    }
  });
});