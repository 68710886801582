define('social-web/helpers/date-label', ['exports', 'ember-moment/utils/helper-compute', 'ember-moment/helpers/-base'], function (exports, _helperCompute, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = _base.default.extend({
    moment: Ember.inject.service(),
    threshold: 2592000000,

    compute: (0, _helperCompute.default)(function (params, _ref) {
      var hideSuffix = _ref.hideSuffix,
          locale = _ref.locale,
          timeZone = _ref.timeZone,
          threshold = _ref.threshold;

      var moment = this.get('moment');
      var date = moment.moment.apply(moment, _toConsumableArray(params));
      threshold = threshold || this.get('threshold');

      return date.isBefore(moment.moment() - threshold) || date.isAfter(moment.moment() + threshold) ? this.morphMoment(moment.moment.apply(moment, _toConsumableArray(params)), { locale: locale, timeZone: timeZone }).format(this.get('moment.defaultFormat')) : this.morphMoment(moment.moment.apply(moment, _toConsumableArray(params)), { locale: locale, timeZone: timeZone }).fromNow(hideSuffix);
    })
  });
});