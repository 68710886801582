define('social-web/mixins/share-button-component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    i18n: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),

    windowHeight: 600,
    windowWidth: 600,
    windowTitle: '',
    shareUrl: '',
    queryParams: {},

    fullUrl: Ember.computed('url', function () {
      var url = this.get('url');
      return !url ? document.location.href : url.includes('http') ? url : '' + document.location.origin + url;
    }),
    click: function click() {
      var queryParams = this.get('queryParams');
      var event = this.get('model');
      var adapter = this.get('store').adapterFor('invite');
      var self = this;

      if (event && this.get('session.isAuthenticated')) {
        adapter.createInvite(event.get('id')).then(function (response) {
          var url = response.data.attributes['forwarding-url'];
          queryParams[queryParams.url ? 'url' : 'u'] = url; // Not sure what was behid this but I'll just fix it for pupperCI.

          self.openSharePopup(self.get('windowTitle'), self.get('shareUrl') + '?' + Ember.$.param(queryParams));
        });
      } else {
        this.openSharePopup(this.get('windowTitle'), this.get('shareUrl') + '?' + Ember.$.param(queryParams));
      }
    },
    getPopupPosition: function getPopupPosition() {
      var dualScreenLeft = screen.availLeft;
      var dualScreenTop = screen.availTop;

      var windowWidth = screen.availWidth;
      var windowheight = screen.availHeight;

      var left = windowWidth / 2 - this.windowWidth / 2 + dualScreenLeft;
      var top = windowheight / 2 - this.windowHeight / 2 + dualScreenTop;

      return { left: left, top: top };
    },
    openSharePopup: function openSharePopup(title, url) {
      var popupPosition = this.getPopupPosition();
      var newWindow = window.open(url, title, 'location=no,toolbar=no,menubar=no,scrollbars=no,status=no,' + ('width=' + this.windowWidth + ',height=' + this.windowHeight + ',') + ('top=' + popupPosition.top + ',left=' + popupPosition.left));

      if (newWindow && newWindow.focus) {
        newWindow.focus();
      }
    }
  });
});