define('social-web/pods/group/show/detail/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),

    init: function init() {

      Ember.Logger.log('group.show.detail.controller.init');
    },


    actions: {
      searchGroups: function searchGroups(groups) {
        return groups.map(function (group) {
          return {
            title: group.get('name'),
            description: group.get('description'),
            id: group.get('slug')
          };
        });
      },
      routeToGroup: function routeToGroup(group) {
        this.transitionToRoute('group.show.detail', group.id);
        return true;
      }
    }
  });
});