define('social-web/mixins/lazy-image', ['exports', 'social-web/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    resolve: _environment.default.environment !== 'test',
    loaded: false,
    errorThrown: false,
    onLoad: function onLoad() {},
    onError: function onError() {},
    didInsertElement: function didInsertElement() {
      Ember.setProperties(this, {
        viewportUseRAF: true,
        viewportSpy: true,
        viewportTolerance: {
          top: 1000,
          bottom: 1000,
          left: 1000,
          right: 1000
        }
      });
      this._super.apply(this, arguments);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (!this.get('resolve')) {
        this.set('viewportEntered', true);
      }
      this._setImageUrl();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      if (this.get('viewportEntered')) {
        this.setProperties({
          loaded: false,
          errorThrown: false,
          lazyUrl: null
        });
        this._resolveImage();
        this._setImageUrl();
      }
    },

    _setImageUrl: Ember.on('didEnterViewport', function () {
      var url = Ember.get(this, 'url');
      var cache = Ember.get(this, '_cache');
      var lazyUrl = Ember.get(this, 'lazyUrl');
      var cacheKey = Ember.get(this, '_cacheKey');
      var viewportEntered = Ember.get(this, 'viewportEntered');

      if (cacheKey && Ember.get(cache, cacheKey)) {
        Ember.set(this, 'lazyUrl', url);
      }

      if (viewportEntered && lazyUrl === null) {
        Ember.set(this, 'lazyUrl', url);

        if (cacheKey) {
          Ember.set(cache, cacheKey, true);
        }
      }
    }),
    _resolveImage: Ember.on('didRender', function () {
      var src = this.get('lazyUrl') || this.get('url');
      if (src) {
        var component = this;
        var image = new Image();
        image.src = src;

        this.$(image).one('load', function () {
          Ember.$(image).off('error');
          if (!component.isDestroyed && !component.isDestroying && component.get('resolve')) {
            Ember.run.schedule('afterRender', component, function () {
              Ember.set(component, 'loaded', true);
              this.onLoad(src);
            });
          }
        });
        this.$(image).one('error', function () {
          Ember.$(image).off('load');
          if (!component.isDestroyed && !component.isDestroying && component.get('resolve')) {
            Ember.run.schedule('afterRender', component, function () {
              Ember.set(component, 'errorThrown', true);
              this.onError();
            });
          }
        });
      }
    })
  });
});