define("social-web/pods/components/feed-widget/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 13,
              "column": 0
            }
          },
          "moduleName": "social-web/pods/components/feed-widget/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          return morphs;
        },
        statements: [["inline", "status-update-card-new", [], ["currentGroup", ["subexpr", "@mut", [["get", "receivedGroup", ["loc", [null, [3, 17], [3, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "currentEdition", ["subexpr", "@mut", [["get", "receivedEdition", ["loc", [null, [4, 19], [4, 34]]], 0, 0, 0, 0]], [], [], 0, 0], "onSave", ["subexpr", "@mut", [["get", "onPost", ["loc", [null, [5, 11], [5, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "postPlaceholder", ["subexpr", "@mut", [["get", "postPlaceholder", ["loc", [null, [6, 20], [6, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "defaults", ["subexpr", "hash", [], ["publisher", ["get", "session.currentUser", ["loc", [null, [8, 16], [8, 35]]], 0, 0, 0, 0]], ["loc", [null, [7, 13], [9, 5]]], 0, 0], "onPostStart", ["subexpr", "@mut", [["get", "onPostStart", ["loc", [null, [10, 16], [10, 27]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [2, 2], [10, 29]]], 0, 0], ["content", "horizontal-separator", ["loc", [null, [12, 2], [12, 26]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 0
            },
            "end": {
              "line": 26,
              "column": 0
            }
          },
          "moduleName": "social-web/pods/components/feed-widget/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "status-update-card", [], ["currentGroup", ["subexpr", "@mut", [["get", "receivedGroup", ["loc", [null, [19, 17], [19, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "currentEdition", ["subexpr", "@mut", [["get", "receivedEdition", ["loc", [null, [20, 19], [20, 34]]], 0, 0, 0, 0]], [], [], 0, 0], "statusUpdate", ["subexpr", "@mut", [["get", "feedItem", ["loc", [null, [21, 17], [21, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "disabled", ["subexpr", "@mut", [["get", "disabled", ["loc", [null, [22, 13], [22, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "onCommentStart", ["subexpr", "@mut", [["get", "onCommentStart", ["loc", [null, [23, 19], [23, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "beforeVote", ["subexpr", "@mut", [["get", "beforeVote", ["loc", [null, [24, 15], [24, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "beforeReport", ["subexpr", "@mut", [["get", "beforeReport", ["loc", [null, [25, 17], [25, 29]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [18, 2], [25, 31]]], 0, 0]],
        locals: ["feedItem"],
        templates: []
      };
    }();
    var child2 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 30,
              "column": 0
            },
            "end": {
              "line": 39,
              "column": 0
            }
          },
          "moduleName": "social-web/pods/components/feed-widget/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "status-update-card", [], ["currentGroup", ["subexpr", "@mut", [["get", "receivedGroup", ["loc", [null, [32, 17], [32, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "currentEdition", ["subexpr", "@mut", [["get", "receivedEdition", ["loc", [null, [33, 19], [33, 34]]], 0, 0, 0, 0]], [], [], 0, 0], "statusUpdate", ["subexpr", "@mut", [["get", "feedItem", ["loc", [null, [34, 17], [34, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "disabled", ["subexpr", "@mut", [["get", "disabled", ["loc", [null, [35, 13], [35, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "onCommentStart", ["subexpr", "@mut", [["get", "onCommentStart", ["loc", [null, [36, 19], [36, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "beforeVote", ["subexpr", "@mut", [["get", "beforeVote", ["loc", [null, [37, 15], [37, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "beforeReport", ["subexpr", "@mut", [["get", "beforeReport", ["loc", [null, [38, 17], [38, 29]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [31, 2], [38, 31]]], 0, 0]],
        locals: ["feedItem"],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 40,
            "column": 0
          }
        },
        "moduleName": "social-web/pods/components/feed-widget/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        morphs[3] = dom.createMorphAt(fragment, 6, 6, contextualElement);
        morphs[4] = dom.createMorphAt(fragment, 8, 8, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "unless", [["get", "disabled", ["loc", [null, [1, 10], [1, 18]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [13, 11]]]], ["inline", "loader-widget", [["subexpr", "not", [["get", "loading", ["loc", [null, [15, 21], [15, 28]]], 0, 0, 0, 0]], [], ["loc", [null, [15, 16], [15, 29]]], 0, 0]], [], ["loc", [null, [15, 0], [15, 31]]], 0, 0], ["block", "each", [["subexpr", "take", [3, ["get", "feed", ["loc", [null, [17, 16], [17, 20]]], 0, 0, 0, 0]], [], ["loc", [null, [17, 8], [17, 21]]], 0, 0]], [], 1, null, ["loc", [null, [17, 0], [26, 9]]]], ["content", "recommendation-list", ["loc", [null, [28, 0], [28, 23]]], 0, 0, 0, 0], ["block", "each", [["subexpr", "drop", [3, ["get", "feed", ["loc", [null, [30, 16], [30, 20]]], 0, 0, 0, 0]], [], ["loc", [null, [30, 8], [30, 21]]], 0, 0]], [], 2, null, ["loc", [null, [30, 0], [39, 9]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  }());
});