define('social-web/pods/components/md-modal/component', ['exports', 'ember-cli-materialize/components/md-modal'], function (exports, _mdModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _mdModal.default.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      Ember.$('body').addClass('modalized');
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      Ember.$('body').removeClass('modalized');
    }
  });
});