define('social-web/mirage/factories/comment', ['exports', 'ember-cli-mirage', 'social-web/mirage/factories/application'], function (exports, _emberCliMirage, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    body: function body() {
      return _emberCliMirage.faker.lorem.paragraph();
    },
    publishedAt: function publishedAt() {
      return _emberCliMirage.faker.date.past(1);
    },
    afterCreate: function afterCreate(comment, server) {
      if (!comment.publisher) {
        comment.publisher = server.create('user');
      }
      if (!comment.statusUpdate) {
        comment.statusUpdate = server.create('status-update');
      }

      comment.save();
    },


    withMention: (0, _emberCliMirage.trait)({
      //eventually
    })
  });
});