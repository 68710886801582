define('social-web/helpers/list-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.listItem = listItem;
  function listItem(index) {
    var idx = Number(index);
    return idx + 1;
  }

  exports.default = Ember.Helper.helper(listItem);
});