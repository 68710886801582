define('social-web/pods/group-member/model', ['exports', 'ember-data/attr', 'ember-data/relationships', 'social-web/pods/application/model'], function (exports, _attr, _relationships, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    i18n: Ember.inject.service(),

    user: (0, _relationships.belongsTo)('user', { async: false }),
    group: (0, _relationships.belongsTo)('group', { async: false }),

    display: Ember.computed.alias('user.display'),

    email: (0, _attr.default)('string'),
    firstName: (0, _attr.default)('string'),
    lastName: (0, _attr.default)('string'),

    registeredAt: (0, _attr.default)('utc'),

    moderator: (0, _attr.default)('string'),
    blocked: (0, _attr.default)('string'),

    inviteCount: (0, _attr.default)('number'),
    lastInviteSentAt: (0, _attr.default)('utc')
  });
});