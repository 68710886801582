define('social-web/pods/media/service', ['exports', 'ember-responsive/media'], function (exports, _media) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _media.default.extend({
    logo: {
      svg: '/assets/images/logo/splife-logo.svg',
      small: '/assets/images/logo/logo-small.png',
      favicon: '/assets/images/logo/favicon.png'
    },
    marketing: {
      splifeBranding: '/assets/images/marketing/splife-branding.svg'
    },
    errors: {
      cloud: '/assets/images/errors/cloud.png',
      stars: '/assets/images/errors/stars.png',
      monster: '/assets/images/errors/monster.svg'
    },
    defaults: {
      profilePicture: '/assets/images/defaults/default-profile-picture.png',
      profileCover: '/assets/images/defaults/default-profile-cover.jpg',
      eventLogo: '/assets/images/defaults/default-event-logo.png',
      eventCover: '/assets/images/defaults/default-event-cover.jpg',
      groupLogo: '/assets/images/defaults/default-group-logo.png',
      groupCover: '/assets/images/defaults/default-group-cover.jpg',
      mediaPlaceholder: '/assets/images/defaults/media-placeholder.png',
      causesPlaceholder: '/assets/images/defaults/causes-fallback.png',
      sponsorsPlaceholder: '/assets/images/defaults/sponsor-fallback.png',
      partnersPlaceholder: '/assets/images/defaults/partner-fallback.png'
    },
    account: {
      birthday: '/assets/images/account/birthday.png',
      distance: '/assets/images/account/distance.png',
      email: '/assets/images/account/email.png',
      gender: '/assets/images/account/gender.png',
      location: '/assets/images/account/location.png',
      password: '/assets/images/account/password.png',
      phone: '/assets/images/account/phone.png',
      shirt: '/assets/images/account/t-shirt.png',
      emailNotification: '/assets/images/account/email-notification.png',
      pushNotification: '/assets/images/account/push-notification.png'
    },
    onboarding: {
      s1: '/assets/images/onboarding/step-I.png',
      s2: '/assets/images/onboarding/step-II.png',
      s3: '/assets/images/onboarding/step-III.png',
      s4: '/assets/images/onboarding/step-IV.png'
    },
    icons: {
      pin: '/assets/images/icons/pin.svg',
      blackPin: '/assets/images/icons/black-pin.svg'
    },

    users: Ember.computed('isLarge', function () {
      return {
        banner: this.get('isLarge') ? '/assets/images/user-index-large.png' : '/assets/images/user-index-medium.png'
      };
    }),
    events: Ember.computed('isLarge', function () {
      return {
        banner: this.get('isLarge') ? '/assets/images/event-index-header-large.png' : '/assets/images/event-index-header-medium.png'
      };
    }),

    nav: Ember.Object.create({
      fixed: true,
      secondary: false
    }),
    columns: {
      narrow: 'sixteen wide mobile eight wide tablet six wide computer column',
      main: 'sixteen wide mobile ten wide tablet eight wide computer column',
      wide: 'sixteen wide mobile sixteen wide tablet sixteen wide computer column',
      extraWide: 'sixteen wide mobile sixteen wide tablet sixteen wide computer column',
      onboarding: 'sixteen wide mobile twelve wide tablet sixteen wide computer column'
    },

    disableBodyScroll: function disableBodyScroll() {
      Ember.$('body').css('overflow-y', 'hidden');
    },
    enableBodyScroll: function enableBodyScroll() {
      Ember.$('body').css('overflow-y', 'auto');
    }
  });
});