define('social-web/pods/scrapbook/model', ['exports', 'ember-data/attr', 'ember-data/relationships', 'social-web/pods/application/model'], function (exports, _attr, _relationships, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    display: Ember.computed.alias('name'),
    journal: (0, _attr.default)('string'),
    stars: (0, _attr.default)('number'),
    reviewText: (0, _attr.default)('string'),

    followersCount: null,
    editionsCount: (0, _attr.default)('number'),

    edition: (0, _relationships.belongsTo)('edition', { async: true }),
    // coverImage: belongsTo('coverImage', { async: true }),
    user: (0, _relationships.belongsTo)('user', { async: true }),
    statusUpdate: (0, _relationships.belongsTo)('statusUpdate', { async: true })

  });
});