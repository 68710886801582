define('social-web/tests/mirage/mirage/factories/edition', ['exports', 'ember-cli-mirage', 'social-web/mirage/factories/application', 'moment'], function (exports, _emberCliMirage, _application, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    slug: null,
    composedName: null,
    name: function name() {
      return _emberCliMirage.faker.company.companyName();
    },
    description: function description() {
      return _emberCliMirage.faker.lorem.paragraph();
    },
    faq: function faq() {
      return _emberCliMirage.faker.lorem.paragraph();
    },
    rules: function rules() {
      return _emberCliMirage.faker.lorem.paragraph();
    },
    startDate: function startDate() {
      return _emberCliMirage.faker.date.between((0, _moment.default)().subtract(_moment.default.duration('6 months')), (0, _moment.default)().subtract(_moment.default.duration('1 month')));
    },
    endDate: function endDate() {
      return _emberCliMirage.faker.date.between((0, _moment.default)().add(_moment.default.duration('1 month')), (0, _moment.default)().add(_moment.default.duration('6 months')));
    },
    participantsCount: function participantsCount() {
      return _emberCliMirage.faker.random.number(1000);
    },

    participatableCompetitionsCount: 0,
    competitionsCount: 0,
    featured: false,

    primaryPhoto: function primaryPhoto() {
      return _emberCliMirage.faker.image.sports();
    },
    secondaryPhoto: function secondaryPhoto() {
      return _emberCliMirage.faker.image.sports();
    },
    tertiaryPhoto: function tertiaryPhoto() {
      return _emberCliMirage.faker.image.sports();
    },
    quaternaryPhoto: function quaternaryPhoto() {
      return _emberCliMirage.faker.image.sports();
    },


    primaryPhotoOriginal: null,
    primaryPhotoLarge: null,
    primaryPhotoMedium: null,
    primaryPhotoSmall: null,

    secondaryPhotoOriginal: null,
    secondaryPhotoLarge: null,
    secondaryPhotoMedium: null,
    secondaryPhotoSmall: null,

    tertiaryPhotoOriginal: null,
    tertiaryPhotoLarge: null,
    tertiaryPhotoMedium: null,
    tertiaryPhotoSmall: null,

    quaternaryPhotoOriginal: null,
    quaternaryPhotoLarge: null,
    quaternaryPhotoMedium: null,
    quaternaryPhotoSmall: null,

    afterCreate: function afterCreate(edition, server) {
      if (!edition.event) {
        edition.event = server.create('event');
      }
      if (!edition.location) {
        edition.location = server.create('location');
      }

      ['primary', 'secondary', 'tertiary', 'quaternary'].forEach(function (field) {
        ['Original', 'Large', 'Medium', 'Small'].forEach(function (size) {
          edition[field + 'Photo' + size] = edition[field + 'Photo'];
        });
      });

      edition.composedName = edition.event.name + ' - ' + edition.name;
      edition.slug = ((edition.series ? edition.series.name : '') + '\n        ' + edition.event.name + '\n        ' + edition.name + '\n        ' + edition.location.formattedAddress + '\n        ' + (0, _moment.default)(edition.startDate).format('YYYY-MM-DD')).dasherize().replace(/([^a-zA-Z0-9\-_]|^-)/g, '').replace(/-{2,}/g, '-');
      edition.constructor.modelName = edition.modelName;
      edition.save();

      if (edition.propagate) {
        edition.competitions = server.createList('competition', edition.competitionsCount || _emberCliMirage.faker.random.number({ min: 1, max: 5 }), { edition: edition, propagate: true });
      }

      edition.save();
    },


    withMedia: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(edition, server) {
        edition.media = server.createList('media', edition.mediaCount === undefined ? _emberCliMirage.faker.random.number({ min: 1, max: 5 }) : edition.mediaCount);
        edition.save();
      }
    }),
    withCompetitions: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(edition, server) {
        server.createList('competition', edition.competitionsCount === undefined ? _emberCliMirage.faker.random.number({ min: 1, max: 5 }) : edition.competitionsCount, { edition: edition });
      }
    }),

    withCauses: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(edition, server) {
        server.createList('cause-category', edition.causeCategoryCount === undefined ? _emberCliMirage.faker.random.number({ min: 1, max: 5 }) : edition.causeCategoryCount, { edition: edition });
      }
    }),
    withPartners: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(edition, server) {
        server.createList('partner-category', edition.partnerCategoryCount === undefined ? _emberCliMirage.faker.random.number({ min: 1, max: 5 }) : edition.partnerCategoryCount, { edition: edition });
      }
    }),
    withSponsors: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(edition, server) {
        server.createList('sponsor-category', edition.sponsorCategoryCount === undefined ? _emberCliMirage.faker.random.number({ min: 1, max: 5 }) : edition.sponsorCategoryCount, { edition: edition });
      }
    }),
    withTravels: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(edition, server) {
        server.createList('travel-category', edition.travelCategoryCount === undefined ? _emberCliMirage.faker.random.number({ min: 1, max: 5 }) : edition.travelCategoryCount, { edition: edition });
      }
    }),
    withAccommodations: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(edition, server) {
        server.createList('accommodation-category', edition.accommodationCategoryCount === undefined ? _emberCliMirage.faker.random.number({ min: 1, max: 5 }) : edition.accommodationCategoryCount, { edition: edition });
      }
    }),
    withActivities: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(edition, server) {
        server.createList('edition-activity', edition.editionActivityCount === undefined ? _emberCliMirage.faker.random.number({ min: 1, max: 5 }) : edition.editionActivityCount, { edition: edition });
      }
    })
  });
});