define("social-web/pods/components/power-selection/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 8,
            "column": 0
          }
        },
        "moduleName": "social-web/pods/components/power-selection/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("span");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2]), 0, 0);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "lazy-image", [], ["url", ["subexpr", "@mut", [["get", "option.event.logoThumb", ["loc", [null, [2, 6], [2, 28]]], 0, 0, 0, 0]], [], [], 0, 0], "fallbackUrl", ["subexpr", "@mut", [["get", "media.defaults.eventLogo", ["loc", [null, [3, 14], [3, 38]]], 0, 0, 0, 0]], [], [], 0, 0], "alt", ["subexpr", "@mut", [["get", "option.composedName", ["loc", [null, [4, 6], [4, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "circle responsive-img"], ["loc", [null, [1, 0], [6, 2]]], 0, 0], ["content", "option.display", ["loc", [null, [7, 6], [7, 24]]], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  }());
});