define('social-web/mirage/factories/participation', ['exports', 'ember-cli-mirage', 'social-web/mirage/factories/application'], function (exports, _emberCliMirage, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    firstName: _emberCliMirage.faker.name.firstName,
    lastName: _emberCliMirage.faker.name.lastName,
    bib: null,
    birthday: function birthday() {
      return _emberCliMirage.faker.date.between('1900-01-01', '2000-01-01');
    },

    fullName: null,
    gender: function gender() {
      return _emberCliMirage.faker.random.boolean() ? 'M' : 'F';
    },
    afterCreate: function afterCreate(participation, server) {
      if (!participation.competition) {
        participation.competition = server.create('competition', {
          edition: participation.edition
        });
      }
      participation.edition = participation.competition.edition;

      if (!participation.location) {
        participation.location = server.create('location');
      }
      if (!participation.user) {
        participation.user = server.create('user');
      }
      participation.firstName = participation.user.firstName;
      participation.lastName = participation.user.lastName;
      participation.email = participation.user.email;
      participation.fullName = participation.firstName + ' ' + participation.lastName;
      participation.save();
    }
  });
});