define('social-web/pods/onboarding/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service(),

    redirect: function redirect() {
      if (this.get('session.isAuthenticated')) {
        this.replaceWith('feed');
      } else {
        this.replaceWith('onboarding.signin');
      }
    }
  });
});