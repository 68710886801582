define('social-web/mirage/factories/series-edition', ['exports', 'ember-cli-mirage', 'social-web/mirage/factories/application', 'moment'], function (exports, _emberCliMirage, _application, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    slug: null,
    name: function name() {
      return _emberCliMirage.faker.company.companyName();
    },
    description: function description() {
      return _emberCliMirage.faker.lorem.paragraph();
    },
    startDate: function startDate() {
      return _emberCliMirage.faker.date.between((0, _moment.default)().subtract(_moment.default.duration('6 months')), (0, _moment.default)().subtract(_moment.default.duration('1 month')));
    },
    endDate: function endDate() {
      return _emberCliMirage.faker.date.between((0, _moment.default)().add(_moment.default.duration('1 month')), (0, _moment.default)().add(_moment.default.duration('6 months')));
    },
    editionsCount: function editionsCount() {
      return 0;
    },
    afterCreate: function afterCreate(seriesEdition, server) {
      if (!seriesEdition.series) {
        seriesEdition.series = server.create('series');
      }
      if (!seriesEdition.location) {
        seriesEdition.location = server.create('location');
      }
      if (seriesEdition.propagate) {
        seriesEdition.editions = server.createList('edition', 16, {
          propagate: seriesEdition.propagate,
          series: seriesEdition.series
        });
      }

      seriesEdition.slug = (seriesEdition.series.name + ' ' + seriesEdition.name + ' ' + seriesEdition.location.city).dasherize().replace(/([^a-zA-Z0-9\-_]|^-)/g, '').replace(/-{2,}/g, '-');
      seriesEdition.editionsCount = seriesEdition.editions.models.length;
      seriesEdition.constructor.modelName = seriesEdition.modelName;
      seriesEdition.save();
    }
  });
});