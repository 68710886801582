define('social-web/pods/user-stats/model', ['exports', 'ember-data', 'ember-data/attr'], function (exports, _emberData, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    completedMiles: (0, _attr.default)('number'),
    completedMeters: (0, _attr.default)('number'),
    completedEventsCount: (0, _attr.default)('number'),
    upcomingEventsCount: (0, _attr.default)('number'),

    eventsCount: Ember.computed('completedEventsCount', 'upcomingEventsCount', function () {
      return (this.get('completedEventsCount') || 0) + (this.get('upcomingEventsCount') || 0);
    })
  });
});