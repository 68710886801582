define('social-web/pods/group/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    store: Ember.inject.service(),

    featuredGroups: Ember.computed(function () {
      return this.get('store').query('group', {
        per_page: 3,
        featured: true
      });
    })
  });
});