define('social-web/pods/event/show/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service(),

    beforeModel: function beforeModel(transition) {
      if (Ember.get(transition, 'queryParams.invite_id') && !this.get('session.isAuthenticated')) {
        sessionStorage.setItem('redirectPath', this.get('router.url'));
        this.transitionTo('onboarding.signin');
      }
    },
    model: function model(params, transition) {
      if (transition.targetName.replace('.index', '') === this.routeName) {
        return this.store.findRecord('event', params.event_slug, { include: 'latest-edition' });
      }
    },
    redirect: function redirect(model, transition) {
      var _this = this;

      var inviteId = Ember.get(transition, 'queryParams.invite_id');
      if (inviteId) {
        this.store.findRecord('invite', inviteId).then(function (invite) {
          return _this.store.adapterFor('invite').inviteProcess(invite);
        }).then(function () {
          return _this.store.adapterFor('event').isFollowing(model);
        }).then(function (response) {
          model.set('_currentUserFollowing', response.get('currentUserFollowing'));
          model.set('followersCount', response.get('followersCount'));
        });
        sessionStorage.clear();
      }

      if (transition.targetName.replace('.index', '') === this.routeName) {
        this.replaceWith('event.show.edition.show', model.get('latestEdition'));
      }
    }
  });
});