define('social-web/pods/application/route', ['exports', 'ember-simple-auth/mixins/application-route-mixin', 'social-web/mixins/infinity-route', 'social-web/mixins/feed-route'], function (exports, _applicationRouteMixin, _infinityRoute, _feedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_applicationRouteMixin.default, _infinityRoute.default, _feedRoute.default, {
    ajax: Ember.inject.service(),
    i18n: Ember.inject.service(),
    session: Ember.inject.service(),

    title: function title(tokens) {
      return tokens.length ? tokens.join(' | ') : this.get('i18n').t('meta.title');
    },
    model: function model() {
      var accessToken = Ember.$('meta[name=access_token][content]').attr('content');
      return accessToken && !this.get('session.isAuthenticated') ? this.get('session').authenticate('authenticator:application', accessToken).catch(function () {}) : true;
    },
    setupController: function setupController() {
      this.controllerFor('application').set('innerAppLayout', true);
    },


    actions: {
      notYetImplemented: function notYetImplemented() {
        window.Materialize.toast('Not Yet Implemented', 2000);
      },
      transitionTo: function transitionTo(route) {
        this.transitionTo(route);
      },
      transitionToParent: function transitionToParent(currentRoute) {
        var route = currentRoute || this.router.currentRouteName;
        this.transitionTo(route.substring(0, route.lastIndexOf('.')));
      },
      forceLogin: function forceLogin() {
        var _this = this;

        return new Ember.RSVP.Promise(function (resolve, reject) {
          if (_this.get('session.isAuthenticated')) {
            resolve();
          } else {
            _this.set('session.loginModalVisible', true);
            reject();
          }
        });
      },
      error: function error(_error) {
        if (_error.isAdapterError) {
          if (_error.message.includes('404')) {
            this.intermediateTransitionTo('404', window.location.pathname);
          } else {
            this.intermediateTransitionTo('500');
            throw _error;
          }
        } else {
          throw _error;
        }
      }
    },
    sessionAuthenticated: function sessionAuthenticated() {
      //swallow
    }
  });
});