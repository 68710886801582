define('social-web/pods/group/show/detail/members/route', ['exports', 'social-web/mixins/refreshable-infinity-route'], function (exports, _refreshableInfinityRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_refreshableInfinityRoute.default, {
    i18n: Ember.inject.service(),
    groupMember: Ember.inject.service('group-member'),

    titleToken: function titleToken() {
      return this.get('i18n').t('groups.show.members.title');
    },

    modelName: 'group-member',
    modelParams: function modelParams(params) {
      var scopeModel = this.get('controller.member') || this.get('group-member') || this.modelFor('group.show');
      return {
        perPage: 24,
        modelPath: 'controller.model.members',
        search: Ember.get(params, 'search'),
        sort: {
          user_id: 'not_null'
        },
        scope: {
          model: scopeModel,
          action: 'members'
        }
      };
    },
    model: function model(params) {

      Ember.Logger.log('group.show.detail.members route.js .model');

      params.search = params.currentSearch;
      var group = this.modelFor('group.show');
      // return new Ember.RSVP.hash({
      //   group,
      //   members: this.getInfinityModel(params)
      // });
      if (group.get('membersCount')) {
        return new Ember.RSVP.hash({
          group: group,
          members: this.getInfinityModel(params)
          // members: this.store.query('group-member', {
          //   scope: {
          //     model: group, action: 'members'
          //   }
          // }),
        });
      } else {
        return new Ember.RSVP.hash({
          group: group
        });
      }
    },

    // afterInfinityModel(members) {
    //   let controller = this.get('controller');
    //   if(controller) {
    //     this.set('controller.currentSearch', this.get('controller.search'));
    //   }
    //   return new Ember.RSVP.Promise((resolve) => {
    //     this.get('group-member').checkCurrentUserMemberUsers(members).then(() => {
    //       resolve(members);
    //     });
    //   });
    // },
    afterInfinityModel: function afterInfinityModel(members) {
      var _this = this;

      if (this.get('controller')) {
        this.set('controller.currentSearch', this.get('controller.search'));
      }

      // let controller = this.get('controller');
      // if(!controller) {
      //   this.set('group-member');
      // } else {
      //   let controller = this.get('controller');
      //   controller.set('currentSearch', controller.get('search'));
      // }


      return new Ember.RSVP.Promise(function (resolve) {
        new Ember.RSVP.all(members.getEach('user')).then(function (users) {
          _this.get('groupMember').checkCurrentUserMemberUsers(users.compact()).then(function () {
            resolve(members);
          });
        });
      });
    },

    //   setupController(controller) {
    //     this._super(...arguments);
    //     controller.set('currentSearch', this.get('_extraParams.search'));
    //   }
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      if (model.members && model.members.get('length')) {
        var member = model.members.findBy('id', controller.get('groupID'));
        controller.set('search', this.get('_extraParams.search'));
        controller.set('currentSearch', this.get('_extraParams.search'));
        controller.set('group-member', member);
        controller.set('members', model.members);
      }
    },

    actions: {
      clearAndRefresh: function clearAndRefresh() {
        this.set('controller.search', '');
        return this.send('refresh');
      }
    }
  });
});