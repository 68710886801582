define('social-web/pods/event/show/edition/show/route', ['exports', 'social-web/mixins/reset-scroll'], function (exports, _resetScroll) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_resetScroll.default, {
    ht: Ember.inject.service('head-tags'),

    titleToken: function titleToken(model) {
      return model.get('composedName');
    },
    headTags: function headTags() {
      var model = this.modelFor(this.routeName);
      return this.get('ht').getBasicHeadTags({
        title: model.get('composedName'),
        description: model.get('description'),
        image: model.get('event.logoThumb'),
        type: 'fitness.course',
        liveURL: model.get('liveURL')
      });
    },
    model: function model(params) {
      var _this = this;

      var edition = this.store.findRecord('edition', params.slug, { include: 'event' });

      edition.then(function (edition) {
        _this.controllerFor('application').set('currentGroup', {});
        _this.controllerFor('application').set('currentEdition', edition);
      });

      return edition;
    }
  });
});