define('social-web/pods/components/feed-widget/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    session: Ember.inject.service(),

    receivedGroup: Ember.computed('currentGroup', function () {
      return this.get('currentGroup');
    }),
    receivedEdition: Ember.computed('currentEdition', function () {
      return this.get('currentEdition');
    }),

    init: function init() {
      this._super.apply(this, arguments);

      this.set('selected', this.get('scopes.firstObject'));
    },


    scopes: Ember.computed(function () {
      var i18n = this.get('i18n');
      return [{
        title: i18n.t('feed.filter.recent.title'),
        description: i18n.t('feed.filter.recent.description'),
        value: 'recent'
      }, {
        title: i18n.t('feed.filter.trending.title'),
        description: i18n.t('feed.filter.trending.description'),
        value: 'trending'
      }];
    })
  });
});