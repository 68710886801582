define('social-web/pods/components/notification-dropdown/component', ['exports', 'social-web/mixins/infinity-route'], function (exports, _infinityRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_infinityRoute.default, {
    media: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    notification: Ember.inject.service(),

    classNames: ['ui', 'dropdown'],

    init: function init() {
      this._super.apply(this, arguments);
      this.set('loader', this.infinityModel('notification', {
        perPage: 25,
        modelPath: 'notifications',
        scope: {
          model: this.get('session.currentUser'),
          action: 'notifications'
        }
      }));
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var context = this,
          media = this.get('media');
      this.get('notification').registerNotificationDropdown(this);

      this.$().dropdown({
        action: 'hide',
        onShow: function onShow() {
          if (media.get('isSmall')) {
            media.disableBodyScroll();
          }

          var store = context.get('store');
          if (store.peekAll('notification').rejectBy('seen').length) {
            store.adapterFor('notification').acknowledgeUnseen(context.get('session.currentUser')).then(function () {
              context.set('markedSeen', true);
            });
          }
        },
        onHide: function onHide() {
          if (media.get('isSmall')) {
            media.enableBodyScroll();
          }
        }
      });
    },
    afterInfinityModel: function afterInfinityModel(notifications) {
      if (!this.get('notifications')) {
        this.set('notifications', notifications);
      }
      return notifications;
    },
    selfOffset: function selfOffset() {
      var menu = this.$().parent();
      var scrollHeight = menu.prop('scrollHeight');
      return !scrollHeight || !this.get('_firstPageLoaded') || this.get('_loadingMore') ? 99999 : this.$().offset().top - menu.offset().top + menu.scrollTop();
    },

    actions: {
      close: function close() {
        this.$().dropdown('hide');
      },
      open: function open() {
        this.$().dropdown('show');
      }
    }
  });
});