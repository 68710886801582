define('social-web/pods/group/show/detail/about/resources/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {

      Ember.Logger.log('group.show.detail.about.resources route.js .model');

      return this.modelFor('group.show');
    }
  });
});