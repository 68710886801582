define('social-web/pods/scrapbook/show/results/info/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    modelName: 'split',

    model: function model() {
      var _modelFor = this.modelFor('scrapbook.show'),
          user = _modelFor.user,
          splits = _modelFor.splits;

      var splitId = splits.get('firstObject.id');

      return {
        user: user,
        split: this.store.findRecord('split', splitId)
      };
    },

    actions: {
      updateInfo: function updateInfo(changeset, model) {
        var _this = this;

        changeset.execute();
        return model.save().then(function () {
          return _this.send('transitionToParent');
        });
      }
    }
  });
});