define('social-web/pods/map-file/model', ['exports', 'ember-data/attr', 'ember-data/relationships', 'social-web/pods/application/model'], function (exports, _attr, _relationships, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    name: (0, _attr.default)('string'),
    sortPriority: (0, _attr.default)('number'),
    file: (0, _attr.default)('string'),

    edition: (0, _relationships.belongsTo)('edition'),
    getExtension: Ember.computed('file', function () {
      var file = this.get('file') || '';
      var tokens = /\.(.*)?\?/.exec(file.substring(file.lastIndexOf('/') + 1));
      return tokens && tokens.length > 1 ? tokens[1] : '';
    }),
    isImage: Ember.computed('getExtension', function () {
      return ['jpg', 'jpeg', 'png', 'gif'].includes(this.get('getExtension'));
    }),
    isPdfFile: Ember.computed('getExtension', function () {
      return this.get('getExtension') === 'pdf';
    })
  });
});