define('social-web/mixins/member-model', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    session: Ember.inject.service(),

    updateMemberData: function updateMemberData(memberData) {
      this.set('_currentUserMember', memberData.get('currentUserMember'));
      if (this.get('isLoaded')) {
        this.set('membersCount', memberData.get('membersCount'));
      }
    },
    include: function include() {
      return this.get('adapter').include(this).then(this.updateMemberData.bind(this));
    },
    remove: function remove() {
      return this.get('adapter').remove(this).then(this.updateMemberData.bind(this));
    },

    currentUserMember: Ember.computed('_currentUserMember', 'session.currentUser', function () {
      if (this.constructor.modelName === 'user' && this.get('session.currentUser.id') === this.id) {
        return null;
      }
      var value = this.get('_currentUserMember');
      return !Ember.isNone(value) ? value : this.get('adapter').isMember(this).then(this.updateMemberData.bind(this));
    })
  });
});