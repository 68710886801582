define('social-web/pods/components/ui-button/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var UiButton = Ember.Component.extend({
    tagName: 'button',
    classNames: ['ui', 'button'],
    classNameBindings: ['loading', 'fluid', 'inverted', 'basic', 'primary', 'circular'],
    attributeBindings: ['disabled', 'type'],
    loading: false,
    fluid: false,
    inverted: false,
    basic: false,
    primary: false,
    circular: false,

    click: function click() {
      var _this = this;

      var action = this.attrs.action || this.get('params')[0];
      if (action) {
        var result = action();
        if (result && result.then) {
          this.set('loading', true);
          return new Ember.RSVP.Promise(function (resolve, reject) {
            result.then(function (data) {
              resolve(data);
            }, function (error) {
              reject(error);
            });
          }).finally(function () {
            _this.set('loading', false);
          });
        } else {
          return result;
        }
      }
    }
  });

  UiButton.reopenClass({
    positionalParams: 'params'
  });

  exports.default = UiButton;
});