define('social-web/pods/event/show/edition/show/about/competitions/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var model = this.modelFor('event.show.edition.show');
      return this.get('store').query('competition', {
        scope: {
          model: model,
          action: 'competitions'
        }
      });
    },
    redirect: function redirect(model) {
      if (!model.get('length')) {
        this.send('transitionToParent', this.routeName);
      }
    }
  });
});