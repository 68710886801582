define('social-web/pods/components/member-card/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    media: Ember.inject.service(),

    classNames: ['card', 'hoverable'],
    classNameBindings: ['horizontal:horizontal:vertical']
  });
});