define('social-web/mirage/models/group', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Model.extend({
    members: (0, _emberCliMirage.hasMany)('user'),
    media: (0, _emberCliMirage.hasMany)(),
    organization: (0, _emberCliMirage.belongsTo)(),

    location: (0, _emberCliMirage.belongsTo)(),

    causeCategories: (0, _emberCliMirage.hasMany)(),
    sponsorCategories: (0, _emberCliMirage.hasMany)(),
    groupActivities: (0, _emberCliMirage.hasMany)()
  });
});