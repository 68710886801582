define('social-web/pods/user/show/friends/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    i18n: Ember.inject.service(),

    queryParams: ['search', 'group'],
    search: null,
    group: 'followees',
    formattedGroup: Ember.computed('group', function () {
      return this.get('group') || 'followees';
    }),

    groups: Ember.computed(function () {
      var i18n = this.get('i18n');
      return [{
        display: i18n.t('user.show.friends.filters.groups.followees'),
        id: 'followees'
      }, {
        display: i18n.t('user.show.friends.filters.groups.followers'),
        id: 'followers'
      }];
    })
  });
});