define("social-web/pods/search/group/route", ["exports", "social-web/mixins/refreshable-infinity-route"], function (exports, _refreshableInfinityRoute) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_refreshableInfinityRoute.default, {
    i18n: Ember.inject.service(),

    titleToken: function titleToken(model) {
      return this.get("i18n").t("search.group.title", {
        count: model.get("meta.count")
      });
    },

    modelName: "group",
    modelParams: function modelParams(params, transition) {
      var search = Ember.get(params, "search") || Ember.get(transition, "queryParams.search");
      return {
        search: search
      };
    },
    afterInfinityModel: function afterInfinityModel() {
      if (this.get("controller")) {
        this.set("controller.currentSearch", this.get("controller.search"));
      }
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set("currentSearch", this.get("_extraParams.search"));
    }
  });
});