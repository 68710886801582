define("social-web/pods/components/user-follow-button/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 4
              },
              "end": {
                "line": 7,
                "column": 4
              }
            },
            "moduleName": "social-web/pods/components/user-follow-button/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "t", [["subexpr", "if", [["get", "user.currentUserFollowing", ["loc", [null, [6, 14], [6, 39]]], 0, 0, 0, 0], "user.following", "user.follow"], [], ["loc", [null, [6, 10], [6, 71]]], 0, 0]], [], ["loc", [null, [6, 6], [6, 73]]], 0, 0]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 9,
              "column": 0
            }
          },
          "moduleName": "social-web/pods/components/user-follow-button/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "card-follow-action");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["block", "ui-button", [["subexpr", "queue", [["subexpr", "action", ["forceLogin"], [], ["loc", [null, [3, 24], [3, 45]]], 0, 0], ["subexpr", "perform", [["get", "toggleFollow", ["loc", [null, [3, 55], [3, 67]]], 0, 0, 0, 0], ["get", "user", ["loc", [null, [3, 68], [3, 72]]], 0, 0, 0, 0]], [], ["loc", [null, [3, 46], [3, 73]]], 0, 0]], [], ["loc", [null, [3, 17], [3, 74]]], 0, 0]], ["circular", true, "class", ["subexpr", "concat", ["follow x-small ", ["subexpr", "if", [["get", "user.currentUserFollowing", ["loc", [null, [5, 42], [5, 67]]], 0, 0, 0, 0], "followed", "splife-red"], [], ["loc", [null, [5, 38], [5, 92]]], 0, 0]], [], ["loc", [null, [5, 12], [5, 93]]], 0, 0]], 0, null, ["loc", [null, [3, 4], [7, 18]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 10,
            "column": 0
          }
        },
        "moduleName": "social-web/pods/components/user-follow-button/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["subexpr", "and", [["get", "user", ["loc", [null, [1, 11], [1, 15]]], 0, 0, 0, 0], ["subexpr", "not", [["get", "user.isSelf", ["loc", [null, [1, 21], [1, 32]]], 0, 0, 0, 0]], [], ["loc", [null, [1, 16], [1, 33]]], 0, 0]], [], ["loc", [null, [1, 6], [1, 34]]], 0, 0]], [], 0, null, ["loc", [null, [1, 0], [9, 7]]]]],
      locals: [],
      templates: [child0]
    };
  }());
});