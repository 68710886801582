define('social-web/pods/application/adapter', ['exports', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin', 'social-web/config/environment'], function (exports, _emberData, _dataAdapterMixin, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPIAdapter.extend(_dataAdapterMixin.default, {
    store: Ember.inject.service(),
    session: Ember.inject.service(),

    host: _environment.default.APP.API_HOST,
    namespace: 'api/v2',
    authorizer: 'authorizer:application',

    init: function init() {
      this.set('authorizer-instance', Ember.getOwner(this).lookup('authorizer:application'));
      this._super.apply(this, arguments);
    },
    buildURL: function buildURL(modelName, id) {
      var snapshot = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var requestType = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
      var query = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};

      var url = null;
      if (query.scope) {
        url = this.scopeURL(url, query.scope);
        delete query.scope;
      } else if (snapshot && Ember.get(snapshot, 'adapterOptions.scope')) {
        url = this.scopeURL(url, Ember.get(snapshot, 'adapterOptions.scope'));
      } else {
        url = this._super.apply(this, arguments);
      }
      return url.replace('/' + modelName.pluralize(), '/' + modelName.pluralize().underscore());
    },
    scopeURL: function scopeURL(url, _ref) {
      var model = _ref.model,
          action = _ref.action,
          scope = _ref.scope;

      var modelName = Ember.get(model, 'content.id') ? Ember.get(model, 'content').constructor.modelName : model.constructor.modelName;
      var id = Ember.get(model, 'id');
      if (!url) {
        var adapter = this.get('store').adapterFor(modelName);
        url = adapter.buildURL(modelName, id);
      } else {
        if (model) {
          var resourceName = modelName.pluralize().underscore();
          url = url + '/' + resourceName;
          if (id) {
            url += '/' + id;
          }
        }
      }

      if (action) {
        url = url + '/' + action;
      }

      return scope ? this.scopeURL(url, scope) : url;
    },
    addParameters: function addParameters(url, snapshot) {
      var query = Ember.get(snapshot, 'adapterOptions.query');
      if (query) {
        url += '?' + Ember.$.param(query);
      }
      return url;
    },
    urlForFindRecord: function urlForFindRecord(id, modelName) {
      var snapshot = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      var url = this._super.apply(this, arguments);
      return this.addParameters(url, snapshot);
    },
    urlForUpdateRecord: function urlForUpdateRecord(id, modelName) {
      var snapshot = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      var url = this._super.apply(this, arguments);
      return this.addParameters(url, snapshot);
    },
    urlForCreateRecord: function urlForCreateRecord(id, modelName) {
      var snapshot = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      var url = this._super.apply(this, arguments);
      return this.addParameters(url, snapshot);
    }
  });
});