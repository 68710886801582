define("social-web/pods/search/edition/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 4,
              "column": 0
            }
          },
          "moduleName": "social-web/pods/search/edition/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "spinner-widget", [], ["class", "spinner-wrapper"], ["loc", [null, [3, 2], [3, 44]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      var child0 = function () {
        var child0 = function () {
          var child0 = function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 23,
                    "column": 14
                  },
                  "end": {
                    "line": 25,
                    "column": 14
                  }
                },
                "moduleName": "social-web/pods/search/edition/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "date-label", [["get", "event.latestEdition.startDate", ["loc", [null, [24, 29], [24, 58]]], 0, 0, 0, 0]], [], ["loc", [null, [24, 16], [24, 60]]], 0, 0]],
              locals: [],
              templates: []
            };
          }();
          var child1 = function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 25,
                    "column": 14
                  },
                  "end": {
                    "line": 27,
                    "column": 14
                  }
                },
                "moduleName": "social-web/pods/search/edition/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                 \n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 11,
                  "column": 6
                },
                "end": {
                  "line": 32,
                  "column": 6
                }
              },
              "moduleName": "social-web/pods/search/edition/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "ui dark-grey-i inverted segment");
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "content");
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3, "class", "ui white-text header truncate");
              var el4 = dom.createTextNode("\n              ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("strong");
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3, "class", "location truncate");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3, "class", "date truncate");
              var el4 = dom.createTextNode("\n");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("            ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "sports truncate");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var element2 = dom.childAt(element1, [3]);
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(element1, 1, 1);
              morphs[1] = dom.createMorphAt(dom.childAt(element2, [1, 1]), 0, 0);
              morphs[2] = dom.createMorphAt(dom.childAt(element2, [3]), 0, 0);
              morphs[3] = dom.createMorphAt(dom.childAt(element2, [5]), 1, 1);
              return morphs;
            },
            statements: [["inline", "inline-lazy-image", [], ["url", ["subexpr", "@mut", [["get", "event.logoThumb", ["loc", [null, [13, 34], [13, 49]]], 0, 0, 0, 0]], [], [], 0, 0], "fallbackUrl", ["subexpr", "@mut", [["get", "media.defaults.eventLogo", ["loc", [null, [14, 42], [14, 66]]], 0, 0, 0, 0]], [], [], 0, 0], "alt", ["subexpr", "or", [["get", "event.latestEdition.composedName", ["loc", [null, [15, 38], [15, 70]]], 0, 0, 0, 0], ["get", "event.name", ["loc", [null, [15, 71], [15, 81]]], 0, 0, 0, 0]], [], ["loc", [null, [15, 34], [15, 82]]], 0, 0], "class", "ui tiny square left floated avatar image"], ["loc", [null, [13, 10], [16, 80]]], 0, 0], ["inline", "or", [["get", "event.latestEdition.composedName", ["loc", [null, [19, 27], [19, 59]]], 0, 0, 0, 0], ["get", "event.name", ["loc", [null, [19, 60], [19, 70]]], 0, 0, 0, 0]], [], ["loc", [null, [19, 22], [19, 72]]], 0, 0], ["content", "event.latestEdition.location.display", ["loc", [null, [21, 43], [21, 83]]], 0, 0, 0, 0], ["block", "if", [["get", "event.latestEdition.startDate", ["loc", [null, [23, 20], [23, 49]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [23, 14], [27, 21]]]]],
            locals: [],
            templates: [child0, child1]
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 10,
                "column": 4
              },
              "end": {
                "line": 33,
                "column": 4
              }
            },
            "moduleName": "social-web/pods/search/edition/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "link-to", ["event.show.edition.show", ["get", "event.slug", ["loc", [null, [11, 43], [11, 53]]], 0, 0, 0, 0], ["get", "event.latestEdition.slug", ["loc", [null, [11, 54], [11, 78]]], 0, 0, 0, 0]], ["class", "item"], 0, null, ["loc", [null, [11, 6], [32, 18]]]]],
          locals: ["event"],
          templates: [child0]
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 36,
                "column": 2
              },
              "end": {
                "line": 38,
                "column": 2
              }
            },
            "moduleName": "social-web/pods/search/edition/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "spinner-widget", [], ["class", "spinner-wrapper"], ["loc", [null, [37, 4], [37, 46]]], 0, 0]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 0
            },
            "end": {
              "line": 39,
              "column": 0
            }
          },
          "moduleName": "social-web/pods/search/edition/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h6");
          dom.setAttribute(el1, "class", "ui inverted upcase header");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "ui animated list");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
          morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "t", ["search.edition.title"], ["count", ["subexpr", "@mut", [["get", "model.meta.count", ["loc", [null, [7, 73], [7, 89]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [7, 40], [7, 91]]], 0, 0], ["block", "each", [["get", "model", ["loc", [null, [10, 12], [10, 17]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [10, 4], [33, 13]]]], ["block", "infinity-loader", [], ["infinityModel", ["subexpr", "@mut", [["get", "model", ["loc", [null, [36, 35], [36, 40]]], 0, 0, 0, 0]], [], [], 0, 0], "triggerOffset", 500], 1, null, ["loc", [null, [36, 2], [38, 22]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    var child2 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 41,
              "column": 0
            },
            "end": {
              "line": 48,
              "column": 0
            }
          },
          "moduleName": "social-web/pods/search/edition/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h2");
          dom.setAttribute(el1, "class", "ui inverted centered header");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "sub header");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element0, 1, 1);
          morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]), 1, 1);
          return morphs;
        },
        statements: [["inline", "t", ["search.edition.empty.title"], [], ["loc", [null, [43, 4], [43, 38]]], 0, 0], ["inline", "t", ["search.edition.empty.description"], [], ["loc", [null, [45, 6], [45, 46]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 49,
            "column": 0
          }
        },
        "moduleName": "social-web/pods/search/edition/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "liquid-if", [["get", "loading", ["loc", [null, [2, 13], [2, 20]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [2, 0], [4, 14]]]], ["block", "liquid-if", [["get", "model.meta.count", ["loc", [null, [6, 13], [6, 29]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [6, 0], [39, 14]]]], ["block", "liquid-if", [["subexpr", "and", [["get", "currentSearch", ["loc", [null, [41, 18], [41, 31]]], 0, 0, 0, 0], ["subexpr", "not", [["get", "model.meta.count", ["loc", [null, [41, 37], [41, 53]]], 0, 0, 0, 0]], [], ["loc", [null, [41, 32], [41, 54]]], 0, 0]], [], ["loc", [null, [41, 13], [41, 55]]], 0, 0]], [], 2, null, ["loc", [null, [41, 0], [48, 14]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  }());
});