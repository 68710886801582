define('social-web/transforms/utc', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Transform.extend({
    serialize: function serialize(value) {
      var m = (0, _moment.default)(value);
      var result = (0, _moment.default)(m).utc().add(m.utcOffset(), 'm');
      return result ? result.toJSON() : null;
    },
    deserialize: function deserialize(value) {
      var m = (0, _moment.default)(value);
      var result = (0, _moment.default)(m).utc();
      return result.toDate();
    }
  });
});