define('social-web/mirage/factories/application', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    createdAt: function createdAt() {
      return _emberCliMirage.faker.date.past(1);
    },
    updatedAt: function updatedAt() {
      return _emberCliMirage.faker.date.past(1);
    }
  });
});