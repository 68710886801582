define('social-web/pods/series-edition/route', ['exports', 'social-web/mixins/infinity-route', 'moment'], function (exports, _infinityRoute, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_infinityRoute.default, {
    ht: Ember.inject.service('head-tags'),

    titleToken: function titleToken(model) {
      var lastChunk = [];
      var startDate = model.seriesEdition.get('startDate');
      var location = model.seriesEdition.get('location.display');
      if (startDate) {
        lastChunk.push((0, _moment.default)(startDate).format('LL'));
      }
      if (location) {
        lastChunk.push(location);
      }
      return [model.seriesEdition.get('series.name') + ' ' + model.seriesEdition.get('name'), lastChunk.join(' - ')].join(' | ');
    },
    headTags: function headTags() {
      var model = this.modelFor(this.routeName);
      return this.get('ht').getBasicHeadTags({
        title: model.seriesEdition.get('name'),
        description: model.seriesEdition.get('description'),
        image: model.seriesEdition.get('series.logoThumb'),
        type: 'fitness.course'
      });
    },
    model: function model(params) {
      return Ember.RSVP.hash({
        seriesEdition: this.store.findRecord('series-edition', params.id, {
          adapterOptions: { query: { include: 'series,location' } }
        }),
        editions: this.getEditions(params.id)
      });
    },
    getEditions: function getEditions(id) {
      return this.infinityModel('edition', {
        perPage: 24,
        modelPath: 'controller.model.editions',

        scope: { model: {
            constructor: { modelName: 'series-edition' }, id: id
          }, action: 'editions' }
      });
    }
  });
});