define('social-web/pods/email/adapter', ['exports', 'social-web/pods/application/adapter'], function (exports, _adapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _adapter.default.extend({
    setDefault: function setDefault(email) {
      var updateUrl = this.urlForUpdateRecord(email.get('id'), 'email', { adapterOptions: { model: email.get('user') } });
      return this.ajax(updateUrl + '/default_email', 'POST');
    },
    urlForFindAll: function urlForFindAll(modelName, snapshot) {
      var registrationID = snapshot.adapterOptions.model.get('id');
      return '' + (this.host || '/') + this.namespace + '/registrations/' + registrationID + '/emails';
    },
    urlForCreateRecord: function urlForCreateRecord(modelName, snapshot) {
      var registrationID = snapshot.adapterOptions.model.get('id');
      return '' + (this.host || '/') + this.namespace + '/registrations/' + registrationID + '/emails';
    },
    urlForDeleteRecord: function urlForDeleteRecord(id, modelName, snapshot) {
      var registrationID = snapshot.adapterOptions.model.get('id');
      return '' + (this.host || '/') + this.namespace + '/registrations/' + registrationID + '/emails/' + id;
    },
    urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
      var registrationID = snapshot.adapterOptions.model.get('id');
      return '' + (this.host || '/') + this.namespace + '/registrations/' + registrationID + '/emails/' + id;
    }
  });
});