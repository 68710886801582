define('social-web/pods/components/md-chips/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    options: [],
    actions: {
      onkeydown: function onkeydown(select, e) {
        if ((e.which === 13 || e.which === 9) && select.isOpen && !select.highlighted && !Ember.isBlank(select.searchText)) {

          var selected = this.get('selected');
          if (!selected.includes(select.searchText)) {
            if (this.attrs.beforeAdd) {
              this.attrs.beforeAdd(select, e);
            }

            this.get('options').pushObject(select.searchText);
            select.actions.choose(select.searchText);
            select.actions.search('');
            this.get('options').removeObject(select.searchText);
            e.preventDefault();

            if (this.attrs.afterAdd) {
              this.attrs.afterAdd(select, e);
            }
          }
        }
      }
    }
  });
});