define('social-web/pods/user/validations', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    firstName: (0, _validators.validatePresence)(true),
    lastName: (0, _validators.validatePresence)(true),
    phoneNumber: (0, _validators.validateFormat)({ type: 'phone', allowBlank: true }),
    gender: (0, _validators.validatePresence)(true),
    quote: (0, _validators.validateLength)({ max: 255 }),
    bio: (0, _validators.validateLength)({ max: 255 })
  };
});