define("social-web/pods/components/tile-widget/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 0
            },
            "end": {
              "line": 8,
              "column": 0
            }
          },
          "moduleName": "social-web/pods/components/tile-widget/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1, "class", "btn-floating btn-large left");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element0, 'style');
          morphs[1] = dom.createAttrMorph(element0, 'title');
          return morphs;
        },
        statements: [["attribute", "style", ["subexpr", "stylize", [], ["background-image", ["subexpr", "join", ["", ["subexpr", "array", ["url(", ["get", "logoUrl", ["loc", [null, [5, 60], [5, 67]]], 0, 0, 0, 0], ")"], [], ["loc", [null, [5, 46], [5, 72]]], 0, 0]], [], ["loc", [null, [5, 37], [5, 73]]], 0, 0]], ["loc", [null, [null, null], [5, 75]]], 0, 0], 0, 0, 0, 0], ["attribute", "title", ["get", "title", ["loc", [null, [6, 12], [6, 17]]], 0, 0, 0, 0], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 16,
            "column": 0
          }
        },
        "moduleName": "social-web/pods/components/tile-widget/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "overlay");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h4");
        dom.setAttribute(el1, "class", "white-text");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h6");
        dom.setAttribute(el1, "class", "white-text");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "time-label");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h2");
        dom.setAttribute(el2, "class", "white-text");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "white-text");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "slant");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [8]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(fragment, 3, 3, contextualElement);
        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [4]), 0, 0);
        morphs[2] = dom.createMorphAt(dom.childAt(fragment, [6]), 0, 0);
        morphs[3] = dom.createMorphAt(dom.childAt(element1, [1]), 0, 0);
        morphs[4] = dom.createMorphAt(dom.childAt(element1, [3]), 0, 0);
        return morphs;
      },
      statements: [["block", "if", [["get", "logoUrl", ["loc", [null, [3, 6], [3, 13]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [3, 0], [8, 7]]]], ["content", "title", ["loc", [null, [9, 23], [9, 32]]], 0, 0, 0, 0], ["content", "subtitle", ["loc", [null, [10, 23], [10, 35]]], 0, 0, 0, 0], ["inline", "moment-format", [["get", "date", ["loc", [null, [12, 41], [12, 45]]], 0, 0, 0, 0], "DD"], [], ["loc", [null, [12, 25], [12, 52]]], 0, 0], ["inline", "moment-format", [["get", "date", ["loc", [null, [13, 42], [13, 46]]], 0, 0, 0, 0], "MMM"], [], ["loc", [null, [13, 26], [13, 54]]], 0, 0]],
      locals: [],
      templates: [child0]
    };
  }());
});