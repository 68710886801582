define('social-web/breakpoints', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    small: '(max-width: 600px)',
    medium: '(min-width: 601px) and (max-width: 992px)',
    large: '(min-width: 993px)',

    mobile: '(max-width: 767px)',
    tablet: '(min-width: 768px) and (max-width: 991px)',
    computer: '(min-width 992px) and (max-width: 1200px)',
    largeScreen: '(min-width: 1201px)'
  };
});