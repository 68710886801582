define('social-web/pods/application/serializer', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPISerializer.extend({
    attrs: {
      createdAt: { serialize: false },
      updatedAt: { serialize: false }
    }
  });
});