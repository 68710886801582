define('social-web/tests/mirage/mirage/factories/email', ['exports', 'ember-cli-mirage', 'social-web/mirage/factories/application'], function (exports, _emberCliMirage, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    defaultEmail: false,
    email: _emberCliMirage.faker.internet.email,

    afterCreate: function afterCreate(email, server) {
      if (!email.user) {
        var user = server.schema.users.where({ current: true }).models[0] || server.create('user');
        email.user = user;
      }
      email.save();
    }
  });
});