define('social-web/pods/user/show/index/route', ['exports', 'social-web/mixins/infinity-route', 'social-web/mixins/feed-route', 'social-web/mixins/reset-scroll'], function (exports, _infinityRoute, _feedRoute, _resetScroll) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_resetScroll.default, _infinityRoute.default, _feedRoute.default, {
    i18n: Ember.inject.service(),

    feedScope: function feedScope() {
      return {
        model: this.modelFor('user.show'),
        action: 'feed'
      };
    },
    titleToken: function titleToken() {
      return this.get('i18n').t('user.show.activity.title');
    },
    model: function model() {
      return Ember.RSVP.hash({
        user: this.modelFor('user.show'),
        feed: this.getFeed(),
        currentEdition: {},
        currentGroup: {}
      });
    }
  });
});