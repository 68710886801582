define('social-web/pods/components/user-card/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['card', 'avatar', 'hoverable'],
    classNameBindings: ['dark']
  });
});