define('social-web/pods/notification/service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    registerNotificationDropdown: function registerNotificationDropdown(component) {
      this.set('notification-dropdown', component);
    },
    showNotificationDropdown: function showNotificationDropdown() {
      var notificationDropdown = this.get('notification-dropdown');
      notificationDropdown && notificationDropdown.send('open');
    }
  });
});