define("social-web/pods/application/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        var child0 = function () {
          var child0 = function () {
            var child0 = function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 10,
                      "column": 14
                    },
                    "end": {
                      "line": 12,
                      "column": 14
                    }
                  },
                  "moduleName": "social-web/pods/application/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "t", ["feed.post.actions.create"], [], ["loc", [null, [11, 16], [11, 48]]], 0, 0]],
                locals: [],
                templates: []
              };
            }();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 8,
                    "column": 10
                  },
                  "end": {
                    "line": 14,
                    "column": 10
                  }
                },
                "moduleName": "social-web/pods/application/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "id", "post-button-section");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("            ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                return morphs;
              },
              statements: [["block", "ui-button", [["subexpr", "toggle-action", ["postModal", ["get", "this", ["loc", [null, [10, 54], [10, 58]]], 0, 0, 0, 0]], [], ["loc", [null, [10, 27], [10, 59]]], 0, 0]], ["class", "ui circular splife-red waves-effect"], 0, null, ["loc", [null, [10, 14], [12, 28]]]]],
              locals: [],
              templates: [child0]
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 7,
                  "column": 8
                },
                "end": {
                  "line": 15,
                  "column": 8
                }
              },
              "moduleName": "social-web/pods/application/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "currentGroup.isCurrentUserMember", ["loc", [null, [8, 16], [8, 48]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [8, 10], [14, 17]]]]],
            locals: [],
            templates: [child0]
          };
        }();
        var child1 = function () {
          var child0 = function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 17,
                    "column": 12
                  },
                  "end": {
                    "line": 19,
                    "column": 12
                  }
                },
                "moduleName": "social-web/pods/application/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "t", ["feed.post.actions.create"], [], ["loc", [null, [18, 14], [18, 46]]], 0, 0]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 15,
                  "column": 8
                },
                "end": {
                  "line": 21,
                  "column": 8
                }
              },
              "moduleName": "social-web/pods/application/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "id", "post-button-section");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
              return morphs;
            },
            statements: [["block", "ui-button", [["subexpr", "toggle-action", ["postModal", ["get", "this", ["loc", [null, [17, 52], [17, 56]]], 0, 0, 0, 0]], [], ["loc", [null, [17, 25], [17, 57]]], 0, 0]], ["class", "ui circular splife-red waves-effect"], 0, null, ["loc", [null, [17, 12], [19, 26]]]]],
            locals: [],
            templates: [child0]
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 6,
                "column": 6
              },
              "end": {
                "line": 22,
                "column": 6
              }
            },
            "moduleName": "social-web/pods/application/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "currentGroup.id", ["loc", [null, [7, 14], [7, 29]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [7, 8], [21, 15]]]]],
          locals: [],
          templates: [child0, child1]
        };
      }();
      var child1 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 27,
                  "column": 8
                },
                "end": {
                  "line": 29,
                  "column": 8
                }
              },
              "moduleName": "social-web/pods/application/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("i");
              dom.setAttribute(el1, "class", "material-icons");
              var el2 = dom.createTextNode("edit");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 26,
                "column": 6
              },
              "end": {
                "line": 30,
                "column": 6
              }
            },
            "moduleName": "social-web/pods/application/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "ui-button", [["subexpr", "toggle-action", ["postModal", ["get", "this", ["loc", [null, [27, 48], [27, 52]]], 0, 0, 0, 0]], [], ["loc", [null, [27, 21], [27, 53]]], 0, 0]], ["id", "mobile-post-button", "class", "ui circular splife-red waves-effect"], 0, null, ["loc", [null, [27, 8], [29, 22]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 0
            },
            "end": {
              "line": 39,
              "column": 0
            }
          },
          "moduleName": "social-web/pods/application/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "ui grid centered");
          dom.setAttribute(el1, "id", "inner-container");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "hide-on-med-and-down three wide computer column");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "sixteen wide mobile seven wide computer column");
          dom.setAttribute(el2, "id", "inner-content");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "hide-on-med-and-down three wide computer column");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [3]);
          var element2 = dom.childAt(element0, [5]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 1, 1);
          morphs[1] = dom.createMorphAt(element1, 1, 1);
          morphs[2] = dom.createMorphAt(element1, 3, 3);
          morphs[3] = dom.createMorphAt(element2, 1, 1);
          morphs[4] = dom.createMorphAt(element2, 3, 3);
          return morphs;
        },
        statements: [["block", "if", [["get", "session.isAuthenticated", ["loc", [null, [6, 12], [6, 35]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [6, 6], [22, 13]]]], ["block", "if", [["get", "session.isAuthenticated", ["loc", [null, [26, 12], [26, 35]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [26, 6], [30, 13]]]], ["content", "outlet", ["loc", [null, [31, 6], [31, 16]]], 0, 0, 0, 0], ["inline", "featured-event-list", [], ["editions", ["subexpr", "@mut", [["get", "featuredEditions", ["loc", [null, [35, 37], [35, 53]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [35, 6], [35, 55]]], 0, 0], ["inline", "featured-user-list", [], ["users", ["subexpr", "@mut", [["get", "featuredUsers", ["loc", [null, [36, 33], [36, 46]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [36, 6], [36, 48]]], 0, 0]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 39,
              "column": 0
            },
            "end": {
              "line": 41,
              "column": 0
            }
          },
          "moduleName": "social-web/pods/application/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["content", "outlet", ["loc", [null, [40, 2], [40, 12]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    }();
    var child2 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 46,
              "column": 0
            },
            "end": {
              "line": 59,
              "column": 0
            }
          },
          "moduleName": "social-web/pods/application/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "status-update-card-new", [], ["whoCalls", "application", "currentGroup", ["subexpr", "@mut", [["get", "currentGroup", ["loc", [null, [49, 17], [49, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "currentEdition", ["subexpr", "@mut", [["get", "currentEdition", ["loc", [null, [50, 19], [50, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "onSave", ["subexpr", "toggle-action", ["postModal", ["get", "this", ["loc", [null, [51, 38], [51, 42]]], 0, 0, 0, 0]], [], ["loc", [null, [51, 11], [51, 43]]], 0, 0], "postPlaceholder", ["subexpr", "t", ["feed.post.placeholders.content"], [], ["loc", [null, [52, 20], [52, 56]]], 0, 0], "defaults", ["subexpr", "hash", [], ["publisher", ["get", "session.currentUser", ["loc", [null, [54, 16], [54, 35]]], 0, 0, 0, 0]], ["loc", [null, [53, 13], [55, 5]]], 0, 0], "onPostStart", ["subexpr", "route-action", ["forceLogin"], [], ["loc", [null, [56, 16], [56, 43]]], 0, 0], "onSave", ["subexpr", "queue", [["subexpr", "toggle-action", ["postModal", ["get", "this", ["loc", [null, [57, 45], [57, 49]]], 0, 0, 0, 0]], [], ["loc", [null, [57, 18], [57, 50]]], 0, 0], ["subexpr", "route-action", ["resetFeed"], [], ["loc", [null, [57, 51], [57, 77]]], 0, 0]], [], ["loc", [null, [57, 11], [57, 78]]], 0, 0]], ["loc", [null, [47, 2], [58, 4]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 62,
            "column": 0
          }
        },
        "moduleName": "social-web/pods/application/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        morphs[3] = dom.createMorphAt(fragment, 6, 6, contextualElement);
        morphs[4] = dom.createMorphAt(fragment, 8, 8, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["content", "top-nav", ["loc", [null, [1, 0], [1, 11]]], 0, 0, 0, 0], ["block", "if", [["get", "innerAppLayout", ["loc", [null, [3, 6], [3, 20]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [3, 0], [41, 7]]]], ["content", "signin-modal", ["loc", [null, [43, 0], [43, 16]]], 0, 0, 0, 0], ["block", "action-modal", [], ["title", ["subexpr", "t", ["common.post"], [], ["loc", [null, [46, 22], [46, 39]]], 0, 0], "visible", ["subexpr", "mut", [["get", "postModal", ["loc", [null, [46, 53], [46, 62]]], 0, 0, 0, 0]], [], ["loc", [null, [46, 48], [46, 63]]], 0, 0]], 2, null, ["loc", [null, [46, 0], [59, 17]]]], ["content", "sidebar-nav", ["loc", [null, [61, 0], [61, 15]]], 0, 0, 0, 0]],
      locals: [],
      templates: [child0, child1, child2]
    };
  }());
});