define('social-web/pods/components/model-selector/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['collection'],

    titleClass: '',
    options: [],
    selected: [],
    initSelected: [],
    selectedMap: Ember.Object.create(),
    init: function init() {
      this._super.apply(this, arguments);
      var selectedMap = this.get('selectedMap'),
          selected = this.get('selected');
      selected.forEach(function (option) {
        selectedMap.set(option.id + '', true);
      });
      this.set('initSelected', selected.toArray());
    },

    actions: {
      toggleSelection: function toggleSelection(model) {
        var selected = this.get('selected'),
            selectedMap = this.get('selectedMap');
        if (selected.includes(model)) {
          selected.removeObject(model);
          Ember.set(selectedMap, model.id + '', false);
        } else {
          selected.pushObject(model);
          Ember.set(selectedMap, model.id + '', true);
        }
      }
    }
  });
});