define('social-web/pods/account/notification-settings/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      toggleNotificationSetting: function toggleNotificationSetting(changeset) {
        changeset.save();
      }
    }
  });
});