define('social-web/pods/components/basic-modal/component', ['exports', 'semantic-ui-ember/components/ui-modal'], function (exports, _uiModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _uiModal.default.extend({
    attributeBindings: ['name'],
    actions: {
      hide: function hide() {
        this.execute('hide');
      }
    }
  });
});