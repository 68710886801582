define('social-web/pods/404/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    setupController: function setupController() {
      this.controllerFor('application').set('innerAppLayout', false);
    }
  });
});