define('social-web/tests/mirage/mirage/models/accommodation-category', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Model.extend({
    edition: (0, _emberCliMirage.belongsTo)(),
    accommodations: (0, _emberCliMirage.hasMany)(),
    items: Ember.computed.alias('accommodations')
  });
});