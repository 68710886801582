define('social-web/pods/participation/adapter', ['exports', 'social-web/pods/application/adapter'], function (exports, _adapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _adapter.default.extend({
    urlForCreateRecord: function urlForCreateRecord(modelName, snapshot) {
      var host = this.get('host') || '/',
          namespace = this.get('namespace'),
          competitionId = snapshot.record.get('competition.id');
      return '' + host + namespace + '/competitions/' + competitionId + '/' + modelName.pluralize();
    }
  });
});