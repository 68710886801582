define('social-web/helpers/bg-image-style', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.bgImageStyle = bgImageStyle;
  function bgImageStyle() /*, hash*/{
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

    var style = '';
    if (params[0]) {
      style = 'background-image: url("' + params[0] + '");';
    }
    return Ember.String.htmlSafe(style.trim());
  }

  exports.default = Ember.Helper.helper(bgImageStyle);
});