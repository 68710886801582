define('social-web/mirage/factories/competition', ['exports', 'ember-cli-mirage', 'social-web/mirage/factories/application'], function (exports, _emberCliMirage, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    name: function name() {
      return _emberCliMirage.faker.company.companyName();
    },
    start: function start() {
      return _emberCliMirage.faker.date.future(1);
    },
    end: function end() {
      return _emberCliMirage.faker.date.future(2);
    },
    startTimezone: function startTimezone() {},
    endTimezone: function endTimezone() {},
    distanceInMeters: function distanceInMeters(n) {
      return _emberCliMirage.faker.list.random(5000, 10000, 15000, 25000, 50000)(n);
    },
    minTeamsize: function minTeamsize() {
      return 1;
    },
    maxTeamsize: function maxTeamsize() {
      return 1;
    },
    distanceType: function distanceType() {
      return 'km';
    },
    distanceMeasureUnitShorthand: function distanceMeasureUnitShorthand() {
      return 'km';
    },

    registrationType: 'public',
    participationType: 'solo',
    distance: null,
    resultsCount: null,

    afterCreate: function afterCreate(competition, server) {
      if (!competition.edition) {
        competition.edition = server.create('edition');
      }

      if (competition.distanceType === 'mi') {
        competition.distance = Number((competition.distanceInMeters / 1609.34).toFixed(2));
      } else if (competition.distanceType === 'km') {
        competition.distance = Number((competition.distanceInMeters / 1000).toFixed(2));
      }
      competition.constructor.modelName = competition.modelName;
      competition.save();
    }
  });
});