define('social-web/pods/components/report-modal/component', ['exports', 'social-web/pods/components/prompt-modal/component', 'social-web/pods/components/prompt-modal/template'], function (exports, _component, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    layout: _template.default,
    i18n: Ember.inject.service(),

    modalClassNames: ['prompt-modal', 'report-modal'],
    title: Ember.computed(function () {
      return this.get('i18n').t('report.reportPrompt.title');
    }),

    contextActions: Ember.computed(function () {
      var i18n = this.get('i18n');
      return [{
        title: i18n.t('report.reportPrompt.confirm'),
        action: this.actions.confirmReport,
        args: [this.get('model')],
        context: this.get('context')
      }, { title: i18n.t('report.reportPrompt.cancel'), action: function action() {
          return true;
        } }];
    }),

    actions: {
      confirmReport: function confirmReport(model) {
        return model.report().then(function () {
          return true;
        });
      }
    }
  });
});