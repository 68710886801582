define('social-web/pods/status-update/adapter', ['exports', 'social-web/pods/application/adapter', 'social-web/mixins/report-adapter'], function (exports, _adapter, _reportAdapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _adapter.default.extend(_reportAdapter.default, {
    votes: function votes(statusUpdate) {
      return this.makeVoteRequest(statusUpdate, 'GET');
    },
    vote: function vote(statusUpdate) {
      return this.makeVoteRequest(statusUpdate, 'POST');
    },
    unvote: function unvote(statusUpdate) {
      return this.makeVoteRequest(statusUpdate, 'DELETE');
    },
    makeVoteRequest: function makeVoteRequest(statusUpdate, requestType) {
      var _this = this;

      var buildURL = this.buildURL('status-update', statusUpdate.id, null, null, {
        scope: { model: statusUpdate, action: 'votes' }
      });
      return this.ajax(buildURL, requestType).then(function (response) {
        var data = _this.store.normalize('vote-data', response.data);
        return _this.store.push(data);
      });
    }
  });
});