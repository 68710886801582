define('social-web/helpers/http', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.http = http;
  function http(params /*, hash*/) {
    return params.length === 0 || params.length && (params[0].length === 0 || params[0].indexOf('https://') === 0 || params[0].indexOf('http://') === 0) ? params[0] || '' : 'https://' + params[0];
  }

  exports.default = Ember.Helper.helper(http);
});