define('social-web/instance-initializers/i18n', ['exports', 'social-web/config/environment', 'moment'], function (exports, _environment, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  exports.default = {
    name: 'i18n',
    initialize: initialize
  };
  function initialize(_ref) {
    var container = _ref.container;

    var i18n = container.lookup('service:i18n');
    var currentLocale = calculateLocale(i18n.get('locales'));

    i18n.set('locale', currentLocale);
    _moment.default.locale(currentLocale);
  }

  function calculateLocale(locales) {
    if (_environment.default.environment !== 'test') {
      var language = navigator.language || navigator.languages[0] || navigator.userLanguage;
      return locales.includes(language.toLowerCase()) ? language : 'en';
    } else {
      return 'en';
    }
  }
});