define('social-web/pods/event-suggestion/model', ['exports', 'ember-data', 'ember-data/attr', 'social-web/pods/event-suggestion/validations'], function (exports, _emberData, _attr, _validations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        message: (0, _attr.default)('string'),
        validations: _validations.default
    });
});