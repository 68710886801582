define('social-web/pods/user/show/gallery/route', ['exports', 'social-web/mixins/infinity-route'], function (exports, _infinityRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_infinityRoute.default, {
    i18n: Ember.inject.service(),

    titleToken: function titleToken() {
      return this.get('i18n').t('user.show.gallery.title');
    },
    model: function model() {
      var user = this.modelFor('user.show');
      return new Ember.RSVP.hash({
        user: user,
        media: this.infinityModel('media', {
          perPage: 12,
          modelPath: 'controller.model.media',
          scope: {
            model: user, action: 'media'
          }
        })
      });
    }
  });
});