define('social-web/tests/mirage/mirage/serializers/notification-settings', ['exports', 'social-web/mirage/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    serialize: function serialize() {
      var json = _application.default.prototype.serialize.apply(this, arguments);
      json.id = json.slug;

      return json;
    },

    include: ['notification-setting']
  });
});