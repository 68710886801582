define('social-web/pods/components/power-search/component', ['exports', 'ember-power-select/components/power-select/before-options'], function (exports, _beforeOptions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _beforeOptions.default.extend({
    tagName: 'div',
    isVisible: Ember.computed.alias('searchEnabled'),
    classNames: ['ember-power-select-search']
  });
});