define("social-web/pods/feed/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 2
            },
            "end": {
              "line": 19,
              "column": 2
            }
          },
          "moduleName": "social-web/pods/feed/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "spinner-widget", [], ["class", "spinner-wrapper"], ["loc", [null, [18, 4], [18, 46]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 30,
                "column": 10
              },
              "end": {
                "line": 34,
                "column": 10
              }
            },
            "moduleName": "social-web/pods/feed/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "column");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["inline", "event-follow-card", [], ["event", ["subexpr", "@mut", [["get", "edition.event", ["loc", [null, [32, 40], [32, 53]]], 0, 0, 0, 0]], [], [], 0, 0], "edition", ["subexpr", "@mut", [["get", "edition", ["loc", [null, [32, 62], [32, 69]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [32, 14], [32, 71]]], 0, 0]],
          locals: ["edition"],
          templates: []
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 44,
                "column": 10
              },
              "end": {
                "line": 48,
                "column": 10
              }
            },
            "moduleName": "social-web/pods/feed/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "column");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["inline", "user-follow-card", [], ["user", ["subexpr", "@mut", [["get", "user", ["loc", [null, [46, 38], [46, 42]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [46, 14], [46, 44]]], 0, 0]],
          locals: ["user"],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 22,
              "column": 0
            },
            "end": {
              "line": 53,
              "column": 0
            }
          },
          "moduleName": "social-web/pods/feed/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "col s12");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("section");
          dom.setAttribute(el2, "class", "ui centered padded grid featured");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "ui centered two column padded grid");
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "col s12");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("section");
          dom.setAttribute(el2, "class", "ui centered padded grid featured");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "ui centered two column padded grid");
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [3, 1, 1]);
          var element1 = dom.childAt(fragment, [5, 1, 1]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[1] = dom.createAttrMorph(element0, 'class');
          morphs[2] = dom.createMorphAt(dom.childAt(element0, [1]), 1, 1);
          morphs[3] = dom.createAttrMorph(element1, 'class');
          morphs[4] = dom.createMorphAt(dom.childAt(element1, [1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "header-and-subheader", [], ["header", ["subexpr", "t", ["welcome.title"], [], ["loc", [null, [24, 32], [24, 51]]], 0, 0], "subheader", ["subexpr", "t", ["welcome.description"], [], ["loc", [null, [24, 62], [24, 87]]], 0, 0]], ["loc", [null, [24, 2], [24, 89]]], 0, 0], ["attribute", "class", ["get", "media.columns.extraWide", ["loc", [null, [28, 19], [28, 42]]], 0, 0, 0, 0], 0, 0, 0, 0], ["block", "each", [["subexpr", "take", [2, ["get", "model.featuredEditions", ["loc", [null, [30, 26], [30, 48]]], 0, 0, 0, 0]], [], ["loc", [null, [30, 18], [30, 49]]], 0, 0]], [], 0, null, ["loc", [null, [30, 10], [34, 19]]]], ["attribute", "class", ["get", "media.columns.extraWide", ["loc", [null, [42, 19], [42, 42]]], 0, 0, 0, 0], 0, 0, 0, 0], ["block", "each", [["subexpr", "take", [2, ["get", "model.featuredUsers", ["loc", [null, [44, 26], [44, 45]]], 0, 0, 0, 0]], [], ["loc", [null, [44, 18], [44, 46]]], 0, 0]], [], 1, null, ["loc", [null, [44, 10], [48, 19]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 54,
            "column": 0
          }
        },
        "moduleName": "social-web/pods/feed/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "col s12");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(element2, 1, 1);
        morphs[1] = dom.createMorphAt(element2, 3, 3);
        morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "feed-widget", [], ["title", ["subexpr", "t", ["feed.title"], [], ["loc", [null, [2, 22], [2, 38]]], 0, 0], "postPlaceholder", ["subexpr", "t", ["feed.post.placeholders.content"], [], ["loc", [null, [3, 20], [3, 56]]], 0, 0], "feed", ["subexpr", "@mut", [["get", "model.feed", ["loc", [null, [4, 9], [4, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "user", ["subexpr", "@mut", [["get", "model.user", ["loc", [null, [5, 9], [5, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "currentEdition", ["subexpr", "@mut", [["get", "model.currentEdition", ["loc", [null, [6, 19], [6, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "currentGroup", ["subexpr", "@mut", [["get", "model.currentGroup", ["loc", [null, [7, 17], [7, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "loading", ["subexpr", "@mut", [["get", "loading", ["loc", [null, [8, 12], [8, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "onPost", ["subexpr", "route-action", ["resetFeed"], [], ["loc", [null, [9, 11], [9, 37]]], 0, 0], "onFilter", ["subexpr", "route-action", ["resetFeed"], [], ["loc", [null, [10, 13], [10, 39]]], 0, 0], "beforeVote", ["subexpr", "route-action", ["forceLogin"], [], ["loc", [null, [11, 15], [11, 42]]], 0, 0], "onCommentStart", ["subexpr", "route-action", ["forceLogin"], [], ["loc", [null, [12, 19], [12, 46]]], 0, 0], "onPostStart", ["subexpr", "route-action", ["forceLogin"], [], ["loc", [null, [13, 16], [13, 43]]], 0, 0], "beforeReport", ["subexpr", "route-action", ["forceLogin"], [], ["loc", [null, [14, 17], [14, 44]]], 0, 0]], ["loc", [null, [2, 2], [15, 4]]], 0, 0], ["block", "infinity-loader", [], ["infinityModel", ["subexpr", "@mut", [["get", "model.feed", ["loc", [null, [17, 35], [17, 45]]], 0, 0, 0, 0]], [], [], 0, 0], "triggerOffset", 500], 0, null, ["loc", [null, [17, 2], [19, 22]]]], ["block", "unless", [["get", "model.feed.length", ["loc", [null, [22, 10], [22, 27]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [22, 0], [53, 11]]]]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});