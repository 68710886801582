define('social-web/tests/mirage/mirage/factories/cause-category', ['exports', 'ember-cli-mirage', 'social-web/mirage/factories/application'], function (exports, _emberCliMirage, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    name: function name() {
      return _emberCliMirage.faker.company.companyName();
    },
    sortPriority: function sortPriority(n) {
      return n;
    },
    afterCreate: function afterCreate(causeCategory, server) {
      if (!causeCategory.edition) {
        causeCategory.edition = server.create('edition');
      }
      if (!causeCategory.causes.models.length) {
        server.createList('cause', causeCategory.causesCount === undefined ? _emberCliMirage.faker.random.number({ min: 1, max: 5 }) : causeCategory.causesCount, { causeCategory: causeCategory });
      }
      causeCategory.save();
    }
  });
});