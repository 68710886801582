define('social-web/pods/edition/show/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord('edition', params.slug, { include: 'event' });
    },
    redirect: function redirect(model) {
      this.replaceWith('event.show.edition.show', model.get('event.slug'), model.get('slug'));
    }
  });
});