define('social-web/pods/group/show/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service(),

    beforeModel: function beforeModel(transition) {
      if (Ember.get(transition, 'queryParams.invite_id') && !this.get('session.isAuthenticated')) {
        sessionStorage.setItem('redirectPath', this.get('router.url'));
        this.transitionTo('onboarding.signin');
      }
    },
    model: function model(params) {
      var _this = this;

      var group = this.store.findRecord('group', params.group_slug, {});

      group.then(function (group) {
        _this.controllerFor('application').set('currentGroup', group);
        _this.controllerFor('application').set('currentEdition', {});
      });

      return group;
    },
    redirect: function redirect(model, transition) {
      var _this2 = this;

      var inviteId = Ember.get(transition, 'queryParams.invite_id');
      if (inviteId) {
        this.store.findRecord('invite', inviteId).then(function (invite) {
          return _this2.store.adapterFor('invite').inviteProcess(invite);
        }).then(function () {
          return _this2.store.adapterFor('group').isFollowing(model);
        }).then(function (response) {
          model.set('_currentUserMember', response.get('currentUserMember'));
          model.set('membersCount', response.get('membersCount'));
        });
        sessionStorage.clear();
      }

      if (transition.targetName.replace('.index', '') === this.routeName) {
        this.replaceWith('group.show.detail', model.get('group'));
      }
    }
  });
});