define('social-web/pods/components/lazy-background-image/component', ['exports', 'ember-lazy-image/components/lazy-background-image', 'social-web/mixins/lazy-image'], function (exports, _lazyBackgroundImage, _lazyImage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _lazyBackgroundImage.default.extend(_lazyImage.default, {
    attributeBindings: ['href', 'title'],
    classNames: ['lazy-image-wrapper'],
    init: function init() {
      this.classNames.removeObject('lazy-image-container');
      this.classNames.removeObject('lazy-background-image');
      this.attributeBindings.removeObject('style');
      this._super.apply(this, arguments);
    },

    style: Ember.computed('lazyUrl', 'viewportEntered', function () {
      var lazyUrl = this.get('lazyUrl') || this.get('viewportEntered') && this.get('url');
      return Ember.String.htmlSafe(lazyUrl ? 'background-image: url(' + lazyUrl + ');' : '');
    }),
    fallbackStyle: Ember.computed('fallbackUrl', function () {
      var fallbackUrl = this.get('fallbackUrl');
      return Ember.String.htmlSafe(fallbackUrl ? 'background-image: url(' + fallbackUrl + ');' : '');
    })
  });
});