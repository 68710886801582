define('social-web/pods/user/show/route', ['exports', 'social-web/mixins/reset-scroll'], function (exports, _resetScroll) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_resetScroll.default, {
    ht: Ember.inject.service('head-tags'),

    titleToken: function titleToken(model) {
      return model.get('fullName');
    },
    headTags: function headTags() {
      var model = this.modelFor(this.routeName);
      return this.get('ht').getBasicHeadTags({
        title: model.get('fullName'),
        description: model.get('ogDescription'),
        image: model.get('customPictureThumb'),
        type: 'profile',
        url: model.get('ogUrl')
      });
    },
    model: function model(params) {
      return this.store.findRecord('user', params.id, {
        include: 'location'
      }).then(function (user) {
        var content = user.get('location.content');
        if (content) {
          content.reload();
        }
        return user;
      });
    },

    actions: {
      setLoading: function setLoading(value) {
        this.set('controller.loading', value);
      },
      startSetCoverPhoto: function startSetCoverPhoto() {
        if (!this.get('controller.coverPhotoLoading')) {
          Ember.run.debounce(this, function () {
            Ember.$('input.cover-photo[type=file]').click();
          }, 250);
        }
      },
      startSetProfilePicture: function startSetProfilePicture() {
        if (!this.get('controller.profilePictureLoading')) {
          Ember.run.debounce(this, function () {
            Ember.$('input.profile-picture[type=file]').click();
          }, 250);
        }
      },
      removeCoverPhoto: function removeCoverPhoto() {
        var _this = this;

        var userCoverPhoto = this.store.createRecord('user-photos', {
          id: this.get('controller.model.id'),
          deleteCoverPhoto: true
        });
        userCoverPhoto.send('willCommit');
        userCoverPhoto.send('didCommit');
        this.set('controller.coverPhotoLoading', true);
        userCoverPhoto.save().then(function () {
          return _this.get('controller.model').reload();
        }).finally(function () {
          userCoverPhoto.unloadRecord();
          _this.set('controller.coverPhotoLoading');
        });
      },
      setCoverPhoto: function setCoverPhoto(e) {
        var _this2 = this;

        var userCoverPhoto = this.store.createRecord('user-photos', {
          id: this.get('controller.model.id'),
          coverPhoto: e.target.files[0]
        });
        userCoverPhoto.send('willCommit');
        userCoverPhoto.send('didCommit');
        this.set('controller.coverPhotoLoading', true);
        return userCoverPhoto.save().then(function () {
          return _this2.get('controller.model').reload();
        }).finally(function () {
          userCoverPhoto.unloadRecord();
          _this2.set('controller.coverPhotoLoading');
          Ember.$(e.target).val('');
        });
      },
      previewProfilePicture: function previewProfilePicture(e) {
        var _this3 = this;

        var file = e.target.files[0];
        if (file) {
          this.set('controller.profilePicture', file);
          var reader = new FileReader();
          reader.onload = function (e) {
            Ember.run(function () {
              _this3.set('controller.preview', e.target.result);
              Ember.$(e.target).val('');
            });
          };
          reader.readAsDataURL(file);
        }
      },
      setProfilePicture: function setProfilePicture() {
        var _this4 = this;

        var profilePicture = this.get('controller.profilePicture'),
            userProfilePicture = this.store.createRecord('user-photos', {
          id: this.get('controller.model.id'),
          customPicture: profilePicture
        });
        userProfilePicture.send('willCommit');
        userProfilePicture.send('didCommit');
        this.set('controller.profilePictureLoading', true);
        return userProfilePicture.save().then(function () {
          return _this4.get('controller.model').reload();
        }).finally(function () {
          userProfilePicture.unloadRecord();
          _this4.set('controller.profilePictureLoading');
        });
      },
      save: function save(userChangeset) {
        userChangeset.save();
      }
    }
  });
});