define('social-web/pods/series/model', ['exports', 'ember-data/attr', 'ember-data/relationships', 'social-web/pods/application/model'], function (exports, _attr, _relationships, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    name: (0, _attr.default)('string'),

    logoThumb: (0, _attr.default)('string'),
    logoMed: (0, _attr.default)('string'),
    coverPhotoTile: (0, _attr.default)('string'),
    coverPhotoDefault: (0, _attr.default)('string'),

    seriesEditionsCount: (0, _attr.default)('number'),

    seriesEditions: (0, _relationships.hasMany)('series-edition')
  });
});