define("social-web/router", ["exports", "social-web/config/environment"], function (exports, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    headData: Ember.inject.service(),
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,

    setTitle: function setTitle(title) {
      this.get("headData").set("title", title);
    }
  });

  Router.map(function () {
    this.route("privacy");
    this.route("onboarding", function () {
      this.route("signin");
      this.route("signup");
      this.route("recovery");
      this.route("forgot");
    });
    this.route("404", { path: "*path" });
    this.route("500");

    this.route("feed");
    this.route("event", { path: "/events" }, function () {
      this.route("show", { path: "/:event_slug" }, function () {
        this.route("edition", { path: "/editions" }, function () {
          this.route("show", { path: "/:slug" }, function () {
            this.route("about", function () {
              this.route("competitions");
              this.route("resources");
            });
            this.route("gallery");
            this.route("results");
            this.route("athletes");
            this.route("fans");
            this.route("loading");
          });
        });
      });
    });

    this.route("user", { path: "/users" }, function () {
      this.route("show", { path: "/:id" }, function () {
        this.route("friends");
        this.route("gallery");
        this.route("editions", { path: "/events" });
        this.route("loading");
      });
    });

    this.route("group", { path: "/groups" }, function () {
      this.route("show", { path: "/:group_slug" }, function () {
        this.route("detail", { path: "" }, function () {
          this.route("about", function () {
            this.route("resources");
          });
          this.route("gallery");
          this.route("members");
          this.route("loading");
        });
      });
    });

    this.route("status-update", { path: "/posts/:id" });
    this.route("account", function () {
      this.route("general", function () {
        this.route("name");
        this.route("birthday");
        this.route("email");
        this.route("phone");
        this.route("gender");
        this.route("password");
        this.route("shirt");
      });
      this.route("registration");
      this.route("notification-settings");
    });
    this.route("search", function () {
      this.route("user", { path: "users" });
      this.route("edition", { path: "events" });
      this.route("group", { path: "groups" });
      this.route("loading");
    });
    this.route("edition", { path: "/editions" }, function () {
      this.route("show", { path: "/:slug" });
    });
    this.route("scrapbook", { path: "/scrapbooks" }, function () {
      this.route("show", { path: "/:id" }, function () {
        this.route("timeline");
        this.route("results", function () {
          this.route("info");
          this.route("splits");
          this.route("rating");
          this.route("journal");
        });
        this.route("gallery");
      });
    });
    this.route("install-app");
  });

  exports.default = Router;
});