define('social-web/tests/mirage/mirage/models/user', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Model.extend({
    media: (0, _emberCliMirage.hasMany)(),
    editions: (0, _emberCliMirage.hasMany)(),
    userStats: (0, _emberCliMirage.belongsTo)(),
    location: (0, _emberCliMirage.belongsTo)(),
    notifications: (0, _emberCliMirage.hasMany)('notification', { inverse: 'user' }),
    emails: (0, _emberCliMirage.hasMany)(),
    participations: (0, _emberCliMirage.hasMany)(),
    defaultEmail: Ember.computed('emails.@each.defaultEmail', function () {
      return this.get('emails').filterBy('defaultEmail', true).get('firstObject');
    }),
    email: Ember.computed('defaultEmail', function () {
      return this.get('defaultEmail.email');
    })
  });
});