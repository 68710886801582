define("social-web/pods/components/competition-card/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 4
            },
            "end": {
              "line": 7,
              "column": 4
            }
          },
          "moduleName": "social-web/pods/components/competition-card/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("time");
          dom.setAttribute(el1, "class", "time-label live");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("strong");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element1, 'datetime');
          morphs[1] = dom.createMorphAt(dom.childAt(element1, [1]), 0, 0);
          return morphs;
        },
        statements: [["attribute", "datetime", ["get", "competition.start", ["loc", [null, [4, 23], [4, 40]]], 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "t", ["events.live"], [], ["loc", [null, [5, 16], [5, 35]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 4
            },
            "end": {
              "line": 11,
              "column": 4
            }
          },
          "moduleName": "social-web/pods/components/competition-card/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("time");
          dom.setAttribute(el1, "class", "time-label");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("strong");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element0, 'datetime');
          morphs[1] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
          return morphs;
        },
        statements: [["attribute", "datetime", ["get", "competition.start", ["loc", [null, [8, 23], [8, 40]]], 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "date-label", [["get", "competition.start", ["loc", [null, [9, 29], [9, 46]]], 0, 0, 0, 0]], [], ["loc", [null, [9, 16], [9, 48]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    var child2 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 6
            },
            "end": {
              "line": 19,
              "column": 6
            }
          },
          "moduleName": "social-web/pods/components/competition-card/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "truncate-multiline", [], ["text", ["subexpr", "@mut", [["get", "competition.edition.composedName", ["loc", [null, [18, 34], [18, 66]]], 0, 0, 0, 0]], [], [], 0, 0], "lines", 3, "showButton", false], ["loc", [null, [18, 8], [18, 93]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    var child3 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 32,
                "column": 6
              },
              "end": {
                "line": 38,
                "column": 6
              }
            },
            "moduleName": "social-web/pods/components/competition-card/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "user-bubble-list", [], ["title", ["subexpr", "t", ["events.friends.participating"], ["count", ["get", "followees.content.meta.count", ["loc", [null, [33, 73], [33, 101]]], 0, 0, 0, 0]], ["loc", [null, [33, 33], [33, 102]]], 0, 0], "users", ["subexpr", "@mut", [["get", "followees.content", ["loc", [null, [34, 33], [34, 50]]], 0, 0, 0, 0]], [], [], 0, 0], "total", ["subexpr", "@mut", [["get", "followees.content.meta.count", ["loc", [null, [35, 33], [35, 61]]], 0, 0, 0, 0]], [], [], 0, 0], "maxBubbles", ["subexpr", "@mut", [["get", "maxBubbles", ["loc", [null, [36, 38], [36, 48]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "card-content user-bubble-list"], ["loc", [null, [33, 8], [37, 66]]], 0, 0]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 31,
              "column": 4
            },
            "end": {
              "line": 39,
              "column": 4
            }
          },
          "moduleName": "social-web/pods/components/competition-card/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "loader-widget", [["get", "followees.isSettled", ["loc", [null, [32, 23], [32, 42]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [32, 6], [38, 24]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 42,
            "column": 0
          }
        },
        "moduleName": "social-web/pods/components/competition-card/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "card hoverable");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "card-image");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "card-title full-width");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "card-content");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3, "class", "btn-floating btn-large square bg-image no-padding right");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        dom.setAttribute(el3, "class", "black-text");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0]);
        var element3 = dom.childAt(element2, [1]);
        var element4 = dom.childAt(element2, [3]);
        var element5 = dom.childAt(element4, [1]);
        var morphs = new Array(7);
        morphs[0] = dom.createMorphAt(element3, 1, 1);
        morphs[1] = dom.createMorphAt(element3, 3, 3);
        morphs[2] = dom.createMorphAt(dom.childAt(element3, [5]), 1, 1);
        morphs[3] = dom.createAttrMorph(element5, 'title');
        morphs[4] = dom.createMorphAt(element5, 1, 1);
        morphs[5] = dom.createMorphAt(dom.childAt(element4, [3]), 0, 0);
        morphs[6] = dom.createMorphAt(element4, 5, 5);
        return morphs;
      },
      statements: [["block", "if", [["subexpr", "is-between", [["subexpr", "now", [], [], ["loc", [null, [3, 22], [3, 27]]], 0, 0], ["get", "competition.start", ["loc", [null, [3, 28], [3, 45]]], 0, 0, 0, 0], ["get", "competition.end", ["loc", [null, [3, 46], [3, 61]]], 0, 0, 0, 0]], ["interval", 3600000], ["loc", [null, [3, 10], [3, 79]]], 0, 0]], [], 0, 1, ["loc", [null, [3, 4], [11, 11]]]], ["inline", "lazy-image", [], ["url", ["subexpr", "@mut", [["get", "competition.edition.event.coverPhotoTile", ["loc", [null, [13, 21], [13, 61]]], 0, 0, 0, 0]], [], [], 0, 0], "fallbackUrl", ["subexpr", "@mut", [["get", "media.defaults.eventCover", ["loc", [null, [14, 29], [14, 54]]], 0, 0, 0, 0]], [], [], 0, 0], "alt", ["subexpr", "@mut", [["get", "competition.edition.composedName", ["loc", [null, [15, 21], [15, 53]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [13, 4], [15, 55]]], 0, 0], ["block", "if", [["get", "competition.edition.composedName", ["loc", [null, [17, 12], [17, 44]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [17, 6], [19, 13]]]], ["attribute", "title", ["get", "competition.edition.composedName", ["loc", [null, [24, 15], [24, 47]]], 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "lazy-background-image", [], ["url", ["subexpr", "@mut", [["get", "competition.edition.event.logoThumb", ["loc", [null, [25, 34], [25, 69]]], 0, 0, 0, 0]], [], [], 0, 0], "fallbackUrl", ["subexpr", "@mut", [["get", "media.defaults.eventLogo", ["loc", [null, [26, 42], [26, 66]]], 0, 0, 0, 0]], [], [], 0, 0], "title", ["subexpr", "@mut", [["get", "competition.edition.composedName", ["loc", [null, [27, 36], [27, 68]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "full-height"], ["loc", [null, [25, 6], [28, 51]]], 0, 0], ["content", "competition.edition.location.display", ["loc", [null, [30, 26], [30, 66]]], 0, 0, 0, 0], ["block", "if", [["get", "session.currentUser", ["loc", [null, [31, 10], [31, 29]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [31, 4], [39, 11]]]]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  }());
});