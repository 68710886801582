define('social-web/pods/components/loader-widget/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var component = Ember.Component.extend({
    hasLoaded: false,
    loaded: Ember.computed('params.@each', function () {
      var params = this.get('params');
      var loaded = params.length && params.every(function (param) {
        return param;
      });

      if (loaded && !this.hasLoaded) {
        this.set('hasLoaded', true);
        if (this.attrs.onInitLoad) {
          this.onInitLoad();
        }
      }
      if (loaded && this.attrs.onLoad) {
        this.onLoad();
      }
      return loaded;
    })
  });

  component.reopenClass({
    positionalParams: 'params'
  });

  exports.default = component;
});