define('social-web/pods/group/show/detail/gallery/route', ['exports', 'social-web/mixins/infinity-route'], function (exports, _infinityRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_infinityRoute.default, {
    i18n: Ember.inject.service(),

    titleToken: function titleToken() {
      return this.get('i18n').t('user.show.gallery.title');
    },
    model: function model() {

      Ember.Logger.log('group.show.detail.galley route.js .model');

      var group = this.modelFor('group.show');

      var pictures = this.infinityModel('media', {
        perPage: 12,
        modelPath: 'controller.model.pictures',
        scope: {
          model: group, action: 'media'
        }
      });

      return Ember.RSVP.hash({
        pictures: pictures
      });
    }
  });
});