define('social-web/pods/competition/model', ['exports', 'ember-data/attr', 'ember-data/relationships', 'social-web/pods/application/model'], function (exports, _attr, _relationships, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    session: Ember.inject.service(),
    distanceService: Ember.inject.service('distance'),

    display: Ember.computed.alias('name'),

    name: (0, _attr.default)('string'),
    start: (0, _attr.default)('utc'),
    end: (0, _attr.default)('utc'),
    startTimezone: (0, _attr.default)('string'),
    endTimezone: (0, _attr.default)('string'),

    minTeamsize: (0, _attr.default)('number'),
    maxTeamsize: (0, _attr.default)('number'),

    distanceInMeters: (0, _attr.default)('number'),
    distanceType: (0, _attr.default)('string'),
    distanceMeasureUnitShorthand: (0, _attr.default)('string'),
    participationType: (0, _attr.default)('string'),
    registrationType: (0, _attr.default)('string'),

    applicationUrl: (0, _attr.default)('string'),

    resultsCount: (0, _attr.default)('number'),

    distance: Ember.computed('distanceInMeters', 'distanceType', function () {
      return this.get('distanceService').distance(this.get('distanceInMeters'), this.get('distanceType'));
    }),

    edition: (0, _relationships.belongsTo)('edition', { async: true }),
    splits: (0, _relationships.hasMany)(),

    isExternalApplication: Ember.computed.equal('registrationType', 'external'),
    canParticipate: Ember.computed.equal('registrationType', 'public'),
    currentUserParticipation: null,
    currentUserParticipating: Ember.computed.bool('currentUserParticipation'),
    participate: function participate() {
      var _this = this;

      var participation = this.get('store').createRecord('participation', {
        competition: this
      });
      return participation.save().then(function (participation) {
        _this.set('currentUserParticipation', participation);
        if (!_this.get('edition.event.currentUserFollowing')) {
          _this.get('edition.event.content').follow();
        }
        _this.get('edition').set('_participantsCount', null);
      }, function () {
        participation.unloadRecord();
      });
    },
    stopParticipating: function stopParticipating() {
      var _this2 = this;

      return this.get('currentUserParticipation').destroyRecord().then(function () {
        _this2.set('currentUserParticipation');
        _this2.get('edition').set('_participantsCount', null);
      });
    }
  });
});