define('social-web/pods/components/email-selector/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    media: Ember.inject.service(),

    model: null,
    emails: Ember.computed('model', function () {
      return this.get('store').findAll('email', {
        reload: true,
        adapterOptions: { model: this.get('model') }
      });
    }),
    emailsUpdated: Ember.observer('emails.[]', function () {
      if (!this.get('defaultEmail')) {
        this.setInitDefault();
      }
    }),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.get('handlers').set(this.get('handlerProp'), function () {
        return _this.actions.save.call(_this, _this.get('model'));
      });
    },
    setInitDefault: function setInitDefault() {
      var defaultEmail = this.get('emails').filterBy('defaultEmail', true).get('firstObject');
      this.set('defaultEmail', defaultEmail);
      //TODO: remove materialize
      Ember.run.later(function () {
        Ember.$('input[value="' + defaultEmail.get('email') + '"]').click();
      });
    },

    actions: {
      setDefault: function setDefault(email) {
        this.set('defaultEmail', email);
      },
      toggleDelete: function toggleDelete(email) {
        if (email.get('isDeleted')) {
          email.rollbackAttributes();
        } else {
          email.deleteRecord();
          if (email === this.get('defaultEmail')) {
            this.setInitDefault();
          }
        }
      },
      showModal: function showModal() {
        if (!this.get('emailModel')) {
          this.set('emailModel', this.get('store').createRecord('email'));
        }
        this.set('addModal', true);
      },
      closeModal: function closeModal() {
        this.set('addModal');
        if (!this.get('emailModel.id')) {
          this.get('emailModel').unloadRecord();
          this.set('emailModel');
        }
      },
      saveEmail: function saveEmail(emailChangeset) {
        var _this2 = this;

        emailChangeset.set('user', this.get('model'));
        return emailChangeset.validate().then(function () {
          if (emailChangeset.get('isValid')) {
            return emailChangeset.save({
              adapterOptions: { model: _this2.get('model') }
            }).then(function () {
              _this2.send('closeModal');
              _this2.set('emailModel');
            }, function () {
              window.Materialize.toast(_this2.get('i18n').t('account.general.email.modal.error'), 5000, 'red');
            });
          }
        });
      },
      save: function save(user) {
        var defaultPromise = {};
        var originalDefault = this.get('emails').filterBy('defaultEmail').get('firstObject');
        var currentDefault = this.get('defaultEmail');
        if (originalDefault !== currentDefault) {
          defaultPromise = currentDefault.setDefault();
        }
        var promises = [];
        this.get('emails').filterBy('hasDirtyAttributes', true).forEach(function (email) {
          promises.push(email.save({
            adapterOptions: { model: user }
          }));
        });
        return new Ember.RSVP.hash({
          default: defaultPromise,
          promises: new Ember.RSVP.all(promises)
        }).then(function () {
          user.set('email', currentDefault.get('email'));
        });
      }
    }
  });
});