define('social-web/pods/member-data/model', ['exports', 'ember-data', 'ember-data/attr'], function (exports, _emberData, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    currentUserMember: (0, _attr.default)('boolean'),
    membersCount: (0, _attr.default)('number')
  });
});