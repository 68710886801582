define('social-web/pods/account/communication/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    collapsibles: {},
    handlers: Ember.Object.create({
      //expander handlers can go here
    })
  });
});