define('social-web/pods/components/media-upload/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),

    accept: 'image/*',
    loading: 0,
    actions: {
      selectMedia: function selectMedia() {
        this.$('input').click();
      },
      addMedia: function addMedia(payload) {
        var store = this.get('store');
        var media = store.push(store.normalize('media', payload.data));
        this.get('media').pushObject(media);
      },
      removeMedia: function removeMedia(media) {
        var _this = this;

        media.destroyRecord().then(function () {
          _this.get('media').removeObject(media);
        });
      },
      setLoading: function setLoading(value) {
        var _this2 = this;

        Ember.run(function () {
          var loading = _this2.get('loading');
          loading = value ? loading + 1 : loading - 1;
          _this2.set('loading', loading);
          if (_this2.attrs.onLoading) {
            _this2.attrs.onLoading(loading);
          }
        });
      },
      setProgress: function setProgress(value) {
        var _this3 = this;

        Ember.run(function () {
          _this3.set('progress', value === 100 ? null : value);
        });
      }
    }
  });
});