define('social-web/helpers/moment-duration-format', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.momentDurationFormat = momentDurationFormat;
  function momentDurationFormat(params /*, hash*/) {
    var duration = params[0] || 0,
        formatString = params[1] || 'H:mm:ss.s';
    return _moment.default.duration(duration).format(formatString);
  }

  exports.default = Ember.Helper.helper(momentDurationFormat);
});