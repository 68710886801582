define('social-web/pods/series-edition/controller', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    i18n: Ember.inject.service(),

    actions: {
      searchSeriesEditions: function searchSeriesEditions(seriesEditions) {
        return seriesEditions.map(function (seriesEdition) {
          var startDate = seriesEdition.get('startDate');
          startDate = startDate && (0, _moment.default)(startDate).format('LL');
          return {
            title: seriesEdition.get('name'),
            description: startDate,
            id: seriesEdition.get('slug')
          };
        });
      },
      routeToSeriesEdition: function routeToSeriesEdition(seriesEdition) {
        this.transitionToRoute('series-edition', seriesEdition.id);
        return true;
      }
    }
  });
});