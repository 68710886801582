define('social-web/pods/components/md-dropdown/component', ['exports', 'ember-cli-materialize/components/md-btn-dropdown'], function (exports, _mdBtnDropdown) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _mdBtnDropdown.default.extend({
    belowOrigin: true,

    init: function init() {
      this.set('classNames', []);
      this.set('classNameBindings', ['isDisabled:disabled']);
      this._super.apply(this, arguments);
    }
  });
});