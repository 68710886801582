define('social-web/mixins/followable-model', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    session: Ember.inject.service(),

    updateFollowData: function updateFollowData(followData) {
      this.set('_currentUserFollowing', followData.get('currentUserFollowing'));
      if (this.get('isLoaded')) {
        this.set('followersCount', followData.get('followersCount'));
      }
    },
    follow: function follow() {
      return this.get('adapter').follow(this).then(this.updateFollowData.bind(this));
    },
    unfollow: function unfollow() {
      return this.get('adapter').unfollow(this).then(this.updateFollowData.bind(this));
    },

    currentUserFollowing: Ember.computed('_currentUserFollowing', 'session.currentUser', function () {
      if (this.constructor.modelName === 'user' && this.get('session.currentUser.id') === this.id) {
        return null;
      }
      var value = this.get('_currentUserFollowing');
      return !Ember.isNone(value) ? value : this.get('adapter').isFollowing(this).then(this.updateFollowData.bind(this));
    })
  });
});