define('social-web/pods/split/model', ['exports', 'ember-data/attr', 'ember-data/relationships', 'social-web/pods/application/model'], function (exports, _attr, _relationships, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    distanceService: Ember.inject.service('distance'),

    bib: (0, _attr.default)('string'),
    timeInSeconds: (0, _attr.default)('number'),
    distanceInMeters: (0, _attr.default)('number'),

    participation: (0, _relationships.belongsTo)(),
    competition: (0, _relationships.belongsTo)(),
    relayTeam: (0, _relationships.belongsTo)(),

    distance: Ember.computed('distanceInMeters', 'competition.distanceType', function () {
      return this.get('distanceService').distance(this.get('distanceInMeters'), this.get('competition.distanceType'));
    }),
    pace: Ember.computed('timeInMilliseconds', 'distance', function () {
      var pace = 0,
          timeInMilliseconds = this.get('timeInMilliseconds'),
          distance = this.get('distance');
      if (timeInMilliseconds && distance) {
        pace = timeInMilliseconds / distance;
      }
      return pace || null;
    }),
    timeInMilliseconds: Ember.computed('timeInSeconds', function () {
      return this.get('timeInSeconds') * 1000;
    }),
    timeInMinutes: Ember.computed('timeInSeconds', function () {
      return this.get('timeInSeconds') / 60;
    })
  });
});