define('social-web/instance-initializers/route-styles', ['exports', 'ember-component-css/pod-names'], function (exports, _podNames) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() /* appInstance */{
    Ember.Route.reopen({
      extraClassNames: [],
      classNames: Ember.computed(function () {
        return [_podNames.default[this.get('routeName').replace(/\./g, '/')] || ''].concat(this.get('extraClassNames'));
      })
    });
  }

  exports.default = {
    name: 'route-styles',
    initialize: initialize
  };
});