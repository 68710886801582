define('social-web/pods/scrapbook/show/results/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.modelFor('scrapbook.show');
    },

    actions: {
      save: function save(controller, changeset) {
        controller.set('saving', true);
        return new Ember.RSVP.Promise(function (resolve, reject) {
          changeset.save().then(resolve, reject);
        });
      }
    }
  });
});