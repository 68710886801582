define('social-web/pods/relay-team/model', ['exports', 'ember-data/attr', 'ember-data/relationships', 'social-web/pods/application/model'], function (exports, _attr, _relationships, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    display: Ember.computed.alias('name'),

    name: (0, _attr.default)('string'),
    teamBib: (0, _attr.default)('string'),
    bib: Ember.computed('teamBib', function () {
      return this.get('teamBib');
    }),
    splits: (0, _relationships.hasMany)()
  });
});