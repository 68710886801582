define('social-web/pods/components/tile-list/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    media: Ember.inject.service(),

    largeColumns: 6,
    mediumColumns: 4,
    smallColumns: 2,
    colClass: Ember.computed('smallColumns', 'mediumColumns', 'largeColumns', function () {
      var columnCounts = this.getProperties('smallColumns', 'mediumColumns', 'largeColumns');

      var columns = {
        small: 12 / columnCounts.smallColumns,
        medium: 12 / columnCounts.mediumColumns,
        large: 12 / columnCounts.largeColumns
      };
      return 'col s' + columns.small + ' m' + columns.medium + ' l' + columns.large;
    }),
    columns: Ember.computed('smallColumns', 'mediumColumns', 'largeColumns', 'media.isSmall', 'media.isMedium', 'media.isLarge', function () {
      var columnCounts = this.getProperties('smallColumns', 'mediumColumns', 'largeColumns');
      var mediaSizes = this.get('media').getProperties('isSmall', 'isMedium', 'isLarge');
      return mediaSizes.isSmall ? columnCounts.smallColumns : mediaSizes.isMedium ? columnCounts.mediumColumns : mediaSizes.isLarge ? columnCounts.largeColumns : 12;
    })
  });
});