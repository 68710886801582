define('social-web/pods/featured/service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    featuredEditions: [],
    featuredUsers: [],

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.get('store').query('edition', {
        per_page: 3,
        include: 'event',
        featured: true
      }).then(function (editions) {
        _this.set('featuredEditions', editions);
      });

      this.get('store').query('user', {
        per_page: 3,
        featured: true
      }).then(function (users) {
        _this.set('featuredUsers', users);
      });

      this.get('store').query('group', {
        per_page: 3,
        featured: true
      }).then(function (groups) {
        _this.set('featuredGroups', groups);
      });
    }
  });
});