define('social-web/tests/mirage/mirage/factories/transmission', ['exports', 'ember-cli-mirage', 'social-web/mirage/factories/application'], function (exports, _emberCliMirage, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    status: function status() {
      return 'Playback';
    },
    description: function description() {
      return _emberCliMirage.faker.lorem.paragraph();
    },
    hlsAddress: function hlsAddress() {
      return _emberCliMirage.faker.internet.url();
    }
  });
});