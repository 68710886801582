define('social-web/mirage/factories/series', ['exports', 'ember-cli-mirage', 'social-web/mirage/factories/application', 'moment'], function (exports, _emberCliMirage, _application, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    name: function name() {
      return _emberCliMirage.faker.company.companyName();
    },


    logoThumb: _emberCliMirage.faker.image.avatar,
    coverPhotoDefault: _emberCliMirage.faker.image.imageUrl,
    seriesEditionsCount: function seriesEditionsCount() {
      return 0;
    },
    afterCreate: function afterCreate(series, server) {
      series.logoMed = series.logoThumb;
      series.coverPhotoTile = series.coverPhotoDefault;
      series.constructor.modelName = series.modelName;

      if (series.propagate) {
        series.seriesEditions = [server.create('series-edition', {
          propagate: series.propagate,
          startDate: (0, _moment.default)().subtract('36 months'),
          endDate: (0, _moment.default)().subtract('24 months'),
          series: series
        }), server.create('series-edition', {
          propagate: series.propagate,
          startDate: (0, _moment.default)().subtract('24 months'),
          endDate: (0, _moment.default)().subtract('12 months'),
          series: series
        }), server.create('series-edition', {
          propagate: series.propagate,
          startDate: (0, _moment.default)().subtract('12 months'),
          endDate: (0, _moment.default)(),
          series: series
        }), server.create('series-edition', {
          propagate: series.propagate,
          startDate: (0, _moment.default)(),
          endDate: (0, _moment.default)().add('12 months'),
          series: series
        }), server.create('series-edition', {
          propagate: series.propagate,
          startDate: (0, _moment.default)(),
          endDate: (0, _moment.default)().add('12 months'),
          series: series
        })];
      }
      series.seriesEditionsCount = series.seriesEditions.models.length;
      series.save();
    }
  });
});