define('social-web/pods/onboarding/route', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    media: Ember.inject.service(),

    afterModel: function afterModel() {
      this.get('media.nav').setProperties({
        fixed: false,
        secondary: true
      });
    },

    actions: {
      willTransition: function willTransition(transition) {
        if (!transition.targetName.includes('onboarding')) {
          this.get('media.nav').setProperties({
            fixed: true,
            secondary: false
          });
        }
      }
    }
  });
});