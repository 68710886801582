define('social-web/tests/mirage/mirage/factories/edition-activity', ['exports', 'ember-cli-mirage', 'social-web/mirage/factories/application', 'moment'], function (exports, _emberCliMirage, _application, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = _application.default.extend({
    name: function name() {
      return _emberCliMirage.faker.company.companyName();
    },
    url: function url() {
      return _emberCliMirage.faker.internet.url();
    },
    file: function file() {
      return _emberCliMirage.faker.image.sports();
    },
    description: function description() {
      return _emberCliMirage.faker.lorem.paragraph();
    },
    start: function start() {
      return _emberCliMirage.faker.date.future(1);
    },
    end: function end() {
      return _emberCliMirage.faker.date.future(2);
    },
    startTimezone: function startTimezone(n) {
      var _faker$list;

      return (_faker$list = _emberCliMirage.faker.list).random.apply(_faker$list, _toConsumableArray(_moment.default.tz.names()))(n);
    },
    endTimezone: function endTimezone(n) {
      var _faker$list2;

      return (_faker$list2 = _emberCliMirage.faker.list).random.apply(_faker$list2, _toConsumableArray(_moment.default.tz.names()))(n);
    },
    afterCreate: function afterCreate(editionActivity, server) {
      if (!editionActivity.edition) {
        editionActivity.edition = server.create('edition');
      }
      editionActivity.save();
    },


    withLocation: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(editionActivity, server) {
        editionActivity.location = server.create('location');
        editionActivity.save();
      }
    })
  });
});