define("social-web/pods/status-update/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 13,
            "column": 0
          }
        },
        "moduleName": "social-web/pods/status-update/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 1]), 1, 1);
        return morphs;
      },
      statements: [["inline", "status-update-card", [], ["statusUpdate", ["subexpr", "@mut", [["get", "model", ["loc", [null, [3, 38], [3, 43]]], 0, 0, 0, 0]], [], [], 0, 0], "currentGroup", ["subexpr", "@mut", [["get", "currentGroup", ["loc", [null, [4, 19], [4, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "currentEdition", ["subexpr", "@mut", [["get", "currentEdition", ["loc", [null, [5, 21], [5, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "onCommentStart", ["subexpr", "route-action", ["forceLogin"], [], ["loc", [null, [6, 21], [6, 48]]], 0, 0], "beforeVote", ["subexpr", "route-action", ["forceLogin"], [], ["loc", [null, [7, 17], [7, 44]]], 0, 0], "onPostStart", ["subexpr", "route-action", ["forceLogin"], [], ["loc", [null, [8, 18], [8, 45]]], 0, 0], "beforeReport", ["subexpr", "route-action", ["forceLogin"], [], ["loc", [null, [9, 19], [9, 46]]], 0, 0]], ["loc", [null, [3, 4], [10, 6]]], 0, 0]],
      locals: [],
      templates: []
    };
  }());
});