define('social-web/pods/components/md-carousel/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['carousel', 'carousel-slider'],

    fullWidth: false,
    indicators: false,
    noWrap: false,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var options = this.getProperties('fullWidth', 'indicators', 'noWrap');
      this.$().carousel({
        full_width: options.fullWidth,
        indicators: options.indicators,
        no_wrap: options.noWrap
      });
    }
  });
});