define('social-web/mirage/factories/user', ['exports', 'ember-cli-mirage', 'social-web/mirage/factories/application', 'moment'], function (exports, _emberCliMirage, _application, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    slug: null,
    current: false,
    fullName: null,
    statusUpdatesCount: 0,
    sportsCount: 0,
    birthday: function birthday() {
      return _emberCliMirage.faker.date.between('1900-01-01', '2000-01-01');
    },

    firstName: _emberCliMirage.faker.name.firstName,
    lastName: _emberCliMirage.faker.name.lastName,
    customPictureThumb: _emberCliMirage.faker.image.avatar,
    coverPhoto: _emberCliMirage.faker.image.sports,
    gender: function gender() {
      return _emberCliMirage.faker.random.boolean() ? 'M' : 'F';
    },
    distance: function distance() {
      return _emberCliMirage.faker.random.boolean() ? 'mi' : 'km';
    },
    phoneNumber: function phoneNumber() {
      return _emberCliMirage.faker.phone.phoneNumber();
    },
    shirtSize: function shirtSize() {
      return 'l';
    },
    currentUserFollowing: function currentUserFollowing() {
      return _emberCliMirage.faker.random.boolean();
    },
    bio: function bio() {
      return _emberCliMirage.faker.lorem.paragraph();
    },
    quote: function quote() {
      return _emberCliMirage.faker.lorem.paragraph();
    },
    followersCount: function followersCount() {
      return _emberCliMirage.faker.random.number();
    },

    email: null,
    password: 'swordfish',

    afterCreate: function afterCreate(user, server) {
      if (!user.userStats) {
        user.userStats = server.create('user-stats', { sportIds: user.sportIds || [] });
      }
      if (!user.location) {
        user.location = server.create('location');
      }

      server.create('email', { defaultEmail: true, user: user });

      user.customPictureMed = user.customPictureThumb;
      user.eventsCount = user.completedEventsCount + user.upcomingEventsCount;
      user.fullName = user.firstName + ' ' + user.lastName;
      user.slug = (user.firstName + ' ' + user.lastName).dasherize().replace(/([^a-zA-Z0-9\-_]|^-)/g, '').replace(/-{2,}/g, '-');
      user.constructor.modelName = user.modelName;
      user.save();
    },


    currentUser: (0, _emberCliMirage.trait)({
      current: true,

      accessToken: 'pants',
      tokenCreatedAt: (0, _moment.default)().valueOf(),
      tokenExpiresIn: 7200,
      refreshToken: 'skirts',
      tokenType: 'Bearer'
    }),

    admin: (0, _emberCliMirage.trait)({
      admin: true
    }),

    withSports: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(user, server) {
        var sports = server.createList('sport', user.sportsCount || _emberCliMirage.faker.random.number(5));
        var userStats = user.userStats;
        userStats.sports = sports;
        user.sports = sports;
        userStats.save();
        user.save();
      }
    })
  });
});