define('social-web/pods/registration/model', ['exports', 'ember-data', 'ember-data/attr', 'social-web/pods/registration/validations'], function (exports, _emberData, _attr, _validations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    firstName: (0, _attr.default)('string'),
    lastName: (0, _attr.default)('string'),
    email: (0, _attr.default)('string'),
    password: (0, _attr.default)('string'),
    passwordConfirmation: (0, _attr.default)('string'),

    validations: _validations.default
  });
});