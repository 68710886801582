define('social-web/instance-initializers/app-interface', ['exports', 'social-web/pods/application/interface'], function (exports, _interface) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(appInstance) {
    _interface.default.instance = appInstance;
  }

  exports.default = {
    name: 'app-interface',
    initialize: initialize
  };
});