define('social-web/mixins/reset-scroll', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    afterModel: function afterModel() {
      this._super.apply(this, arguments);
      window.scrollTo(0, 0);
      return true;
    }
  });
});