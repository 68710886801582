define('social-web/tests/mirage/mirage/models/participation', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Model.extend({
    edition: (0, _emberCliMirage.belongsTo)(),
    competition: (0, _emberCliMirage.belongsTo)(),
    user: (0, _emberCliMirage.belongsTo)(),
    splits: (0, _emberCliMirage.hasMany)()
  });
});