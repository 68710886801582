define('social-web/tests/mirage/mirage/models/edition', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Model.extend({
    followers: (0, _emberCliMirage.hasMany)('user'),
    competitions: (0, _emberCliMirage.hasMany)(),
    media: (0, _emberCliMirage.hasMany)(),
    transmissions: (0, _emberCliMirage.hasMany)(),
    user: (0, _emberCliMirage.belongsTo)(),

    event: (0, _emberCliMirage.belongsTo)(),
    location: (0, _emberCliMirage.belongsTo)(),
    series: (0, _emberCliMirage.belongsTo)(),
    seriesEdition: (0, _emberCliMirage.belongsTo)(),

    travelCategories: (0, _emberCliMirage.hasMany)(),
    accommodationCategories: (0, _emberCliMirage.hasMany)(),
    causeCategories: (0, _emberCliMirage.hasMany)(),
    partnerCategories: (0, _emberCliMirage.hasMany)(),
    sponsorCategories: (0, _emberCliMirage.hasMany)(),
    editionActivities: (0, _emberCliMirage.hasMany)(),
    mapFiles: (0, _emberCliMirage.hasMany)()
  });
});