define('social-web/pods/components/md-input-date/component', ['exports', 'ember-cli-materialize/components/md-input-date', 'moment'], function (exports, _mdInputDate, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _mdInputDate.default.extend({
    value: Ember.computed('model', 'property', {
      get: function get() {
        var value = Ember.get(this.get('model'), this.get('property'));
        return _moment.default.utc(value).format('D MMMM, YYYY');
      },
      set: function set(key, value) {
        Ember.set(this.get('model'), this.get('property'), _moment.default.utc(value).toDate());
        return value;
      }
    })
  });
});