define("social-web/pods/components/comment-card-list/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        var child0 = function () {
          var child0 = function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 6,
                    "column": 8
                  },
                  "end": {
                    "line": 10,
                    "column": 8
                  }
                },
                "moduleName": "social-web/pods/components/comment-card-list/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1, "href", "");
                dom.setAttribute(el1, "class", "show-more");
                var el2 = dom.createTextNode("\n            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createElementMorph(element0);
                morphs[1] = dom.createMorphAt(element0, 1, 1);
                return morphs;
              },
              statements: [["element", "action", ["showComments"], [], ["loc", [null, [7, 18], [7, 43]]], 0, 0], ["inline", "t", ["feed.comment.showMore"], ["count", ["subexpr", "dec", [["get", "displayComments.length", ["loc", [null, [8, 51], [8, 73]]], 0, 0, 0, 0], ["get", "statusUpdate.commentsCount", ["loc", [null, [8, 74], [8, 100]]], 0, 0, 0, 0]], [], ["loc", [null, [8, 46], [8, 101]]], 0, 0]], ["loc", [null, [8, 12], [8, 103]]], 0, 0]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 4,
                  "column": 4
                },
                "end": {
                  "line": 12,
                  "column": 4
                }
              },
              "moduleName": "social-web/pods/components/comment-card-list/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "collection-item center-align");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
              return morphs;
            },
            statements: [["block", "loader-widget", [["subexpr", "not", [["get", "loading", ["loc", [null, [6, 30], [6, 37]]], 0, 0, 0, 0]], [], ["loc", [null, [6, 25], [6, 38]]], 0, 0]], [], 0, null, ["loc", [null, [6, 8], [10, 26]]]]],
            locals: [],
            templates: [child0]
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 14,
                  "column": 4
                },
                "end": {
                  "line": 19,
                  "column": 4
                }
              },
              "moduleName": "social-web/pods/components/comment-card-list/template.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "comment-card", [], ["comment", ["subexpr", "@mut", [["get", "comment", ["loc", [null, [15, 29], [15, 36]]], 0, 0, 0, 0]], [], [], 0, 0], "onDelete", ["subexpr", "action", ["delete"], [], ["loc", [null, [16, 30], [16, 47]]], 0, 0], "beforeReport", ["subexpr", "@mut", [["get", "beforeReport", ["loc", [null, [17, 34], [17, 46]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "collection-item avatar"], ["loc", [null, [15, 6], [18, 53]]], 0, 0]],
            locals: ["comment"],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 2
              },
              "end": {
                "line": 20,
                "column": 2
              }
            },
            "moduleName": "social-web/pods/components/comment-card-list/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["subexpr", "gt", [["get", "statusUpdate.commentsCount", ["loc", [null, [4, 14], [4, 40]]], 0, 0, 0, 0], ["get", "displayComments.length", ["loc", [null, [4, 41], [4, 63]]], 0, 0, 0, 0]], [], ["loc", [null, [4, 10], [4, 64]]], 0, 0]], [], 0, null, ["loc", [null, [4, 4], [12, 11]]]], ["block", "each", [["subexpr", "sort-by", ["publishedAt", ["get", "displayComments", ["loc", [null, [14, 35], [14, 50]]], 0, 0, 0, 0]], [], ["loc", [null, [14, 12], [14, 51]]], 0, 0]], [], 1, null, ["loc", [null, [14, 4], [19, 13]]]]],
          locals: [],
          templates: [child0, child1]
        };
      }();
      var child1 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 21,
                  "column": 4
                },
                "end": {
                  "line": 26,
                  "column": 4
                }
              },
              "moduleName": "social-web/pods/components/comment-card-list/template.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "comment-card", [], ["comment", ["subexpr", "@mut", [["get", "comment", ["loc", [null, [22, 29], [22, 36]]], 0, 0, 0, 0]], [], [], 0, 0], "onDelete", ["subexpr", "action", ["delete"], [], ["loc", [null, [23, 30], [23, 47]]], 0, 0], "beforeReport", ["subexpr", "@mut", [["get", "beforeReport", ["loc", [null, [24, 34], [24, 46]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "collection-item avatar"], ["loc", [null, [22, 6], [25, 53]]], 0, 0]],
            locals: ["comment"],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 20,
                "column": 2
              },
              "end": {
                "line": 27,
                "column": 2
              }
            },
            "moduleName": "social-web/pods/components/comment-card-list/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "each", [["subexpr", "sort-by", ["publishedAt", ["get", "statusUpdate.savedComments", ["loc", [null, [21, 35], [21, 61]]], 0, 0, 0, 0]], [], ["loc", [null, [21, 12], [21, 62]]], 0, 0]], [], 0, null, ["loc", [null, [21, 4], [26, 13]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 28,
              "column": 0
            }
          },
          "moduleName": "social-web/pods/components/comment-card-list/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "collapsed", ["loc", [null, [3, 8], [3, 17]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [3, 2], [27, 9]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 30,
              "column": 0
            },
            "end": {
              "line": 36,
              "column": 0
            }
          },
          "moduleName": "social-web/pods/components/comment-card-list/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "comment-card-new", [], ["comment", ["subexpr", "hash", [], ["parent", ["get", "statusUpdate", ["loc", [null, [31, 42], [31, 54]]], 0, 0, 0, 0], "publisher", ["get", "session.currentUser", ["loc", [null, [31, 65], [31, 84]]], 0, 0, 0, 0]], ["loc", [null, [31, 29], [31, 85]]], 0, 0], "onSave", ["subexpr", "action", ["save"], [], ["loc", [null, [32, 28], [32, 43]]], 0, 0], "component", ["subexpr", "mut", [["get", "newCommentComponent", ["loc", [null, [33, 36], [33, 55]]], 0, 0, 0, 0]], [], ["loc", [null, [33, 31], [33, 56]]], 0, 0], "onCommentStart", ["subexpr", "@mut", [["get", "onCommentStart", ["loc", [null, [34, 36], [34, 50]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "collection-item avatar new-comment"], ["loc", [null, [31, 2], [35, 65]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 37,
            "column": 0
          }
        },
        "moduleName": "social-web/pods/components/comment-card-list/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "loader-widget", [["get", "loaded", ["loc", [null, [2, 17], [2, 23]]], 0, 0, 0, 0]], ["class", ["subexpr", "unless", [["get", "statusUpdate.commentsCount", ["loc", [null, [2, 38], [2, 64]]], 0, 0, 0, 0], "collection-item hide no-padding", "collection-item no-padding"], [], ["loc", [null, [2, 30], [2, 128]]], 0, 0]], 0, null, ["loc", [null, [2, 0], [28, 18]]]], ["block", "unless", [["get", "disabled", ["loc", [null, [30, 10], [30, 18]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [30, 0], [36, 11]]]]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});