define('social-web/mixins/feed-route', ['exports', 'social-web/mixins/infinity-route'], function (exports, _infinityRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_infinityRoute.default, {
    afterInfinityModel: function afterInfinityModel(feed) {
      if (feed.meta.count === 0 && !localStorage.getItem('feedVisited')) {
        localStorage.setItem('feedVisited', true);
        this.transitionTo('event');
      }

      return Ember.RSVP.hash({
        publishers: new Ember.RSVP.all(feed.getEach('publisher')),
        editions: new Ember.RSVP.all(feed.getEach('edition')),
        events: new Ember.RSVP.all(feed.getEach('event'))
      }).then(function () {
        return feed;
      }, function () {
        return feed;
      });
    },
    getFeed: function getFeed(order) {
      var scope = this.get('feedScope');
      if (order && this.get('controller')) {
        this.set('controller.order', order);
      }

      return this.infinityModel('status-update', {
        perPage: 25,
        startingPage: 1,
        modelPath: 'controller.model.feed',

        order: order,
        scope: Ember.typeOf(scope) === 'function' ? scope.apply(this) : scope,
        include: 'media,latest-comment,mentions'
      });
    },


    actions: {
      resetFeed: function resetFeed(order) {
        var _this = this;

        var toggleLoading = function toggleLoading(value) {
          if (_this.get('controller')) {
            _this.set('controller.loading', value);
          }
        };

        toggleLoading(true);
        if (this.get('controller')) {
          if (Ember.typeOf(order) === 'string') {
            this.set('controller.order', order);
          }
        }

        this.getFeed(this.get('controller.order')).then(function (feed) {
          _this.afterInfinityModel(feed).then(function (feed) {
            _this.set(_this.get('_modelPath'), feed);
            toggleLoading();
          });
        }, function () /*e*/{
          toggleLoading();
        });
      }
    }
  });
});