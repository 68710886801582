define('social-web/pods/group/model', ['exports', 'ember-data/attr', 'ember-data/relationships', 'social-web/pods/application/model', 'social-web/mixins/followable-model'], function (exports, _attr, _relationships, _model, _followableModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend(_followableModel.default, {
    display: Ember.computed.alias('name'),
    group_slug: Ember.computed.alias('slug'),

    owner: (0, _relationships.belongsTo)('user'),
    name: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    slug: (0, _attr.default)('string'),
    faq: (0, _attr.default)('string'),
    rules: (0, _attr.default)('string'),
    start: (0, _attr.default)('utc'),
    groupCreator: (0, _attr.default)('string'),

    background: (0, _attr.default)('string'),
    logo: (0, _attr.default)('string'),

    coverPhotoTile: (0, _attr.default)('string'),
    coverPhotoDefault: (0, _attr.default)('string'),
    logoThumb: (0, _attr.default)('string'),

    privacityType: (0, _attr.default)('string'),

    causeCategories: (0, _relationships.hasMany)('cause-category', { async: true }),
    sponsorCategories: (0, _relationships.hasMany)('sponsor-category', { async: true }),
    groupActivities: (0, _relationships.hasMany)('group-activity', { async: true }),

    _membersCount: null,
    membersCount: Ember.computed('_membersCount', function () {
      var _this = this;

      return !Ember.isNone(this.get('_membersCount')) ? this.get('_membersCount') : this.get('store').query('user-id', {
        scope: { model: this, action: 'members' },
        per_page: 1
      }).then(function (model) {
        _this.set('_membersCount', model.get('meta.count'));
      });
    }),

    canBeMember: Ember.computed.equal('privacityType', 'Public'),

    _isCurrentUserMember: null,
    isCurrentUserMember: Ember.computed('_isCurrentUserMember', function () {
      var _this2 = this;

      return !Ember.isNone(this.get('_isCurrentUserMember')) ? this.get('_isCurrentUserMember') : this.get('store').queryRecord('user-id', {
        scope: { model: this, action: 'members/' + this.get('session.currentUser.id') }
      }).then(function (model) {
        // Ember.Logger.log('é membro', model, 'user', this.get('session.currentUser.id'));
        _this2.set('_isCurrentUserMember', true);
      }).catch(function (err) {
        // Ember.Logger.log('error no member', err);
        _this2.set('_isCurrentUserMember', false);
      });
    }),

    toRequestBeMember: function toRequestBeMember() {
      var _this3 = this;

      var member = this.get('store').createRecord('member', {
        group: this
      });
      return member.save().then(function (member) {
        _this3.set('currentUserMember', member);
        if (!_this3.get('group.currentUserIsMember')) {
          _this3.get('group').follow();
        }
        _this3.get('group').set('_membersCount', null);
      }, function () {
        member.unloadRecord();
      });
    },
    leaveGroup: function leaveGroup() {
      var _this4 = this;

      return this.get('currentUserMember').destroyRecord().then(function () {
        _this4.set('currentUserMember');
        _this4.get('group').set('_membersCount', null);
      });
    }
  });
});