define('social-web/pods/scrapbook/show/timeline/route', ['exports', 'social-web/mixins/infinity-route', 'social-web/mixins/feed-route'], function (exports, _infinityRoute, _feedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_infinityRoute.default, _feedRoute.default, {
    feedScope: function feedScope() {
      var _modelFor = this.modelFor('scrapbook.show'),
          scrapbook = _modelFor.scrapbook;

      return {
        model: scrapbook,
        action: 'feed'
      };
    },
    model: function model() {
      var user = this.modelFor('user.show');
      var feed = this.getFeed();

      return Ember.RSVP.hash({
        user: user,
        feed: feed,
        currentEdition: {},
        currentGroup: {}
      });
    }
  });
});