define('social-web/pods/event/model', ['exports', 'ember-data/attr', 'ember-data/relationships', 'social-web/pods/application/model', 'social-web/mixins/followable-model'], function (exports, _attr, _relationships, _model, _followableModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend(_followableModel.default, {
    display: Ember.computed.alias('name'),
    slug: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),

    coverPhotoTile: (0, _attr.default)('string'),
    coverPhotoDefault: (0, _attr.default)('string'),
    logoThumb: (0, _attr.default)('string'),

    followersCount: null,
    editionsCount: (0, _attr.default)('number'),

    latestEdition: (0, _relationships.belongsTo)('edition', { async: true }),
    editions: (0, _relationships.hasMany)('edition', { async: true }),
    owner: (0, _relationships.belongsTo)('user')
  });
});