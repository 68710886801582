define('social-web/components/md-collection', ['exports', 'ember-cli-materialize/components/md-collection'], function (exports, _mdCollection) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _mdCollection.default;
    }
  });
});