define('social-web/mixins/report-adapter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    report: function report(model) {
      var buildURL = this.buildURL(model.constructor.modelName, model.id, null, null, {
        scope: { model: model, action: 'report' }
      });
      return this.ajax(buildURL, 'POST');
    }
  });
});