define('social-web/pods/components/competition-card/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    media: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),

    tagName: 'a',
    attributeBindings: ['href'],
    maxBubbles: 10,
    href: Ember.computed('competition.edition.event', function () {
      var eventSlug = this.get('competition.edition.event.slug'),
          editionSlug = this.get('competition.edition.slug');
      return '/events/' + eventSlug + '/editions/' + editionSlug;
    }),

    followees: Ember.computed('competition.edition.content', 'session.currentUser', function () {
      var edition = this.get('competition.edition.content');
      var currentUser = this.get('session.currentUser');

      return !!edition && this.get('store').query('user', {
        per_page: this.get('maxBubbles'),

        scope: {
          model: edition,
          scope: {
            model: currentUser,
            action: 'followees'
          }
        }
      });
    })
  });
});