define('social-web/pods/account/notification-settings/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    ajax: Ember.inject.service(),

    model: function model() {
      return this.store.findAll('notification-setting', { reload: true });
    }
  });
});