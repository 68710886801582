define("social-web/pods/components/group-card/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 10,
              "column": 4
            },
            "end": {
              "line": 12,
              "column": 4
            }
          },
          "moduleName": "social-web/pods/components/group-card/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "truncate-multiline", [], ["text", ["subexpr", "@mut", [["get", "model.display", ["loc", [null, [11, 32], [11, 45]]], 0, 0, 0, 0]], [], [], 0, 0], "lines", 3, "showButton", false], ["loc", [null, [11, 6], [11, 72]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 25,
            "column": 0
          }
        },
        "moduleName": "social-web/pods/components/group-card/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "card-image");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("time");
        dom.setAttribute(el2, "class", "time-label");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("strong");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("strong");
        dom.setAttribute(el2, "class", "card-title full-width");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "card-content");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2, "class", "btn-floating btn-large square bg-image no-padding right");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("em");
        dom.setAttribute(el2, "class", "secondary-text truncate");
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(fragment, [2, 1]);
        var morphs = new Array(6);
        morphs[0] = dom.createAttrMorph(element1, 'datetime');
        morphs[1] = dom.createMorphAt(dom.childAt(element1, [1]), 0, 0);
        morphs[2] = dom.createMorphAt(element0, 3, 3);
        morphs[3] = dom.createMorphAt(dom.childAt(element0, [5]), 1, 1);
        morphs[4] = dom.createAttrMorph(element2, 'title');
        morphs[5] = dom.createMorphAt(element2, 1, 1);
        return morphs;
      },
      statements: [["attribute", "datetime", ["get", "model.start", ["loc", [null, [2, 19], [2, 30]]], 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "date-label", [["get", "model.start", ["loc", [null, [3, 25], [3, 36]]], 0, 0, 0, 0]], [], ["loc", [null, [3, 12], [3, 38]]], 0, 0], ["inline", "lazy-background-image", [], ["url", ["subexpr", "@mut", [["get", "model.background", ["loc", [null, [6, 30], [6, 46]]], 0, 0, 0, 0]], [], [], 0, 0], "fallbackUrl", ["subexpr", "@mut", [["get", "media.defaults.groupCover", ["loc", [null, [7, 38], [7, 63]]], 0, 0, 0, 0]], [], [], 0, 0], "title", ["subexpr", "@mut", [["get", "model.display", ["loc", [null, [8, 32], [8, 45]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [6, 2], [8, 47]]], 0, 0], ["block", "if", [["get", "model.display", ["loc", [null, [10, 10], [10, 23]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [10, 4], [12, 11]]]], ["attribute", "title", ["get", "model.name", ["loc", [null, [17, 13], [17, 23]]], 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "lazy-background-image", [], ["url", ["subexpr", "@mut", [["get", "model.logo", ["loc", [null, [18, 32], [18, 42]]], 0, 0, 0, 0]], [], [], 0, 0], "fallbackUrl", ["subexpr", "@mut", [["get", "media.defaults.groupLogo", ["loc", [null, [19, 40], [19, 64]]], 0, 0, 0, 0]], [], [], 0, 0], "title", ["subexpr", "@mut", [["get", "model.display", ["loc", [null, [20, 34], [20, 47]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "full-height"], ["loc", [null, [18, 4], [21, 49]]], 0, 0]],
      locals: [],
      templates: [child0]
    };
  }());
});