define('social-web/instance-initializers/browser/ember-href-to', ['exports', 'ember-href-to/href-to'], function (exports, _hrefTo) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'ember-href-to',
    initialize: function initialize(applicationInstance) {
      var $body = Ember.$(document.body);
      $body.off('click.href-to', 'a');

      $body.on('click.href-to', 'a', function (e) {
        var hrefTo = new _hrefTo.default(applicationInstance, e);
        hrefTo.maybeHandle();

        return true;
      });
    }
  };
});