define('social-web/pods/status-update/model', ['exports', 'ember-data/attr', 'ember-data/relationships', 'social-web/pods/application/model'], function (exports, _attr, _relationships, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    session: Ember.inject.service(),
    mention: Ember.inject.service(),

    content: (0, _attr.default)('string'),
    commentsCount: (0, _attr.default)('number'),
    votesCount: (0, _attr.default)('number'),
    publishedAt: Ember.computed.alias('createdAt'),
    type: (0, _attr.default)('string'),
    pinned: (0, _attr.default)('boolean'),
    postMentions: (0, _attr.default)(),
    geolocation: (0, _attr.default)('point'),

    meta: (0, _attr.default)(),
    time: Ember.computed.alias('meta.time'),
    distance: Ember.computed.alias('meta.distance'),

    publisher: (0, _relationships.belongsTo)('user'),

    event: (0, _relationships.belongsTo)('event', { async: true }),
    edition: (0, _relationships.belongsTo)('edition', { async: true }),
    group: (0, _relationships.belongsTo)('group', { async: true }),
    latestComment: (0, _relationships.belongsTo)('comment', { async: true }),

    media: (0, _relationships.hasMany)('media', { async: true }),
    comments: (0, _relationships.hasMany)('comment', { async: true }),
    mentions: (0, _relationships.hasMany)('mention', { async: true }),

    htmlContent: Ember.computed('content', function () {
      return this.get('mention').toHTML(this.get('content'), { htmlSafe: true });
    }),
    htmlEditContent: Ember.computed('content', function () {
      return this.get('mention').toHTML(this.get('content'), { editable: true });
    }),

    savedComments: Ember.computed('comments.@each.isNew', 'comments.@each.isDeleted', function () {
      return this.get('comments').filter(function (comment) {
        return !comment.get('isNew') && !comment.get('isDeleted');
      });
    }),
    latestComments: Ember.computed('latestComment', function () {
      var latestComment = this.get('latestComment.content');
      return latestComment ? [latestComment] : [];
    }),

    canEdit: Ember.computed('session.currentUser', function () {
      return this.get('type') === 'StatusUpdate' && (this.get('publisher.id') === this.get('session.currentUser.id') || this.get('session.currentUser.admin'));
    }),
    canDelete: Ember.computed('session.currentUser', function () {
      return this.get('publisher.id') === this.get('session.currentUser.id') || this.get('session.currentUser.admin');
    }),
    canPin: Ember.computed('session.currentUser', 'edition.event.owner', function () {
      return this.get('edition.event.owner.id') === this.get('session.currentUser.id') || this.get('session.currentUser.admin');
    }),
    votes: Ember.computed('_votes', function () {
      var _this = this;

      return this.get('_votes') || this.get('adapter').votes(this).then(function (votes) {
        _this.set('votesCount', votes.get('count'));
        _this.set('_votes', votes);
      });
    }),

    vote: function vote() {
      var _this2 = this;

      return this.get('adapter').vote(this).then(function (votes) {
        _this2.set('votesCount', votes.get('count'));
        _this2.set('_votes', votes);
      });
    },
    unvote: function unvote() {
      var _this3 = this;

      return this.get('adapter').unvote(this).then(function (votes) {
        _this3.set('votesCount', votes.get('count'));
        _this3.set('_votes', votes);
      });
    },
    report: function report() {
      return this.get('adapter').report(this);
    },
    togglePinned: function togglePinned() {
      this.set('pinned', !this.get('pinned'));
      return this.save();
    },
    init: function init() {
      this.get('media').setEach('isOwned', false);
      this._super.apply(this, arguments);
    },
    save: function save() {
      return this._super.apply(this, arguments).then(function (model) {
        model.get('media').setEach('isOwned', true);
      });
    },
    rollbackAttributes: function rollbackAttributes() {
      this.set('media', this.get('media').filterBy('isOwned'));
      return this._super.apply(this, arguments);
    }
  });
});