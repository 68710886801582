define('social-web/pods/group/show/detail/about/index/controller', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    schedule: Ember.computed('model.id', 'model.groupActivities.@each.start', function () {
      if (this.get('model.groupActivities').isEvery('start')) {
        var items = new Ember.A();
        items.pushObjects(this.get('model.groupActivities').toArray());
        var sortedDates = items.map(function (item) {
          var date = (0, _moment.default)(Ember.get(item, 'start')).startOf('day');
          return {
            date: date,
            string: date.format('LL')
          };
        }).sort(function (a, b) {
          var momentA = (0, _moment.default)(a.date);
          var momentB = (0, _moment.default)(b.date);
          if (momentA.isBefore(momentB)) {
            return -1;
          } else if (momentA.isAfter(momentB)) {
            return 1;
          } else {
            return 0;
          }
        }).uniqBy('string');

        var comparer = function comparer(property, date) {
          return function (item) {
            return (0, _moment.default)(Ember.get(item, property)).startOf('day').format('LL') === date.format('LL');
          };
        };

        return sortedDates.map(function (hash) {
          return {
            date: hash.date,
            items: items.filter(comparer('start', hash.date)).sort(function (a, b) {
              var momentA = (0, _moment.default)(Ember.get(a, 'start'));
              var momentB = (0, _moment.default)(Ember.get(b, 'start'));
              if (momentA.isBefore(momentB)) {
                return -1;
              } else if (momentA.isAfter(momentB)) {
                return 1;
              } else {
                return 0;
              }
            })
          };
        });
      }
    })
  });
});