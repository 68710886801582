define('social-web/pods/application/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    featured: Ember.inject.service(),
    featuredEditions: Ember.computed.alias('featured.featuredEditions'),
    featuredUsers: Ember.computed.alias('featured.featuredUsers'),
    featuredGroups: Ember.computed.alias('featured.featuredGroups'),
    session: Ember.inject.service()
  });
});