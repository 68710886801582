define('social-web/pods/components/focus-input/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.TextField.extend({
    didInsertElement: function didInsertElement() {
      this.$().focus();
    }
  });
});