define('social-web/pods/components/mobile-menu/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    media: Ember.inject.service(),
    session: Ember.inject.service(),

    click: function click() {
      Ember.$('.ui.sidebar').sidebar('toggle');
      return true;
    }
  });
});