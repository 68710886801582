define('social-web/pods/user-photos/adapter', ['exports', 'social-web/pods/application/adapter', 'ember-cli-form-data/mixins/form-data-adapter'], function (exports, _adapter, _formDataAdapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _adapter.default.extend(_formDataAdapter.default, {
    urlForUpdateRecord: function urlForUpdateRecord(id) {
      return '' + (this.host || '/') + this.namespace + '/users/' + id;
    }
  });
});