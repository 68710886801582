define('social-web/mirage/factories/location', ['exports', 'ember-cli-mirage', 'social-web/mirage/factories/application'], function (exports, _emberCliMirage, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    formattedAddress: function formattedAddress() {
      return _emberCliMirage.faker.address.city();
    },
    geocodeData: function geocodeData() {
      return {
        lat: 0,
        lng: 0,
        place: {
          url: 'https://www.google.com'
        }
      };
    }
  });
});