define('social-web/helpers/stylize', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.stylize = stylize;
  function stylize() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var hash = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    var styles = '';
    for (var i in hash) {
      styles += i + ': ' + hash[i] + '; ';
    }
    return Ember.String.htmlSafe(styles.trim());
  }

  exports.default = Ember.Helper.helper(stylize);
});