define('social-web/pods/event/show/edition/show/gallery/route', ['exports', 'social-web/mixins/infinity-route'], function (exports, _infinityRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_infinityRoute.default, {
    i18n: Ember.inject.service(),

    titleToken: function titleToken() {
      return this.get('i18n').t('user.show.gallery.title');
    },
    model: function model() {
      var edition = this.modelFor('event.show.edition.show');

      var videos = this.get('store').query('transmission', {
        scope: {
          model: edition,
          action: 'transmissions'
        }
      });

      var pictures = this.infinityModel('media', {
        perPage: 12,
        modelPath: 'controller.model.pictures',
        scope: {
          model: edition, action: 'media'
        }
      });

      return Ember.RSVP.hash({
        pictures: pictures,
        videos: videos
      });
    }
  });
});