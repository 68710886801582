define("social-web/locales/en/translations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    // 'some.translation.key': 'Text for some.translation.key',
    //
    // 'a': {
    //   'nested': {
    //     'key': 'Text for a.nested.key'
    //   }
    // },
    //
    // 'key.with.interpolation': 'Text with {{anInterpolation}}'

    meta: {
      title: "Splife",
      description: "A fitness network"
    },
    common: {
      showMore: "Show More",
      readMore: "Read More",
      loading: "Loading...",
      search: {
        placeholder: "Search...",
        noResults: "No results",
        noResultsFor: "No results for \"{{term}}\"",
        noParticipants: "No participants found",
        noMembers: "No members found"
      },
      auth: {
        signin: {
          success: "Signed in successfully.",
          error: "Invalid email or password."
        },
        signup: {
          success: "Welcome! You have signed up successfully."
        },
        recovery: {
          noToken: "You can't access this page without coming from a password reset email. If you do come from a password reset email, please make sure you used the full URL provided.",
          error: "Couldn't update your password. Is the link from the email we sent you correct?"
        },
        forgot: {
          success: "You will receive an email with instructions on how to reset your password in a few minutes.",
          error: "Email not found."
        }
      },
      follow: "Follow",
      viewProfile: "View Profile",
      post: "What's happening?",
      edit: "Edit",
      save: "Save",
      cancel: "Cancel",
      social: {
        share: "Share",
        tweet: "Tweet",
        facebook: "Facebook",
        twitter: "Twitter"
      }
    },
    nav: {
      feed: {
        title: "Home"
      },
      event: {
        title: "Events"
      },
      user: {
        title: "Athletes"
      },
      group: {
        title: "Groups"
      },
      search: {
        title: "Search"
      },
      actions: {
        viewProfile: "My Profile",
        viewAccount: "My Account",
        addEvent: "Add Event",
        admin: "Admin",
        newAdmin: "New Admin",
        signout: "Sign out",
        signin: "Sign in",
        help: "Get Help"
      },
      notifications: {
        title: "Notifications",
        settings: "Settings",
        empty: {
          title: "No notifications",
          description: ""
        }
      }
    },
    welcome: {
      title: "Welcome to Splife",
      description: "When you follow events or athletes, you\u2019ll see live updates, videos, photos, and results.",
      connect: {
        title: "Connect Contacts",
        flavorText: "Find people you know",
        button: "Connect"
      }
    },
    index: {
      heroMain: "Connecting Athletes, Fans & Events",
      missionStatement: "Find the best sports events, follow the action, and capture the experience.",
      signUp: "Sign Up",
      signIn: "Sign In",
      appleAlt: "Apple logo",
      availableOn: "Available on the",
      playStore: "Play Store",
      playAlt: "Play logo",
      appStore: "App Store",
      iosAlt: "iOS app",
      signInWith: "Sign In With",
      facebook: "Facebook",
      facebookLogo: "Facebook logo",
      scrapbookHeader: "Capture the experience",
      scrapbookDescription: "Scrapbook the journey with photos, friends, and results.",
      followHeader: "Follow the Action Live",
      followDescription: "See live event photos, status posts & results.",
      contentHeader: "Find the best sports events",
      contentDescription: "Locate nearby, trending, and top-rated events.",
      followSignUp: "Get Started",
      findEvents: "Find Events",
      downloadApp: "Download App",
      contact: "Contact Us",
      copy: "&copy;",
      brand: "- Splife"
    },
    onboarding: {
      //footer: 'By signing up, you agree to our {{terms}} and {{privacy}}.',
      footer: "By signing up, you agree to our {{privacy}}.",
      signin: {
        header: "Sign Into Splife",
        terms: "Terms of Service",
        privacy: "Privacy Policy",
        labels: {
          email: "Email",
          password: "Password",
          remember: "Remember Me",
          signup: "New to Splife?",
          emailSignInOption: "or sign in with your email"
        },
        actions: {
          forgot: "Forgot Password",
          submit: "Submit",
          signup: "Sign up",
          signin: "Sign In",
          facebook: "Sign in with Facebook",
          google: "Sign in with Google",
          email: "Sign in with Email"
        }
      },
      signup: {
        header: "Sign up for Splife",
        labels: {
          email: "Email",
          password: "Password",
          passwordConfirmation: "Re-enter password",
          firstName: "First Name",
          lastName: "Last Name",
          signin: "Already have an account?",
          s1: "",
          s2: "Find the best sports events.",
          s3: "Follow the action live.",
          s4: "Capture the experience.",
          emailSignUpOption: "or sign up with your email"
        },
        actions: {
          submit: "Sign Up",
          signin: "Sign in",
          facebook: "Sign up with Facebook",
          google: "Sign up with Google",
          email: "Sign up with Email"
        }
      },
      forgot: {
        header: "Forgot your password?",
        subheader: "Enter your email address below for further instruction.",
        labels: {
          email: "Email"
        },
        actions: {
          submit: "Submit"
        }
      },
      recovery: {
        header: "Create New Password",
        labels: {
          password: "New password",
          passwordConfirmation: "Re-enter new password"
        },
        actions: {
          submit: "Submit"
        }
      }
    },
    privacy: {
      title: "Privacy Policy",
      gathering: {
        title: "The information we collect",
        h1: "General",
        p1: "In order to provide Users with a personalized event experience, we collect certain information submitted to us by Users, or transferred to us during the course of communication between Users and this Site. Information is categorized as follows:",
        h2: "Anonymous information",
        p2: "Anonymous information is non-personally identifiable information. This means that it does not directly identify you, although it may identify the computer which you are using. Use of this Site involves the transfer of certain anonymous information between your device and our servers, such as your device\u2019s IP address and the type of browser you are using.",
        h3: "Personally identifiable information",
        p3: "Personally Identifiable Information lets us know who you are. Examples of Personally Identifiable Information are your name, picture, email address and race bib number. By using the Site, you grant us permission to personalize media taken during events."
      },
      usage: {
        title: "How the Information is used",
        p1: "The information is used to enhance the vistor's experience when using the website to display personalised content and possibly advertising.",
        p2: "E-mail addresses will not be sold, rented or leased to 3rd parties.",
        p3: "E-mail may be sent to inform you of news of our services or offers by us or our affiliates."
      },
      options: {
        title: "Visitor Options",
        p1: "If you have subscribed to one of our services, you may unsubscribe by following the instructions which are included in e-mail that you receive.",
        p2: "You may be able to block cookies via your browser settings but this may prevent you from access to certain features of the website."
      },
      cookies: {
        title: "Cookies",
        p1: "Cookies are small digital signature files that are stored by your web browser that allow your preferences to be recorded when visiting the website. Also they may be used to track your return visits to the website.",
        p2: "3rd party advertising companies may also use cookies for tracking purposes."
      },
      advertisement: {
        title: "Google Ads",
        p1: "Google, as a third party vendor, uses cookies to serve ads.",
        p2: "Google's use of the DART cookie enables it to serve ads to visitors based on their visit to sites they visit on the Internet.",
        p3: "Website visitors may opt out of the use of the DART cookie by visiting the Google ad and content network privacy policy."
      }
    },
    terms: {
      title: "Terms of Service"
    },
    search: {
      index: {
        title: "Search",
        placeholder: "Search for athletes, events and groups...",
        empty: {
          title: "No results",
          description: "",
          modal: {
            button: "Suggest event",
            title: "Suggest event",
            name: "Event name",
            accept: "Submit",
            reject: "Cancel"
          }
        }
      },
      user: {
        title: {
          zero: "People",
          one: "Person ({{count}})",
          two: "People ({{count}})",
          other: "People ({{count}})"
        },
        empty: {
          title: "No results",
          description: ""
        },
        actions: {
          seeMore: "See More"
        }
      },
      edition: {
        title: {
          zero: "Event",
          one: "Event ({{count}})",
          two: "Events ({{count}})",
          other: "Events ({{count}})"
        },
        empty: {
          title: "No results",
          description: ""
        },
        actions: {
          seeMore: "See More"
        }
      },
      group: {
        title: {
          zero: "Group",
          one: "Group ({{count}})",
          two: "Groups ({{count}})",
          other: "Groups ({{count}})"
        },
        empty: {
          title: "No groups found",
          description: ""
        },
        actions: {
          seeMore: "See More"
        }
      }
    },
    feed: {
      title: "Activity Feed",
      comment: {
        placeholder: "Your comment...",
        actions: {
          title: "Comment Actions",
          edit: "Edit",
          delete: "Delete",
          create: "Comment",
          finishEdit: "Comment",
          cancelEdit: "Cancel",
          deletePrompt: {
            title: "Delete Comment",
            message: "You cannot undo this action. It will remove the comment from the post.",
            confirm: "Yes",
            cancel: "No"
          }
        },
        showMore: {
          one: "View {{count}} more comment",
          two: "View {{count}} more comments",
          other: "View {{count}} more comments"
        },
        "report.message": "Does this comment contain violent, crude or generally inappropriate content?"
      },
      post: {
        placeholders: {
          content: "Post an update",
          mentions: "@ to mention friends",
          tagBibs: "Tag athlete(s) using bib#",
          tagMembers: "Tag members(s) using @name",
          time: "Elapsed Time",
          distance: "Distance",
          edition: "Select an event to enable post",
          group: "Group to enable post",
          link: "Insert Link",
          share: "{{user}} post on {{edition}}"
        },
        actions: {
          title: "Post Actions",
          edit: "Edit",
          delete: "Delete",
          create: "Post",
          cancel: "Cancel",
          pin: "Pin Post",
          unpin: "Unpin Post",
          share: "Share",
          deletePrompt: {
            title: "Delete Post",
            message: "You cannot undo this action. It will remove the post and all associated comments, videos, and images.",
            confirm: "Yes",
            cancel: "No"
          }
        },
        new: {
          activeEditions: "Active",
          upcomingEditions: "Upcoming",
          completedEditions: "Completed",
          editionsSearch: "Search for an event",
          group: "Group",
          bibsSearch: "Search for a participant",
          memberSearch: "Search for a member",
          reveal: {
            time: {
              title: "Elapsed Time",
              days: "Days",
              hours: "Hours",
              minutes: "Minutes",
              seconds: "Seconds"
            },
            distance: {
              title: "Distance Traveled",
              distance: "Distance",
              mi: "mi",
              km: "km"
            },
            actions: {
              clear: "Clear",
              save: "Save"
            }
          }
        },
        show: {
          pinned: "Pinned",
          with: {
            prefix: "With - ",
            content: {
              one: "{{first}}",
              two: "{{first}} & {{second}}",
              other: "{{first}} & {{lessCount}} others"
            },
            modal: {
              title: {
                one: "{{count}} Tagged Bib",
                two: "{{count}} Tagged Bibs",
                other: "{{count}} Tagged Bibs"
              },
              group: {
                title: {
                  one: "{{count}} Tagged Member",
                  two: "{{count}} Tagged Members",
                  other: "{{count}} Tagged Members"
                }
              }
            }
          },
          time: "Time",
          distance: "Distance"
        },
        "report.message": "Does this post contain violent, crude or generally inappropriate content?"
      },
      filter: {
        recent: {
          title: "Most Recent Posts",
          description: "Default organization of posts"
        },
        trending: {
          title: "Trending Posts",
          description: "Posts with the most recent interactions"
        },
        friends: {
          title: "Friends Only",
          description: "Posts related only to your friends"
        }
      }
    },
    events: {
      follow: "Follow",
      following: "Following",
      "active.title": "Live Events",
      "upcoming.title": "Upcoming Events",
      completed: "Completed",
      live: "Live",
      featured: {
        title: "Featured Events"
      },
      friends: {
        participating: {
          one: "{{count}} Friend in the event",
          two: "{{count}} Friend in the event",
          other: "{{count}} Friends in the event"
        }
      },
      index: {
        title: "Events",
        description: "",
        header: "Discover all the best sporting events in one place.",
        discover: {
          title: "Discover"
        },
        featured: {
          title: "Featured"
        },
        eventBlurb: "Get started today, direct your own Splife experience.",
        actions: {
          createEvent: "Create Event"
        }
      },
      show: {
        invite: "Invite",
        participate: "Participate",
        participating: "I'm Running",
        register: "Register",
        share: "Share",
        editions: {
          title: "Event Edition",
          view: "Editions",
          modal: {
            title: "Change Editions"
          }
        },
        participation: {
          modal: {
            title: "Participate",
            participate: "Participate",
            leave: "Leave",
            apply: "Apply"
          }
        },
        series: {
          title: "Event Series",
          view: "View Series",
          tagline: "This event is a member of"
        },
        activity: {
          title: "Activity",
          feed: {
            title: "Event Feed",
            empty: "There are no posts in this event's feed.",
            placeholders: {
              post: "Post to {{eventName}}"
            }
          }
        },
        gallery: {
          title: "Media",
          empty: {
            title: "No Photos...",
            description: "There are no photos for this event on SPLIFE yet."
          }
        },
        live: {
          title: "Live",
          empty: {
            title: "No live transmission ...",
            description: "There are no live transmission for this event on SPLIFE yet."
          }
        },
        results: {
          title: "Results",
          search: {
            placeholder: "Search",
            competitions: "Search competitions..."
          },
          name: "{{display}}",
          place: "{{place}}",
          time: "Time",
          pace: "Pace",
          elapsed: "Elapsed",
          split: "Split",
          distance: "Distance",
          minPer: "min/",
          bibNum: "Bib#",
          age: "Age",
          empty: {
            title: "No Results...",
            description: "There are no results for this competition on SPLIFE yet."
          }
        },
        athletes: {
          title: "Athletes",
          search: {
            placeholder: "Search for an athlete..."
          },
          empty: {
            title: "No Athletes...",
            description: "There are no participants following this event on SPLIFE yet.",
            invite: "Invite Friends to Participate"
          },
          bib: "Bib",
          viewScrapbook: "View Scrapbook"
        },
        fans: {
          title: "Followers",
          search: {
            placeholder: "Search for a fan..."
          },
          empty: {
            title: "No Fans...",
            description: "There are no fans following this event on SPLIFE yet.",
            invite: "Invite Friends to Follow"
          }
        },
        about: {
          title: "Info",
          empty: {
            title: "No information...",
            description: "There is no information for this event on SPLIFE yet."
          },
          competitions: {
            title: "Competitions",
            labels: {
              name: "Name",
              distance: "Distance",
              startTime: "Start Time",
              athletes: "Athletes"
            },
            actions: {
              showMore: "Show All Competitions"
            }
          },
          description: {
            title: "Event Description"
          },
          faq: {
            title: "Frequently Asked Questions"
          },
          rules: {
            title: "Event Rules"
          },
          travel: {
            title: "Travel",
            emptyTitle: "~"
          },
          accommodation: {
            title: "Accommodation",
            emptyTitle: "~"
          },
          resources: {
            title: "Map Files"
          },
          causes: {
            title: "Causes",
            emptyTitle: "~"
          },
          partners: {
            title: "Partners",
            emptyTitle: "~"
          },
          sponsors: {
            title: "Sponsors",
            emptyTitle: "~"
          },
          schedule: {
            title: "Calendar",
            ongoing: "{{item}} - Ongoing",
            ending: "{{item}} - Ending {{endTime}}"
          }
        }
      }
    },
    groups: {
      member: "Membro",
      featured: {
        title: "Featured Grpups"
      },
      members: {
        participating: {
          one: "{{count}} Member in the group",
          two: "{{count}} Members in the group",
          other: "{{count}} Members in the group"
        }
      },
      index: {
        title: "Groups",
        description: "",
        header: "Discover all the best groups of sport in one place.",
        discover: {
          title: "Discover"
        },
        featured: {
          title: "Featured"
        },
        groupBlurb: "Get started today, direct your own Splife experience.",
        actions: {
          createEvent: "Create Group"
        }
      },
      show: {
        invite: "Invite",
        toBeMember: "Participate",
        IAmMember: "I'm a member",
        register: "Register",
        share: "Share",
        member: {
          modal: {
            title: "Participate",
            participate: "Participate",
            leave: "Leave",
            apply: "Apply"
          }
        },
        activity: {
          title: "Activity",
          feed: {
            title: "Group Feed",
            empty: "There are no posts in this group's feed.",
            placeholders: {
              post: "Post to {{groupName}}"
            }
          }
        },
        gallery: {
          title: "Media",
          empty: {
            title: "No Photos...",
            description: "There are no photos for this group on SPLIFE yet."
          }
        },
        live: {
          title: "Live",
          empty: {
            title: "No live transmission ...",
            description: "There are no live transmission for this group on SPLIFE yet."
          }
        },
        members: {
          title: "Members",
          search: {
            placeholder: "Search for an member..."
          },
          empty: {
            title: "No Members...",
            description: "There are no members in this group on SPLIFE yet.",
            invite: "Invite Friends to Participate"
          },
          viewScrapbook: "View Scrapbook"
        },
        about: {
          title: "Info",
          empty: {
            title: "No information...",
            description: "There is no information for this group on SPLIFE yet."
          },
          description: {
            title: "Group Description"
          },
          faq: {
            title: "Frequently Asked Questions"
          },
          rules: {
            title: "Group Rules"
          },
          causes: {
            title: "Causes",
            emptyTitle: "~"
          },
          sponsors: {
            title: "Sponsors",
            emptyTitle: "~"
          },
          schedule: {
            title: "Calendar",
            ongoing: "{{item}} - Ongoing",
            ending: "{{item}} - Ending {{endTime}}"
          }
        }
      }
    },
    series: {
      title: "Series Description",
      events: "Series Events ({{count}})",
      actions: {
        changeEdition: "Change Editions"
      },
      editions: {
        modal: {
          title: "Change Series Editions"
        }
      }
    },
    distanceMeasureUnit: {
      km: {
        short: "km",
        long: "Kilometer"
      },
      mi: {
        short: "mi",
        long: "Mile"
      }
    },
    user: {
      follow: "Follow",
      following: "Following",
      featured: {
        title: "Featured Athletes"
      },
      gender: {
        m: "Male",
        f: "Female"
      },
      shirtSize: {
        adult: "Adult",
        youth: "Youth",
        xxs: "2XS",
        xs: "XS",
        s: "S",
        m: "M",
        l: "L",
        xl: "XL",
        xxl: "2XL",
        xxxl: "3XL",
        "2year": "2 years",
        "4year": "4 years",
        "6year": "6 years",
        "8year": "8 years",
        "10year": "10 years",
        "12year": "12 years"
      },
      index: {
        title: "People",
        description: "",
        header: "Discover featured and trending athletes from all over the world.",
        discover: {
          title: "Discover"
        },
        featured: {
          title: "Featured"
        }
      },
      show: {
        actions: {
          editProfile: "Edit Profile",
          editBackground: "Edit Background",
          aboutMe: "About Me",
          profilePicture: {
            title: "Edit Profile Image",
            description: ".jpg & .png only, 25mb file size limit",
            upload: "Upload Photo",
            submit: "Accept"
          },
          coverPhoto: {
            upload: "Upload Photo",
            remove: "Remove"
          }
        },
        profile: {
          title: "Profile",
          info: {
            title: "Basic Info",
            name: "Name",
            firstName: "First Name",
            lastName: "Last Name",
            location: {
              title: "Location",
              empty: "No location."
            }
          },
          sports: {
            title: "Athletic Info",
            empty: "No sports to show."
          },
          quote: {
            title: "Quote",
            placeholder: "Add your favorite quote...",
            empty: "No quote to show."
          },
          bio: {
            title: "About Me",
            placeholder: "Enter a short bio...",
            empty: "No bio to show."
          },
          actions: {
            return: "Return To Profile",
            save: "Save",
            edit: "Edit"
          },
          share: "Share"
        },
        stats: {
          events: "Events",
          followers: "Followers",
          miles: "Miles",
          upcoming: "Upcoming",
          completed: "Events",
          posts: "Posts"
        },
        about: {
          title: {
            self: "About Me",
            other: "About {{name}}"
          }
        },
        activity: {
          title: "Activity",
          feed: {
            title: "Activity Feed",
            placeholders: {
              post: {
                self: "What's on your mind?",
                other: "What's on your mind?"
              }
            },
            empty: {
              title: {
                self: "Your profile is nearly empty."
              },
              description: {
                self: "Take a moment to edit and enhance your profile.",
                other: "There are no posts in {{name}}'s feed."
              }
            }
          }
        },
        editions: {
          title: "Events",
          empty: {
            upcomingLive: {
              self: "You do not have any upcoming or live events.",
              other: "{{name}} does not have any upcoming or live events."
            },
            completed: {
              self: "You do not have any completed events.",
              other: "{{name}} does not have any completed events."
            }
          },
          search: {
            placeholder: "Search for an event..."
          },
          filters: {
            groups: {
              title: "Select Group",
              upcomingLive: "Upcoming & Live",
              completed: "Completed"
            }
          }
        },
        gallery: {
          title: "Media",
          empty: {
            title: "No Photos...",
            description: {
              self: "You don't have any photos.",
              other: "{{name}} doesn't have any photos."
            }
          }
        },
        friends: {
          title: "Friends",
          empty: {
            followees: {
              self: "You are not following anyone at this time.",
              other: "{{name}} is not following anyone at this time."
            },
            followers: {
              self: "You do not have any followers at this time.",
              other: "{{name}} does not have any followers at this time."
            }
          },
          search: {
            placeholder: "Search for a person..."
          },
          filters: {
            groups: {
              title: "Select Group",
              followees: "Following",
              followers: "Followers"
            }
          }
        }
      }
    },
    account: {
      title: "My Account",
      general: {
        title: "General",
        description: "This information is used exclusively within Splife for personal, contact, and communication purposes.",
        name: {
          label: "Name",
          "firstName.label": "First Name",
          "lastName.label": "Last Name"
        },
        email: {
          label: "Email",
          primaryContact: "Primary Contact",
          description: "The primary contact address will be used to receive official Splife messages, such as account and password recovery.",
          actions: {
            add: "Add another email address"
          },
          modal: {
            newEmail: "New Email",
            error: "Email already taken.",
            save: "Save",
            cancel: "Cancel"
          }
        },
        phone: {
          label: "Phone"
        },
        gender: {
          label: "Gender"
        },
        shirt: {
          label: "Shirt Size"
        },
        password: {
          label: "Password",
          currentPassword: {
            label: "Current Password"
          },
          password: {
            label: "New Password"
          },
          passwordConfirmation: {
            label: "Re-type New Password"
          }
        },
        birthday: {
          label: "Birthday"
        },
        distance: {
          label: "Distance"
        }
      },
      notificationSettings: {
        title: "Notifications",
        fullTitle: "Notification preferences",
        labels: {
          atTimeOfEvent: "At time of event"
        }
      },
      language: {
        title: "Language"
      },
      communication: {
        title: "Notifications",
        description: "Adjust these preferences as desired for communication purposes.",
        push: {
          label: "On Splife",
          events: {
            label: "Events",
            description: "We should notify you when",
            participant: "I'm a participant of",
            following: "I'm following",
            friend: {
              participant: "A friend is a participant of",
              liveMention: "Live event friend mentions"
            }
          },
          network: {
            label: "Network",
            description: "We should notify you when",
            follower: "Followers",
            comments: "Comments",
            posting: "Live Posting",
            invites: "Accepted Event Invites"
          }
        },
        email: {
          label: "Email",
          events: {
            label: "Events",
            description: "We should notify you when",
            participant: "I'm a participant of",
            following: "I'm following",
            friend: {
              participant: "A friend is a participant of",
              liveMention: "Live event friend mentions"
            }
          },
          network: {
            label: "Network",
            description: "We should notify you when",
            follower: "Followers",
            comments: "Comments",
            posting: "Live Posting",
            invites: "Accepted Event Invites"
          }
        }
      },
      registration: {
        title: "Payment & Registration"
      }
    },
    404: {
      title: "404",
      description: "The page you were looking for is missing or does not exist.",
      label: "Please take a moment to use the navigation above."
    },
    500: {
      title: "500",
      description: "Something went wrong on our end. Don't worry it's not you, it's us.",
      label: "Please use the navigation above, or, try again in just a moment."
    },
    report: {
      reportAbuse: "Report Abuse",
      reportPrompt: {
        title: "Report Abuse",
        confirm: "Yes",
        cancel: "No"
      }
    },
    validations: {
      present: "Must be present",
      date: "Must be a valid date",
      email: "Must be a valid email address",
      phone: "Must be a valid phone number",
      tooLong: "Too long (maximum is {max} characters)",
      tooShort: "Too short (must be at least {min} characters)",
      confirmation: "Must match {{field}}",
      emailTaken: "Email already taken",
      password: "password",
      incorrectPassword: "Password is incorrect"
    },
    scrapbook: {
      landing: {
        finished: "Finished",
        scrapbook: "Scrapbook",
        share: "Share Scrapbook"
      },
      timeline: {
        title: "TIMELINE"
      },
      results: {
        title: "RESULTS"
      },
      gallery: {
        title: "GALLERY"
      },
      show: {
        timeline: {
          feed: {
            title: "Timeline Feed",
            placeholders: {
              post: {
                self: "What's on your mind?",
                other: "What's on your mind?"
              }
            },
            empty: {
              title: {
                self: "Your timeline is nearly empty."
              },
              description: {
                self: "Take a moment to edit and enhance your profile.",
                other: "There are no posts in {{name}}'s feed."
              }
            }
          }
        },
        results: {
          actions: {
            edit: "Edit",
            save: "Save"
          },
          info: {
            official_results: "Official results",
            title: "Basic Info",
            status: "Status",
            race: "Race Time",
            placement: "Placement",
            distance: "Distance",
            pace: "Pace",
            overall: "Overall",
            empty: "Nothing to show",
            enter_info: "Enter info"
          },
          splits: {
            title: "Time Splits",
            timeSplits: "Time splits",
            paceSplits: "Pace splits",
            pace: "Pace",
            split: "Split",
            time: "Time",
            distance: "Distance",
            rate: "Rate",
            kmShort: "km",
            mileShort: "mi"
          },
          rating: {
            title: "Rating",
            placeholder: "Enter review",
            rateEvent: "Rate Event",
            star: "&#9734"
          },
          journal: {
            title: "Journal",
            placeholder: "Compose journal",
            compose: "Compose"
          }
        }
      }
    }
  };
});