define("social-web/pods/components/media-gallery/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 4,
              "column": 0
            }
          },
          "moduleName": "social-web/pods/components/media-gallery/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h4");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element4, 'class');
          morphs[1] = dom.createMorphAt(element4, 0, 0);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["section-header ", ["get", "titleClass", ["loc", [null, [3, 30], [3, 40]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "title", ["loc", [null, [3, 44], [3, 53]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 2
            },
            "end": {
              "line": 12,
              "column": 2
            }
          },
          "moduleName": "social-web/pods/components/media-gallery/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1, "class", "btn-floating btn-large waves-effect waves-light red right");
          var el2 = dom.createTextNode("\n      +");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element3, 'href');
          morphs[1] = dom.createMorphAt(element3, 1, 1);
          return morphs;
        },
        statements: [["attribute", "href", ["subexpr", "get", [["subexpr", "object-at", [["subexpr", "inc", [["get", "maxMedia", ["loc", [null, [9, 33], [9, 41]]], 0, 0, 0, 0]], [], ["loc", [null, [9, 28], [9, 42]]], 0, 0], ["get", "media", ["loc", [null, [9, 43], [9, 48]]], 0, 0, 0, 0]], [], ["loc", [null, [9, 17], [9, 49]]], 0, 0], "fileOrig"], [], ["loc", [null, [null, null], [9, 62]]], 0, 0], 0, 0, 0, 0], ["inline", "dec", [["get", "maxMedia", ["loc", [null, [10, 13], [10, 21]]], 0, 0, 0, 0], ["get", "media.length", ["loc", [null, [10, 22], [10, 34]]], 0, 0, 0, 0]], [], ["loc", [null, [10, 7], [10, 36]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    var child2 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 15,
                "column": 4
              },
              "end": {
                "line": 17,
                "column": 4
              }
            },
            "moduleName": "social-web/pods/components/media-gallery/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "yield", [["get", "media", ["loc", [null, [16, 14], [16, 19]]], 0, 0, 0, 0], ["get", "index", ["loc", [null, [16, 20], [16, 25]]], 0, 0, 0, 0], ["get", "shouldStretchLast", ["loc", [null, [16, 26], [16, 43]]], 0, 0, 0, 0]], [], ["loc", [null, [16, 6], [16, 45]]], 0, 0]],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 17,
                "column": 4
              },
              "end": {
                "line": 25,
                "column": 4
              }
            },
            "moduleName": "social-web/pods/components/media-gallery/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var element2 = dom.childAt(element1, [1]);
            var morphs = new Array(4);
            morphs[0] = dom.createAttrMorph(element1, 'class');
            morphs[1] = dom.createAttrMorph(element2, 'href');
            morphs[2] = dom.createAttrMorph(element2, 'data-owner');
            morphs[3] = dom.createMorphAt(element2, 1, 1);
            return morphs;
          },
          statements: [["attribute", "class", ["subexpr", "if", [["subexpr", "and", [["get", "shouldStretchLast", ["loc", [null, [18, 27], [18, 44]]], 0, 0, 0, 0], ["subexpr", "eq", [["subexpr", "dec", [["get", "limitedMedia.length", ["loc", [null, [18, 54], [18, 73]]], 0, 0, 0, 0]], [], ["loc", [null, [18, 49], [18, 74]]], 0, 0], ["get", "index", ["loc", [null, [18, 75], [18, 80]]], 0, 0, 0, 0]], [], ["loc", [null, [18, 45], [18, 81]]], 0, 0]], [], ["loc", [null, [18, 22], [18, 82]]], 0, 0], "col s12", ["get", "columnClass", ["loc", [null, [18, 93], [18, 104]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [18, 106]]], 0, 0], 0, 0, 0, 0], ["attribute", "href", ["get", "media.fileOrig", ["loc", [null, [19, 18], [19, 32]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "data-owner", ["get", "media.ownerKey", ["loc", [null, [19, 48], [19, 62]]], 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "lazy-background-image", [], ["class", "img-wrap", "url", ["subexpr", "@mut", [["get", "media.fileMed", ["loc", [null, [21, 38], [21, 51]]], 0, 0, 0, 0]], [], [], 0, 0], "fallbackUrl", ["subexpr", "@mut", [["get", "mediaService.defaults.mediaPlaceholder", ["loc", [null, [22, 46], [22, 84]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [20, 10], [22, 86]]], 0, 0]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 14,
              "column": 2
            },
            "end": {
              "line": 26,
              "column": 2
            }
          },
          "moduleName": "social-web/pods/components/media-gallery/template.hbs"
        },
        isEmpty: false,
        arity: 2,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "hasBlock", ["loc", [null, [15, 10], [15, 18]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [15, 4], [25, 11]]]]],
        locals: ["media", "index"],
        templates: [child0, child1]
      };
    }();
    var child3 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 28,
              "column": 2
            },
            "end": {
              "line": 30,
              "column": 2
            }
          },
          "moduleName": "social-web/pods/components/media-gallery/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1, "class", "hide");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element0, 'href');
          morphs[1] = dom.createAttrMorph(element0, 'data-owner');
          return morphs;
        },
        statements: [["attribute", "href", ["get", "media.fileOrig", ["loc", [null, [29, 27], [29, 41]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "data-owner", ["get", "media.ownerKey", ["loc", [null, [29, 57], [29, 71]]], 0, 0, 0, 0], 0, 0, 0, 0]],
        locals: ["media"],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 34,
            "column": 0
          }
        },
        "moduleName": "social-web/pods/components/media-gallery/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element5 = dom.childAt(fragment, [3]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        morphs[1] = dom.createMorphAt(element5, 1, 1);
        morphs[2] = dom.createMorphAt(element5, 3, 3);
        morphs[3] = dom.createMorphAt(element5, 5, 5);
        morphs[4] = dom.createMorphAt(element5, 7, 7);
        return morphs;
      },
      statements: [["block", "if", [["get", "title", ["loc", [null, [2, 6], [2, 11]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [2, 0], [4, 7]]]], ["block", "if", [["get", "hasExtra", ["loc", [null, [7, 8], [7, 16]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [7, 2], [12, 9]]]], ["block", "each", [["get", "limitedMedia", ["loc", [null, [14, 10], [14, 22]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [14, 2], [26, 11]]]], ["block", "each", [["subexpr", "without", [["get", "limitedMedia", ["loc", [null, [28, 19], [28, 31]]], 0, 0, 0, 0], ["get", "media", ["loc", [null, [28, 32], [28, 37]]], 0, 0, 0, 0]], [], ["loc", [null, [28, 10], [28, 38]]], 0, 0]], [], 3, null, ["loc", [null, [28, 2], [30, 11]]]], ["inline", "yield", [], ["to", "inverse"], ["loc", [null, [32, 2], [32, 24]]], 0, 0]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  }());
});