define('social-web/pods/components/live-video/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.componentId = 'transmission-' + this.id;
    },
    didRender: function didRender() {
      if (Hls.isSupported()) {
        var hls = new Hls();
        var video = document.getElementById(this.componentId);

        hls.loadSource(this.hlsAddress);
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
          Ember.Logger.log('manifest loaded, found ' + data.levels.length + ' quality level');
          Ember.Logger.log(data.levels);
        });
      }
    },


    id: null,
    componentId: null,
    hlsAddress: null
  });
});