define('social-web/mirage/serializers/status-update', ['exports', 'social-web/mirage/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    include: ['media', 'latest-comment'],
    normalize: function normalize() /*payload*/{
      var json = _application.default.prototype.normalize.apply(this, arguments);
      json.data.attributes.votesCount = 0;
      json.data.attributes.commentsCount = 0;
      json.data.attributes.type = 'StatusUpdate';
      json.data.relationships.user = { data: { type: 'users', id: '1' } };
      return json;
    }
  });
});