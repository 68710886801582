define('social-web/pods/components/signin-form/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    session: Ember.inject.service(),

    classNames: ['ui', 'raised', 'segments'],

    init: function init() {
      this._super.apply(this, arguments);
      this.set('model', Ember.Object.create({
        remember: true,
        email: '',
        password: ''
      }));
    },

    actions: {
      submit: function submit(model) {
        var _this = this;

        this.set('loading', true);
        return this.get('session').authenticate('authenticator:application', model.email, model.password).then(function (model) {
          window.Materialize.toast(_this.get('i18n').t('common.auth.signin.success'), 2000);
          if (_this.attrs.onSuccess) {
            _this.attrs.onSuccess(model);
          }
        }, function () {
          window.Materialize.toast(_this.get('i18n').t('common.auth.signin.error'), 5000, 'red');
        }).finally(function () {
          _this.set('loading', false);
        });
      }
    }
  });
});