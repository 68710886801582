define('social-web/pods/invite/adapter', ['exports', 'social-web/pods/application/adapter'], function (exports, _adapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _adapter.default.extend({

    createInvite: function createInvite(eventId) {
      return this.ajax(this.buildURL('invite'), 'POST', { data: { data: { attributes: { event_id: eventId } } } });
    },
    inviteProcess: function inviteProcess(model) {
      return this.ajax(this.buildURL(model.constructor.modelName, model.id, null, null, {
        scope: { model: model, action: 'process' }
      }), 'POST');
    }
  });
});