define('social-web/tests/mirage/mirage/factories/status-update', ['exports', 'ember-cli-mirage', 'social-web/mirage/factories/application'], function (exports, _emberCliMirage, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    content: function content() {
      return _emberCliMirage.faker.lorem.paragraph();
    },
    votesCount: function votesCount() {
      return _emberCliMirage.faker.random.number(5);
    },

    commentsCount: 0,
    publishedAt: function publishedAt() {
      return _emberCliMirage.faker.date.past(1);
    },

    type: 'StatusUpdate',
    meta: {
      time: '00:00:00',
      distance: '1 mi'
    },
    pinned: false,

    currentUserVoted: _emberCliMirage.faker.random.boolean,

    afterCreate: function afterCreate(statusUpdate, server) {
      statusUpdate.commentsCount = statusUpdate.commentsCount || statusUpdate.comments.models.length;

      if (!statusUpdate.publisher) {
        statusUpdate.publisher = server.create('user');
      }
      if (!statusUpdate.votesCount) {
        statusUpdate.currentUserVoted = false;
      }
      if (!statusUpdate.user) {
        statusUpdate.user = server.schema.users.where({ current: true }).models[0];
      }

      statusUpdate.save();
    },


    withEdition: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(statusUpdate, server) {
        if (!statusUpdate.edition) {
          statusUpdate.edition = server.create('edition');
          statusUpdate.event = statusUpdate.edition.event;
        }
        statusUpdate.save();
      }
    }),

    withMedia: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(statusUpdate, server) {
        var mediaCount = statusUpdate.mediaCount || _emberCliMirage.faker.random.number(5);
        statusUpdate.media = server.createList('media', mediaCount, { owner: statusUpdate });
        statusUpdate.save();
      }
    }),

    withComments: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(statusUpdate, server) {
        var commentsCount = statusUpdate.commentsCount || _emberCliMirage.faker.random.number(5);
        statusUpdate.comments = server.createList('comment', commentsCount, { statusUpdate: statusUpdate });
        statusUpdate.latestComment = statusUpdate.comments.models.sortBy('publishedAt').get('firstObject');
        statusUpdate.commentsCount = statusUpdate.comments.models.length;
        statusUpdate.save();
      }
    }),

    withOwnComments: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(statusUpdate, server) {
        var commentsCount = statusUpdate.commentsCount || _emberCliMirage.faker.random.number(5);
        statusUpdate.comments = server.createList('comment', commentsCount, { statusUpdate: statusUpdate, publisher: statusUpdate.publisher });
        statusUpdate.latestComment = statusUpdate.comments.models.sortBy('publishedAt').get('firstObject');
        statusUpdate.commentsCount = statusUpdate.comments.models.length;
        statusUpdate.save();
      }
    }),

    withMentions: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(statusUpdate, server) {
        var mentionsCount = !Ember.isNone(statusUpdate.mentionsCount) ? statusUpdate.mentionsCount : _emberCliMirage.faker.random.number(4),
            userMentionsCount = !Ember.isNone(statusUpdate.userMentionsCount) ? statusUpdate.userMentionsCount : _emberCliMirage.faker.random.number(2),
            followedMentionsCount = !Ember.isNone(statusUpdate.followedMentionsCount) ? statusUpdate.followedMentionsCount : _emberCliMirage.faker.random.number(2);
        var mentions = server.createList('mention', mentionsCount);
        for (var i = userMentionsCount; i > 0; i--) {
          mentions.push(server.create('mention', {
            participation: server.create('participation', {
              user: server.create('user', {
                currentUserFollowing: false
              })
            })
          }));
        }
        for (var _i = followedMentionsCount; _i > 0; _i--) {
          mentions.push(server.create('mention', {
            participation: server.create('participation', {
              user: server.create('user', {
                currentUserFollowing: true
              })
            })
          }));
        }
        statusUpdate.mentions = mentions;
        statusUpdate.save();
      }
    })
  });
});