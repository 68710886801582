define('social-web/pods/split/adapter', ['exports', 'social-web/pods/application/adapter', 'social-web/config/environment'], function (exports, _adapter, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _adapter.default.extend({
    host: _environment.default.scoringDomain,
    pathForType: function pathForType(type) {
      return type.pluralize();
    }
  });
});