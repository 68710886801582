define('social-web/authenticators/application', ['exports', 'ember-simple-auth/authenticators/oauth2-password-grant'], function (exports, _oauth2PasswordGrant) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _oauth2PasswordGrant.default.extend({
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),

    refreshAccessTokens: true,
    serverTokenEndpoint: '/oauth/token',
    serverTokenRevocationEndpoint: '/oauth/revoke',

    restore: function restore(data) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this._super(data).then(function (authHash) {
          var headers = { Authorization: 'Bearer ' + authHash.access_token };
          _this.getUserToken(headers).then(function (userToken) {
            _this.setCurrentUser(userToken);
            resolve(authHash);
          }, reject);
        }, reject);
      });
    },
    authenticate: function authenticate() {
      var _arguments = arguments,
          _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var promise = void 0;

        if (_arguments.length === 1) {
          promise = _this2.tokenAuthenticate.apply(_this2, _arguments);
        } else if (_arguments.length === 2) {
          promise = _this2.passwordAuthenticate(_this2._super, _arguments[0], _arguments[1]);
        } else {
          reject();
        }

        promise.then(function (model) {
          var headers = { Authorization: 'Bearer ' + model.access_token, 'Content-Type': 'application/json' };

          return _this2.recordUserLocale(headers).then(function (user) {
            _this2.setCurrentUser(user);

            return model;
          });
        }).then(resolve, reject);
      });
    },
    invalidate: function invalidate() {
      this.set('session.currentUser');
      return this._super.apply(this, arguments);
    },
    passwordAuthenticate: function passwordAuthenticate(parentAuthenticate, username, password) {
      var _this3 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        parentAuthenticate.apply(_this3, [username, password]).then(function (authHash) {
          var headers = { Authorization: 'Bearer ' + authHash.access_token };
          _this3.getUserToken(headers).then(function (userToken) {
            var authHash = _this3.formatAuthHash(userToken);
            _this3.setCurrentUser(userToken);
            resolve(authHash);
          }, reject);
        }, reject);
      });
    },
    tokenAuthenticate: function tokenAuthenticate(accessToken) {
      var _this4 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var headers = { Authorization: 'Bearer ' + accessToken };
        _this4.getUserToken(headers).then(function (userToken) {
          var authHash = _this4.formatAuthHash(userToken);
          _this4.setCurrentUser(userToken);
          resolve(authHash);
        }, reject);
      });
    },
    formatAuthHash: function formatAuthHash(tokenData) {
      return tokenData.data && tokenData.data.attributes ? {
        expires_in: tokenData.data.attributes['token-expires-in'],
        expires_at: this._absolutizeExpirationTime(tokenData.data.attributes['token-expires-in']),
        access_token: tokenData.data.attributes['access-token'],
        refresh_token: tokenData.data.attributes['refresh-token']
      } : null;
    },
    setCurrentUser: function setCurrentUser(payload) {
      var store = this.get('store');
      store.serializerFor('user')._normalizeDocumentHelper(payload);
      try {
        var user = store.push(payload);
        this.set('session.currentUser', user);
      } catch (e) {
        //consume
      }
    },
    getUserToken: function getUserToken() {
      var headers = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      return this.get('ajax').request('/api/v2/users/current', { headers: headers });
    },
    recordUserLocale: function recordUserLocale() {
      var headers = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var user = this.get('session.currentUser');
      var data = {
        data: {
          attributes: {
            locale: navigator.language || 'en-US'
          }
        }
      };

      return this.get('ajax').request('/api/v2/users/' + user.id, {
        data: JSON.stringify(data),
        method: 'patch',
        contentType: 'application/json',
        headers: headers
      });
    }
  });
});