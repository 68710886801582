define('social-web/mirage/models/status-update', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Model.extend({
    publisher: (0, _emberCliMirage.belongsTo)('user'),

    user: (0, _emberCliMirage.belongsTo)(), // not a real concept in the API
    event: (0, _emberCliMirage.belongsTo)(),
    edition: (0, _emberCliMirage.belongsTo)(),

    media: (0, _emberCliMirage.hasMany)(),
    comments: (0, _emberCliMirage.hasMany)('comment', { inverse: 'statusUpdate' }),
    mentions: (0, _emberCliMirage.hasMany)('mention'),
    latestComment: (0, _emberCliMirage.belongsTo)('comment'),

    voteData: (0, _emberCliMirage.belongsTo)('vote-data')
  });
});