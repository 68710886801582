define('social-web/transitions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    var _this = this;

    this.transition(this.hasClass('expander'), this.toValue(true), this.use('fade', { duration: 250 }), this.reverse('fade', { duration: 250 }));

    ['info', 'sports', 'quote', 'bio'].forEach(function (leaf) {
      _this.transition(_this.fromRoute('user.show.profile.index'), _this.toRoute('user.show.profile.' + leaf), _this.use('toLeft'), _this.reverse('toRight'));
    });

    var accountLeafRoutes = ['index', 'general', 'language', 'communication', 'notification-settings', 'registration'];
    var generalLeafRoutes = ['name', 'birthday', 'email', 'phone', 'gender', 'password', 'shirt', 'distance'];
    accountLeafRoutes.forEach(function (branch) {
      accountLeafRoutes.forEach(function (leaf) {
        if (branch !== leaf) {
          _this.transition(_this.fromRoute('account.' + branch), _this.toRoute('account.' + leaf), _this.use('toLeft'), _this.reverse('toRight'));
        }
      });

      generalLeafRoutes.forEach(function (leaf) {
        _this.transition(_this.fromRoute('account.general.index'), _this.toRoute('account.general.' + leaf), _this.use('toLeft'), _this.reverse('toRight'));
      });
    });

    var aboutLeafRoutes = ['competitions'];
    aboutLeafRoutes.forEach(function (leaf) {
      _this.transition(_this.fromRoute('event.show.edition.show.about.index'), _this.toRoute('event.show.edition.show.about.' + leaf), _this.use('toLeft'), _this.reverse('toRight'));
    });

    this.transition(this.fromRoute('onboarding.signin'), this.toRoute('onboarding.signup'), this.use('crossFade', { duration: 250 }), this.reverse('crossFade', { duration: 250 }));
    this.transition(this.fromRoute('onboarding.signin'), this.toRoute('onboarding.forgot'), this.use('crossFade', { duration: 250 }), this.reverse('crossFade', { duration: 250 }));
  };
});