define('social-web/pods/split/serializer', ['exports', 'social-web/pods/application/serializer'], function (exports, _serializer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _serializer.default.extend({
    normalizeQueryResponse: function normalizeQueryResponse() {
      var json = this._super.apply(this, arguments);
      json.data.forEach(function (d) {
        d.type = 'split';
      });
      return json;
    }
  });
});