define('social-web/pods/components/inline-lazy-image/component', ['exports', 'social-web/mixins/lazy-image', 'ember-in-viewport'], function (exports, _lazyImage, _emberInViewport) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_emberInViewport.default, _lazyImage.default, {
    tagName: 'img',
    attributeBindings: ['lazySrc:src', 'alt'],
    init: function init() {
      this._super.apply(this, arguments);
      this.set('lazySrc', this.get('fallbackUrl'));
    },
    onLoad: function onLoad(src) {
      this.fadeSrc(src);
    },
    onError: function onError() {
      this.fadeSrc(this.get('fallbackUrl'));
    },
    fadeSrc: function fadeSrc(src) {
      if (!src || src === this.get('lazySrc')) {
        return;
      }

      var component = this;
      var element = this.$();
      if (element) {
        element.transition({
          animation: 'fade out',
          duration: 100,
          onComplete: function onComplete() {
            Ember.run(function () {
              component.set('lazySrc', src);
            });
            if (element) {
              element.transition({
                animation: 'fade in',
                duration: 100
              });
            }
          }
        });
      }
    }
  });
});