define('social-web/tests/mirage/mirage/factories/media', ['exports', 'ember-cli-mirage', 'social-web/mirage/factories/application'], function (exports, _emberCliMirage, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    fileOrig: function fileOrig() {
      return _emberCliMirage.faker.image.sports();
    },
    fileMed: function fileMed() {
      return _emberCliMirage.faker.image.sports();
    },
    afterCreate: function afterCreate(media, server) {
      if (!media.owner) {
        media.owner = server.create('status-update');
      }
      media.save();
    },


    video: (0, _emberCliMirage.trait)({
      //eventually
    })
  });
});