define('social-web/pods/group/index/route', ['exports', 'social-web/mixins/refreshable-infinity-route'], function (exports, _refreshableInfinityRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_refreshableInfinityRoute.default, {
    ht: Ember.inject.service('head-tags'),
    i18n: Ember.inject.service(),
    media: Ember.inject.service(),

    titleToken: function titleToken() {
      return this.get('i18n').t('groups.index.title');
    },
    headTags: function headTags() {
      var i18n = this.get('i18n');
      return this.get('ht').getBasicHeadTags({
        title: i18n.t('groups.index.title'),
        description: i18n.t('groups.index.description'),
        image: this.get('groups.logo.small')
      });
    },


    modelName: 'group',
    modelParams: function modelParams() {
      return {
        perPage: 24,
        trending: true
      };
    },
    model: function model(params) {

      Ember.Logger.log('group.index.route.js .model params', params);

      return this.getInfinityModel(params);
    }
  });
});