define('social-web/pods/user/index/route', ['exports', 'social-web/mixins/refreshable-infinity-route'], function (exports, _refreshableInfinityRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_refreshableInfinityRoute.default, {
    ht: Ember.inject.service('head-tags'),
    i18n: Ember.inject.service(),
    follow: Ember.inject.service(),
    media: Ember.inject.service(),

    titleToken: function titleToken() {
      return this.get('i18n').t('user.index.title');
    },
    headTags: function headTags() {
      var i18n = this.get('i18n');
      return this.get('ht').getBasicHeadTags({
        title: i18n.t('user.index.title'),
        description: i18n.t('user.index.description'),
        image: this.get('media.logo.small')
      });
    },


    modelName: 'user',
    modelParams: function modelParams() {
      return {
        perPage: 24,
        trending: true
      };
    },
    model: function model(params) {
      return this.getInfinityModel(params);
    },
    afterInfinityModel: function afterInfinityModel(users) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve) {
        _this.get('follow').checkCurrentUserFollowedUsers(users).then(function () {
          resolve(users);
        });
      });
    }
  });
});