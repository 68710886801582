define('social-web/pods/user/serializer', ['exports', 'social-web/pods/application/serializer'], function (exports, _serializer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _serializer.default.extend({
    attrs: {
      customPictureThumb: { serialize: false },
      customPictureMed: { serialize: false },
      coverPhoto: { serialize: false },
      followersCount: { serialize: false },
      statusUpdatesCount: { serialize: false },

      //TODO: remove me
      shirtSize: { serialize: false },
      distance: { serialize: false }
    }
  });
});