define('social-web/pods/components/group-member-button/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['display-inline-block'],
    session: Ember.inject.service(),
    store: Ember.inject.service(),

    didInsertElement: function didInsertElement() {},


    actions: {
      forceLogin: function forceLogin() {
        var _this = this;

        return new Ember.RSVP.Promise(function (resolve, reject) {
          if (_this.get('session.isAuthenticated')) {
            resolve();
          } else {
            _this.set('session.loginModalVisible', true);
            reject();
          }
        });
      },
      triggerRequestToBeMember: function triggerRequestToBeMember() {
        this.get('group-member')();
      },
      triggerLeaveGroup: function triggerLeaveGroup() {
        this.get('group-member')();
      }
    }
  });
});