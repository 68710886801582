define('social-web/pods/search/user/route', ['exports', 'social-web/mixins/refreshable-infinity-route'], function (exports, _refreshableInfinityRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_refreshableInfinityRoute.default, {
    i18n: Ember.inject.service(),
    follow: Ember.inject.service(),

    titleToken: function titleToken(model) {
      return this.get('i18n').t('search.user.title', { count: model.get('meta.count') });
    },

    modelName: 'user',
    modelParams: function modelParams(params, transition) {
      var search = Ember.get(params, 'search') || Ember.get(transition, 'queryParams.search');
      return {
        per_page: 24,
        search: search
      };
    },
    afterInfinityModel: function afterInfinityModel(users) {
      var _this = this;

      if (this.get('controller')) {
        this.set('controller.currentSearch', this.get('controller.search'));
      }
      return new Ember.RSVP.Promise(function (resolve) {
        _this.get('follow').checkCurrentUserFollowedUsers(users).then(function () {
          resolve(users);
        });
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('currentSearch', this.get('_extraParams.search'));
    }
  });
});