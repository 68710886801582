define('social-web/pods/account/general/email/route', ['exports', 'social-web/mixins/mobile-leaf-route'], function (exports, _mobileLeafRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_mobileLeafRoute.default, {
    actions: {
      save: function save(handler) {
        var _this = this;

        return handler().then(function () {
          _this.send('transitionToParent');
        });
      }
    }
  });
});