define('social-web/pods/event/show/edition/show/results/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    store: Ember.inject.service(),

    queryParams: {
      competitionID: {},
      currentSearch: 'search'
    },
    competitionID: null,
    currentSearch: '',
    search: '',
    splits: Ember.A(),
    currentSplits: null,

    competitionUpdated: Ember.observer('competitionID', function () {
      var currentCompetitionID = this.get('competition.id'),
          newCompetitionID = this.get('competitionID');
      if (currentCompetitionID !== undefined && newCompetitionID !== currentCompetitionID) {
        this.set('competition', this.get('store').peekRecord('competition', newCompetitionID || this.get('competitions.firstObject.id')));
      }
    }),

    searchParams: Ember.computed('model.edition', function () {
      return {
        scope: {
          model: this.get('model.edition'),
          action: 'competitions'
        }
      };
    })
  });
});