define('social-web/pods/components/user-bubble-list/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    media: Ember.inject.service(),

    users: [],
    maxBubbles: 8,

    isVisible: Ember.computed.bool('total'),
    hasExtra: Ember.computed.gt('extra', 0),
    extra: Ember.computed('users', 'total', 'maxBubbles', function () {
      return this.get('total') - Math.min(this.get('users.length'), this.get('maxBubbles'));
    }),

    init: function init() {
      if (!this.attrs.total) {
        this.set('total', this.get('users.length'));
      }
      this._super.apply(this, arguments);
    }
  });
});