define('social-web/pods/onboarding/recovery/route', ['exports', 'social-web/pods/password/validations'], function (exports, _validations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    i18n: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),

    model: function model(params, transition) {
      return this.store.createRecord('password', {
        resetPasswordToken: transition.queryParams.reset_password_token
      });
    },
    redirect: function redirect(model, transition) {
      if (!(transition && transition.queryParams.reset_password_token)) {
        window.Materialize.toast(this.get('i18n').t('common.auth.recovery.noToken'));
        this.replaceWith('index');
      }
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      this.controllerFor('application').set('innerAppLayout', false);
      controller.set('validations', _validations.validations);
    },


    actions: {
      submit: function submit(changeset, model) {
        var _this = this;

        this.set('controller.loading', true);
        changeset.validate().then(function () {
          if (changeset.get('isValid')) {
            _this.set('controller.loading', true);
            changeset.execute();
            var data = model.serialize();
            _this.get('ajax').put('/api/v2/authentication/passwords', { data: data }).then(function (userToken) {
              var accessToken = userToken.data.attributes['access-token'];
              _this.get('session').authenticate('authenticator:application', accessToken).then(function () {
                window.Materialize.toast(_this.get('i18n').t('common.auth.signin.success'), 2000);
                _this.transitionTo('feed');
              });
            }, function () {
              window.Materialize.toast(_this.get('i18n').t('common.auth.recovery.error'), 5000, 'red');
            });
          }
        }).finally(function () {
          _this.set('controller.loading', false);
        });
      },
      willTransition: function willTransition() {
        var model = this.get('controller.model');
        if (model) {
          model.unloadRecord();
        }
        return true;
      }
    }
  });
});