define('social-web/pods/password/adapter', ['exports', 'social-web/pods/application/adapter'], function (exports, _adapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _adapter.default.extend({
    namespace: 'api/v2/authentication',
    urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
      var host = this.get('host') || '/',
          namespace = this.get('namespace');
      return '' + host + namespace + '/registrations/' + snapshot.adapterOptions.registrationID;
    }
  });
});