define('social-web/pods/search/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      openModal: function openModal(name) {
        Ember.$('.ui.' + name + '.modal').modal('show');
      }
    }
  });
});