define('social-web/pods/cause/model', ['exports', 'ember-data/attr', 'ember-data/relationships', 'social-web/pods/application/model'], function (exports, _attr, _relationships, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    display: Ember.computed.alias('name'),
    name: (0, _attr.default)('string'),
    sortPriority: (0, _attr.default)('number'),
    url: (0, _attr.default)('string'),
    file: (0, _attr.default)('string'),
    causeCategory: (0, _relationships.belongsTo)()
  });
});