define('social-web/pods/components/signup-form/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),

    classNames: ['ui', 'raised', 'segments'],

    init: function init() {
      this._super.apply(this, arguments);
      this.set('model', this.get('store').createRecord('registration'));
    },
    willDestroyElement: function willDestroyElement() {
      if (this.get('model.isNew')) {
        this.get('model').unloadRecord();
      }
    },

    actions: {
      submit: function submit(changeset) {
        var _this = this;

        this.set('loading', true);
        changeset.set('passwordConfirmation', changeset.get('password'));
        changeset.validate().then(function () {
          if (changeset.get('isValid')) {
            changeset.save().then(function () {
              _this.get('session').authenticate('authenticator:application', changeset.get('email'), changeset.get('password')).then(function (model) {
                window.Materialize.toast(_this.get('i18n').t('common.auth.signup.success'), 5000);
                if (_this.attrs.onSuccess) {
                  _this.attrs.onSuccess(model);
                }
              });
            }, function (_ref) {
              var errors = _ref.errors;

              if (errors && errors.length && errors[0].detail === 'has already been taken') {
                changeset.pushErrors('email', _this.get('i18n').t('validations.emailTaken'));
              }
            });
          }
        }).finally(function () {
          _this.set('loading', false);
        });
      }
    }
  });
});