define('social-web/tests/mirage/mirage/factories/notification', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    message: function message() {
      return _emberCliMirage.faker.lorem.paragraph();
    },

    seen: false,
    read: false,
    editionId: null,
    eventId: null,
    type: function type(n) {
      return _emberCliMirage.faker.list.random('UserYouFollowIsMentionedNotification', 'NewCommentNotification', 'LikeNotification', 'PinnedPostNotification', 'NewPostMentionNotification', 'UserPostsResultsNotification', 'UserYouFollowPostsNotification', 'UserJoinsCompetitionNotification', 'InterimResultsNotification', 'FollowedByNotification', 'InviteeAcceptedNotification')(n);
    },
    afterCreate: function afterCreate(notification, server) {
      if (!notification.statusUpdate) {
        notification.statusUpdate = server.create('status-update');
      }
      if (!notification.user) {
        notification.user = server.schema.users.where({ current: true }).models[0];
      }
      if (!notification.notificator) {
        notification.notificator = server.create('user');
      }
      if (!notification.eventId || !notification.editionId) {
        var edition = server.create('edition');
        notification.editionId = edition.id;
        notification.eventId = edition.event.id;
      }
      notification.save();
    }
  });
});