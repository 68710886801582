define('social-web/pods/components/forgot-form/component', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    ajax: Ember.inject.service(),

    classNames: ['ui', 'raised', 'segments'],

    init: function init() {
      this._super.apply(this, arguments);
      this.set('model', Ember.Object.create({
        email: ''
      }));
      this.set('validations', {
        email: (0, _validators.validateFormat)({ type: 'email' })
      });
    },

    actions: {
      submit: function submit(changeset, model) {
        var _this = this;

        this.set('loading', true);
        changeset.validate().then(function () {
          if (changeset.get('isValid')) {
            changeset.execute();
            var data = {
              data: { attributes: { email: model.get('email') } }
            };
            return _this.get('ajax').post('/api/v2/authentication/passwords', { data: data }).then(function (response) {
              window.Materialize.toast(_this.get('i18n').t('common.auth.forgot.success'));
              if (_this.attrs.onSuccess) {
                _this.attrs.onSuccess(response);
              }
            }).catch(function () {
              window.Materialize.toast(_this.get('i18n').t('common.auth.forgot.error'), 5000, 'red');
            });
          }
        }).finally(function () {
          _this.set('loading', false);
        });
      }
    }
  });
});