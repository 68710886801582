define('social-web/pods/components/file-upload/component', ['exports', 'ember-uploader'], function (exports, _emberUploader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Uploader = _emberUploader.default.Uploader.extend({
    paramNamespace: 'data[attributes]',
    ajaxPromise: function ajaxPromise(settings) {
      var didUpload = settings.success,
          didError = settings.error;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        settings.success = function (data, responseText, jqXHR) {
          Ember.run(null, resolve, didUpload(data, responseText, jqXHR));
        };
        settings.error = function (jqXHR /*, error, statusText*/) {
          Ember.run(null, reject, didError(jqXHR));
        };

        Ember.$.ajax(settings);
      });
    }
  });

  exports.default = _emberUploader.default.FileField.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    adapter: Ember.computed(function () {
      return this.get('store').adapterFor(this.get('modelName'));
    }),

    modelName: 'media',
    multiple: true,
    url: Ember.computed('parent', function () {
      var parent = this.get('parent');
      var scope = parent ? { model: parent, action: this.get('modelName').underscore() } : null;
      return this.get('adapter').buildURL(this.get('modelName'), null, null, { scope: scope });
    }),

    clearInput: function clearInput() {
      this.$().val(null);
    },
    change: function change(e) {
      var _this = this;

      var files = e.target.data || e.target.files;
      if (!Ember.isEmpty(files)) {
        var context = this;
        var uploader = Uploader.create({
          url: this.get('url'),
          ajaxSettings: {
            beforeSend: function beforeSend(xhr) {
              context.get('session').authorize('authorizer:application', function (key, value) {
                xhr.setRequestHeader(key, value);
              });
            },
            success: function success(payload /*, status, jqXHR*/) {
              if (!_this.get('isDestroyed')) {
                Ember.run(function () {
                  _this.set('isUploading', false);
                  uploader.destroy();
                  _this.clearInput();
                  if (_this.attrs.onFinish) {
                    _this.attrs.onFinish(payload);
                  }
                });
              }
            },
            error: function error(jqXHR) {
              if (!_this.get('isDestroyed')) {
                Ember.run(function () {
                  _this.set('isUploading', false);
                  uploader.destroy();
                  _this.clearInput();
                  if (_this.attrs.onError) {
                    _this.attrs.onError(jqXHR);
                  }
                });
              }
            }
          }
        });

        uploader.on('progress', function (e) {
          if (_this.attrs.onProgress) {
            _this.attrs.onProgress(e.percent);
          }
        });
        for (var i = files.length - 1; i >= 0; i--) {
          if (this.attrs.onUpload) {
            this.attrs.onUpload(files[i]);
          }
          uploader.upload(files[i]);
        }
      }
    }
  });
});