define('social-web/validations/messages', ['exports', 'social-web/pods/application/interface'], function (exports, _interface) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Messages = Ember.Object.extend({
    i18n: Ember.computed(function () {
      var service = _interface.default.instance.lookup('service:i18n');
      return service;
    }),
    present: Ember.computed('i18n.locale', function () {
      return this.get('i18n').t('validations.present').string;
    }),
    date: Ember.computed('i18n.locale', function () {
      return this.get('i18n').t('validations.date').string;
    }),
    email: Ember.computed('i18n.locale', function () {
      return this.get('i18n').t('validations.email').string;
    }),
    phone: Ember.computed('i18n.locale', function () {
      return this.get('i18n').t('validations.phone').string;
    }),
    tooLong: Ember.computed('i18n.locale', function () {
      return this.get('i18n').t('validations.tooLong').string;
    }),
    tooShort: Ember.computed('i18n.locale', function () {
      return this.get('i18n').t('validations.tooShort').string;
    }),
    confirmation: Ember.computed('i18n.locale', function () {
      return this.get('i18n').t('validations.confirmation', {
        field: this.get('i18n').t('validations.password').string
      }).string;
    })
  });

  exports.default = Messages.create();
});