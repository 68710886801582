define('social-web/pods/user-id/serializer', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPISerializer.extend({
    normalizeResponse: function normalizeResponse(store, modelClass, payload) {
      payload.data.id = '1';
      return this._super.apply(this, arguments);
    }
  });
});