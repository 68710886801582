define('social-web/pods/components/read-more-text/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    value: '',
    maxLength: 100,
    display: false,
    displayValue: Ember.computed('display', 'value', 'maxLength', function () {
      var value = this.get('value'),
          maxLength = this.get('maxLength'),
          display = this.get('display');
      return display || value.length <= maxLength ? value : value.slice(0, maxLength).trim() + '\u2026';
    })
  });
});