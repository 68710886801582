define('social-web/pods/scrapbook/show/results/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    i18n: Ember.inject.service(),
    distance: Ember.inject.service(),
    modelName: 'split',

    model: function model() {
      var _this = this;

      var _modelFor = this.modelFor('scrapbook.show'),
          user = _modelFor.user,
          feed = _modelFor.feed,
          scrapbook = _modelFor.scrapbook,
          edition = _modelFor.edition;

      return this.store.query('participation', {
        scope: {
          model: user,
          action: 'participations'
        },
        edition_id: edition.get('id')
      }).then(function (_ref) {
        var participations = _ref.content;

        return _this.store.query('split', {
          participation_id: participations.get('firstObject.id')
        }).then(function (_ref2) {
          var splits = _ref2.content;

          var transformResults = function transformResults(splits) {
            return splits.map(function (_ref3) {
              var id = _ref3.id,
                  _data = _ref3._data;
              var status = _data.status,
                  distanceInMeters = _data.distanceInMeters,
                  timeInSeconds = _data.timeInSeconds,
                  place = _data.place;

              var distance = _this.get('distance').distance(distanceInMeters, 'km');
              var rate = null;
              if (timeInSeconds && distance) {
                rate = Math.round(timeInSeconds * 1000 / distance);
              }
              return {
                id: id,
                status: status,
                distanceInMeters: distanceInMeters,
                timeInSeconds: timeInSeconds,
                distance: distance,
                rate: rate,
                place: place
              };
            });
          };
          return Ember.RSVP.hash({
            user: user,
            feed: feed,
            scrapbook: scrapbook,
            splits: transformResults(splits)
          });
        });
      });
    }
  });
});