define('social-web/pods/scrapbook/show/gallery/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var _modelFor = this.modelFor('scrapbook.show'),
          scrapbook = _modelFor.scrapbook,
          media = _modelFor.media;

      return new Ember.RSVP.hash({
        scrapbook: scrapbook,
        media: media
      });
    }
  });
});