define('social-web/pods/distance/service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    distance: function distance(distanceInMeters, distanceType) {
      if (distanceType === 'mi') {
        return Number((distanceInMeters / 1609.34).toFixed(2));
      } else if (distanceType === 'km') {
        return Number((distanceInMeters / 1000).toFixed(2));
      }
    }
  });
});