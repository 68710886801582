define('social-web/pods/notification-setting/model', ['exports', 'ember-data/attr', 'ember-data/relationships', 'social-web/pods/application/model'], function (exports, _attr, _relationships, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    accepts_email: (0, _attr.default)('boolean'),
    kind: (0, _attr.default)('string'),
    label: (0, _attr.default)('string'),

    user: (0, _relationships.belongsTo)('user', { async: true })
  });
});