define('social-web/pods/notification-setting/adapter', ['exports', 'social-web/pods/application/adapter'], function (exports, _adapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _adapter.default.extend({
    updateRecord: function updateRecord(store, type, record) {
      return this.ajax(this.buildURL(type.modelName), record.attr('accepts_email') ? 'POST' : 'DELETE', { data: { data: { attributes: { kind: record.attr('kind') } } } });
    }
  });
});