define('social-web/pods/components/facebook-button/component', ['exports', 'social-web/mixins/share-button-component'], function (exports, _shareButtonComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_shareButtonComponent.default, {
    i18n: Ember.inject.service(),

    svgFill: '#fff',
    shareUrl: 'https://www.facebook.com/sharer.php',
    windowTitle: Ember.computed(function () {
      return this.get('i18n').t('common.social.facebook');
    }),
    queryParams: Ember.computed('fullUrl', function () {
      return { u: this.get('fullUrl') };
    })
  });
});