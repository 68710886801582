define("social-web/pods/components/group-member-button/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 7,
                  "column": 10
                },
                "end": {
                  "line": 9,
                  "column": 10
                }
              },
              "moduleName": "social-web/pods/components/group-member-button/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "t", ["groups.show.IAmMember"], [], ["loc", [null, [8, 12], [8, 41]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 2
              },
              "end": {
                "line": 13,
                "column": 2
              }
            },
            "moduleName": "social-web/pods/components/group-member-button/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "no-left-padding no-right-padding");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var element3 = dom.childAt(element2, [1, 1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element2, 'class');
            morphs[1] = dom.createAttrMorph(element3, 'class');
            morphs[2] = dom.createMorphAt(element3, 1, 1);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["full-width padded no-left-padding ", ["subexpr", "unless", [["get", "media.isMobile", ["loc", [null, [4, 59], [4, 73]]], 0, 0, 0, 0], "display-inline-block", "center aligned no-right-padding"], [], ["loc", [null, [4, 50], [4, 132]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "class", ["concat", ["ui padded button-container ", ["subexpr", "unless", [["get", "media.isMobile", ["loc", [null, [6, 56], [6, 70]]], 0, 0, 0, 0], "no-left-padding", "centered"], [], ["loc", [null, [6, 47], [6, 101]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "ui-button", [["subexpr", "queue", [["subexpr", "action", ["forceLogin"], [], ["loc", [null, [7, 30], [7, 51]]], 0, 0], ["subexpr", "action", ["triggerLeaveGroup"], [], ["loc", [null, [7, 52], [7, 80]]], 0, 0]], [], ["loc", [null, [7, 23], [7, 81]]], 0, 0]], ["class", "ui fluid circular followed waves-effect participate"], 0, null, ["loc", [null, [7, 10], [9, 24]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      var child1 = function () {
        var child0 = function () {
          var child0 = function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 18,
                    "column": 12
                  },
                  "end": {
                    "line": 20,
                    "column": 12
                  }
                },
                "moduleName": "social-web/pods/components/group-member-button/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "t", ["groups.show.toBeMember"], [], ["loc", [null, [19, 14], [19, 44]]], 0, 0]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 14,
                  "column": 4
                },
                "end": {
                  "line": 24,
                  "column": 4
                }
              },
              "moduleName": "social-web/pods/components/group-member-button/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "no-left-padding no-right-padding");
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              var el4 = dom.createTextNode("\n");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("          ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var element1 = dom.childAt(element0, [1, 1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element0, 'class');
              morphs[1] = dom.createAttrMorph(element1, 'class');
              morphs[2] = dom.createMorphAt(element1, 1, 1);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["full-width padded no-left-padding ", ["subexpr", "unless", [["get", "media.isMobile", ["loc", [null, [15, 61], [15, 75]]], 0, 0, 0, 0], "display-inline-block", "center aligned no-right-padding"], [], ["loc", [null, [15, 52], [15, 134]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "class", ["concat", ["ui padded button-container ", ["subexpr", "unless", [["get", "media.isMobile", ["loc", [null, [17, 58], [17, 72]]], 0, 0, 0, 0], "no-left-padding", "centered"], [], ["loc", [null, [17, 49], [17, 103]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "ui-button", [["subexpr", "queue", [["subexpr", "action", ["forceLogin"], [], ["loc", [null, [18, 32], [18, 53]]], 0, 0], ["subexpr", "action", ["triggerRequestToBeMember"], [], ["loc", [null, [18, 54], [18, 89]]], 0, 0]], [], ["loc", [null, [18, 25], [18, 90]]], 0, 0]], ["class", "ui fluid circular blue waves-effect participate"], 0, null, ["loc", [null, [18, 12], [20, 26]]]]],
            locals: [],
            templates: [child0]
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 13,
                "column": 2
              },
              "end": {
                "line": 25,
                "column": 2
              }
            },
            "moduleName": "social-web/pods/components/group-member-button/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "group.canBeMember", ["loc", [null, [14, 10], [14, 27]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [14, 4], [24, 11]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 26,
              "column": 0
            }
          },
          "moduleName": "social-web/pods/components/group-member-button/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "group.isCurrentUserMember", ["loc", [null, [3, 8], [3, 33]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [3, 2], [25, 9]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 26,
            "column": 7
          }
        },
        "moduleName": "social-web/pods/components/group-member-button/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [false], [], 0, null, ["loc", [null, [2, 0], [26, 7]]]]],
      locals: [],
      templates: [child0]
    };
  }());
});