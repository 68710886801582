define('social-web/pods/series-edition/serializer', ['exports', 'social-web/pods/application/serializer'], function (exports, _serializer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _serializer.default.extend({
    attrs: {
      editionsCount: { serialize: false }
    }
  });
});