define('social-web/initializers/header-shim', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() /* application */{
    Ember.$(document).on('click', '.dropdown-toggle', function (e) {
      e.preventDefault();
      var $this = Ember.$(this);
      var $dropdown = $this.parent();

      Ember.$('.dropdown.open').not($dropdown).removeClass('open');
      $dropdown.toggleClass('open');
    });

    var closeHandler = function closeHandler() {
      Ember.$('.alert').fadeOut(function () {
        Ember.$(this).remove();
      });
    };
    Ember.$(document).on('click', '.alert button.close', closeHandler);
    if (Ember.$('.alert-success').length > 0) {
      Ember.run.later(function () {
        closeHandler();
      }, 5000);
    }
  }

  exports.default = {
    name: 'header-shim',
    initialize: initialize
  };
});