define('social-web/pods/password/validations', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.changePasswordValidations = exports.validations = undefined;


  var validations = {
    password: (0, _validators.validateLength)({ min: 8 }),
    passwordConfirmation: (0, _validators.validateConfirmation)({ on: 'password' })
  };

  var changePasswordValidations = {
    currentPassword: (0, _validators.validatePresence)(true),
    password: (0, _validators.validateLength)({ min: 8 }),
    passwordConfirmation: (0, _validators.validateConfirmation)({ on: 'password' })
  };

  exports.validations = validations;
  exports.changePasswordValidations = changePasswordValidations;
});