define('social-web/tests/mirage/mirage/config', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    var _this = this;

    // These comments are here to help you get started. Feel free to delete them.

    /*
      Config (with defaults).
       Note: these only affect routes defined *after* them!
    */

    // this.urlPrefix = '';    // make this `http://localhost:8080`, for example, if your API is on a different server
    // this.namespace = '';    // make this `api`, for example, if your API is namespaced
    // this.timing = 400;      // delay for each request, automatically set to 0 during testing

    /*
      Shorthand cheatsheet:
       this.get('/posts');
      this.post('/posts');
      this.get('/posts/:id');
      this.put('/posts/:id'); // or this.patch
      this.del('/posts/:id');
       http://www.ember-cli-mirage.com/docs/v0.2.x/shorthands/
    */

    this.paginate = function (collection, request) {
      var perPage = parseInt(request.queryParams.per_page) || 25;
      var page = request.queryParams.page || 1;
      var offset = (page - 1) * perPage;

      collection.meta = { count: collection.models.length };
      collection.models = collection.models.slice(offset, offset + perPage);
      return collection;
    };

    this.search = function (collection, searchField, request) {
      if (request.queryParams.search) {
        collection.models = collection.models.filter(function (item) {
          return Ember.get(item, searchField).toLowerCase().indexOf(request.queryParams.search.toLowerCase()) >= 0;
        });
      }
      return collection;
    };

    this.findByIdOrSlug = function (collection, id) {
      return collection.find(id) || collection.findBy({ slug: id });
    };

    this.empty = function (mirageModel) {
      var collection = mirageModel.all();
      collection.models = [];
      return collection;
    };

    this.mapFollowableRoutes = function (modelName) {
      var pluralized = modelName.pluralize(),
          camelized = pluralized.camelize();
      _this.get('/' + pluralized + '/:id/follow', function (server, request) {
        var model = _this.findByIdOrSlug(server[camelized], request.params.id);
        return server.create('follow-data', {
          currentUserFollowing: model.currentUserFollowing,
          followersCount: model.followersCount
        });
      });
      _this.post('/' + pluralized + '/:id/follow', function (server, request) {
        var model = _this.findByIdOrSlug(server[camelized], request.params.id);
        model.currentUserFollowing = true;
        model.followersCount++;
        model.save();
        return server.create('follow-data', {
          currentUserFollowing: model.currentUserFollowing,
          followersCount: model.followersCount
        });
      });
      _this.del('/' + pluralized + '/:id/follow', function (server, request) {
        var model = _this.findByIdOrSlug(server[camelized], request.params.id);
        model.currentUserFollowing = false;
        model.followersCount--;
        model.save();
        return server.create('follow-data', {
          currentUserFollowing: model.currentUserFollowing,
          followersCount: model.followersCount
        });
      });
    };

    this.timing = 0;

    this.get('/oauth/token', function () {
      return {
        access_token: 'pants',
        created_at: (0, _moment.default)().valueOf(),
        expires_in: 7200,
        refresh_token: 'pants',
        token_type: 'bearer'
      };
    });
    this.post('/oauth/token', function () {
      return {
        access_token: 'pants',
        created_at: (0, _moment.default)().valueOf(),
        expires_in: 7200,
        refresh_token: 'pants',
        token_type: 'bearer'
      };
    });
    this.post('/oauth/revoke', function () {
      return null;
    });

    this.namespace = '/api/v2';

    this.get('/users', function (server, request) {
      var collection = server.users.all();
      if (request.queryParams.featured) {
        collection = server.users.where({ featured: true });
      }
      return _this.paginate(_this.search(collection, 'fullName', request), request);
    });
    this.get('/users/current', function (server) {
      return server.users.findBy({ current: true });
    });
    this.get('/users/:id', function (server, request) {
      return _this.findByIdOrSlug(server.users, request.params.id);
    });
    this.patch('/users/:id', 'users');
    this.get('/users/:id/competitions', function (server, request) {
      var collection = server.competitions.all();
      return _this.paginate(collection, request);
    });
    this.get('/users/:id/participations', function (server, request) {
      var user = server.users.find(request.params.id);
      return _this.paginate(user.participations, request);
    });
    this.get('/users/:id/recommendations', function (server, request) {
      var collection = server.users.where({ current: false, currentUserFollowing: false });
      if (request.queryParams.exclude_ids) {
        collection.models = collection.models.reject(function (user) {
          return request.queryParams.exclude_ids.indexOf(user.id) > 0;
        });
      }
      return _this.paginate(collection, request);
    });
    this.get('/users/:id/notifications', function (server, request) {
      var user = _this.findByIdOrSlug(server.users, request.params.id);
      var collection = _this.paginate(user.notifications, request);
      collection.meta['unseen-count'] = user.notifications.models.rejectBy('seen').length;
      return collection;
    });
    this.post('/users/:id/notifications/acknowledge_unseen', function (server, request) {
      var user = _this.findByIdOrSlug(server.users, request.params.id);
      user.notifications.models.forEach(function (notification) {
        notification.seen = true;
        notification.save();
      });
    });
    this.post('/users/:id/notifications/:notification_id/mark_as_read', function (server, request) {
      var notification = _this.findByIdOrSlug(server.notifications, request.params.notification_id);
      notification.read = true;
      notification.save();
    });

    this.mapFollowableRoutes('user');
    this.get('/users/:id/followed_users', function (server, request) {
      var followedIDs = request.queryParams.followed_user_ids;
      var followedUsers = server.users.all().filter(function (user) {
        return followedIDs.indexOf(user.id) > 0;
      });
      return {
        data: followedUsers.models.map(function (user) {
          return {
            attributes: {
              followed: user.currentUserFollowing,
              'followed-user-id': user.id
            }
          };
        })
      };
    });
    // this is the users timeline/news_feed
    this.get('/users/:id/news_feed', function (server, request) {
      var user = _this.findByIdOrSlug(server.users, request.params.id);
      var collection = server.statusUpdates.where({ userId: user.id }) || _this.empty(server.statusUpdates);
      collection.models = collection.models.sort(function (left, right) {
        return (0, _moment.default)(right.createdAt).diff((0, _moment.default)(left.createdAt));
      });

      return _this.paginate(collection, request);
    });
    // this is the users posts that they were mentioned in or wrote themselves
    this.get('/users/:id/feed', function (server, request) {
      var user = _this.findByIdOrSlug(server.users, request.params.id);
      var collection = server.statusUpdates.where({ userId: user.id }) || _this.empty(server.statusUpdates);
      collection.models = collection.models.sort(function (left, right) {
        return (0, _moment.default)(right.createdAt).diff((0, _moment.default)(left.createdAt));
      });

      return _this.paginate(collection, request);
    });

    this.get('/users/:id/default_editions', function (server, request) {
      var user = _this.findByIdOrSlug(server.users, request.params.id);
      return _this.paginate(_this.search(user.editions, 'composedName', request), request);
    });
    this.get('/users/:id/editions', function (server, request) {
      var user = _this.findByIdOrSlug(server.users, request.params.id);
      return _this.paginate(_this.search(user.editions, 'composedName', request), request);
    });
    this.get('/users/:id/media', function (server, request) {
      var user = _this.findByIdOrSlug(server.users, request.params.id);
      return user.media;
    });
    this.get('/users/:id/stats', function (server, request) {
      return _this.findByIdOrSlug(server.users, request.params.id).userStats;
    });

    this.get('/notification_settings', function (server) {
      return server.notificationSettings.all();
    });

    this.post('/notification_settings', function (server, request) {
      var requestBody = JSON.parse(request.requestBody);
      var setting = server.notificationSettings.where({ kind: requestBody.data.attributes.kind }).models[0];
      setting.accepts_email = true;
      setting.save();
      return {
        data: {
          id: setting.id,
          type: 'notification-settings',
          attributes: {
            kind: setting.kind,
            'accepts-email': setting.accepts_email
          }
        }
      };
    });

    this.get('/registrations/:id/emails', function (server, request) {
      var user = _this.findByIdOrSlug(server.users, request.params.id);
      return server.emails.where({ userId: user.id });
    });
    this.patch('/authentication/registrations/:id', function (server, request) {
      var user = _this.findByIdOrSlug(server.users, request.params.id);
      user.password = JSON.parse(request.requestBody).data.attributes.password;
      user.save();
      return user;
    });
    this.post('/registrations/:user_id/emails');
    this.post('/registrations/:user_id/emails/:id/default_email', function (server, request) {
      var email = _this.findByIdOrSlug(server.emails, request.params.id);
      if (email) {
        email.defaultEmail = true;
        email.save();
      }
      return email;
    });
    this.del('/registrations/:user_id/emails/:id');

    this.get('/editions', function (server, request) {
      var collection = server.editions.all();
      if (request.queryParams.featured) {
        collection = server.editions.where({ featured: true });
      }
      return _this.paginate(_this.search(collection, 'composedName', request), request);
    });
    this.get('/scrapbooks/:id', function (server, request) {
      return _this.findByIdOrSlug(server.scrapbooks, request.params.id);
    });
    this.get('/scrapbooks/:id/media', function (server, request) {
      var scrapbook = _this.findByIdOrSlug(server.scrapbooks, request.params.id);
      return scrapbook.media;
    });
    this.get('/scrapbooks/:id/feed', function (server, request) {
      var scrapbook = _this.findByIdOrSlug(server.scrapbooks, request.params.id);
      var collection = server.statusUpdates.where({ scrapbookId: scrapbook.id }) || _this.empty(server.editions);
      collection.models = collection.models.sort(function (left, right) {
        return (0, _moment.default)(right.createdAt).diff((0, _moment.default)(left.createdAt));
      });

      return _this.paginate(collection, request);
    });
    this.get('/editions/:id', function (server, request) {
      return _this.findByIdOrSlug(server.editions, request.params.id);
    });
    this.get('/editions/:id/media', function (server, request) {
      var edition = _this.findByIdOrSlug(server.editions, request.params.id);
      return edition.media;
    });
    this.get('/editions/:id/transmissions', function (server, request) {
      var edition = _this.findByIdOrSlug(server.editions, request.params.id);
      return edition.transmissions;
    });
    this.get('/editions/:id/feed', function (server, request) {
      var edition = _this.findByIdOrSlug(server.editions, request.params.id);
      var collection = server.statusUpdates.where({ editionId: edition.id }) || _this.empty(server.editions);
      collection.models = collection.models.sort(function (left, right) {
        return (0, _moment.default)(right.createdAt).diff((0, _moment.default)(left.createdAt));
      });

      return _this.paginate(collection, request);
    });
    this.get('/editions/:id/competitions', function (server, request) {
      var edition = _this.findByIdOrSlug(server.editions, request.params.id);
      var collection = server.competitions.where({ editionId: edition.id });
      return _this.paginate(_this.search(collection, 'title', request), request);
    });
    this.get('/editions/:edition_id/users/:user_id/followees', function (server, request) {
      var collection = server.users.where({ current: false });
      return _this.paginate(_this.search(collection, 'fullName', request), request);
    });
    this.get('/editions/:edition_id/participations', function (server, request) {
      var collection = server.participations.all(); //where({ editionId: request.params.edition_id });
      return _this.paginate(_this.search(collection, 'fullName', request), request);
    });
    this.get('/competitions/:id/results', function (server, request) {
      var competition = _this.findByIdOrSlug(server.competitions, request.params.id);
      var collection = server.results.where({ competitionId: competition.id });
      return _this.paginate(_this.search(collection, 'user.fullName', request), request);
    });
    this.get('splits', function () {
      return { data: [] };
    });
    this.post('/competitions/:id/participations', function (server, request) {
      var data = JSON.parse(request.requestBody).data;
      return server.participations.create(Ember.merge(data.attributes || {}, {
        user: server.users.findBy({ current: true }),
        competition: server.competitions.find(request.params.id)
      }));
    });
    this.get('/users/:id/followees', function (server, request) {
      return _this.paginate(_this.search(server.users.where({ current: false, currentUserFollowing: true }), 'fullName', request), request);
    });
    this.get('/users/:id/followers', function (server, request) {
      return _this.paginate(_this.search(server.users.all(), 'fullName', request), request);
    });

    this.get('/events', function (server, request) {
      var collection = server.events.all();
      if (request.queryParams.featured) {
        collection = server.events.where({ featured: true });
      }
      return _this.paginate(_this.search(collection, 'name', request), request);
    });
    this.get('/events/:id', function (server, request) {
      return _this.findByIdOrSlug(server.events, request.params.id);
    });
    this.get('/events/:id/editions', function (server, request) {
      return _this.paginate(_this.search(_this.findByIdOrSlug(server.events, request.params.id).editions, 'composedName', request), request);
    });
    this.get('/events/:event_id/followers', function (server, request) {
      var collection = server.users.where({ current: false });
      return _this.paginate(_this.search(collection, 'fullName', request), request);
    });
    this.mapFollowableRoutes('event');

    // 
    // 
    // 
    // 
    this.get('/groups', function (server, request) {
      var collection = server.groups.all();
      if (request.queryParams.featured) {
        collection = server.groups.where({ featured: true });
      }
      return _this.paginate(_this.search(collection, 'name', request), request);
    });
    this.get('/groups/:id', function (server, request) {
      return _this.findByIdOrSlug(server.groups, request.params.id);
    });
    this.get('/groups/:group_id/members', function (server, request) {
      var collection = server.users.where({ current: false });
      return _this.paginate(_this.search(collection, 'display', request), request);
    });
    this.mapFollowableRoutes('group');
    // 
    // 
    // 
    // 
    // 

    this.get('/sports');
    this.get('/sports/:id');
    this.get('/locations/:id', function (server, request) {
      return _this.findByIdOrSlug(server.locations, request.params.id);
    });
    this.post('/locations');

    this.post('/status_updates');
    this.get('/status_updates/:id');
    this.patch('/status_updates/:id');
    this.put('/status_updates/:id');
    this.del('/status_updates/:id');

    this.getVoteData = function (server, parent) {
      return server.voteData.create({
        count: parent.votesCount,
        currentUserVoted: parent.currentUserVoted,
        parent: parent
      });
    };
    this.get('/status_updates/:id/votes', function (server, request) {
      var statusUpdate = server.statusUpdates.find(request.params.id);
      return _this.getVoteData(server, statusUpdate);
    });
    this.post('/status_updates/:id/votes', function (server, request) {
      var statusUpdate = server.statusUpdates.find(request.params.id);
      statusUpdate.votesCount++;
      statusUpdate.currentUserVoted = true;
      statusUpdate.save();
      return _this.getVoteData(server, statusUpdate);
    });
    this.del('/status_updates/:id/votes', function (server, request) {
      var statusUpdate = server.statusUpdates.find(request.params.id);
      statusUpdate.votesCount = statusUpdate.votesCount - 1 || 0;
      statusUpdate.currentUserVoted = false;
      statusUpdate.save();
      return _this.getVoteData(server, statusUpdate);
    });
    this.post('/status_updates/:id/report', function () /*server, request*/{
      return {};
    });

    this.get('/status_updates/:id/comments', function (server, request) {
      return server.comments.where({ statusUpdateId: request.params.id });
    });
    this.post('/status_updates/:id/comments', function (server, request) {
      var data = JSON.parse(request.requestBody).data;
      return server.comments.create(Ember.merge(data.attributes, {
        'createdAt': new Date(), 'updatedAt': new Date(), 'publishedAt': new Date(),
        'statusUpdateId': data.relationships['status-update'].data.id,
        'publisherId': data.relationships['publisher'].data.id
      }));
    });
    this.patch('/status_updates/:status_update_id/comments/:id');
    this.put('/status_updates/:status_update_id/comments/:id');
    this.del('/status_updates/:status_update_id/comments/:id');

    this.post('/comments/:id/report', function () /*server, request*/{
      return {};
    });

    this.post('/media', function (server /*, request*/) {
      return server.media.create();
    });
    this.patch('/media/:id');
    this.put('/media/:id');
    this.del('/media/:id');

    this.get('/series_editions/:id', function (server, request) {
      return _this.findByIdOrSlug(server.seriesEditions, request.params.id);
    });
    this.get('/series_editions/:id/editions', function (server, request) {
      return _this.findByIdOrSlug(server.seriesEditions, request.params.id).editions;
    });
    this.get('/series/:id/series_editions', function (server, request) {
      return _this.paginate(_this.search(_this.findByIdOrSlug(server.series, request.params.id).seriesEditions, 'name', request), request);
    });
    this.get('/participations/:id');
    this.del('/participations/:id');
    this.get('/competitions/:id');
    this.get('/competitions/:id/participations', function () /* server, request*/{
      return { data: [] };
    });
    this.get('/editions/:id/user_ids', function (server) {
      return server.create('user-id');
    });
    this.get('/events/:id/user_ids', function (server) {
      return server.create('user-id');
    });

    this.get('/travel_categories/:id');
    this.get('/accommodation_categories/:id');
    this.get('/travels/:id');
    this.get('/accommodations/:id');
    this.get('/partner_categories/:id');
    this.get('/sponsor_categories/:id');
    this.get('/cause_categories/:id');
    this.get('/partners/:id');
    this.get('/sponsors/:id');
    this.get('/causes/:id');
    this.get('/edition_activities/:id');
    this.get('/map_files/:id');
    this.post('/event_suggestions');
    this.get('/invites/:id');

    this.post('/invites', function (server, request) {
      var data = JSON.parse(request.requestBody).data;
      return server.create('invite', { entityToFollowId: data.attributes.event_id });
    });
    this.post('/invites/:id/process', function (server, request) {
      var invite = _this.findByIdOrSlug(server.invites, request.params.id);
      var event = _this.findByIdOrSlug(server.events, invite.entityToFollowId);
      event.currentUserFollowing = true;
      event.followersCount++;
      event.save();
      server.create('follow-data', {
        currentUserFollowing: event.currentUserFollowing,
        followersCount: event.followersCount
      });
      return _this.findByIdOrSlug(server.invites, request.params.id);
    });
  };
});