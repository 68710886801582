define("social-web/pods/components/lazy-image/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 3,
            "column": 0
          }
        },
        "moduleName": "social-web/pods/components/lazy-image/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("img");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("img");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(fragment, [2]);
        var morphs = new Array(6);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createAttrMorph(element0, 'src');
        morphs[2] = dom.createAttrMorph(element0, 'alt');
        morphs[3] = dom.createAttrMorph(element1, 'class');
        morphs[4] = dom.createAttrMorph(element1, 'src');
        morphs[5] = dom.createAttrMorph(element1, 'alt');
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["loader ", ["subexpr", "unbound", [["get", "class", ["loc", [null, [1, 29], [1, 34]]], 0, 0, 0, 0]], [], ["loc", [null, [1, 19], [1, 36]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "src", ["get", "fallbackUrl", ["loc", [null, [1, 44], [1, 55]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "alt", ["subexpr", "unbound", [["get", "alt", ["loc", [null, [1, 72], [1, 75]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [1, 77]]], 0, 0], 0, 0, 0, 0], ["attribute", "class", ["concat", ["content ", ["subexpr", "unbound", [["get", "class", ["loc", [null, [2, 30], [2, 35]]], 0, 0, 0, 0]], [], ["loc", [null, [2, 20], [2, 37]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "src", ["get", "lazyUrl", ["loc", [null, [2, 45], [2, 52]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "alt", ["subexpr", "unbound", [["get", "alt", ["loc", [null, [2, 69], [2, 72]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [2, 74]]], 0, 0], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  }());
});