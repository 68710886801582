define('social-web/pods/components/md-basic-tabs/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'ul',
    classNames: ['tabs'],

    didInsertElement: function didInsertElement() {
      var element = this.$('.ember-transitioning-in, .active');
      if (!element.length) {
        element = this.$('.tab:first-child a');
      }
      if (element.length) {
        element.addClass('active');
      }
      this.$().tabs({
        swipeable: true
      });
    }
  });
});