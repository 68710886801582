define('social-web/pods/components/basic-expander/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: ['active'],

    editLabel: null,
    active: false,
    noToggle: false,
    toggleClass: '',
    onConfirm: function onConfirm() {},
    onCancel: function onCancel() {},
    onExpand: function onExpand() {}
  });
});