define('social-web/tests/mirage/mirage/factories/split', ['exports', 'ember-cli-mirage', 'social-web/mirage/factories/application'], function (exports, _emberCliMirage, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    timeInSeconds: function timeInSeconds() {
      return _emberCliMirage.faker.random.number({ min: 50000, max: 100000 });
    },
    distanceInMeters: function distanceInMeters() {
      return _emberCliMirage.faker.random.number({ min: 0, max: 25000 });
    },
    afterCreate: function afterCreate(result, server) {
      if (!result.competition) {
        result.competition = server.create('competition');
      }
      if (!result.participation) {
        result.participation = server.create('participation', { competition: result.competition });
      }

      result.constructor.modelName = result.modelName;
      result.save();
    }
  });
});