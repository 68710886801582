define("social-web/pods/components/power-search/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 12,
              "column": 0
            },
            "end": {
              "line": 14,
              "column": 0
            }
          },
          "moduleName": "social-web/pods/components/power-search/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "spinner-widget", [], ["class", "abs-right"], ["loc", [null, [13, 2], [13, 38]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 15,
            "column": 0
          }
        },
        "moduleName": "social-web/pods/components/power-search/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("input");
        dom.setAttribute(el1, "type", "search");
        dom.setAttribute(el1, "autocomplete", "off");
        dom.setAttribute(el1, "autocorrect", "off");
        dom.setAttribute(el1, "autocapitalize", "off");
        dom.setAttribute(el1, "spellcheck", "false");
        dom.setAttribute(el1, "role", "combobox");
        dom.setAttribute(el1, "class", "ember-power-select-search-input");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(8);
        morphs[0] = dom.createAttrMorph(element0, 'value');
        morphs[1] = dom.createAttrMorph(element0, 'aria-controls');
        morphs[2] = dom.createAttrMorph(element0, 'placeholder');
        morphs[3] = dom.createAttrMorph(element0, 'oninput');
        morphs[4] = dom.createAttrMorph(element0, 'onfocus');
        morphs[5] = dom.createAttrMorph(element0, 'onblur');
        morphs[6] = dom.createAttrMorph(element0, 'onkeydown');
        morphs[7] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["attribute", "value", ["get", "select.searchText", ["loc", [null, [5, 10], [5, 27]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "aria-controls", ["get", "listboxId", ["loc", [null, [6, 18], [6, 27]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "placeholder", ["get", "searchPlaceholder", ["loc", [null, [7, 16], [7, 33]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "oninput", ["get", "onInput", ["loc", [null, [8, 12], [8, 19]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "onfocus", ["get", "onFocus", ["loc", [null, [9, 12], [9, 19]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "onblur", ["get", "onBlur", ["loc", [null, [10, 11], [10, 17]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "onkeydown", ["subexpr", "action", ["onKeydown"], [], ["loc", [null, [null, null], [11, 34]]], 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "select.loading", ["loc", [null, [12, 6], [12, 20]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [12, 0], [14, 7]]]]],
      locals: [],
      templates: [child0]
    };
  }());
});