define("social-web/pods/user/show/editions/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 2
            },
            "end": {
              "line": 4,
              "column": 2
            }
          },
          "moduleName": "social-web/pods/user/show/editions/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "spinner-widget", [], ["class", "spinner-wrapper"], ["loc", [null, [3, 4], [3, 46]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      var child0 = function () {
        var child0 = function () {
          var child0 = function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 14,
                    "column": 14
                  },
                  "end": {
                    "line": 19,
                    "column": 14
                  }
                },
                "moduleName": "social-web/pods/user/show/editions/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "lazy-image", [], ["url", ["subexpr", "@mut", [["get", "edition.event.logoThumb", ["loc", [null, [15, 33], [15, 56]]], 0, 0, 0, 0]], [], [], 0, 0], "fallbackUrl", ["subexpr", "@mut", [["get", "media.defaults.eventLogo", ["loc", [null, [16, 30], [16, 54]]], 0, 0, 0, 0]], [], [], 0, 0], "alt", ["subexpr", "@mut", [["get", "edition.event.name", ["loc", [null, [17, 22], [17, 40]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "circle responsive-img"], ["loc", [null, [15, 16], [18, 49]]], 0, 0]],
              locals: [],
              templates: []
            };
          }();
          var child1 = function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 21,
                    "column": 14
                  },
                  "end": {
                    "line": 21,
                    "column": 120
                  }
                },
                "moduleName": "social-web/pods/user/show/editions/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["content", "edition.event.name", ["loc", [null, [21, 14], [21, 120]]], 0, 0, 0, 0]],
              locals: [],
              templates: []
            };
          }();
          var child2 = function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 25,
                    "column": 18
                  },
                  "end": {
                    "line": 29,
                    "column": 18
                  }
                },
                "moduleName": "social-web/pods/user/show/editions/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("time");
                dom.setAttribute(el1, "class", "time-label live");
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("strong");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element1 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createAttrMorph(element1, 'datetime');
                morphs[1] = dom.createMorphAt(dom.childAt(element1, [1]), 0, 0);
                return morphs;
              },
              statements: [["attribute", "datetime", ["get", "edition.startDate", ["loc", [null, [26, 37], [26, 54]]], 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "t", ["events.live"], [], ["loc", [null, [27, 30], [27, 49]]], 0, 0]],
              locals: [],
              templates: []
            };
          }();
          var child3 = function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 29,
                    "column": 18
                  },
                  "end": {
                    "line": 33,
                    "column": 18
                  }
                },
                "moduleName": "social-web/pods/user/show/editions/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("time");
                dom.setAttribute(el1, "class", "time-label");
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("strong");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createAttrMorph(element0, 'datetime');
                morphs[1] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
                return morphs;
              },
              statements: [["attribute", "datetime", ["get", "edition.startDate", ["loc", [null, [30, 37], [30, 54]]], 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "date-label", [["get", "edition.startDate", ["loc", [null, [31, 43], [31, 60]]], 0, 0, 0, 0]], [], ["loc", [null, [31, 30], [31, 62]]], 0, 0]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 6,
                  "column": 6
                },
                "end": {
                  "line": 39,
                  "column": 6
                }
              },
              "moduleName": "social-web/pods/user/show/editions/template.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("tr");
              dom.setAttribute(el1, "class", "title");
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2, "class", "white-text center-align");
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3, "class", "place");
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3, "class", "basic avatar");
              var el4 = dom.createTextNode("\n");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n              ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n\n              ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              var el5 = dom.createTextNode("\n                ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("small");
              dom.setAttribute(el5, "class", "left");
              var el6 = dom.createTextNode("\n");
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("                ");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n              ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [1, 3, 1]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(element2, 1, 1);
              morphs[1] = dom.createMorphAt(element2, 3, 3);
              morphs[2] = dom.createMorphAt(dom.childAt(element2, [5, 1]), 1, 1);
              return morphs;
            },
            statements: [["block", "link-to", ["event.show.edition.show", ["get", "edition.event.slug", ["loc", [null, [14, 51], [14, 69]]], 0, 0, 0, 0], ["get", "edition.slug", ["loc", [null, [14, 70], [14, 82]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [14, 14], [19, 26]]]], ["block", "link-to", ["event.show.edition.show", ["get", "edition.event.slug", ["loc", [null, [21, 69], [21, 87]]], 0, 0, 0, 0], ["get", "edition.slug", ["loc", [null, [21, 88], [21, 100]]], 0, 0, 0, 0]], ["class", "modelName"], 1, null, ["loc", [null, [21, 14], [21, 120]]]], ["block", "if", [["subexpr", "is-between", [["subexpr", "now", [], [], ["loc", [null, [25, 36], [25, 41]]], 0, 0], ["get", "edition.startDate", ["loc", [null, [25, 42], [25, 59]]], 0, 0, 0, 0], ["get", "edition.endDate", ["loc", [null, [25, 60], [25, 75]]], 0, 0, 0, 0]], ["interval", 3600000], ["loc", [null, [25, 24], [25, 93]]], 0, 0]], [], 2, 3, ["loc", [null, [25, 18], [33, 25]]]]],
            locals: ["edition"],
            templates: [child0, child1, child2, child3]
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 5,
                "column": 4
              },
              "end": {
                "line": 40,
                "column": 4
              }
            },
            "moduleName": "social-web/pods/user/show/editions/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "accordion-table", [], ["model", ["subexpr", "@mut", [["get", "model.editions", ["loc", [null, [6, 31], [6, 45]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [6, 6], [39, 26]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 40,
                "column": 4
              },
              "end": {
                "line": 57,
                "column": 4
              }
            },
            "moduleName": "social-web/pods/user/show/editions/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row center-align white-text");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "row");
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("h2");
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3, 1, 1]), 1, 1);
            return morphs;
          },
          statements: [["inline", "t", [["subexpr", "join", ["", ["subexpr", "array", ["user.show.editions.empty.", ["get", "formattedGroup", ["loc", [null, [49, 16], [49, 30]]], 0, 0, 0, 0], ".", ["subexpr", "if", [["get", "model.user.isSelf", ["loc", [null, [51, 20], [51, 37]]], 0, 0, 0, 0], "self", "other"], [], ["loc", [null, [51, 16], [51, 53]]], 0, 0]], [], ["loc", [null, [47, 14], [52, 15]]], 0, 0]], [], ["loc", [null, [45, 16], [53, 13]]], 0, 0]], ["name", ["subexpr", "@mut", [["get", "model.user.firstName", ["loc", [null, [53, 19], [53, 39]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [45, 12], [53, 41]]], 0, 0]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 2
            },
            "end": {
              "line": 58,
              "column": 2
            }
          },
          "moduleName": "social-web/pods/user/show/editions/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "model.editions.length", ["loc", [null, [5, 10], [5, 31]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [5, 4], [57, 11]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    var child2 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 60,
              "column": 2
            },
            "end": {
              "line": 62,
              "column": 2
            }
          },
          "moduleName": "social-web/pods/user/show/editions/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "spinner-widget", [], ["class", "spinner-wrapper"], ["loc", [null, [61, 4], [61, 46]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 64,
            "column": 0
          }
        },
        "moduleName": "social-web/pods/user/show/editions/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "main-col");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element3 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(element3, 1, 1);
        morphs[1] = dom.createMorphAt(element3, 3, 3);
        return morphs;
      },
      statements: [["block", "if", [["get", "loading", ["loc", [null, [2, 8], [2, 15]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [2, 2], [58, 9]]]], ["block", "infinity-loader", [], ["infinityModel", ["subexpr", "@mut", [["get", "model.editions", ["loc", [null, [60, 35], [60, 49]]], 0, 0, 0, 0]], [], [], 0, 0], "triggerOffset", 500], 2, null, ["loc", [null, [60, 2], [62, 22]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  }());
});