define('social-web/mirage/models/mention', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Model.extend({
    statusUpdate: (0, _emberCliMirage.belongsTo)('status-update'),
    participation: (0, _emberCliMirage.belongsTo)('participation')
  });
});