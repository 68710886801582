define("social-web/locales/es/translations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    404: {
      title: "404",
      description: "La pagina que buscas esta faltando o no existe",
      label: "Por favor tome un minuto para usar la navegacion arriba"
    },
    500: {
      title: "500",
      description: "Algo esta mal de nuestra parte. No se preocupe no es usted, somos nosotros.",
      label: "Favor usar la navegacion arriba, o, intente nuevamente en unos minutos."
    },
    meta: {
      title: "SPLIFE",
      description: "Una red de fitness"
    },
    common: {
      showMore: "Mostrar mas",
      readMore: "Leer mas",
      loading: "Cargando...",
      search: {
        placeholder: "Buscar...",
        noResults: "No hay resultados",
        noResultsFor: "No hay resultados para '{{term}}'"
      },
      auth: {
        signin: {
          success: "Se conecto exitosamente",
          error: "Correo electronico o contrasena son invalidos."
        },
        signup: {
          success: "Bienvenido! Te has registrado exitosamente."
        },
        recovery: {
          noToken: "No puedes accesar esta pagina sin no entras por un correo electronico de reinicio de contrasena. Si vienes por un correo electronico de reinicio de contrasena, favor asegurarse que ha usado el URL completo.",
          error: "No hemos podido actualizar su contrasena. Es este el enlace del correo electronico que le enviamos correcto?"
        },
        forgot: {
          success: "En unos minutos usted recibira un correo electronico con instrucciones de como reiniciar su contrasena.",
          error: "Correo electronico no encontrado."
        }
      },
      follow: "Seguir",
      edit: "Editar",
      save: "Guardar",
      cancel: "Cancelar",
      social: {
        share: "Compartir",
        tweet: "Tweet",
        facebook: "Facebook",
        twitter: "Twitter"
      },
      viewProfile: "Ver Perfil"
    },
    nav: {
      feed: {
        title: "Feed"
      },
      event: {
        title: "Eventos"
      },
      user: {
        title: "Atletas"
      },
      search: {
        title: "Buscar"
      },
      actions: {
        viewProfile: "Mi Perfil",
        viewAccount: "Mi Cuenta",
        addEvent: "Anadir Evento",
        admin: "Admin",
        newAdmin: "Nuevo Admin",
        signout: "Desconectar",
        signin: "Registrarse"
      },
      notifications: {
        title: "Notificaciones",
        settings: "Configuracion",
        empty: {
          title: "No notificaciones",
          description: "␣"
        }
      }
    },
    welcome: {
      title: "Bienvenido a Splife!",
      description: "Este es su home feed, aqui podra encontrar actualizaciones de eventos y atletas que sigues.",
      discover: "Dale Click aqui para encontrar {{events}} y {{users}} para seguir.",
      events: "Eventos",
      users: "Usuarios"
    },
    index: {
      heroMain: "Conectando Atletas, Fanaticos y Eventos",
      missionStatement: "Encuentra los mejores eventos deportivos, siga la accion y capture la experiencia",
      signUp: "Registrarse",
      signIn: "Conectarse",
      appleAlt: "Apple Logo",
      availableOn: "Disponible en",
      playStore: "Play Store",
      playAlt: "Play Logo",
      appStore: "App Store",
      iosAlt: "iOS app",
      signInWith: "Inicia sesion con",
      facebook: "Facebook",
      facebookLogo: "Facebook logo",
      scrapbookHeader: "Captura la experiencia",
      scrapbookDescription: "Crea un scrapbook con fotos, amigos y resultados.",
      followHeader: "Siga la accion en vivo",
      followDescription: "Vea fotos del evento en vivo, actualizacion de status y resultados.",
      contentHeader: "Encuentra los mejores eventos deportivos",
      contentDescription: "Localiza eventos cercanos, en tendencia y mas valorados.",
      followSignUp: "Iniciarse",
      findEvents: "Encontrar Eventos",
      downloadApp: "Descargar App",
      contact: "Contactenos",
      copy: "&copy;",
      brand: "- Splife"
    },
    onboarding: {
      footer: "Por registrarse, usted esta de acuerdo con nuestras {{privacy}}",
      signin: {
        header: "Iniciar sesion a Splife",
        terms: "Terminos de Servicio",
        privacy: "Politica de Privacidad",
        labels: {
          email: "Correo Electronico",
          password: "Contrasena",
          remember: "Recordarme",
          signup: "Nuevo en Splife?",
          emailSignInOption: "o inicia sesión con tu correo electrónico"
        },
        actions: {
          forgot: "Olvide la Contrasena",
          submit: "Enviar",
          signup: "Registrarse",
          signin: "Iniciar sesion",
          facebook: "Iniciar sesion usando Facebook",
          google: "Iniciar sesion usando Google",
          email: "Iniciar sesion con el  correo electronico"
        }
      },
      signup: {
        header: "Registrase con Splife",
        labels: {
          email: "Correo Electronico",
          password: "Contrasena",
          passwordConfirmation: "Colocar contrasena nuevamente",
          firstName: "Primer Nombre",
          lastName: "Apellido",
          facebook: "No publicamos nada en Facebook sin su permiso.",
          signin: "Ya tienes una cuenta en Splife?",
          s1: " ",
          s2: "Encuentra los mejores eventos deportivos",
          s3: "Siga la accion en vivo",
          s4: "Captura la experiencia",
          emailSignUpOption: "o regístrate con tu correo electrónico"
        },
        actions: {
          submit: "Registrarse",
          signin: "Iniciar sesion",
          facebook: "Registrate con Facebook",
          google: "Registrate con Google",
          email: "Ingresa con e-mail"
        }
      },
      forgot: {
        header: "Olvido su contrasena?",
        subheader: "Introduzca su correo electronico para obtener mas instrucciones",
        labels: {
          email: "Email"
        },
        actions: {
          submit: "Enviar"
        }
      },
      recovery: {
        header: "Crear nueva contrasena",
        labels: {
          password: "Nueva contrasena",
          passwordConfirmation: "Ingrese nuevamente su contrasena"
        },
        actions: {
          submit: "Enviar"
        }
      }
    },
    privacy: {
      title: "Política de Privacidad",
      gathering: {
        title: "The information we collect",
        h1: "General",
        p1: "Para brindar a los Usuarios una experiencia de evento personalizada, recopilamos cierta informaci\xF3n que nos env\xEDan los Usuarios o que nos transfieren durante el curso de la comunicaci\xF3n entre los Usuarios y este Sitio. La informaci\xF3n se clasifica de la siguiente manera:",
        h2: "Informaci\xF3n an\xF3nima",
        p2: "La informaci\xF3n an\xF3nima es informaci\xF3n no identificable personalmente. Esto significa que no te identifica directamente, aunque puede identificar la computadora que est\xE1s usando. El uso de este Sitio implica la transferencia de cierta informaci\xF3n an\xF3nima entre su dispositivo y nuestros servidores, como la direcci\xF3n IP de su dispositivo y el tipo de navegador que est\xE1 utilizando.",
        h3: "Informaci\xF3n de identificaci\xF3n personal",
        p3: "La informaci\xF3n de identificaci\xF3n personal nos permite saber qui\xE9n es usted. Ejemplos de informaci\xF3n de identificaci\xF3n personal son su nombre, fotograf\xEDa, direcci\xF3n de correo electr\xF3nico y n\xFAmero de dorsal. Al usar el Sitio, nos otorga permiso para personalizar los medios tomados durante los eventos."
      },
      usage: {
        title: "Como la informacion es usada",
        p1: "La informacion es usada para mejorar la experiencia del visitante cuando utiliza la pagina y mostrar contenido personalizado y posiblemente propagandas.",
        p2: "Correo electronicos no seran vendidos, alquilados o arrendados a 3ras partes.",
        p3: "Puede que le enviemos un correo electronico con noticias de nuestros servicios u ofertas de nuestros afiliados."
      },
      options: {
        title: "Opciones del visitante",
        p1: "Si te has suscrito a uno de nuestros servicios, ustedes puede salir de la suscripcion siguiendo las intrucciones que estan incluidas en el correo electronico que recibe.",
        p2: "Usted puede bloquear las cookies mediante la configuración de su navegador, pero esto puede evitar que le de acceso a ciertas funciones del sitio web"
      },
      cookies: {
        title: "Cookies",
        p1: "Las cookies son archivos de firma digital pequeño que son almacenados por su navegador de Internet que permiten sus preferencias sean guardadas cuando visite el sitio Web. También puede usarse para rastrear sus visitas de retorno al sitio web.",
        p2: "\nLas compañías publicitarias de terceros también pueden usar cookies con fines de seguimiento."
      },
      advertisement: {
        title: "Google Ads",
        p1: "Google, como proveedor externo, utiliza cookies para publicar anuncios.",
        p2: "El uso de Google de la cookie de DART le permite publicar avisos a los visitantes en función de su visita a los sitios que visitan en Internet.",
        p3: "Los visitantes del sitio web pueden optar por excluirse del uso de la cookie de DART visitando la política de privacidad de la red de contenido y anuncios de Google."
      }
    },
    terms: {
      title: "Terminos de Servicio"
    },
    search: {
      index: {
        title: "Buscar",
        placeholder: "Buscar atletas y eventos.",
        empty: {
          title: "No hay resultados",
          description: " ",
          modal: {
            button: "Sugerir evento",
            title: "Sugerir evento",
            name: "Nombre del evento",
            accept: "Enviar",
            reject: "Cancelar"
          }
        }
      },
      user: {
        title: {
          zero: "Personas",
          one: "Personas ({{count}})",
          two: "Personas ({{count}})",
          other: "Personas ({{count}})"
        },
        empty: {
          title: "No hay resultados",
          description: " "
        },
        actions: {
          seeMore: "Ver mas"
        }
      },
      edition: {
        title: {
          zero: "Eventos",
          one: "Eventos ({{count}})",
          two: "Eventos ({{count}})",
          other: "Eventos ({{count}})"
        },
        empty: {
          title: "No hay resultados",
          description: " "
        },
        actions: {
          seeMore: "Ver mas"
        }
      }
    },
    feed: {
      title: "Actividad de Feed",
      empty: "No hay publicaciones en tu feed",
      comment: {
        placeholder: "Tu comentario...\n",
        actions: {
          title: "Acciones de comentario",
          edit: "Editar",
          delete: "Borrar",
          create: "Comentario",
          finishEdit: "Comentario",
          cancelEdit: "Cancelar",
          deletePrompt: {
            title: "Eliminar comentario",
            message: "\nNo puedes deshacer esta acción. Eliminará el comentario de la publicación.",
            confirm: "Yes",
            cancel: "No"
          }
        },
        showMore: {
          one: "Ver {{count}} comentario mas",
          two: "Ver {{count}} comentarios mas.",
          other: "Ver {{count}} comentarios mas"
        },
        "report.message": "¿Este comentario contiene contenido violento, crudo o generalmente inapropiado?"
      },
      post: {
        placeholders: {
          content: "\n¿Qué tienes en mente?",
          mentions: "\n@ para mencionar amigos",
          tagBibs: "Tag Bibs",
          time: "Tiempo Transcurrido",
          distance: "Distancia",
          edition: "Seleccione un evento para una publicación habilitada",
          link: "Insertar el link",
          share: "{{user}} publicar en {{edition}}"
        },
        actions: {
          title: "Acciones Posteriores",
          edit: "Editar",
          delete: "Borrar",
          create: "Comentario",
          cancel: "Cancelar",
          pin: "Pin Comenatario",
          unpin: "Unpin Comentario",
          share: "Compartir",
          deletePrompt: {
            title: "Eliminar Comentario",
            message: "No puedes deshacer esta acción. Eliminará la publicación y todos los comentarios y videos asociados.",
            confirm: "Si",
            cancel: "Cancelar"
          }
        },
        new: {
          activeEditions: "Activo",
          upcomingEditions: "Proximo",
          completedEditions: "Completado",
          editionsSearch: "Buscar un evento",
          bibsSearch: "Buscar un participante",
          reveal: {
            time: {
              title: "Tiempo Transcurrido",
              days: "Dias",
              hours: "Horas",
              minutes: "Minutos",
              seconds: "Segundos"
            },
            distance: {
              title: "Distancia Viajada",
              distance: "Distancia",
              mi: "mi",
              km: "km"
            },
            actions: {
              clear: "Limpiar",
              save: "Salvar"
            }
          }
        },
        show: {
          pinned: "Pinned",
          with: {
            prefix: "Con -",
            content: {
              one: "{{first}}",
              two: "{{first}} & {{second}}",
              other: " {{first}} & {{lessCount}} otros"
            },
            modal: {
              title: {
                one: "{{count}} Tagged Bib",
                two: "{{count}} Tagged Bibs",
                other: "{{count}} Tagged Bibs"
              }
            }
          },
          time: "Tiempo Transcurrido",
          distance: "Distancia\n"
        },
        "report.message": "¿Esta publicación contiene contenidos violentos, crudos, o generalmente inapropiados?"
      },
      filter: {
        default: {
          title: {
            self: "Filtra Feed",
            other: "Filtro feed de {{name}}",
            edition: "Filtra Feed"
          }
        },
        recent: {
          title: "Publicaciones mas recientes",
          description: "Organización predeterminada de publicaciones"
        },
        trending: {
          title: "Publicaciones en tendencia.",
          description: "Publicaciones con las interacciones más recientes"
        },
        friends: {
          title: "Solo Amigos",
          description: "Publicacion relacionada solo con tus amigos"
        }
      }
    },
    events: {
      follow: "Seguir",
      following: "Siguiendo",
      "active.title": "Eventos en Vivo",
      "upcoming.title": "Proximos Eventos",
      completed: "Completado",
      live: "En Vivo",
      friends: {
        participating: {
          one: "{{count}} Amigo en el Evento",
          two: "{{count}} Amigo en el Evento",
          other: "{{count}} Amigos en el Evento"
        }
      },
      index: {
        title: "Eventos",
        description: "␣",
        header: "Descubre los mejores eventos deportivos en un solo lugar.",
        discover: {
          title: "Descubrir"
        },
        featured: {
          title: "Destacado"
        },
        eventBlurb: "Comience hoy a dirigir su propia SPLIFE experiencia",
        actions: {
          createEvent: "Crear Evento"
        }
      },
      show: {
        invite: "Invitacion",
        participate: "Participar",
        register: "Registro",
        share: "Compartir",
        editions: {
          title: "Edicion de Eventos",
          view: "Ver Ediciones",
          modal: {
            title: "Cambiar Ediciones"
          }
        },
        participation: {
          modal: {
            title: "Participar",
            participate: "Participar",
            leave: "Salir",
            apply: "Aplicar"
          }
        },
        series: {
          title: "Serie de Eventos",
          view: "Ver Series",
          tagline: "Este evento es miembro de"
        },
        activity: {
          title: "Actividad",
          feed: {
            title: "Feed de Eventos",
            empty: "No hay publicaciones en este feed de eventos.",
            placeholders: {
              post: "Publicar en {{eventName}}"
            }
          }
        },
        gallery: {
          title: "Galeria",
          empty: {
            title: "Sin Fotos...",
            description: "Aun no hay fotos para este evento en Splife."
          }
        },
        live: {
          title: "En vivo",
          empty: {
            title: "No hay transmisión en vivo ...",
            description: "Todavía no hay transmisión en vivo para este evento en Splife."
          }
        },
        results: {
          title: "Resultados",
          search: {
            placeholder: "Buscar un atleta...",
            competitions: "Buscar competiciones..."
          },
          name: "{{display}}",
          place: "#{{place}}",
          time: "Tiempo",
          pace: "Paso",
          elapsed: "Transcurrido",
          split: "Tiempo Parcial",
          distance: "Distancia",
          minPer: "min/",
          bibNum: "BIB#",
          age: "Años",
          empty: {
            title: "No hay resultados...",
            description: "Todavia no hay resultados para esta competencia en Splife."
          }
        },
        athletes: {
          title: "Atletas",
          search: {
            placeholder: "Buscar un atleta..."
          },
          empty: {
            title: "No hay Atletas",
            description: "No hay participantes siguiendo este evento en Splife aún",
            invite: "Invita Amigos a Participar"
          },
          bib: "Bib",
          viewScrapbook: "View Scrapbook"
        },
        fans: {
          title: "Fanaticos",
          search: {
            placeholder: "Buscar un fanatico.."
          },
          empty: {
            title: "Sin Fanaticos",
            description: "No hay fans siguiendo este evento en Splife aún.",
            invite: "Invita a Amigos a Seguir"
          }
        },
        about: {
          title: "Acerca de",
          empty: {
            title: "Sin informacion...",
            description: "No hay información para este evento en splife todavía"
          },
          competitions: {
            title: "Competiciones",
            labels: {
              name: "Nombre",
              distance: "Distancia\n",
              startTime: "Hora de Inicio",
              athletes: "Atletas"
            },
            actions: {
              showMore: "Mostrar todas las competiciones"
            }
          },
          description: {
            title: "Descripcion de Evento"
          },
          faq: {
            title: "Preguntas Frecuentes"
          },
          rules: {
            title: "Reglas del evento"
          },
          travel: {
            title: "Viaje",
            emptyTitle: "~"
          },
          accommodation: {
            title: "Alojamiento",
            emptyTitle: "~"
          },
          resources: {
            title: "Archivos de Mapas"
          },
          causes: {
            title: "Causas",
            emptyTitle: "~"
          },
          partners: {
            title: "Partners",
            emptyTitle: "~"
          },
          sponsors: {
            title: "Patrocinadores",
            emptyTitle: "~"
          },
          schedule: {
            title: "Calendario",
            ongoing: "{{item}} - En marcha",
            ending: "{{item}} - Finalizando {{endTime}}"
          }
        }
      }
    },
    series: {
      title: "Descripcion de la Serie",
      events: "Eventos de la Serie ({{count}})",
      actions: {
        changeEdition: "Cambiar Ediciones"
      },
      editions: {
        modal: {
          title: "Cambiar Ediciones de la Serie"
        }
      }
    },
    distanceMeasureUnit: {
      km: {
        short: "km",
        long: "Kilometro"
      },
      mi: {
        short: "mi",
        long: "Milla"
      }
    },
    user: {
      follow: "Seguir",
      following: "Siguiendo",
      recommendations: {
        title: "Personas Que Puedas Conocer"
      },
      gender: {
        m: "Masculino",
        f: "Femenino"
      },
      shirtSize: {
        adult: "Adulto",
        youth: "Juventud",
        xxs: "2XS",
        xs: "XS",
        s: "S",
        m: "M",
        l: "L",
        xl: "XL",
        xxl: "2XL",
        xxxl: "3XL",
        "2year": "2 Anos",
        "4year": "4 Anos",
        "6year": "6 Anos",
        "8year": "8 Anos",
        "10year": "10 Anos",
        "12year": "12 Anos"
      },
      index: {
        title: "Personas",
        description: "␣",
        header: "Descubre atletas destacados y en tendencias de todo el mundo.",
        discover: {
          title: "Descubrir"
        },
        featured: {
          title: "Destacado"
        }
      },
      show: {
        actions: {
          editProfile: "Editar Perfil",
          editBackground: "Editar fondo",
          aboutMe: "Sobre Mi",
          profilePicture: {
            title: "Editar Imagen de Perfil",
            description: ".jpg & .png solamente, 25mb limite de tamano de archivo.",
            upload: "Subir Foto",
            submit: "Aceptar"
          },
          coverPhoto: {
            upload: "Subir Foto",
            remove: "Remover"
          }
        },
        profile: {
          title: "Perfil",
          info: {
            title: "Informacion basica",
            name: "Nombre",
            firstName: "Primer Nombre",
            lastName: "Apellido",
            location: {
              title: "Ubicacion",
              empty: "No hay ubicacion."
            }
          },
          sports: {
            title: "informacion atletica",
            empty: "No hay deportes para mostrar"
          },
          quote: {
            title: "Citar",
            placeholder: "Ingresar cita..",
            empty: "Sin cita para mostrar."
          },
          bio: {
            title: "Sobre Mi",
            placeholder: "Ingrese una breve biografia.",
            empty: "Ninguna biografia para mostrar."
          },
          actions: {
            return: "Regresar al Perfil",
            save: "Guardar & Regresar",
            edit: "Editar"
          }
        },
        stats: {
          events: "Eventos",
          followers: "Seguidores",
          miles: "Millas",
          upcoming: "Por venir",
          completed: "Eventos",
          posts: "Publicaciones"
        },
        about: {
          title: {
            self: "Sobre Mi",
            other: "Acerca de {{name}}"
          }
        },
        activity: {
          title: "Actividad",
          feed: {
            title: "Actividad de Feed",
            placeholders: {
              post: {
                self: "Que tienes en mente?",
                other: "Que tienes en mente?"
              }
            },
            empty: {
              title: {
                self: "Tu perfil esta casi vacio."
              },
              description: {
                self: "Tómese un momento para editar y mejorar su perfil",
                other: "No hay publicaciones en {{name}}'s feed."
              }
            }
          }
        },
        editions: {
          title: "Eventos",
          empty: {
            upcomingLive: {
              self: "No tienes ningún evento próximo o en vivo",
              other: "{{nombre}} no tiene ningun evento proximo o en vivo."
            },
            completed: {
              self: "No tienes ningún evento completado",
              other: "{{name}} no tiene ningun evento completado."
            }
          },
          search: {
            placeholder: "Buscar un evento..."
          },
          filters: {
            groups: {
              title: "Selecciona Grupo",
              upcomingLive: "Proximo & Vivo",
              completed: "Completado"
            }
          }
        },
        gallery: {
          title: "Galeria",
          empty: {
            title: "Sin Fotos...",
            description: {
              self: "No tienes fotos",
              other: "{{name}} no tiene fotos."
            }
          }
        },
        friends: {
          title: "Amigos",
          empty: {
            followees: {
              self: "No estas siguiendo a nadie en este momento.",
              other: "{{name}} no te esta siguiendo en este momento."
            },
            followers: {
              self: "No tienes seguidores en este momento.",
              other: "{{name}} no tiene seguidores en esto."
            }
          },
          search: {
            placeholder: "Buscar una persona..."
          },
          filters: {
            groups: {
              title: "Selecciona Grupo.",
              followees: "Siguiendo",
              followers: "Seguidores"
            }
          }
        }
      }
    },
    account: {
      title: "Mi Cuenta",
      general: {
        title: "General",
        description: "Esta información se usa exclusivamente dentro de Splife para fines de contacto personal y comunicación.",
        name: {
          label: "Nombre",
          "firstName.label": "Primer Nombre",
          "lastName.label": "Apellido"
        },
        email: {
          label: "Email",
          primaryContact: "Contacto Primario",
          description: "La dirección de contacto principal se usará para recibir mensajes oficiales de Splife, como recuperación de cuenta y contraseña.",
          actions: {
            add: "Agregue otra direccion de correo electronico"
          },
          modal: {
            newEmail: "Nuevo Correo Electronico",
            error: "Correo electrónico ya tomado",
            save: "Salvar",
            cancel: "Cancelar"
          }
        },
        phone: {
          label: "Telefono"
        },
        gender: {
          label: "Genero"
        },
        location: {
          label: "Ubicacion"
        },
        shirt: {
          label: "Talla de camisa"
        },
        password: {
          label: "Contrasena",
          currentPassword: {
            label: "Contrasena Actual"
          },
          password: {
            label: "Nueva Contrasena"
          },
          passwordConfirmation: {
            label: "Reescriba nueva Contrasena"
          }
        },
        birthday: {
          label: "Cumpleanos"
        },
        distance: {
          label: "Distancia"
        }
      },
      notificationSettings: {
        title: "Notificaciones",
        fullTitle: "Preferencias de notificacion",
        labels: {
          atTimeOfEvent: "En el momento del Evento"
        }
      },
      language: {
        title: "Idioma"
      },
      communication: {
        title: "Notificaciones",
        description: "Ajuste estas preferencias como desee para fines de comunicación.",
        push: {
          label: "En Splife",
          events: {
            label: "Eventos",
            description: "Deberiamos notifcarte cuando",
            participant: "Soy un participante de",
            following: "Estoy siguiendo",
            friend: {
              participant: "Un amigo es un participante de",
              liveMention: "Menciones de amigos de eventos en vivo"
            }
          },
          network: {
            label: "Red",
            description: "Deberíamos notificarte cuando",
            follower: "Seguidores",
            comments: "Comentarios",
            posting: "Publicacion en Vivo",
            invites: "Invitaciones de Eventos aceptadas"
          }
        },
        email: {
          label: "Email",
          events: {
            label: "Eventos",
            description: "Deberiamos notifcarte cuando",
            participant: "\nSoy un participante de",
            following: "Estoy siguiendo",
            friend: {
              participant: "Un amigo es un participante de ",
              liveMention: "Menciones de amigos de eventos en vivo"
            }
          },
          network: {
            label: "Red",
            description: "Deberiamos notifcarte cuando ",
            follower: "Seguidores",
            comments: "Comentarios",
            posting: "Publicacion en Vivo",
            invites: "Invitaciones de Eventos Aceptadas"
          }
        }
      },
      registration: {
        title: "Pago & Inscripcion"
      }
    },
    report: {
      reportAbuse: "Reportar Abuso",
      reportPrompt: {
        title: "Reportar Abuso",
        confirm: "Si",
        cancel: "No"
      }
    },
    validations: {
      present: "Debe estar presente",
      date: "Debe ser una fecha valida",
      email: "Debe ser una direccion de correo electronico valida",
      phone: "Debe ser un numero de telefono valido",
      tooLong: "Es demasiado largo(maximo es de {max} caracteres).",
      tooShort: "Demasiado corto (debe tener al menos {min} caracteres).",
      confirmation: "Debe coincidir con {{field}}",
      emailTaken: "Correo electronico ya tomado",
      password: "Contrasena",
      incorrectPassword: "La contrasena es incorrecta"
    }
  };
});