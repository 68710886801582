define('social-web/pods/components/scope-filter/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    title: null,
    selected: null,
    defaultScope: null,
    scopes: [],
    /*
    [{
      title: 'Title',
      description: 'Description',
      value: 'key'
    }]
    */
    collapseOnSelect: false,

    classNameBindings: ['defaultSelected::filtered'],

    defaultSelected: Ember.computed('selected', 'defaultScope', function () {
      return this.get('selected.value') === this.get('defaultScope.value');
    }),
    selectedTitle: Ember.computed('selected', 'title', function () {
      return this.get('defaultScope') && this.get('defaultSelected') || !this.get('selected.title') ? this.get('title') : this.get('selected.title');
    }),

    observeSelected: Ember.observer('selection', function () {
      this.set('selected', this.get('scopes').findBy('value', this.get('selection')));
      if (this.get('collapseOnSelect')) {
        this.$('.collapsible').collapsible('close');
      }
      if (this.attrs.onFilter) {
        this.attrs.onFilter(this.get('selected.value'));
      }
    }),

    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('defaultScope')) {
        this.set('selected', this.get('defaultScope'));
        this.set('selection', this.get('defaultScope.value'));
      }
    }
  });
});