define("social-web/pods/components/edition-card/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 2
            },
            "end": {
              "line": 6,
              "column": 2
            }
          },
          "moduleName": "social-web/pods/components/edition-card/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("time");
          dom.setAttribute(el1, "class", "time-label live");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("strong");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element1, 'datetime');
          morphs[1] = dom.createMorphAt(dom.childAt(element1, [1]), 0, 0);
          return morphs;
        },
        statements: [["attribute", "datetime", ["get", "model.startDate", ["loc", [null, [3, 21], [3, 36]]], 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "t", ["events.live"], [], ["loc", [null, [4, 14], [4, 33]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 2
            },
            "end": {
              "line": 10,
              "column": 2
            }
          },
          "moduleName": "social-web/pods/components/edition-card/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("time");
          dom.setAttribute(el1, "class", "time-label");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("strong");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element0, 'datetime');
          morphs[1] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
          return morphs;
        },
        statements: [["attribute", "datetime", ["get", "model.startDate", ["loc", [null, [7, 21], [7, 36]]], 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "date-label", [["get", "model.startDate", ["loc", [null, [8, 27], [8, 42]]], 0, 0, 0, 0]], [], ["loc", [null, [8, 14], [8, 44]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    var child2 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 16,
              "column": 4
            },
            "end": {
              "line": 18,
              "column": 4
            }
          },
          "moduleName": "social-web/pods/components/edition-card/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "truncate-multiline", [], ["text", ["subexpr", "@mut", [["get", "model.composedName", ["loc", [null, [17, 32], [17, 50]]], 0, 0, 0, 0]], [], [], 0, 0], "lines", 3, "showButton", false], ["loc", [null, [17, 6], [17, 77]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 32,
            "column": 0
          }
        },
        "moduleName": "social-web/pods/components/edition-card/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "card-image");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("strong");
        dom.setAttribute(el2, "class", "card-title full-width");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "card-content");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2, "class", "btn-floating btn-large square bg-image no-padding right");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("strong");
        dom.setAttribute(el2, "class", "primary-text truncate");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("em");
        dom.setAttribute(el2, "class", "secondary-text truncate");
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0]);
        var element3 = dom.childAt(fragment, [2]);
        var element4 = dom.childAt(element3, [1]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(element2, 1, 1);
        morphs[1] = dom.createMorphAt(element2, 3, 3);
        morphs[2] = dom.createMorphAt(dom.childAt(element2, [5]), 1, 1);
        morphs[3] = dom.createAttrMorph(element4, 'title');
        morphs[4] = dom.createMorphAt(element4, 1, 1);
        morphs[5] = dom.createMorphAt(dom.childAt(element3, [3]), 0, 0);
        return morphs;
      },
      statements: [["block", "if", [["subexpr", "is-between", [["subexpr", "now", [], [], ["loc", [null, [2, 20], [2, 25]]], 0, 0], ["get", "model.startDate", ["loc", [null, [2, 26], [2, 41]]], 0, 0, 0, 0], ["get", "model.endDate", ["loc", [null, [2, 42], [2, 55]]], 0, 0, 0, 0]], ["interval", 3600000], ["loc", [null, [2, 8], [2, 73]]], 0, 0]], [], 0, 1, ["loc", [null, [2, 2], [10, 9]]]], ["inline", "lazy-background-image", [], ["url", ["subexpr", "@mut", [["get", "model.event.coverPhotoTile", ["loc", [null, [12, 30], [12, 56]]], 0, 0, 0, 0]], [], [], 0, 0], "fallbackUrl", ["subexpr", "@mut", [["get", "media.defaults.eventCover", ["loc", [null, [13, 38], [13, 63]]], 0, 0, 0, 0]], [], [], 0, 0], "title", ["subexpr", "@mut", [["get", "model.event.name", ["loc", [null, [14, 32], [14, 48]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [12, 2], [14, 50]]], 0, 0], ["block", "if", [["get", "model.composedName", ["loc", [null, [16, 10], [16, 28]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [16, 4], [18, 11]]]], ["attribute", "title", ["get", "model.event.name", ["loc", [null, [23, 13], [23, 29]]], 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "lazy-background-image", [], ["url", ["subexpr", "@mut", [["get", "model.event.logoThumb", ["loc", [null, [24, 32], [24, 53]]], 0, 0, 0, 0]], [], [], 0, 0], "fallbackUrl", ["subexpr", "@mut", [["get", "media.defaults.eventLogo", ["loc", [null, [25, 40], [25, 64]]], 0, 0, 0, 0]], [], [], 0, 0], "title", ["subexpr", "@mut", [["get", "model.event.name", ["loc", [null, [26, 34], [26, 50]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "full-height"], ["loc", [null, [24, 4], [27, 49]]], 0, 0], ["content", "model.location.display", ["loc", [null, [29, 40], [29, 66]]], 0, 0, 0, 0]],
      locals: [],
      templates: [child0, child1, child2]
    };
  }());
});