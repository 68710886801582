define('social-web/pods/user/show/editions/route', ['exports', 'social-web/mixins/refreshable-infinity-route'], function (exports, _refreshableInfinityRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_refreshableInfinityRoute.default, {
    i18n: Ember.inject.service(),
    follow: Ember.inject.service(),

    titleToken: function titleToken() {
      return this.get('i18n').t('user.show.editions.title');
    },

    modelName: 'user',
    modelParams: function modelParams(params) {
      return {
        perPage: 24,
        modelPath: 'controller.model.editions',
        search: Ember.get(params, 'search'),
        include: 'event',
        time_scope: Ember.get(params, 'group') || 'completed',
        scope: {
          model: this.modelFor('user.show'),
          action: 'editions'
        }
      };
    },
    model: function model(params) {
      return new Ember.RSVP.hash({
        user: this.modelFor('user.show'),
        editions: this.getInfinityModel(params)
      });
    },
    afterInfinityModel: function afterInfinityModel(editions) {
      var _this = this;

      if (this.get('controller')) {
        this.set('controller.currentSearch', this.get('controller.search'));
      }
      return new Ember.RSVP.Promise(function (resolve) {
        var events = editions.getEach('event');
        _this.get('follow').checkCurrentUserFollowedUsers(events).then(function () {
          resolve(editions);
        });
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('currentSearch', this.get('_extraParams.search'));
    }
  });
});