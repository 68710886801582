define('social-web/pods/components/accordion-table/component', ['exports', 'semantic-ui-ember/components/ui-accordion'], function (exports, _uiAccordion) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _uiAccordion.default.extend({
    tagName: 'table',
    classNames: ['bordered']
  });
});