define('social-web/instance-initializers/accept-language', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(instance) {
    var i18n = instance.lookup('service:i18n');

    Ember.$.ajaxPrefilter(function (options, originalOptions, xhr) {
      xhr.setRequestHeader('Accept-Language', i18n.get('locale'));
    });
  }

  exports.default = {
    name: 'accept-language',
    after: 'i18n',
    initialize: initialize
  };
});