define('social-web/mirage/factories/scrapbook', ['exports', 'ember-cli-mirage', 'social-web/mirage/factories/application'], function (exports, _emberCliMirage, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    id: 1,
    reviewText: null,
    journal: null,
    afterCreate: function afterCreate(scrapbook, server) {
      if (!scrapbook.edition) {
        scrapbook.edition = server.create('edition');
      }
      if (!scrapbook.user) {
        scrapbook.user = server.create('user');
      }
      if (!scrapbook.statusUpdate) {
        scrapbook.statusUpdate = server.create('status-update');
      }
      if (!scrapbook.media) {
        scrapbook.media = server.createList('media', scrapbook.mediaCount === undefined ? _emberCliMirage.faker.random.number({ min: 1, max: 5 }) : scrapbook.mediaCount);
      }
      scrapbook.save();
    }
  });
});