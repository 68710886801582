define('social-web/pods/follow/service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),

    checkCurrentUserFollowedUsers: function checkCurrentUserFollowedUsers(users) {
      return this.checkCurrentUserFollowing('user', 'followed-user-id', users);
    },
    checkCurrentUserFollowedEvents: function checkCurrentUserFollowedEvents(events) {
      return this.checkCurrentUserFollowing('event', 'event-id', events);
    },
    checkCurrentUserFollowing: function checkCurrentUserFollowing(modelName, prop, modelArray) {
      var currentUser = this.get('session.currentUser');
      var store = this.get('store');
      var adapter = store.adapterFor(modelName);
      if (currentUser && Ember.get(modelArray, 'length')) {
        return adapter.checkUserFollowing(currentUser, modelArray).then(function (response) {
          response.data.forEach(function (followingData) {
            var id = followingData.attributes[prop],
                followed = followingData.attributes.followed;
            var model = store.peekRecord(modelName, id);
            if (model) {
              model.set('_currentUserFollowing', followed);
            }
          });
        });
      } else {
        return new Ember.RSVP.Promise(function (resolve) {
          resolve(modelArray);
        });
      }
    }
  });
});