define('social-web/pods/group/show/detail/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    i18n: Ember.inject.service(),
    session: Ember.inject.service(),

    scopes: Ember.computed('session.currentUser', function () {
      var currentUser = this.get('session.currentUser');
      var i18n = this.get('i18n');
      var scopes = [{
        title: i18n.t('feed.filter.recent.title'),
        description: i18n.t('feed.filter.recent.description'),
        value: 'recent'
      }];
      if (currentUser) {
        scopes.push({
          title: i18n.t('feed.filter.friends.title'),
          description: i18n.t('feed.filter.friends.description'),
          value: 'friends'
        });
      }
      scopes.push({
        title: i18n.t('feed.filter.trending.title'),
        description: i18n.t('feed.filter.trending.description'),
        value: 'trending'
      });
      return scopes;
    }),
    currentEdition: {}
  });
});