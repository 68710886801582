define('social-web/mirage/factories/user-id', ['exports', 'social-web/mirage/factories/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    userIds: function userIds() {
      return [1, 2, 3, 4, 5];
    }
  });
});