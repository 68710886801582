define("social-web/pods/components/result-row/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 9,
                "column": 6
              },
              "end": {
                "line": 28,
                "column": 6
              }
            },
            "moduleName": "social-web/pods/components/result-row/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            dom.setNamespace("http://www.w3.org/2000/svg");
            var el1 = dom.createElement("svg");
            dom.setAttribute(el1, "class", "progress-ring");
            dom.setAttribute(el1, "width", "42");
            dom.setAttribute(el1, "height", "42");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("circle");
            dom.setAttribute(el2, "class", "progress-ring__circle");
            dom.setAttribute(el2, "stroke-width", "2");
            dom.setAttribute(el2, "fill", "transparent");
            dom.setAttribute(el2, "cx", "21");
            dom.setAttribute(el2, "cy", "21");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1, 1]);
            var morphs = new Array(4);
            morphs[0] = dom.createAttrMorph(element1, 'stroke');
            morphs[1] = dom.createAttrMorph(element1, 'style');
            morphs[2] = dom.createAttrMorph(element1, 'r');
            morphs[3] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            return morphs;
          },
          statements: [["attribute", "stroke", ["get", "progressColor", ["loc", [null, [16, 21], [16, 34]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "style", ["subexpr", "concat", ["stroke-dasharray: ", ["get", "circumference", ["loc", [null, [19, 48], [19, 61]]], 0, 0, 0, 0], " ", ["get", "circumference", ["loc", [null, [19, 66], [19, 79]]], 0, 0, 0, 0], "; ", "stroke-dashoffset: ", ["get", "percentageAsCircumference", ["loc", [null, [19, 107], [19, 132]]], 0, 0, 0, 0], ";"], [], ["loc", [null, [null, null], [19, 138]]], 0, 0], 0, 0, 0, 0], ["attribute", "r", ["get", "radius", ["loc", [null, [20, 16], [20, 22]]], 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "lazy-image", [], ["url", ["subexpr", "@mut", [["get", "model.participation.user.customPictureThumb", ["loc", [null, [24, 25], [24, 68]]], 0, 0, 0, 0]], [], [], 0, 0], "fallbackUrl", ["subexpr", "@mut", [["get", "media.defaults.profilePicture", ["loc", [null, [25, 22], [25, 51]]], 0, 0, 0, 0]], [], [], 0, 0], "alt", ["subexpr", "@mut", [["get", "model.participation.display", ["loc", [null, [26, 14], [26, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "circle responsive-img"], ["loc", [null, [24, 8], [27, 41]]], 0, 0]],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 30,
                "column": 6
              },
              "end": {
                "line": 33,
                "column": 26
              }
            },
            "moduleName": "social-web/pods/components/result-row/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "t", ["events.show.results.name"], ["display", ["subexpr", "@mut", [["get", "model.participation.display", ["loc", [null, [30, 54], [30, 81]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [30, 6], [33, 26]]], 0, 0]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 4
            },
            "end": {
              "line": 34,
              "column": 4
            }
          },
          "moduleName": "social-web/pods/components/result-row/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["block", "link-to", ["user.show", ["get", "model.participation.user.slug", ["loc", [null, [9, 29], [9, 58]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [9, 6], [28, 18]]]], ["block", "link-to", ["user.show", ["get", "model.participation.user.slug", ["loc", [null, [32, 8], [32, 37]]], 0, 0, 0, 0]], ["class", "lightens"], 1, null, ["loc", [null, [30, 6], [33, 26]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    var child1 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 50,
                "column": 6
              },
              "end": {
                "line": 55,
                "column": 6
              }
            },
            "moduleName": "social-web/pods/components/result-row/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "lazy-image", [], ["url", ["subexpr", "@mut", [["get", "media.defaults.sponsorsPlaceholder", ["loc", [null, [51, 25], [51, 59]]], 0, 0, 0, 0]], [], [], 0, 0], "fallbackUrl", ["subexpr", "@mut", [["get", "media.defaults.sponsorsPlaceholder", ["loc", [null, [52, 22], [52, 56]]], 0, 0, 0, 0]], [], [], 0, 0], "alt", ["subexpr", "@mut", [["get", "model.relayTeam.display", ["loc", [null, [53, 14], [53, 37]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "circle responsive-img"], ["loc", [null, [51, 8], [54, 41]]], 0, 0]],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 55,
                "column": 6
              },
              "end": {
                "line": 60,
                "column": 6
              }
            },
            "moduleName": "social-web/pods/components/result-row/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "lazy-image", [], ["url", ["subexpr", "@mut", [["get", "media.defaults.profilePicture", ["loc", [null, [56, 25], [56, 54]]], 0, 0, 0, 0]], [], [], 0, 0], "fallbackUrl", ["subexpr", "@mut", [["get", "media.defaults.profilePicture", ["loc", [null, [57, 22], [57, 51]]], 0, 0, 0, 0]], [], [], 0, 0], "alt", ["subexpr", "@mut", [["get", "model.participation.display", ["loc", [null, [58, 14], [58, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "circle responsive-img"], ["loc", [null, [56, 8], [59, 41]]], 0, 0]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 34,
              "column": 4
            },
            "end": {
              "line": 67,
              "column": 4
            }
          },
          "moduleName": "social-web/pods/components/result-row/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          dom.setNamespace("http://www.w3.org/2000/svg");
          var el1 = dom.createElement("svg");
          dom.setAttribute(el1, "class", "progress-ring");
          dom.setAttribute(el1, "width", "42");
          dom.setAttribute(el1, "height", "42");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("circle");
          dom.setAttribute(el2, "class", "progress-ring__circle");
          dom.setAttribute(el2, "stroke-width", "2");
          dom.setAttribute(el2, "fill", "transparent");
          dom.setAttribute(el2, "cx", "21");
          dom.setAttribute(el2, "cy", "21");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          dom.setNamespace(null);
          var el1 = dom.createElement("span");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(5);
          morphs[0] = dom.createAttrMorph(element0, 'stroke');
          morphs[1] = dom.createAttrMorph(element0, 'style');
          morphs[2] = dom.createAttrMorph(element0, 'r');
          morphs[3] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          morphs[4] = dom.createMorphAt(dom.childAt(fragment, [5]), 1, 1);
          return morphs;
        },
        statements: [["attribute", "stroke", ["get", "progressColor", ["loc", [null, [41, 19], [41, 32]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "style", ["subexpr", "concat", ["stroke-dasharray: ", ["get", "circumference", ["loc", [null, [44, 46], [44, 59]]], 0, 0, 0, 0], " ", ["get", "circumference", ["loc", [null, [44, 64], [44, 77]]], 0, 0, 0, 0], "; ", "stroke-dashoffset: ", ["get", "percentageAsCircumference", ["loc", [null, [44, 105], [44, 130]]], 0, 0, 0, 0], ";"], [], ["loc", [null, [null, null], [44, 136]]], 0, 0], 0, 0, 0, 0], ["attribute", "r", ["get", "radius", ["loc", [null, [45, 14], [45, 20]]], 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "model.relayTeam.display", ["loc", [null, [50, 12], [50, 35]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [50, 6], [60, 13]]]], ["inline", "t", ["events.show.results.name"], ["display", ["subexpr", "or", [["get", "model.participation.display", ["loc", [null, [64, 22], [64, 49]]], 0, 0, 0, 0], ["get", "model.relayTeam.display", ["loc", [null, [64, 50], [64, 73]]], 0, 0, 0, 0]], [], ["loc", [null, [64, 18], [64, 74]]], 0, 0]], ["loc", [null, [63, 8], [65, 10]]], 0, 0]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 90,
            "column": 0
          }
        },
        "moduleName": "social-web/pods/components/result-row/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("td");
        dom.setAttribute(el1, "class", "white-text center-align");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "place");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("td");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "basic avatar");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("small");
        dom.setAttribute(el3, "class", "right time");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(":\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4, "class", "grey-text");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("i");
        dom.setAttribute(el5, "class", "material-icons expand-icon");
        var el6 = dom.createTextNode("chevron_right");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("small");
        dom.setAttribute(el4, "class", "left");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("small");
        dom.setAttribute(el4, "class", "right pace");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(":\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [2, 1]);
        var element3 = dom.childAt(element2, [3]);
        var element4 = dom.childAt(element2, [5]);
        var element5 = dom.childAt(element4, [1]);
        var element6 = dom.childAt(element4, [3]);
        var morphs = new Array(9);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 1]), 1, 1);
        morphs[1] = dom.createMorphAt(element2, 1, 1);
        morphs[2] = dom.createMorphAt(element3, 1, 1);
        morphs[3] = dom.createMorphAt(element3, 3, 3);
        morphs[4] = dom.createMorphAt(element5, 1, 1);
        morphs[5] = dom.createMorphAt(element5, 3, 3);
        morphs[6] = dom.createMorphAt(element5, 5, 5);
        morphs[7] = dom.createMorphAt(element6, 1, 1);
        morphs[8] = dom.createMorphAt(element6, 3, 3);
        return morphs;
      },
      statements: [["inline", "t", ["events.show.results.place"], ["place", ["subexpr", "@mut", [["get", "model.place", ["loc", [null, [3, 42], [3, 53]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [3, 4], [3, 55]]], 0, 0], ["block", "if", [["get", "model.participation.user", ["loc", [null, [8, 10], [8, 34]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [8, 4], [67, 11]]]], ["inline", "t", ["events.show.results.time"], [], ["loc", [null, [70, 6], [70, 38]]], 0, 0], ["inline", "moment-duration-format", [["get", "model.timeInMilliseconds", ["loc", [null, [71, 31], [71, 55]]], 0, 0, 0, 0]], [], ["loc", [null, [71, 6], [71, 57]]], 0, 0], ["content", "model.status", ["loc", [null, [79, 8], [79, 24]]], 0, 0, 0, 0], ["content", "model.distance", ["loc", [null, [80, 8], [80, 26]]], 0, 0, 0, 0], ["inline", "lowercase", [["get", "model.competition.distanceMeasureUnitShorthand", ["loc", [null, [81, 20], [81, 66]]], 0, 0, 0, 0]], [], ["loc", [null, [81, 8], [81, 68]]], 0, 0], ["inline", "t", ["events.show.results.pace"], [], ["loc", [null, [84, 8], [84, 40]]], 0, 0], ["inline", "moment-duration-format", [["get", "model.pace", ["loc", [null, [85, 33], [85, 43]]], 0, 0, 0, 0], "H:mm:ss.S"], [], ["loc", [null, [85, 8], [85, 57]]], 0, 0]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});