define("social-web/pods/user/show/friends/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 12,
              "column": 0
            }
          },
          "moduleName": "social-web/pods/user/show/friends/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "basic-select", [], ["label", ["subexpr", "t", ["user.show.friends.filters.groups.title"], [], ["loc", [null, [7, 23], [7, 67]]], 0, 0], "options", ["subexpr", "@mut", [["get", "groups", ["loc", [null, [8, 25], [8, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "selectedID", ["subexpr", "@mut", [["get", "group", ["loc", [null, [9, 28], [9, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "onSelect", ["subexpr", "action", [["subexpr", "queue", [["subexpr", "route-action", ["refresh"], [], ["loc", [null, [10, 41], [10, 65]]], 0, 0], ["get", "collapser", ["loc", [null, [10, 66], [10, 75]]], 0, 0, 0, 0]], [], ["loc", [null, [10, 34], [10, 76]]], 0, 0]], [], ["loc", [null, [10, 26], [10, 77]]], 0, 0], "class", "transparent"], ["loc", [null, [7, 2], [11, 38]]], 0, 0]],
        locals: ["collapser"],
        templates: []
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 2
            },
            "end": {
              "line": 27,
              "column": 2
            }
          },
          "moduleName": "social-web/pods/user/show/friends/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "user-card", [], ["model", ["subexpr", "@mut", [["get", "friend", ["loc", [null, [23, 22], [23, 28]]], 0, 0, 0, 0]], [], [], 0, 0], "dark", ["subexpr", "@mut", [["get", "media.isSmall", ["loc", [null, [24, 21], [24, 34]]], 0, 0, 0, 0]], [], [], 0, 0], "beforeFollow", ["subexpr", "route-action", ["forceLogin"], [], ["loc", [null, [25, 29], [25, 56]]], 0, 0], "class", "grow no-margin no-border-radius-on-small-only no-bottom-border-on-small-only no-right-border-on-small-only no-left-border-on-small-only"], ["loc", [null, [23, 4], [26, 161]]], 0, 0]],
        locals: ["friend"],
        templates: []
      };
    }();
    var child2 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 28,
                "column": 4
              },
              "end": {
                "line": 30,
                "column": 4
              }
            },
            "moduleName": "social-web/pods/user/show/friends/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1, "class", "center-align white-text");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["inline", "t", ["common.search.noResultsFor"], ["term", ["subexpr", "@mut", [["get", "currentSearch", ["loc", [null, [29, 79], [29, 92]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [29, 41], [29, 94]]], 0, 0]],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 30,
                "column": 4
              },
              "end": {
                "line": 44,
                "column": 4
              }
            },
            "moduleName": "social-web/pods/user/show/friends/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row center-align white-text");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("p");
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
            return morphs;
          },
          statements: [["inline", "t", [["subexpr", "join", ["", ["subexpr", "array", ["user.show.friends.empty.", ["get", "formattedGroup", ["loc", [null, [37, 14], [37, 28]]], 0, 0, 0, 0], ".", ["subexpr", "if", [["get", "model.user.isSelf", ["loc", [null, [39, 18], [39, 35]]], 0, 0, 0, 0], "self", "other"], [], ["loc", [null, [39, 14], [39, 51]]], 0, 0]], [], ["loc", [null, [35, 12], [40, 13]]], 0, 0]], [], ["loc", [null, [33, 14], [41, 11]]], 0, 0]], ["name", ["subexpr", "@mut", [["get", "model.user.firstName", ["loc", [null, [41, 17], [41, 37]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [33, 10], [41, 39]]], 0, 0]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 27,
              "column": 2
            },
            "end": {
              "line": 45,
              "column": 2
            }
          },
          "moduleName": "social-web/pods/user/show/friends/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "currentSearch", ["loc", [null, [28, 10], [28, 23]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [28, 4], [44, 11]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    var child3 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 47,
              "column": 2
            },
            "end": {
              "line": 49,
              "column": 2
            }
          },
          "moduleName": "social-web/pods/user/show/friends/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "spinner-widget", [], ["class", "spinner-wrapper"], ["loc", [null, [48, 4], [48, 46]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    var child4 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 51,
              "column": 2
            },
            "end": {
              "line": 53,
              "column": 2
            }
          },
          "moduleName": "social-web/pods/user/show/friends/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "spinner-widget", [], ["class", "spinner-wrapper"], ["loc", [null, [52, 4], [52, 46]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 55,
            "column": 0
          }
        },
        "moduleName": "social-web/pods/user/show/friends/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "main-col no-padding-on-small-only");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [3]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        morphs[1] = dom.createMorphAt(element0, 1, 1);
        morphs[2] = dom.createMorphAt(element0, 3, 3);
        morphs[3] = dom.createMorphAt(element0, 5, 5);
        return morphs;
      },
      statements: [["block", "search-filter", [], ["placeholder", ["subexpr", "t", ["user.show.friends.search.placeholder"], [], ["loc", [null, [2, 29], [2, 71]]], 0, 0], "search", ["subexpr", "@mut", [["get", "search", ["loc", [null, [3, 23], [3, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "loading", ["subexpr", "and", [["get", "loading", ["loc", [null, [4, 29], [4, 36]]], 0, 0, 0, 0], ["get", "model.friends.length", ["loc", [null, [4, 37], [4, 57]]], 0, 0, 0, 0]], [], ["loc", [null, [4, 24], [4, 58]]], 0, 0], "filtered", ["subexpr", "@mut", [["get", "group", ["loc", [null, [5, 25], [5, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "onSearch", ["subexpr", "route-action", ["debouncedRefresh"], [], ["loc", [null, [6, 25], [6, 58]]], 0, 0]], 0, null, ["loc", [null, [2, 0], [12, 18]]]], ["block", "tile-list", [], ["title", ["subexpr", "t", ["user.show.friends.title"], [], ["loc", [null, [15, 21], [15, 50]]], 0, 0], "titleClass", "hide-on-small-only", "largeColumns", 4, "mediumColumns", 3, "smallColumns", 1, "rowClass", "flex no-margin", "columnClass", "flex-column no-padding-on-small-only no-margin", "items", ["subexpr", "@mut", [["get", "model.friends", ["loc", [null, [22, 22], [22, 35]]], 0, 0, 0, 0]], [], [], 0, 0]], 1, 2, ["loc", [null, [15, 2], [45, 16]]]], ["block", "if", [["subexpr", "and", [["get", "loading", ["loc", [null, [47, 13], [47, 20]]], 0, 0, 0, 0], ["subexpr", "not", [["get", "model.friends.length", ["loc", [null, [47, 26], [47, 46]]], 0, 0, 0, 0]], [], ["loc", [null, [47, 21], [47, 47]]], 0, 0]], [], ["loc", [null, [47, 8], [47, 48]]], 0, 0]], [], 3, null, ["loc", [null, [47, 2], [49, 9]]]], ["block", "infinity-loader", [], ["infinityModel", ["subexpr", "@mut", [["get", "model.friends", ["loc", [null, [51, 35], [51, 48]]], 0, 0, 0, 0]], [], [], 0, 0], "triggerOffset", 500], 4, null, ["loc", [null, [51, 2], [53, 22]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  }());
});