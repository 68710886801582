define("social-web/pods/components/user-card/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 7,
              "column": 0
            }
          },
          "moduleName": "social-web/pods/components/user-card/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "lazy-image", [], ["url", ["subexpr", "@mut", [["get", "model.customPictureThumb", ["loc", [null, [3, 19], [3, 43]]], 0, 0, 0, 0]], [], [], 0, 0], "fallbackUrl", ["subexpr", "@mut", [["get", "media.defaults.profilePicture", ["loc", [null, [4, 27], [4, 56]]], 0, 0, 0, 0]], [], [], 0, 0], "alt", ["subexpr", "@mut", [["get", "model.fullName", ["loc", [null, [5, 19], [5, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "circle responsive-img"], ["loc", [null, [3, 2], [6, 46]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 2
            },
            "end": {
              "line": 15,
              "column": 72
            }
          },
          "moduleName": "social-web/pods/components/user-card/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["content", "model.fullName", ["loc", [null, [15, 2], [15, 72]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 25,
            "column": 0
          }
        },
        "moduleName": "social-web/pods/components/user-card/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "card-content");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "right-align");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        dom.setAttribute(el2, "class", "user-location secondary-text");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "right-align");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "card-action-spacer hide-on-small-only");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(fragment, [4]);
        var morphs = new Array(7);
        morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        morphs[1] = dom.createAttrMorph(element1, 'class');
        morphs[2] = dom.createMorphAt(dom.childAt(element1, [1]), 1, 1);
        morphs[3] = dom.createMorphAt(element0, 3, 3);
        morphs[4] = dom.createMorphAt(dom.childAt(element0, [5]), 0, 0);
        morphs[5] = dom.createAttrMorph(element2, 'class');
        morphs[6] = dom.createMorphAt(dom.childAt(element2, [1]), 1, 1);
        return morphs;
      },
      statements: [["block", "link-to", ["user.show", ["get", "model.slug", ["loc", [null, [2, 23], [2, 33]]], 0, 0, 0, 0]], ["class", "card-image"], 0, null, ["loc", [null, [2, 0], [7, 12]]]], ["attribute", "class", ["concat", ["card-action ", ["subexpr", "if", [["get", "media.isSmall", ["loc", [null, [9, 31], [9, 44]]], 0, 0, 0, 0], "mobile"], [], ["loc", [null, [9, 26], [9, 55]]], 0, 0], " right hide-on-med-and-up ", ["subexpr", "if", [["get", "model.isSelf", ["loc", [null, [9, 86], [9, 98]]], 0, 0, 0, 0], "hide"], [], ["loc", [null, [9, 81], [9, 107]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "user-follow-button", [], ["user", ["subexpr", "@mut", [["get", "model", ["loc", [null, [11, 32], [11, 37]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [11, 6], [11, 39]]], 0, 0], ["block", "link-to", ["user.show", ["get", "model.slug", ["loc", [null, [15, 39], [15, 49]]], 0, 0, 0, 0]], ["class", "primary-text"], 1, null, ["loc", [null, [15, 2], [15, 72]]]], ["content", "model.location.display", ["loc", [null, [16, 42], [16, 68]]], 0, 0, 0, 0], ["attribute", "class", ["concat", ["card-action hide-on-small-only no-top-padding transparent ", ["subexpr", "if", [["get", "model.isSelf", ["loc", [null, [19, 75], [19, 87]]], 0, 0, 0, 0], "hide"], [], ["loc", [null, [19, 70], [19, 96]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "user-follow-button", [], ["user", ["subexpr", "@mut", [["get", "model", ["loc", [null, [21, 30], [21, 35]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [21, 4], [21, 37]]], 0, 0]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});