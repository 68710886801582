define('social-web/mirage/factories/result', ['exports', 'ember-cli-mirage', 'social-web/mirage/factories/application'], function (exports, _emberCliMirage, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    bib: function bib() {
      return _emberCliMirage.faker.random.number({ min: 1, max: 2000 });
    },
    timeInSeconds: function timeInSeconds() {
      return _emberCliMirage.faker.random.number({ min: 50000, max: 100000 });
    },
    distanceInMeters: function distanceInMeters() {
      return _emberCliMirage.faker.random.number({ min: 0, max: 25000 });
    },
    status: function status(i) {
      return _emberCliMirage.faker.list.random('Finished', 'Interim', 'DNF', 'DNS')(i);
    },
    place: function place(n) {
      return n + 1;
    },
    afterCreate: function afterCreate(result, server) {
      if (!result.competition) {
        result.competition = server.create('competition');
      }

      // For some reason this breaks everything with:
      // "You tried to push data with a type 'follow-datum' but no model could be found with that name."
      // when you try to put it in store.
      if (!result.participation) {
        result.participation = server.create('participation', { competition: result.competition });
      }

      result.constructor.modelName = result.modelName;
      result.save();
    }
  });
});