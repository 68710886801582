define('social-web/helpers/trim', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.trim = trim;
  function trim(params /*, hash*/) {
    return params[0] && params[0].trim() ? params[0].trim() : undefined;
  }

  exports.default = Ember.Helper.helper(trim);
});