define("social-web/locales/pt-br/translations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    404: {
      title: "404",
      description: "A página que você procura está faltando ou nāo existe.",
      label: "Por favor use a navegaçāo acima."
    },
    500: {
      title: "500",
      description: "Algo deu errado pela nossa parte. Nāo se preocupe, nāo foi sua falta. A falta foi nossa",
      label: "Por favor use a navegaçāo acima ou tente outra vez em alguns momentos."
    },
    meta: {
      title: "Splife",
      description: "Uma rede de fitness"
    },
    common: {
      showMore: "Mostre Mais",
      readMore: "Leia Mais",
      loading: "Carregando...",
      search: {
        placeholder: "Procurar...",
        noResults: "Nāo há resultado",
        noResultsFor: "Nāo há resultados Para '{{term}}'"
      },
      auth: {
        signin: {
          success: "Sessāo Iniciada Com Sucesso.",
          error: "Email ou senha nāo válida."
        },
        signup: {
          success: "Bem-vindo! Sessāo Iniciada com Sucesso."
        },
        recovery: {
          noToken: "Você nāo pode acessar essa página sem a redefiniçāo de senha enviada. Se você vier de uma email de redefinaçāo de senha, certifique-se de que você usou o URL completo.",
          error: "Nāo foi possível atualizar sua senha. O link do email que lhe enviamos está correto?"
        },
        forgot: {
          success: "Você reberá um email com instruções sobre como redefinir sua senha em alguns minutos.",
          error: "Você reberá um email com instruções sobre como redefinir sua senha em alguns minutos."
        }
      },
      follow: "Seguir",
      edit: "Editar",
      save: "Armazenar",
      cancel: "Cancelar",
      social: {
        share: "Dividir",
        tweet: "Tweet",
        facebook: "Facebook",
        twitter: "Twitter"
      }
    },
    nav: {
      feed: {
        title: "Feed"
      },
      event: {
        title: "Eventos"
      },
      user: {
        title: "Atletas"
      },
      search: {
        title: "Buscar"
      },
      group: {
        title: "Grupos"
      },
      actions: {
        viewProfile: "Meu perfil",
        viewAccount: "Minha conta",
        addEvent: "Adicionar Evento",
        admin: "Administraçāo",
        newAdmin: "Nova Administraçā",
        signout: "Sair",
        signin: "Entrar"
      },
      notifications: {
        title: "Notificações",
        settings: "Configurações",
        empty: {
          title: "Nenhuma notificaçāo",
          description: " "
        }
      }
    },
    welcome: {
      title: "Bem-Vindo ao Splife!",
      description: "Esta é sua Home Feed, Aqui você verá atualizações de eventos e atletas que queira seguir",
      discover: "Clique aqui para achar {{eventos}} e {{usuários}} para seguir.",
      events: "Eventos",
      users: "Usuários"
    },
    index: {
      heroMain: "Conectando atletas, fās & Eventos",
      missionStatement: "Encontre os melhores eventos esportivos, siga a açāo, e faça parte da experiência",
      signUp: "Inscrever-se",
      signIn: "Entrar",
      appleAlt: "Apple logo",
      availableOn: "Disponível no",
      playStore: "Play Store",
      playAlt: "Play logo",
      appStore: "Loja de aplicativo",
      iosAlt: "iOS aplicativo",
      signInWith: "Entrar com",
      facebook: "Facebook",
      facebookLogo: "Facebook logo",
      scrapbookHeader: "Capture a experiência",
      scrapbookDescription: "Faça uma página de lembranças com fotos, amigos, e resultados.",
      followHeader: "Siga a açāo ao vivo",
      followDescription: "Veja fotos de eventos ao vivo, postagem de status e resultados.",
      contentHeader: "Descubra os melhores eventos esportivos",
      contentDescription: "Localize eventos nas proximidades e os mais votados.",
      followSignUp: "Iniciar",
      findEvents: "Achar Eventos",
      downloadApp: "Baixar aplicativo",
      contact: "Fale conosco",
      copy: "e Copiar;",
      brand: "- Splife"
    },
    onboarding: {
      footer: "Ao se inscrever você concorda com nossos termos de {{privacy}}.",
      signin: {
        header: "Entrar no Splife",
        terms: "Termos de serviço",
        privacy: "Regras de Privacidade",
        labels: {
          email: "Email",
          password: "Senha",
          remember: "Lembre de mim",
          signup: "Novo no Splife?",
          emailSignInOption: "ou entre com o seu email"
        },
        actions: {
          forgot: "Esqueceu senha",
          submit: "Enviar",
          signup: "Inscrever-se",
          signin: "Entrar",
          facebook: "Entrar com Facebook",
          google: "Entrar com Google",
          email: "Entrar com Email"
        }
      },
      signup: {
        header: "Inscrever-se em Splife",
        labels: {
          email: "Email",
          password: "Senha",
          passwordConfirmation: "Reentrar Senha",
          firstName: "Nome",
          lastName: "Sobrenome",
          facebook: "Nós podemos postar qualquer coisa no Facebook sem o seu consentimento.",
          signin: "Já tem uma conta?",
          s1: " ",
          s2: "Achar os melhores eventos esportivos.",
          s3: "Siga ao vivo a açāo.",
          s4: "Capture a experiência.",
          emailSignUpOption: "or crie uma conta com o seu email"
        },
        actions: {
          submit: "Enviar",
          signin: "Entrar",
          facebook: "Inscrever-se com Facebook",
          google: "Inscrever-se com Googl",
          email: "Inscrever-se com Email"
        }
      },
      forgot: {
        header: "Esqueceu sua senha?",
        subheader: "Entre seu endereço de email abaixo para mais instruções.",
        labels: {
          email: "Email"
        },
        actions: {
          submit: "Enviar"
        }
      },
      recovery: {
        header: "Criar nova senha",
        labels: {
          password: "Nova senha",
          passwordConfirmation: "Re-introduza sua nova senha"
        },
        actions: {
          submit: "Entrar"
        }
      }
    },
    privacy: {
      title: "Termos de Privacidade",
      gathering: {
        title: "As informações que coletamos",
        h1: "Geral",
        p1: "Para fornecer aos usu\xE1rios uma experi\xEAncia de evento personalizada, coletamos certas informa\xE7\xF5es enviadas a n\xF3s pelos usu\xE1rios ou transferidas para n\xF3s durante o curso de comunica\xE7\xE3o entre os usu\xE1rios e este site. As informa\xE7\xF5es s\xE3o categorizadas da seguinte forma:",
        h2: "Informa\xE7\xF5es an\xF4nimas",
        p2: "Informa\xE7\xF5es an\xF4nimas s\xE3o informa\xE7\xF5es n\xE3o pessoalmente identific\xE1veis. Isso significa que ele n\xE3o o identifica diretamente, embora possa identificar o computador que voc\xEA est\xE1 usando. O uso deste Site envolve a transfer\xEAncia de certas informa\xE7\xF5es an\xF4nimas entre seu dispositivo e nossos servidores, como o endere\xE7o IP do seu dispositivo e o tipo de navegador que voc\xEA est\xE1 usando.",
        h3: "Informa\xE7\xE3o pessoalmente identific\xE1vel",
        p3: "As informa\xE7\xF5es de identifica\xE7\xE3o pessoal nos permitem saber quem voc\xEA \xE9. Exemplos de informa\xE7\xF5es de identifica\xE7\xE3o pessoal s\xE3o seu nome, foto, endere\xE7o de e-mail e n\xFAmero de identifica\xE7\xE3o da corrida. Ao usar o Site, voc\xEA nos concede permiss\xE3o para personalizar a m\xEDdia obtida durante os eventos."
      },
      usage: {
        title: "Como a informaçāo é usada",
        p1: "A informaçāo é utilizada para melhorar a experiência do visitante enquanto usando o site para exibir conteúdo personalizado e possivelmente publicidade.",
        p2: "Os endereços de E-mail nāo serāo vendidos, emprestados ou alugados a terceiros.",
        p3: "E-mail pode ser enviado para informá-lo sobre novidades de nossos serviços ou ofertas feitas por nós ou nossos afiliados."
      },
      options: {
        title: "Opções do visitante",
        p1: "Se você se inscreveu em nossos serviços, poderá cancelar a inscriçāo seguindo as instruções que estāo incluídas num e-mail que você recebe.",
        p2: "Você pode bloquear cookies através das configurações do seu navegador, mas isso pode impedir o acesso a determinadas características do site."
      },
      cookies: {
        title: "Cookies",
        p1: "Os cookies sāo pequenos arquivos de assinatura digital que sāo armazenados pelo seu navegador e que permitem que sua preferência seja registrada ao visitar o site. Além disso, eles podem ser usados para rastrear seu retorno ao site.",
        p2: "Empresas de publicidade terceirizadas também podem usar cookies para fins de rastreamento."
      },
      advertisement: {
        title: "Publicidade do Goggle",
        p1: "Google, como um fornecedor de terceiros usa cookies para exibir anúncios.",
        p2: "O uso do cookie DART pela Google permite exibir anúncios aos vistantes baseado nos sites que visitam na Internet.",
        p3: "Os visitantes do site podem optar por nāo usar o cookie visitando a política de privacidade da Rede de anúncios e conteúdo do Google."
      }
    },
    terms: {
      title: "Termos de serviço."
    },
    search: {
      index: {
        title: "Procurar",
        placeholder: "Procurar eventos e atletas...",
        empty: {
          title: "Nāo há resultados",
          description: " "
        }
      },
      user: {
        title: {
          zero: "Pessoas",
          one: "Pessoas ({{count}})",
          two: "Pessoas ({{count}})",
          other: "Pessoas ({{count}})"
        },
        empty: {
          title: "Nāo há resultados",
          description: " "
        },
        actions: {
          seeMore: "Ver mais"
        }
      },
      edition: {
        title: {
          zero: "Eventos",
          one: "Eventos ({{count}})",
          two: "Eventos ({{count}})",
          other: "Eventos ({{count}})"
        },
        empty: {
          title: "Nāo há resultados",
          description: " "
        },
        actions: {
          seeMore: "Ver mais"
        }
      }
    },
    feed: {
      title: "Feed de atividade",
      empty: "Nāo há postagens em seu feed.",
      comment: {
        placeholder: "Seu comentário...",
        actions: {
          title: "Ações sobre comentário",
          edit: "Editar",
          delete: "Deletar",
          create: "Comentar",
          finishEdit: "Comentar",
          cancelEdit: "Cancelar",
          deletePrompt: {
            title: "Deletar comentário",
            message: "Você nāo pode desfazer esta açāo porque irá remover o comentário da postagem",
            confirm: "Sim",
            cancel: "Nāo"
          }
        },
        showMore: {
          one: "Ver {{count}} mais comentários",
          two: "Ver {{count}} mais comentários",
          other: "Ver {{count}} mais comentários"
        },
        "report.message": "Esse cometário contém algo violento, rude or geralmente inapropiado?"
      },
      post: {
        placeholders: {
          content: "O que você está pensando?",
          mentions: "@ para mencionar amigos",
          tagBibs: "Marcar Número de Peito de atletas",
          time: "Tempo decorrido",
          distance: "Distância",
          edition: "Selecionar um evento para habilitar a publicaçāo",
          link: "Inserir Link",
          share: "{{user}} postar na {{edition}}"
        },
        actions: {
          title: "Postar Ações",
          edit: "Editar",
          delete: "Deletar",
          create: "Postar",
          cancel: "Cancelar",
          pin: "Marcar Post",
          unpin: "Desmarcar Post",
          share: "Compartilhar",
          deletePrompt: {
            title: "Deletar Post",
            message: "Nāo é possível desfazer essa açāo. Resultará na remoçāo de toda publicaçāo e dos comentários, vídeos e imagens",
            confirm: "Sim",
            cancel: "Nāo"
          }
        },
        new: {
          activeEditions: "Ativo",
          upcomingEditions: "Próximo",
          completedEditions: "Completo",
          editionsSearch: "Procurar eventos",
          bibsSearch: "Procurar participantes",
          reveal: {
            time: {
              title: "Tempo decorrido",
              days: "Dias",
              hours: "Horas",
              minutes: "Minutos",
              seconds: "Segundos"
            },
            distance: {
              title: "Distância percorrida",
              distance: "Distância",
              mi: "milhas",
              km: "km"
            },
            actions: {
              clear: "Limpar?",
              save: "Salvar"
            }
          }
        },
        show: {
          pinned: "Marcado",
          with: {
            prefix: "Com - ",
            content: {
              one: "{{first}}",
              two: "{{first}} & {{second}}",
              other: "{{first}} & {{second}} outros"
            },
            modal: {
              title: {
                one: "{{count}} Número de Peito",
                two: "{{count}} Número de Peito",
                other: "{{count}} Número de Peito"
              }
            }
          },
          time: "Tempo decorrido",
          distance: "Distância"
        },
        "report.message": "Esse cometário contém algo violento, rude or geralmente inapropiado?"
      },
      filter: {
        default: {
          title: {
            self: "Filtrar seu feed",
            other: "Filtrar o feed de {{name}}",
            edition: "Filtrar Feed"
          }
        },
        recent: {
          title: "Post mais recente",
          description: "Organizaçāo padrāo de postagen"
        },
        trending: {
          title: "Posts em tendência",
          description: "Posts com as interações mais recentes"
        },
        friends: {
          title: "Sómente amigos",
          description: "Posts relacionados apenas aos seus amigos"
        }
      }
    },
    groups: {
      index: {
        header: "Veja o que está acontecendo em nossos Grupos."
      },
      show: {
        activity: {
          title: "Atividades"
        },
        members: {
          title: "Participantes"
        },
        gallery: {
          title: "Mídia"
        },
        about: {
          title: "Sobre"
        }
      }
    },
    events: {
      follow: "Seguir",
      following: "Seguindo",
      "active.title": "Eventos ao vivo",
      "upcoming.title": "Próximos eventos",
      completed: "Completo",
      live: "Ao vivo",
      featured: {
        title: "Eventos em destaque"
      },
      friends: {
        participating: {
          one: "{{count}} amigos no evento",
          two: "{{count}} amigos no evento",
          other: "{{count}} amigos no evento"
        }
      },
      index: {
        title: "Eventos",
        description: "",
        header: "Descubra os melhores eventos esportivos em um só lugar.",
        discover: {
          title: "Descobrir"
        },
        featured: {
          title: "Destaque"
        },
        eventBlurb: "Inicie hoje. Faça a sua própria experiência SPLIFE.",
        actions: {
          createEvent: "Criar evento"
        }
      },
      show: {
        invite: "Convidar",
        participate: "Participar",
        register: "Registrar",
        share: "Compartilhar",
        editions: {
          title: "Editar Evento",
          view: "Ver ediçāo",
          modal: {
            title: "Trocar ediçāo"
          }
        },
        participation: {
          modal: {
            title: "Participar",
            participate: "Participar",
            leave: "Sair",
            apply: "Aplicar"
          }
        },
        series: {
          title: "Séries de eventos",
          view: "Ver Séries",
          tagline: "Esse evento faz parte de"
        },
        activity: {
          title: "Atividade",
          feed: {
            title: "Feed do evento",
            empty: "Nāo há post no feed desse evento.",
            placeholders: {
              post: "Postar em {{eventName}}"
            }
          }
        },
        gallery: {
          title: "Galeria",
          empty: {
            title: "Nāo há fotos...",
            description: "Ainda nāo há fotos desse evento no Splife."
          }
        },
        live: {
          title: "Ao vivo",
          empty: {
            title: "Não há transmissão ao vivo ...",
            description: "Ainda nāo há transmissão ao vivo para este evento no SPLife."
          }
        },
        results: {
          title: "Resultados",
          search: {
            placeholder: "Procurar um atleta...",
            competitions: "Procurar competições..."
          },
          name: "{{display}}",
          place: "#{{place}}",
          time: "Tempo",
          pace: "Ritmo",
          elapsed: "Transcorrido",
          split: "Split",
          distance: "Distância",
          minPer: "min/",
          bibNum: "BIB#",
          age: "Idade",
          empty: {
            title: "Nāo há resultados...",
            description: "Ainda nāo há resultados para essa competiçāo no SPLIFE."
          }
        },
        athletes: {
          title: "atletas",
          search: {
            placeholder: "Procurar um atleta.."
          },
          empty: {
            title: "Nāo há atletas...",
            description: "Ainda nāo há participantes acompanhando esse evento no SPLIFE.",
            invite: "Convidar amigos para participa"
          },
          bib: "Número de peito",
          viewScrapbook: "Ver página de recado"
        },
        fans: {
          title: "Fās",
          search: {
            placeholder: "Procurar fā..."
          },
          empty: {
            title: "Nāo há fās...",
            description: "Ainda nāo há fās acompanhando esse evento no SPLIFE.",
            invite: "Convidar amigos para acompanhar"
          }
        },
        about: {
          title: "Sobre",
          empty: {
            title: "Nāo há informaçāo...",
            description: "Ainda nāo há informaçāo sobre esse evento SPLIFE."
          },
          competitions: {
            title: "Competições",
            labels: {
              name: "Nome",
              distance: "Distância",
              startTime: "Start Time",
              athletes: "Atletas"
            },
            actions: {
              showMore: "Mostrar todas as competições"
            }
          },
          description: {
            title: "Descriçāo do evento"
          },
          faq: {
            title: "Perguntas frequentes"
          },
          rules: {
            title: "Regras do Evento"
          },
          travel: {
            title: "Viagem",
            emptyTitle: "~"
          },
          accommodation: {
            title: "Acomodaçāo",
            emptyTitle: "~"
          },
          resources: {
            title: "Arquivos de mapas"
          },
          causes: {
            title: "Causas",
            emptyTitle: "~"
          },
          partners: {
            title: "Parceiros",
            emptyTitle: "~"
          },
          sponsors: {
            title: "Patrocinadores",
            emptyTitle: "~"
          },
          schedule: {
            title: "Calendário",
            ongoing: "{{item}} - Em progresso",
            ending: "{{item}} - Final {{endTime}}"
          }
        }
      }
    },
    series: {
      title: "Descriçāo de séries",
      events: "Séries  de eventos ({{count}})",
      actions: {
        changeEdition: "Mudar edições"
      },
      editions: {
        modal: {
          title: "Mudar edições das séries"
        }
      }
    },
    distanceMeasureUnit: {
      km: {
        short: "km",
        long: "Kilometro"
      },
      mi: {
        short: "mi",
        long: "Milhas"
      }
    },
    user: {
      follow: "Seguir",
      following: "Seguindo",
      featured: {
        title: "Atletas em destaque"
      },
      recommendations: {
        title: "Pessoas que talvez você conheça"
      },
      gender: {
        m: "m",
        f: "f"
      },
      shirtSize: {
        adult: "Adulto",
        youth: "Joven",
        xxs: "2XP",
        xs: "XP",
        s: "P",
        m: "M",
        l: "G",
        xl: "XG",
        xxl: "2XG",
        xxxl: "3XG",
        "2year": "2 anos",
        "4year": "4 anos",
        "6year": "6 anos",
        "8year": "8 anos",
        "10year": "10 anos",
        "12year": "12 anos"
      },
      index: {
        title: "Pessoas",
        description: " ",
        header: "Descubra atletas em destaque de todo o mundo.",
        discover: {
          title: "Descobrir"
        },
        featured: {
          title: "Destaques"
        }
      },
      show: {
        actions: {
          editProfile: "Editar perfil",
          editBackground: "Editar Backgroun",
          aboutMe: "Sobre mim",
          profilePicture: {
            title: "Edit Imagen do perfil",
            description: ".jpg e .png sómente, 25mb limite do arquivo",
            upload: "Carregar foto",
            submit: "Aceitar"
          },
          coverPhoto: {
            upload: "Carregar foto",
            remove: "Remover"
          }
        },
        profile: {
          title: "Perfil",
          info: {
            title: "Informaçāo básica",
            name: "Nome",
            firstName: "Nome",
            lastName: "Sobrenome",
            location: {
              title: "Localizaçāo",
              empty: "Nāo há localizaçāo."
            }
          },
          sports: {
            title: "Informaçāo atlética",
            empty: "Nāo há esportes para mostrar."
          },
          quote: {
            title: "Cotaçāo",
            placeholder: "Entrar uma pequena biografia...",
            empty: "Nāo há cotaçāo para mostrar."
          },
          bio: {
            title: "Sobre mim",
            placeholder: "Entrar uma pequena biografia...",
            empty: "Nāo há biografia para mostrar."
          },
          actions: {
            return: "Retornar para perfil",
            save: "Guardar e retornar",
            edit: "Editar"
          }
        },
        stats: {
          events: "Eventos",
          followers: "seguidores",
          miles: "Milhas",
          upcoming: "Próximo",
          completed: "Eventos",
          posts: "Posts"
        },
        about: {
          title: {
            self: "Sobre mim",
            other: "Sobre {{name}}"
          }
        },
        activity: {
          title: "Atividade",
          feed: {
            title: "Feed da atividade",
            placeholders: {
              post: {
                self: "O que você está pensando?",
                other: "O que você está pensando?"
              }
            },
            empty: {
              title: {
                self: "Seu perfil está quase vazio."
              },
              description: {
                self: "Aproveite para editar e aprimorar seu perfil.",
                other: "Nāo há posts no feed de {{name}}."
              }
            }
          }
        },
        editions: {
          title: "Eventos",
          empty: {
            upcomingLive: {
              self: "Você nāo tem nenhum evento próximo ou ao vivo.",
              other: "{{name}} nāo tem nenhum evento próximo ou ao vivo."
            },
            completed: {
              self: "Você nāo tem nenhum evento completo.",
              other: "{{name}} nāo tem nenhum evento completo."
            }
          },
          search: {
            placeholder: "Procurar um evento..."
          },
          filters: {
            groups: {
              title: "Selecionar Groupo",
              upcomingLive: "Próximo e Ao vivo",
              completed: "Completado"
            }
          }
        },
        gallery: {
          title: "Galeria",
          empty: {
            title: "Nāo há fotos...",
            description: {
              self: "Você nāo tem fotos.",
              other: "{{name}} nāo tem fotos."
            }
          }
        },
        friends: {
          title: "Amigos",
          empty: {
            followees: {
              self: "Você nāo está seguindo ninguém neste momento.",
              other: "{{name}} nāo está seguindo ninguém neste momento."
            },
            followers: {
              self: "Você nāo tem seguidores nesse momento.",
              other: "{{name}} nāo tem seguidores nesse momento."
            }
          },
          search: {
            placeholder: "Procurar pessoas..."
          },
          filters: {
            groups: {
              title: "Selecionar grupo",
              followees: "Seguindo",
              followers: "Seguidores"
            }
          }
        }
      }
    },
    account: {
      title: "Minha conta",
      general: {
        title: "Geral",
        description: "Esta informaçāo é usada exclusivamente no SPLIFE para contato pessoal e propósito de comunicaçāo",
        name: {
          label: "Nome",
          "firstName.label": "Nome",
          "lastName.label": "Sobrenome"
        },
        email: {
          label: "Email",
          primaryContact: "Contato principal",
          description: "O endereço do contato principal será usado para receber mensagens oficiais do Splife , como recuperaçāo de conta e senha.",
          actions: {
            add: "Adicionar outro endereço de E-mail"
          },
          modal: {
            newEmail: "Novo Email",
            error: "Esse Email já existe.",
            save: "Salvar",
            cancel: "Cancelar"
          }
        },
        phone: {
          label: "Telefone"
        },
        gender: {
          label: "Sexo"
        },
        shirt: {
          label: "Tamanho da camisa"
        },
        password: {
          label: "Senha",
          currentPassword: {
            label: "Senha atual"
          },
          password: {
            label: "Nova senha"
          },
          passwordConfirmation: {
            label: "Redigite nova senha"
          }
        },
        birthday: {
          label: "Data de nascimento"
        },
        distance: {
          label: "Distância"
        }
      },
      notificationSettings: {
        title: "Notificações",
        fullTitle: "",
        labels: {
          atTimeOfEvent: ""
        }
      },
      language: {
        title: "Língua"
      },
      communication: {
        title: "Notificações",
        description: "Ajuste essas preferências conforme desejado para fins de comunicaçāo.",
        push: {
          label: "No Splife",
          events: {
            label: "Eventos",
            description: "Deveríamos notificá-lo quando",
            participant: "Estou participando em",
            following: "Estou seguindo",
            friend: {
              participant: "Um amigo/a é participante de",
              liveMention: "Menções de amigos em eventos ao vivo"
            }
          },
          network: {
            label: "Rede",
            description: "Deveríamos notificá-lo quando",
            follower: "Seguidores",
            comments: "Comentários",
            posting: "Comentários ao vivo",
            invites: "Convites para eventos aceito"
          }
        },
        email: {
          label: "Email",
          events: {
            label: "Eventos",
            description: "Deveríamos notificá-lo quando",
            participant: "Sou participante em",
            following: "Estou seguindo",
            friend: {
              participant: "Um amigo/a meu é paticipante em",
              liveMention: "Menções de amigos em eventos ao vivo"
            }
          },
          network: {
            label: "Rede",
            description: "Deveríamos notificá-lo quando",
            follower: "Seguidores",
            comments: "Comentários",
            posting: "Comentários ao vivo",
            invites: "Convites para eventos aceito"
          }
        }
      },
      registration: {
        title: "Pagamento e Registro"
      }
    },
    report: {
      reportAbuse: "Reportar abuso",
      reportPrompt: {
        title: "Reportar abuso",
        confirm: "Sim",
        cancel: "Nāo"
      }
    },
    validations: {
      present: "Deve estar presente",
      date: "Deve ser uma data válida",
      email: "Deve ser um endereço de email vàlido",
      phone: "Deve ser um número de telefone válido",
      tooLong: "Muito longo (o máximo é {max} caracteres)",
      tooShort: "Muito curto (o mínimo é {min} caracteres)",
      confirmation: "Deve ser igual {{field}}",
      emailTaken: "Email já existe",
      password: "Senha",
      incorrectPassword: "A Senha está incorret"
    }
  };
});