define('social-web/pods/location/model', ['exports', 'ember-data/attr', 'social-web/pods/application/model'], function (exports, _attr, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    display: Ember.computed.alias('formattedAddress'),

    formattedAddress: (0, _attr.default)('string'),
    geocodeData: (0, _attr.default)({
      defaultValue: function defaultValue() {
        return {};
      }
    }),
    mapsLink: Ember.computed('geocodeData', function () {
      return this.get('geocodeData.place.url');
    })
  });
});