define("social-web/pods/components/mobile-menu/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 15,
            "column": 0
          }
        },
        "moduleName": "social-web/pods/components/mobile-menu/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "item");
        dom.setAttribute(el1, "id", "right-menu");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "text");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("i");
        dom.setAttribute(el3, "class", "big icon no-margin");
        var el4 = dom.createTextNode("\n      menu\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "user-account");
        dom.setAttribute(el1, "class", "top right link item");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [2]), 1, 1);
        return morphs;
      },
      statements: [["inline", "lazy-image", [], ["url", ["subexpr", "@mut", [["get", "session.currentUser.customPictureThumb", ["loc", [null, [10, 19], [10, 57]]], 0, 0, 0, 0]], [], [], 0, 0], "fallbackUrl", ["subexpr", "@mut", [["get", "media.defaults.profilePicture", ["loc", [null, [11, 16], [11, 45]]], 0, 0, 0, 0]], [], [], 0, 0], "alt", ["subexpr", "@mut", [["get", "session.currentUser.fullName", ["loc", [null, [12, 8], [12, 36]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "ui avatar image"], ["loc", [null, [10, 2], [13, 29]]], 0, 0]],
      locals: [],
      templates: []
    };
  }());
});