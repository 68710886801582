define('social-web/pods/group/adapter', ['exports', 'social-web/pods/application/adapter', 'social-web/mixins/followable-adapter'], function (exports, _adapter, _followableAdapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _adapter.default.extend(_followableAdapter.default, {
    // export default ApplicationAdapter.extend(MemberAdapterMixin, {
    checkUserMember: function checkUserMember(user, groups) {
      var queryParams = {
        scope: { model: user, action: 'group_member' },
        group_ids: groups.mapBy('id')
      };
      var buildURL = this.buildURL('group', user.id, null, null, queryParams);
      return this.ajax(buildURL, 'GET', { data: queryParams });
    }
  });
});