define('social-web/pods/vote-data/model', ['exports', 'ember-data', 'ember-data/attr', 'ember-data/relationships'], function (exports, _emberData, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    count: (0, _attr.default)('number'),
    currentUserVoted: (0, _attr.default)('boolean'),

    parent: (0, _relationships.belongsTo)('application', { polymorphic: true })
  });
});