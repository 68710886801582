define("social-web/pods/account/general/name/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 29,
                "column": 2
              },
              "end": {
                "line": 32,
                "column": 2
              }
            },
            "moduleName": "social-web/pods/account/general/name/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "t", ["common.save"], [], ["loc", [null, [31, 4], [31, 23]]], 0, 0]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 13,
              "column": 0
            },
            "end": {
              "line": 37,
              "column": 0
            }
          },
          "moduleName": "social-web/pods/account/general/name/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "row");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "row");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "class", "btn-round secondary waves-effect no-border white-text full-width-on-small-only cancel");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [7]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
          morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
          morphs[3] = dom.createElementMorph(element0);
          morphs[4] = dom.createMorphAt(element0, 1, 1);
          return morphs;
        },
        statements: [["inline", "md-input", [], ["value", ["subexpr", "@mut", [["get", "changeset.firstName", ["loc", [null, [15, 21], [15, 40]]], 0, 0, 0, 0]], [], [], 0, 0], "label", ["subexpr", "t", ["account.general.name.firstName.label"], [], ["loc", [null, [16, 20], [16, 62]]], 0, 0], "validate", true, "errors", ["subexpr", "@mut", [["get", "changeset.error.firstName.validation", ["loc", [null, [18, 21], [18, 57]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "white-text"], ["loc", [null, [15, 4], [19, 34]]], 0, 0], ["inline", "md-input", [], ["value", ["subexpr", "@mut", [["get", "changeset.lastName", ["loc", [null, [22, 21], [22, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "label", ["subexpr", "t", ["account.general.name.lastName.label"], [], ["loc", [null, [23, 20], [23, 61]]], 0, 0], "validate", true, "errors", ["subexpr", "@mut", [["get", "changeset.error.lastName.validation", ["loc", [null, [25, 21], [25, 56]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "white-text"], ["loc", [null, [22, 4], [26, 34]]], 0, 0], ["block", "ui-button", [["subexpr", "route-action", ["saveAndTransition", ["get", "changeset", ["loc", [null, [29, 49], [29, 58]]], 0, 0, 0, 0]], [], ["loc", [null, [29, 15], [29, 59]]], 0, 0]], ["class", "circular red fluid waves-effect confirm"], 0, null, ["loc", [null, [29, 2], [32, 16]]]], ["element", "action", [["subexpr", "route-action", ["transitionToParent"], [], ["loc", [null, [34, 19], [34, 54]]], 0, 0]], [], ["loc", [null, [34, 10], [34, 56]]], 0, 0], ["inline", "t", ["common.cancel"], [], ["loc", [null, [35, 4], [35, 25]]], 0, 0]],
        locals: ["changeset"],
        templates: [child0]
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 38,
            "column": 0
          }
        },
        "moduleName": "social-web/pods/account/general/name/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "ui basic segment no-left-padding");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h3");
        dom.setAttribute(el2, "class", "ui inverted upcase header");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row center-align");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 0, 0);
        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
        morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "t", ["account.general.name.label"], [], ["loc", [null, [3, 40], [3, 74]]], 0, 0], ["inline", "inline-lazy-image", [], ["url", ["subexpr", "@mut", [["get", "model.customPictureThumb", ["loc", [null, [7, 26], [7, 50]]], 0, 0, 0, 0]], [], [], 0, 0], "fallbackUrl", ["subexpr", "@mut", [["get", "media.defaults.profilePicture", ["loc", [null, [8, 34], [8, 63]]], 0, 0, 0, 0]], [], [], 0, 0], "alt", ["subexpr", "t", ["account.general.name.label"], [], ["loc", [null, [9, 26], [9, 58]]], 0, 0], "class", "ui small image avatar"], ["loc", [null, [7, 2], [10, 53]]], 0, 0], ["block", "with", [["subexpr", "changeset", [["get", "model", ["loc", [null, [13, 19], [13, 24]]], 0, 0, 0, 0], ["get", "model.validations", ["loc", [null, [13, 25], [13, 42]]], 0, 0, 0, 0]], [], ["loc", [null, [13, 8], [13, 43]]], 0, 0]], [], 0, null, ["loc", [null, [13, 0], [37, 9]]]]],
      locals: [],
      templates: [child0]
    };
  }());
});