define('social-web/pods/comment/model', ['exports', 'ember-data/attr', 'ember-data/relationships', 'social-web/pods/application/model'], function (exports, _attr, _relationships, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    session: Ember.inject.service(),

    body: (0, _attr.default)('string'),
    publishedAt: (0, _attr.default)('date'),

    publisher: (0, _relationships.belongsTo)('user'),
    statusUpdate: (0, _relationships.belongsTo)('status-update', { inverse: 'comments' }),
    parent: Ember.computed.alias('statusUpdate'),

    canEdit: Ember.computed('session.currentUser', function () {
      return this.get('publisher.content') === this.get('session.currentUser');
    }),
    canDelete: Ember.computed('session.currentUser', function () {
      return this.get('publisher.content') === this.get('session.currentUser');
    }),

    report: function report() {
      return this.get('adapter').report(this);
    }
  });
});