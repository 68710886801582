define('social-web/pods/event/show/edition/show/about/index/controller', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    //TODO replace during reconciliation if possible
    competitions: Ember.computed('model.id', function () {
      return this.get('store').query('competition', {
        scope: {
          model: this.get('model'),
          action: 'competitions'
        }
      });
    }),
    schedule: Ember.computed('model.id', 'model.editionActivities.@each.start', 'model.competitions.@each.start', function () {
      if (this.get('model.editionActivities').isEvery('start') && this.get('model.competitions').isEvery('start')) {
        var items = new Ember.A();
        items.pushObjects(this.get('model.editionActivities').toArray());
        items.pushObjects(this.get('model.competitions').toArray());
        var sortedDates = items.map(function (item) {
          var date = (0, _moment.default)(Ember.get(item, 'start')).startOf('day');
          return {
            date: date,
            string: date.format('LL')
          };
        }).sort(function (a, b) {
          var momentA = (0, _moment.default)(a.date);
          var momentB = (0, _moment.default)(b.date);
          if (momentA.isBefore(momentB)) {
            return -1;
          } else if (momentA.isAfter(momentB)) {
            return 1;
          } else {
            return 0;
          }
        }).uniqBy('string');

        var comparer = function comparer(property, date) {
          return function (item) {
            return (0, _moment.default)(Ember.get(item, property)).startOf('day').format('LL') === date.format('LL');
          };
        };

        return sortedDates.map(function (hash) {
          return {
            date: hash.date,
            items: items.filter(comparer('start', hash.date)).sort(function (a, b) {
              var momentA = (0, _moment.default)(Ember.get(a, 'start'));
              var momentB = (0, _moment.default)(Ember.get(b, 'start'));
              if (momentA.isBefore(momentB)) {
                return -1;
              } else if (momentA.isAfter(momentB)) {
                return 1;
              } else {
                return 0;
              }
            })
          };
        });
      }
    })
  });
});