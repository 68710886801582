define("social-web/pods/components/basic-select/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 4,
              "column": 0
            }
          },
          "moduleName": "social-web/pods/components/basic-select/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          return morphs;
        },
        statements: [["content", "label", ["loc", [null, [3, 9], [3, 18]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 16,
                "column": 2
              },
              "end": {
                "line": 18,
                "column": 2
              }
            },
            "moduleName": "social-web/pods/components/basic-select/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "yield", [["get", "model", ["loc", [null, [17, 12], [17, 17]]], 0, 0, 0, 0]], [], ["loc", [null, [17, 4], [17, 19]]], 0, 0]],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 18,
                "column": 2
              },
              "end": {
                "line": 20,
                "column": 2
              }
            },
            "moduleName": "social-web/pods/components/basic-select/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["content", "model.display", ["loc", [null, [19, 4], [19, 21]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 0
            },
            "end": {
              "line": 21,
              "column": 0
            }
          },
          "moduleName": "social-web/pods/components/basic-select/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "hasBlock", ["loc", [null, [16, 8], [16, 16]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [16, 2], [20, 9]]]]],
        locals: ["model"],
        templates: [child0, child1]
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 22,
            "column": 0
          }
        },
        "moduleName": "social-web/pods/components/basic-select/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "label", ["loc", [null, [2, 6], [2, 11]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [2, 0], [4, 7]]]], ["block", "power-select", [], ["placeholder", ["subexpr", "@mut", [["get", "placeholder", ["loc", [null, [5, 28], [5, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "searchPlaceholder", ["subexpr", "@mut", [["get", "searchPlaceholder", ["loc", [null, [6, 34], [6, 51]]], 0, 0, 0, 0]], [], [], 0, 0], "noMatchesMessage", ["subexpr", "or", [["get", "noMatchesMessages", ["loc", [null, [7, 37], [7, 54]]], 0, 0, 0, 0], ["subexpr", "t", ["common.search.noResults"], [], ["loc", [null, [7, 55], [7, 84]]], 0, 0]], [], ["loc", [null, [7, 33], [7, 85]]], 0, 0], "allowClear", ["subexpr", "@mut", [["get", "allowClear", ["loc", [null, [8, 27], [8, 37]]], 0, 0, 0, 0]], [], [], 0, 0], "options", ["subexpr", "@mut", [["get", "options", ["loc", [null, [9, 24], [9, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "selected", ["subexpr", "@mut", [["get", "selected", ["loc", [null, [10, 25], [10, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "renderInPlace", ["subexpr", "@mut", [["get", "renderInPlace", ["loc", [null, [11, 30], [11, 43]]], 0, 0, 0, 0]], [], [], 0, 0], "search", ["subexpr", "if", [["get", "modelName", ["loc", [null, [12, 27], [12, 36]]], 0, 0, 0, 0], ["subexpr", "action", ["search"], [], ["loc", [null, [12, 37], [12, 54]]], 0, 0]], [], ["loc", [null, [12, 23], [12, 55]]], 0, 0], "searchEnabled", ["subexpr", "@mut", [["get", "modelName", ["loc", [null, [13, 30], [13, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "onchange", ["subexpr", "action", ["setSelected"], [], ["loc", [null, [14, 25], [14, 47]]], 0, 0], "oninput", ["subexpr", "if", [["get", "modelName", ["loc", [null, [15, 28], [15, 37]]], 0, 0, 0, 0], ["subexpr", "action", ["clear"], [], ["loc", [null, [15, 38], [15, 54]]], 0, 0]], [], ["loc", [null, [15, 24], [15, 55]]], 0, 0]], 1, null, ["loc", [null, [5, 0], [21, 17]]]]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});