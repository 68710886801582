define('social-web/pods/event/show/edition/show/controller', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),

    actions: {
      searchEditions: function searchEditions(editions) {
        return editions.map(function (edition) {
          var startDate = edition.get('startDate');
          startDate = startDate && (0, _moment.default)(startDate).format('LL');
          return {
            title: edition.get('name'),
            description: startDate,
            id: edition.get('slug'),
            liveURL: edition.get('liveURL')
          };
        });
      },
      routeToEdition: function routeToEdition(edition) {
        this.transitionToRoute('event.show.edition.show', edition.id);
        return true;
      },
      searchCompetitions: function searchCompetitions(competitions) {
        var _this = this;

        return new Ember.RSVP.Promise(function (resolve) {
          _this.get('store').query('participation', {
            scope: { model: _this.get('session.currentUser'), action: 'participations' },
            competition_ids: competitions.mapBy('id')
          }).then(function (participations) {
            participations.forEach(function (participation) {
              var participatingCompetition = competitions.findBy('id', participation.get('competition.id'));
              if (participatingCompetition) {
                participatingCompetition.set('currentUserParticipation', participation);
              }
            });
            resolve(competitions);
          }, function () {
            resolve(competitions);
          });
        });
      }
    }
  });
});