define('social-web/mirage/factories/sponsor-category', ['exports', 'ember-cli-mirage', 'social-web/mirage/factories/application'], function (exports, _emberCliMirage, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    name: function name() {
      return _emberCliMirage.faker.company.companyName();
    },
    sortPriority: function sortPriority(n) {
      return n;
    },
    afterCreate: function afterCreate(sponsorCategory, server) {
      if (!sponsorCategory.edition) {
        sponsorCategory.edition = server.create('edition');
      }
      if (!sponsorCategory.sponsors.models.length) {
        server.createList('sponsor', sponsorCategory.sponsorsCount === undefined ? _emberCliMirage.faker.random.number({ min: 1, max: 5 }) : sponsorCategory.sponsorsCount, { sponsorCategory: sponsorCategory });
      }
      sponsorCategory.save();
    }
  });
});