define('social-web/pods/components/action-modal/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    modalClassNames: ['action-modal'],
    actions: {
      handleAction: function handleAction(actionHash) {
        var _this = this;

        var result = actionHash.action.apply(actionHash.context || this, actionHash.args);
        if (result && result.then) {
          result.then(function (close) {
            if (close) {
              _this.set('visible');
            }
          });
        } else if (result) {
          this.set('visible');
        }
      }
    }
  });
});