define('social-web/pods/series-edition/model', ['exports', 'ember-data/attr', 'ember-data/relationships', 'social-web/pods/application/model'], function (exports, _attr, _relationships, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    slug: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    startDate: (0, _attr.default)('date'),
    endDate: (0, _attr.default)('date'),

    editionsCount: (0, _attr.default)('number'),

    series: (0, _relationships.belongsTo)(),
    location: (0, _relationships.belongsTo)(),
    editions: (0, _relationships.hasMany)()
  });
});