define("social-web/pods/components/media-upload/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 0
            },
            "end": {
              "line": 14,
              "column": 0
            }
          },
          "moduleName": "social-web/pods/components/media-upload/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "class", "bg-image media white-text narrow btn-flat");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "material-icons");
          var el3 = dom.createTextNode("close");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element0, 'style');
          morphs[1] = dom.createElementMorph(element0);
          return morphs;
        },
        statements: [["attribute", "style", ["subexpr", "stylize", [], ["background-image", ["subexpr", "join", ["", ["subexpr", "array", ["url(", ["get", "m.fileMed", ["loc", [null, [10, 116], [10, 125]]], 0, 0, 0, 0], ")"], [], ["loc", [null, [10, 102], [10, 130]]], 0, 0]], [], ["loc", [null, [10, 93], [10, 131]]], 0, 0]], ["loc", [null, [null, null], [10, 133]]], 0, 0], 0, 0, 0, 0], ["element", "action", ["removeMedia", ["get", "m", ["loc", [null, [11, 33], [11, 34]]], 0, 0, 0, 0]], [], ["loc", [null, [11, 10], [11, 36]]], 0, 0]],
        locals: ["m"],
        templates: []
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 16,
              "column": 0
            },
            "end": {
              "line": 18,
              "column": 0
            }
          },
          "moduleName": "social-web/pods/components/media-upload/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "class", "ui btn-flat white loading button");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 29,
            "column": 0
          }
        },
        "moduleName": "social-web/pods/components/media-upload/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("button");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("i");
        dom.setAttribute(el2, "class", "material-icons");
        var el3 = dom.createTextNode("add_a_photo");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [2]);
        var morphs = new Array(5);
        morphs[0] = dom.createAttrMorph(element1, 'class');
        morphs[1] = dom.createElementMorph(element1);
        morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        morphs[3] = dom.createMorphAt(fragment, 6, 6, contextualElement);
        morphs[4] = dom.createMorphAt(fragment, 8, 8, contextualElement);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["narrow grey-text btn-flat ", ["subexpr", "unless", [["get", "saving", ["loc", [null, [4, 50], [4, 56]]], 0, 0, 0, 0], "waves-effect waves-red", "disabled"], [], ["loc", [null, [4, 41], [4, 94]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["selectMedia"], [], ["loc", [null, [5, 8], [5, 32]]], 0, 0], ["block", "each", [["get", "media", ["loc", [null, [9, 8], [9, 13]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [9, 0], [14, 9]]]], ["block", "if", [["get", "loading", ["loc", [null, [16, 6], [16, 13]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [16, 0], [18, 7]]]], ["inline", "file-upload", [], ["accept", ["subexpr", "@mut", [["get", "accept", ["loc", [null, [20, 21], [20, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "name", "data[attributes][file]", "onUpload", ["subexpr", "action", ["setLoading", true], [], ["loc", [null, [22, 23], [22, 49]]], 0, 0], "onError", ["subexpr", "action", ["setLoading", false], [], ["loc", [null, [23, 22], [23, 49]]], 0, 0], "onProgress", ["subexpr", "action", ["setProgress", ["get", "progress", ["loc", [null, [24, 47], [24, 55]]], 0, 0, 0, 0]], [], ["loc", [null, [24, 25], [24, 56]]], 0, 0], "onFinish", ["subexpr", "action", [["subexpr", "queue", [["subexpr", "action", ["addMedia"], [], ["loc", [null, [26, 16], [26, 35]]], 0, 0], ["subexpr", "action", ["setLoading", false], [], ["loc", [null, [27, 16], [27, 43]]], 0, 0]], [], ["loc", [null, [25, 31], [28, 15]]], 0, 0]], [], ["loc", [null, [25, 23], [28, 16]]], 0, 0]], ["loc", [null, [20, 0], [28, 18]]], 0, 0]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});