define('social-web/pods/onboarding/signin/route', ['exports', 'social-web/mixins/reset-scroll'], function (exports, _resetScroll) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_resetScroll.default, {
    actions: {
      onSuccess: function onSuccess() {
        if (sessionStorage.getItem('redirectPath')) {
          this.transitionTo(sessionStorage.getItem('redirectPath'));
        } else {
          this.controllerFor('application').set('innerAppLayout', true);
          this.transitionTo('feed');
        }
      }
    },

    setupController: function setupController() {
      this.controllerFor('application').set('innerAppLayout', false);
    }
  });
});