define('social-web/pods/account/general/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    i18n: Ember.inject.service(),
    session: Ember.inject.service(),

    model: function model() {
      return this.modelFor('account');
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      var password = this.store.createRecord('password');
      password.send('willCommit');
      password.send('didCommit');

      controller.setProperties({
        password: password,
        collapsibles: {}
      });
    },
    afterModel: function afterModel(model) {
      if (this.get('controller')) {
        this.setupController(this.get('controller'), model);
      }
    },

    actions: {
      savePassword: function savePassword(changeset, model) {
        var _this = this;

        return new Ember.RSVP.Promise(function (resolve, reject) {
          changeset.validate().then(function () {
            if (changeset.get('isValid')) {
              changeset.save({
                adapterOptions: { registrationID: _this.get('session.currentUser.id') }
              }).then(function () {
                _this.get('session').authenticate('authenticator:application', _this.get('session.currentUser.email'), model.get('password')).then(function () {
                  model.unloadRecord();
                  resolve();
                });
              }, function (_ref) {
                var errors = _ref.errors;

                if (errors && errors.length && errors[0].status === '403') {
                  changeset.pushErrors('currentPassword', _this.get('i18n').t('validations.incorrectPassword'));
                  reject();
                }
              });
            }
          });
        });
      },
      willTransition: function willTransition() {
        var _get$getProperties = this.get('controller').getProperties('password'),
            password = _get$getProperties.password;

        if (!password.get('isDestroyed')) {
          password.unloadRecord();
        }
      }
    }
  });
});