define('social-web/pods/account/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),

    model: function model() {
      return this.get('session.currentUser');
    },

    actions: {
      collapse: function collapse(expanderObject, index) {
        for (var prop in expanderObject) {
          if (prop !== index + '') {
            Ember.set(expanderObject, prop, false);
          }
        }
      },
      saveAndTransition: function saveAndTransition(changeset) {
        var _this = this;

        changeset.validate();
        if (changeset.get('isValid')) {
          return changeset.save().then(function () {
            _this.send('transitionToParent');
          }, function () {});
        }
      }
    }
  });
});