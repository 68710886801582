define("social-web/pods/components/notification-dropdown/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 15,
                  "column": 6
                },
                "end": {
                  "line": 38,
                  "column": 6
                }
              },
              "moduleName": "social-web/pods/components/notification-dropdown/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "content no-padding");
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "header");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "description");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "description timestamp");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element4 = dom.childAt(fragment, [3]);
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[1] = dom.createMorphAt(dom.childAt(element4, [1]), 0, 0);
              morphs[2] = dom.createMorphAt(dom.childAt(element4, [3]), 0, 0);
              morphs[3] = dom.createMorphAt(dom.childAt(element4, [5]), 0, 0);
              return morphs;
            },
            statements: [["inline", "inline-lazy-image", [], ["fallbackUrl", ["subexpr", "@mut", [["get", "media.defaults.profilePicture", ["loc", [null, [29, 40], [29, 69]]], 0, 0, 0, 0]], [], [], 0, 0], "url", ["subexpr", "@mut", [["get", "notification.notificator.customPictureThumb", ["loc", [null, [30, 32], [30, 75]]], 0, 0, 0, 0]], [], [], 0, 0], "alt", ["subexpr", "@mut", [["get", "notification.notificator.fullName", ["loc", [null, [31, 32], [31, 65]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "ui huge avatar image"], ["loc", [null, [29, 8], [32, 58]]], 0, 0], ["content", "notification.notificator.fullName", ["loc", [null, [34, 30], [34, 67]]], 0, 0, 0, 0], ["inline", "truncate", [["get", "notification.formattedMessage", ["loc", [null, [35, 46], [35, 75]]], 0, 0, 0, 0], 200], [], ["loc", [null, [35, 35], [35, 81]]], 0, 0], ["inline", "date-label", [["get", "notification.createdAt", ["loc", [null, [36, 58], [36, 80]]], 0, 0, 0, 0]], [], ["loc", [null, [36, 45], [36, 82]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 14,
                "column": 4
              },
              "end": {
                "line": 39,
                "column": 4
              }
            },
            "moduleName": "social-web/pods/components/notification-dropdown/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "link-to", [["subexpr", "if", [["get", "notification.linkTo.post", ["loc", [null, [16, 12], [16, 36]]], 0, 0, 0, 0], "status-update", ["subexpr", "if", [["get", "notification.linkTo.edition", ["loc", [null, [17, 14], [17, 41]]], 0, 0, 0, 0], "edition.show", ["subexpr", "if", [["get", "notification.linkTo.user", ["loc", [null, [18, 16], [18, 40]]], 0, 0, 0, 0], "user.show"], [], ["loc", [null, [18, 12], [18, 53]]], 0, 0]], [], ["loc", [null, [17, 10], [19, 11]]], 0, 0]], [], ["loc", [null, [16, 8], [20, 9]]], 0, 0], ["subexpr", "if", [["get", "notification.linkTo.post", ["loc", [null, [21, 12], [21, 36]]], 0, 0, 0, 0], ["get", "notification.statusUpdateId", ["loc", [null, [21, 37], [21, 64]]], 0, 0, 0, 0], ["subexpr", "if", [["get", "notification.linkTo.edition", ["loc", [null, [22, 14], [22, 41]]], 0, 0, 0, 0], ["get", "notification.editionId", ["loc", [null, [22, 42], [22, 64]]], 0, 0, 0, 0], ["subexpr", "if", [["get", "notification.linkTo.user", ["loc", [null, [23, 16], [23, 40]]], 0, 0, 0, 0], ["get", "notification.notificator.slug", ["loc", [null, [23, 41], [23, 70]]], 0, 0, 0, 0]], [], ["loc", [null, [23, 12], [23, 71]]], 0, 0]], [], ["loc", [null, [22, 10], [24, 11]]], 0, 0]], [], ["loc", [null, [21, 8], [25, 9]]], 0, 0]], ["invokeAction", ["subexpr", "action", [["subexpr", "invoke", ["markAsRead", ["get", "notification", ["loc", [null, [26, 50], [26, 62]]], 0, 0, 0, 0]], [], ["loc", [null, [26, 29], [26, 63]]], 0, 0]], [], ["loc", [null, [26, 21], [26, 64]]], 0, 0], "activeClass", "", "class", ["subexpr", "if", [["subexpr", "not", [["get", "notification.read", ["loc", [null, [28, 23], [28, 40]]], 0, 0, 0, 0]], [], ["loc", [null, [28, 18], [28, 41]]], 0, 0], "active item", "item"], [], ["loc", [null, [28, 14], [28, 63]]], 0, 0]], 0, null, ["loc", [null, [15, 6], [38, 18]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 39,
                "column": 4
              },
              "end": {
                "line": 52,
                "column": 4
              }
            },
            "moduleName": "social-web/pods/components/notification-dropdown/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1, "href", "");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "content no-padding");
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "header");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "description");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "description timestamp");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var element3 = dom.childAt(element2, [3]);
            var morphs = new Array(6);
            morphs[0] = dom.createAttrMorph(element2, 'class');
            morphs[1] = dom.createElementMorph(element2);
            morphs[2] = dom.createMorphAt(element2, 1, 1);
            morphs[3] = dom.createMorphAt(dom.childAt(element3, [1]), 0, 0);
            morphs[4] = dom.createMorphAt(dom.childAt(element3, [3]), 0, 0);
            morphs[5] = dom.createMorphAt(dom.childAt(element3, [5]), 0, 0);
            return morphs;
          },
          statements: [["attribute", "class", ["subexpr", "if", [["subexpr", "not", [["get", "notification.read", ["loc", [null, [41, 25], [41, 42]]], 0, 0, 0, 0]], [], ["loc", [null, [41, 20], [41, 43]]], 0, 0], "active item", "item"], [], ["loc", [null, [null, null], [41, 66]]], 0, 0], 0, 0, 0, 0], ["element", "action", [["subexpr", "invoke", ["markAsRead", ["get", "notification", ["loc", [null, [40, 44], [40, 56]]], 0, 0, 0, 0]], [], ["loc", [null, [40, 23], [40, 57]]], 0, 0]], [], ["loc", [null, [40, 14], [40, 59]]], 0, 0], ["inline", "inline-lazy-image", [], ["fallbackUrl", ["subexpr", "@mut", [["get", "media.defaults.profilePicture", ["loc", [null, [42, 40], [42, 69]]], 0, 0, 0, 0]], [], [], 0, 0], "url", ["subexpr", "@mut", [["get", "notification.notificator.customPictureThumb", ["loc", [null, [43, 32], [43, 75]]], 0, 0, 0, 0]], [], [], 0, 0], "alt", ["subexpr", "@mut", [["get", "notification.notificator.fullName", ["loc", [null, [44, 32], [44, 65]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "ui huge avatar image"], ["loc", [null, [42, 8], [45, 58]]], 0, 0], ["content", "notification.notificator.fullName", ["loc", [null, [47, 30], [47, 67]]], 0, 0, 0, 0], ["content", "notification.message", ["loc", [null, [48, 35], [48, 59]]], 0, 0, 0, 0], ["inline", "date-label", [["get", "notification.createdAt", ["loc", [null, [49, 58], [49, 80]]], 0, 0, 0, 0]], [], ["loc", [null, [49, 45], [49, 82]]], 0, 0]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 13,
              "column": 2
            },
            "end": {
              "line": 53,
              "column": 2
            }
          },
          "moduleName": "social-web/pods/components/notification-dropdown/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "notification.linkTo", ["loc", [null, [14, 10], [14, 29]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [14, 4], [52, 11]]]]],
        locals: ["notification"],
        templates: [child0, child1]
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 53,
              "column": 2
            },
            "end": {
              "line": 65,
              "column": 2
            }
          },
          "moduleName": "social-web/pods/components/notification-dropdown/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "id", "empty");
          dom.setAttribute(el1, "class", "centered item");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("h2");
          dom.setAttribute(el2, "class", "ui icon header no-margin");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("i");
          dom.setAttribute(el3, "class", "icon no-margin");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "content no-padding");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "sub header");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1, 1]);
          var element1 = dom.childAt(element0, [3]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(element1, 1, 1);
          morphs[2] = dom.createMorphAt(dom.childAt(element1, [3]), 1, 1);
          return morphs;
        },
        statements: [["inline", "or", ["notifications_none"], [], ["loc", [null, [56, 34], [56, 61]]], 0, 0], ["inline", "t", ["nav.notifications.empty.title"], [], ["loc", [null, [58, 10], [58, 47]]], 0, 0], ["inline", "t", ["nav.notifications.empty.description"], [], ["loc", [null, [60, 12], [60, 55]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    var child2 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 67,
              "column": 2
            },
            "end": {
              "line": 73,
              "column": 2
            }
          },
          "moduleName": "social-web/pods/components/notification-dropdown/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "spinner-widget", [], ["class", "spinner-wrapper"], ["loc", [null, [72, 4], [72, 46]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 75,
            "column": 0
          }
        },
        "moduleName": "social-web/pods/components/notification-dropdown/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "text hide-on-mobile");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("i");
        dom.setAttribute(el2, "class", "big icon no-margin");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "notifications");
        dom.setAttribute(el1, "class", "raised inverted dark-grey-i menu ui list");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "first header");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h5");
        dom.setAttribute(el3, "class", "ui inverted header no-margin");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "divider no-margin");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element5 = dom.childAt(fragment, [3]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element5, [1, 1]), 0, 0);
        morphs[2] = dom.createMorphAt(element5, 5, 5);
        morphs[3] = dom.createMorphAt(element5, 7, 7);
        return morphs;
      },
      statements: [["inline", "if", [["subexpr", "and", [["subexpr", "not", [["get", "markedSeen", ["loc", [null, [4, 19], [4, 29]]], 0, 0, 0, 0]], [], ["loc", [null, [4, 14], [4, 30]]], 0, 0], ["get", "notifications.meta.unseen-count", ["loc", [null, [4, 31], [4, 62]]], 0, 0, 0, 0]], [], ["loc", [null, [4, 9], [4, 63]]], 0, 0], "notifications", "notifications_none"], [], ["loc", [null, [4, 4], [4, 102]]], 0, 0], ["inline", "t", ["nav.notifications.title"], [], ["loc", [null, [10, 45], [10, 76]]], 0, 0], ["block", "each", [["get", "notifications", ["loc", [null, [13, 10], [13, 23]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [13, 2], [65, 11]]]], ["block", "infinity-loader", [], ["infinityModel", ["subexpr", "@mut", [["get", "notifications", ["loc", [null, [67, 35], [67, 48]]], 0, 0, 0, 0]], [], [], 0, 0], "scrollable", "#notifications", "_firstPageLoaded", ["subexpr", "@mut", [["get", "_firstPageLoaded", ["loc", [null, [69, 38], [69, 54]]], 0, 0, 0, 0]], [], [], 0, 0], "_loadingMore", ["subexpr", "@mut", [["get", "_loadingMore", ["loc", [null, [70, 34], [70, 46]]], 0, 0, 0, 0]], [], [], 0, 0], "_selfOffset", ["subexpr", "@mut", [["get", "selfOffset", ["loc", [null, [71, 33], [71, 43]]], 0, 0, 0, 0]], [], [], 0, 0]], 2, null, ["loc", [null, [67, 2], [73, 22]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  }());
});